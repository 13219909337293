import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
  Paper,
  Grid,
} from "@mui/material";
import { getCookie } from "../../utils/utils";
//import { createFilterOptions } from "@mui/material/Autocomplete";

const ContactNumber = forwardRef(
  (
    {
      title,
      onValueChange,
      onCountrySelect,
      preFilledvalue,
      preFilledcode,
      defaultCountry = "United Kingdom +44",
      contactExistsError = "",
    },
    ref
  ) => {
    const [value, setValue] = useState(preFilledvalue || "");
    const [country, setCountry] = useState("");
    const [inputCountry, setInputCountry] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [originalCountries, setOriginalCountries] = useState([]);

    useEffect(() => {
      setValue(preFilledvalue || "");
      setErrorMessage("");
    }, [preFilledvalue]);

    useEffect(() => {
      // Fetch countries and set the default or prefilled country
      const fetchCountries = async () => {
        try {
          const token = getCookie();
          const URL = `${process.env.REACT_APP_BASE_URL}/countries`;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await fetch(URL, options);
          const data = await response.json();

          if (response.ok) {
            const modifiedCountryList = data.data.map((each) => ({
              ...each,
              id: each.id,
              text: each.countries,
              value: each.countries,
              code: each.country_code,
            }));
            setOriginalCountries(modifiedCountryList);

            // Determine the initial country based on preFilledcode or defaultCountry
            const initialCountry =
              modifiedCountryList.find(
                (option) => option.code === preFilledcode
              ) ||
              modifiedCountryList.find(
                (option) => option.text === defaultCountry
              ) ||
              null;

            setCountry(initialCountry || "");
            setInputCountry(initialCountry?.text || "");
          } else {
            setOriginalCountries([]);
            setCountry("");
            setInputCountry("");
          }
        } catch (e) {
          console.error("Error fetching countries:", e.message);
          setOriginalCountries([]);
          setCountry("");
          setInputCountry("");
        }
      };

      fetchCountries();
    }, [preFilledcode]);

    useEffect(() => {
      if (contactExistsError) {
        setErrorMessage(contactExistsError);
      }
    }, [contactExistsError]);

    // Function to handle changes in the contact number field
    const handleChange = (event) => {
      const inputValue = event.target.value;
      const sanitizedValue = sanitizeInput(inputValue);
      setValue(sanitizedValue);
      setErrorMessage(""); // Clear error message when country is selected
      onValueChange(sanitizedValue); // Notify parent component about value change
    };

    // Function to handle changes in the country selection
    const handleCountryChange = (e, newCountry) => {
      setCountry(newCountry);
      setErrorMessage(""); // Clear error message when country is selected
      onCountrySelect(newCountry); // Pass the selected country to the parent
    };

    // Function to sanitize input by allowing only digits
    const sanitizeInput = (input) => {
      return input.replace(/[^0-9]/g, ""); // Remove non-digit characters
    };

    // Function to validate the input
    const handleValidation = () => {
      const strValue = String(value).trim(); // Ensure value is treated as a string
      if (!strValue) {
        setErrorMessage("Contact Number is required.");
        return false;
      }
      if (!/^[0-9]\d{9,}$/.test(strValue)) {
        // Validate that it is a 10-digit number not starting with 0
        setErrorMessage("Please enter a valid Contact Number.");
        return false;
      }
      if (!country) {
        setErrorMessage("Country Code is required.");
        return false;
      }
      setErrorMessage("");
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            {title || "Contact Number"}
            <span style={{ color: "#279989", fontSize: "16px" }}>*</span>
          </Typography>
        </FormLabel>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            border: "1.5px solid #2799894d",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            margin: "10px 0",
            "&:focus-within": {
              borderColor: "#27998999", // Border color when a child element is focused
            },
          }}
        >
          <Box sx={{ width: "45px", marginRight: "25px" }}>
            <Autocomplete
              value={country}
              onChange={handleCountryChange}
              inputValue={inputCountry}
              onInputChange={(e, newInputCountry) =>
                setInputCountry(newInputCountry)
              }
              PaperComponent={(paperProps) => (
                <Paper {...paperProps} sx={{ width: "270px" }} />
              )}
              options={originalCountries}
              getOptionLabel={(option) => option?.code || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box key={key} component="li" {...optionProps}>
                    {option.text}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder=""
                  sx={{
                    margin: "0 10px 0 2px", // Adjust margin
                    "& .MuiInputBase-root": {
                      height: "26px",
                      backgroundColor: "#eefefa",
                      borderRadius: "5px 0 0 5px",
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              placeholder={title || "Contact Number"}
              value={value}
              onChange={handleChange}
              sx={{
                margin: "0 0px", // Adjust margin
                "& .MuiInputBase-root": {
                  border: "none !important",
                  height: "30px",
                },
                "& .MuiInputBase-root.Mui-focused": {
                  border: "1px solid blue",
                },
              }}
              inputProps={{ maxLength: 11 }} // Limit input length to 10 characters
            />
          </Box>
        </Box>
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default ContactNumber;
