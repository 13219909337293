import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Box,
  FormLabel,
  FormControlLabel,
  Slider,
  Card,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Name } from "../../reusableComponents"; // Adjust the path
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme, ViewTheme } from "../../users/Theme";
import { getCookie } from "../../../utils/utils";
// import { DropzoneArea } from "mui-file-dropzone";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import TaskIcon from "@mui/icons-material/Task";
import axios from "axios";

export default function UploadDocumentDialog({
  open,
  onClose,
  clientId,
  uploadFiles,
  setUploadFiles,
}) {
  const [finalForm, setFinalForm] = useState([]);

  const [fileSizeAlert, setFileSizeAlert] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null); // Single file state
  const [documentName, setDocumentName] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (!finalForm[0].document_name.trim()) {
    //   alert("Please enter a document name.");
    //   return;
    // }
    // if (!selectedFile) {
    //   alert("Please select a file before submitting.");
    //   return;
    // }

    try {
      await uploadFile(selectedFile, finalForm);
      //alert("File uploaded successfully!");
      setSelectedFile(null);
      setDocumentName("");
      onClose();
    } catch (error) {
      console.error("Error uploading file:", error);
      //alert("Failed to upload file.");
    }
  };

  const uploadFile = async (file, finalForm) => {
    // const formData = new FormData();
    // formData.append("client_id", clientId);
    // formData.append("document_name", finalForm[0].document_name);
    // formData.append("file", file);
    // formData.append("additional_info", finalForm[0].additional_info);

    const formData = {
      ...finalForm[0],
      client_id: clientId,
      file: file,
    };

    console.log("before post payload", formData);

    const token = getCookie();

    const response = await fetch(
      "https://3.8.158.142:8443/api/careplan-add-document",
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      console.error("Upload error:", errorData);
      throw new Error(errorData.message || "Something went wrong!");
    }

    const responseData = await response.json();
    console.log("File uploaded successfully:", responseData);
  };

  const CustomDropzone = () => {
    const onDrop = (acceptedFiles) => {
      const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0); // Calculate total size of all files

      if (totalSize > 5 * 1024 * 1024) {
        console.log(
          "Total file size exceeds 5MB. Please upload smaller files."
        );
        setFileSizeAlert(true);
        return; // Exit if the total size exceeds the limit
      }

      setFileSizeAlert(false);

      setFinalForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : [{}];
        updatedForm[0] = {
          ...updatedForm[0],
          files: [...(updatedForm[0].files || []), ...acceptedFiles], // Add File objects directly
        };
        return updatedForm;
      });
      //setFinalForm((prevState) => [...prevState, ...acceptedFiles]);
      //setUploadFiles((prevState) => [...prevState, ...acceptedFiles]);
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      noClick: true, // Disable default click behavior
      noKeyboard: true, // Disable keyboard focus behavior
    });
    useEffect(() => {
      console.log("Upload file final List", uploadFiles);
    }, [uploadFiles]);

    return (
      <Box
        {...getRootProps()}
        sx={{
          p: 2,
          border: `2px dashed ${isDragActive ? "#279989" : "#2799894d"}`,
          backgroundColor: isDragActive ? "#27998912" : "#FFFFFF",
          borderRadius: "10px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          position: "relative",
          // cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <FileUploadIcon
          sx={{ fontSize: "35px", color: "#279989" }}
        ></FileUploadIcon>
        <Typography variant="h6" gutterBottom>
          Drag and Drop here
        </Typography>
        <Typography variant="body2" color="textSecondary">
          or
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#279989",
            fontWeight: "bold",
            cursor: "pointer", // To indicate it's clickable
          }}
          onClick={() => {
            open();
            setFileSizeAlert(false);
          }} // This will trigger the open function when clicked
        >
          Browse files
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#279989",
            fontWeight: "300",
          }}
        >
          Maximum size 5MB
        </Typography>
      </Box>
    );
  };

  const handleValueChange = (value, name) => {
    setFinalForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [""]; // Initialize if empty
      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };
      // handleChange(updatedForm);
      return updatedForm;
    });
  };

  useEffect(() => {
    console.log("final form data", finalForm);

    //console.log("path:", finalForm[0].files[0].path);
  }, [finalForm]);
  return (
    <ThemeProvider theme={EditTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={false} // Disable default max-width behavior
        sx={{
          "& .MuiDialog-paper": {
            width: "auto", // Allow width to adjust automatically
            maxWidth: "100%", // Prevent exceeding viewport width
            // margin: "10px", // Add some margin around the dialog
          },
        }}
      >
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            width: "28px ! important",
            height: "28px ! important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent
          sx={{
            background: "#FFFFFF",
            paddingInline: "30px",
          }}
        >
          <Box>
            <Grid
              container
              spacing={2}
              p={3}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                paddingInline: "100px",
                width: "50vw",
              }}
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  textAlign: "center",
                }}
              >
                <TaskIcon sx={{ fontSize: "35px", color: "#279989" }} />
                <Typography variant="h3">Upload Documents</Typography>
              </Grid>
              <Grid item width="80%" lg={10} sm={12}>
                <Name
                  name="document_name"
                  label="Document Name"
                  placeholder="Document Name"
                  onValueChange={handleValueChange}
                  required
                />
              </Grid>
              <Grid item width="80%" lg={10} sm={12}>
                <Name
                  className="multiline-textfield"
                  name="additional_info"
                  label="Additional Info"
                  multiline
                  rows="4"
                  onValueChange={handleValueChange}
                />
              </Grid>
              <Grid item width="80%" lg={10} sm={12}>
                <Typography variant="h6">Attach Documents</Typography>
                {/* <CustomDropzone /> */}
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{ marginTop: 2 }}
                >
                  Choose File
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="application/pdf"
                    hidden
                  />
                </Button>
              </Grid>

              <Grid item sx={{ width: "80%" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#279989",
                    fontWeight: "300",
                  }}
                >
                  ***Accepted All Images and Documents Formats
                </Typography>
              </Grid>
              <Grid item width="80%">
                {/* {uploadFiles && uploadFiles.length > 0 ? ( */}

                <Typography variant="h6">File Added</Typography>

                <Box
                  sx={{
                    backgroundColor: "#27998912",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    padding: "20px",
                    height: "20vh", // Restrict height to make it scrollable
                    overflow: "scroll", // Enable vertical scrolling
                    scrollbarWidth: "none", // For Firefox
                    "-ms-overflow-style": "none", // For Internet Explorer and Edge
                    "&::-webkit-scrollbar": {
                      display: "none", // For Chrome, Safari, and Opera
                    },
                  }}
                >
                  {selectedFile && (
                    <List dense>
                      <ListItem>
                        <ListItemText primary={selectedFile.name} />
                      </ListItem>
                    </List>
                  )}
                </Box>
              </Grid>
              <Grid item justifyContent="center" width="80%" lg={10} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Distribute buttons to opposite sides
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Button variant="customcancel" onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="customsave"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
