import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { getCookie } from "../../../utils/utils";
import useStyles from "../../clients/styles/useStyles";
import Loader from "../Loader";
import dayjs from "dayjs";
import Download from "../../billings/Download";
import ManageColumns from "../ManageColumns";


const paginationModel = { page: 0, pageSize: 20 };
function UserReports() {
  const [userReports, setUserReports] = React.useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "contactNumber",
      headerName: "Phone Number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        console.log("DOB Column Params:", params); 
        const dob = params;
        if (!dob) return "-"; 
    
        return dayjs(dob).format("DD-MMM-YYYY"); 
      },
      sortComparator: (v1, v2, paramOrder) => {
        const date1 = dayjs(v1, "DD-MMM-YYYY");
        const date2 = dayjs(v2, "DD-MMM-YYYY");
    
        if (!date1.isValid()) return 1;
        if (!date2.isValid()) return -1;
    
        return paramOrder === "asc"
          ? date2.unix() - date1.unix() // Fix: Swap v1 and v2 for correct order
          : date1.unix() - date2.unix();
       }
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "admin",
      headerName: "Admin",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dbsNo",
      headerName: "Dbs No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dbsExpiryDate",
      headerName: "Dbs Expiry Date",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {   
        const dbsExpiryDate = params;
        if (!dbsExpiryDate) return "-"; 
    
        return dayjs(dbsExpiryDate).format("DD-MMM-YYYY"); 
      },
      sortComparator: (v1, v2, paramOrder) => {
        const date1 = dayjs(v1, "DD-MM-YYYY");
        const date2 = dayjs(v2, "DD-MM-YYYY");
    
        if (!date1.isValid()) return 1;
        if (!date2.isValid()) return -1;
    
        return paramOrder === "asc"
          ? date2.unix() - date1.unix() 
          : date1.unix() - date2.unix();
       }
    },
    {
      field: "drive",
      headerName: "Drive",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "employmentStartDate",
      headerName: "Employment Start Date",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {   
        const employmentStartDate = params;
        if (!employmentStartDate) return "-"; 
    
        return dayjs(employmentStartDate).format("DD-MMM-YYYY"); 
      },
      sortComparator: (v1, v2, paramOrder) => {
        const date1 = dayjs(v1, "DD-MMM-YYYY");
        const date2 = dayjs(v2, "DD-MMM-YYYY");
    
        if (!date1.isValid()) return 1;
        if (!date2.isValid()) return -1;
    
        return paramOrder === "asc"
          ? date2.unix() - date1.unix() 
          : date1.unix() - date2.unix();
       }
    },
    {
      field: "employmentEndDate",
      headerName: "Employment End Date",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {   
        const employmentEndDate = params;
        if (!employmentEndDate) return "-"; 
    
        return dayjs(employmentEndDate).format("DD-MMM-YYYY"); 
      },
      sortComparator: (v1, v2, paramOrder) => {
        const date1 = dayjs(v1, "DD-MMM-YYYY");
        const date2 = dayjs(v2, "DD-MMM-YYYY");
    
        if (!date1.isValid()) return 1;
        if (!date2.isValid()) return -1;
    
        return paramOrder === "asc"
          ? date2.unix() - date1.unix() 
          : date1.unix() - date2.unix();
       }
    },
    {
      field: "specialist",
      headerName: "Specialist",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hourlyRate",
      headerName: "Hourly Rate",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "payPerMile",
      headerName: "Pay Per Mile",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "niNumber",
      headerName: "Ni Number",
      headerAlign: "center",
      align: "center",
    },
  ];

  const hiddenColumns = [
    "employmentStartDate",
    "employmentEndDate",
    "dbsNo",
    "dbsExpiryDate",
    "hourlyRate",
    "payPerMile",
    "niNumber",
  ];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columns.reduce(
      (acc, col) => ({
        ...acc,
        [col.field]: !hiddenColumns.includes(col.field), // Set to true if not in hiddenColumns
      }),
      {}
    )
  );
  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/getreportuserdetails`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        console.log(
          data.data,
          "=============================================="
        );

        // setmedicationroute(transformedData);

        if (response.ok === true) {
          setUserReports(data.data);
        } else {
          console.log("get all failed");
          setUserReports([]);
        }
      } catch (e) {
        console.log("error", e.message);
      } finally {
        setLoading(false); // Ensure loading is turned off regardless of success or failure
      }
    };
    fetchUsersAPI();
  }, []);
  useEffect(() => {
    if (rowSelectionModel?.length > 0) {
      console.log(rowSelectionModel);
    }
  }, [rowSelectionModel]);
  const rows = userReports?.map((user, index) => ({
    id: user?.id,
    userName: user?.userName,
    email: user?.email,
    contactNumber: user?.contactNumber,
    dob: user?.dob ,
    gender: user?.gender,
    status: user?.status,
    admin: user?.admin,
    address: user?.address,
    dbsNo: user?.dbsNo,
    dbsExpiryDate: user?.dbsExpiryDate,
    drive: user?.drive,
    employmentStartDate: user?.employmentStartDate,
    employmentEndDate: user?.employmentEndDate,
    specialist: user?.specialist,
    hourlyRate: user?.hourlyRate,
    payPerMile: user?.payPerMile,
    niNumber: user?.niNumber,
  }));
  
  const adjustedColumns = columns?.map((col) => {
    const maxColumnWidth = Math.max(
      col.headerName.length * 9 + 1, // Header text width
      ...rows?.map((row) => String(row[col.field]).length * 8 + 1) // Row text width
    );
    return {
      ...col,
      width: maxColumnWidth,
      minWidth: 100, // Set a minimum width to avoid extremely narrow columns
    };
  });
  const clientColumns = [
    "User Name",
    // "email",
    " Phone Number",
    "DOB",
    "Gender",
    "Status",
    "Admin",
    // 'Address',
    "DBS No",
    // 'Dbs ED',
    "Drive",
    // 'Emp S D',
    // 'Emp E D',
    // 'Specialist',
    // 'Hourly Rate',
    // 'Pay Per Mile',
    // 'Ni Number',
  ];
  const finalClients = rows?.filter((u) => rowSelectionModel?.includes(u.id));
  const body = finalClients?.map((d) => {
    return [
      d?.userName,
      // d?.email,
      d?.contactNumber,
      d?.dob && dayjs(d?.dob).format("DD-MMM-YYYY"),
      d?.gender,
      d?.status,
      d?.admin,
      // d?.address,
      d?.dbsNo,
      // d?.dbsExpiryDate,
      d?.drive,
      // d?.employmentStartDate,
      // d?.employmentEndDate,
      // d?.specialist,
      // d?.hourlyRate,
      // d?.payPerMile,
      // d?.niNumber
    ];
  });
  // need to change the keys names for excel sheet
  const updatedfinalClients = finalClients?.map(
    ({
      userName,
      email,
      contactNumber,
      dob,
      gender,
      status,
      admin,
      address,
      dbsNo,
      dbsExpiryDate,
      drive,
      employmentStartDate,
      employmentEndDate,
      specialist,
      hourlyRate,
      payPerMile,
      niNumber,
    }) => ({
      UserName: userName,
      Email: email,
      ContactNumber: contactNumber,
      DateOfBirth: dob && dayjs(dob).format("DD-MMM-YYYY")||"-",
      Gender: gender,
      Status: status,
      Admin: admin,
      Address: address,
      DbsNo: dbsNo,
      DbsExpiryDate: dbsExpiryDate && dayjs(dbsExpiryDate).format("DD-MMM-YYYY")||"-",
      Drive: drive,
      EmploymentStartDate: employmentStartDate && dayjs(employmentStartDate).format("DD-MMM-YYYY")||"-",
      EmploymentEndDate: employmentEndDate && dayjs(employmentEndDate).format("DD-MMM-YYYY")||"-",
      Specialist: specialist,
      HourlyRate: hourlyRate,
      PayPerMile: payPerMile,
      NiNumber: niNumber,
    })
  );

  return (
    <Box sx={{ px: 3, pt: 4, position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 3,
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Download
            data={body}
            columns={clientColumns}
            selectToDownload={rowSelectionModel}
            heading="Users Report"
            rotateMode={true}
            excelData={updatedfinalClients}
          />
          <ManageColumns
            columns={columns}
            hiddenColumns={hiddenColumns}
            initialSelectedColumns={columns
              .filter((col) => columnVisibilityModel[col.field])
              .map((col) => col.headerName)}
            onVisibilityChange={handleColumnVisibilityChange}
          />
        </Box>
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          pageSizeOptions={[20, 50, 100]}
          className={classes.datagridmain}
          checkboxSelection // Enable checkbox selection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          loading={loading} 
          slots={{
            loadingOverlay: Loader, // Displays Loader inside table
          }}
        />
      </Box>
    </Box>
  );
}
export default UserReports;
