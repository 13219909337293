import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Slider,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Name, Calendar, RadioInput, Dropdown } from "../../reusableComponents"; // Adjust the path
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../users/Theme";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";
import { BpIcon } from "../../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../../reusableComponents/StyledCheckbox";

export default function HistoricalAuditDialog({
  open,
  selectedAuditCard,
  onClose,
  history,
  auditName,
  auditResponseForm,
}) {
  return (
    <ThemeProvider theme={ViewTheme}>
      <Dialog open={open} onClose={onClose}>
        <Box textAlign="center" justifyContent="Center">
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              width: "28px ! important",
              height: "28px ! important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
            }}
          >
            {/* Empty content since the SVG icons are used in background */}
          </IconButton>
          <DialogContent
            sx={{
              background: "#F0FCFA",
              padding: "0",
            }}
          >
            <Box
              sx={{
                textAlign: "Left",
                height: "100%",
                padding: 6,
                width: "100%",
              }}
            >
              <Typography variant="h4">{auditName}</Typography>

              <Box sx={{ textAlign: "left" }}>
                <FormLabel>
                  <Typography variant="h6">
                    Method of Audit being carried out?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  // name={name}
                  // onChange={handleChange}
                  value={history?.method_audit || ""}
                  sx={{ marginTop: "8px" }}
                >
                  <FormControlLabel
                    value="Document Review"
                    control={<Radio disabled />}
                    label="Document Review"
                  />
                  <FormControlLabel
                    value="Spot Checks/Unannounced Visits"
                    control={<Radio disabled />}
                    label="Spot Checks/Unannounced Visits"
                  />
                  <FormControlLabel
                    value="Interview with Client/Next of Kin"
                    control={<Radio disabled />}
                    label="Interview with Client/Next of Kin"
                  />
                  <FormControlLabel
                    value="Care Plan Reviews"
                    control={<Radio disabled />}
                    label="Care Plan Reviews"
                  />
                  <FormControlLabel
                    value="Outcome-Based Audits"
                    control={<Radio disabled />}
                    label="Outcome-Based Audits"
                  />
                  <FormControlLabel
                    value="Focus Group Discussions"
                    control={<Radio disabled />}
                    label="Focus Group Discussions"
                  />
                </RadioGroup>
                {/* {errorMessage && (
                               <Typography variant="errmsg" sx={{ marginTop: "2px" }}>
                                 {errorMessage}
                               </Typography>
                             )} */}
              </Box>
              <Box>
                {selectedAuditCard?.questions?.map((question, index) => (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      {question?.ques}
                    </Typography>
                    <Container>
                      <Name
                        name={question.ques_name}
                        className="multiline-textfield"
                        label="Please Mention"
                        placeholder={question?.placeholder}
                        multiline
                        rows="8"
                        // onValueChange={handleValueChange}
                        preFilledvalue={history?.[question.ques_name]}
                        disabled
                      ></Name>
                    </Container>
                  </>
                ))}
                <Typography variant="h4">Action Plan</Typography>
                {selectedAuditCard?.action_plan?.map((action, index) => (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        marginTop: "4px",
                      }}
                    >
                      {action.action}
                    </Typography>
                    <Container>
                      <Name
                        name={action.action_name}
                        className="multiline-textfield"
                        label="Please Mention"
                        multiline
                        rows="5"
                        // onValueChange={handleValueChange}
                        preFilledvalue={history?.[action.action_name]}
                        disabled
                      ></Name>
                    </Container>
                  </>
                ))}
              </Box>

              <Box>
                <Grid
                  container
                  direction="row"
                  alignItems="top"
                  sx={{
                    gap: 8,
                    p: 2,
                    borderRadius: "8px",
                    backgroundColor: "#F0FCFA",
                  }}
                >
                  <Grid item xs={10} md={5}>
                    <Name
                      name="audited_on"
                      label="Audited on"
                      placeholder="Audited on"
                      preFilledvalue={dayjs(history?.audited_on).format(
                        "DD-MM-YYYY"
                      )}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={10} md={5}>
                    <Calendar
                      name="date_2"
                      label="Due on"
                      //   onDateChange={handleDateChange}
                      preFilledvalue={history?.due_on}
                      disabled
                    />
                  </Grid>
                </Grid>
                <FormLabel sx={{ fontSize: 18, paddingTop: "20px" }}>
                  Signatures of all involved persons in assessment Required
                </FormLabel>
                <Grid // First signature
                  container
                  direction="row"
                  alignItems="top"
                  sx={{
                    gap: 8,
                    p: 2,
                    borderRadius: "8px",
                    backgroundColor: "#F0FCFA",
                    mb: -2,
                  }}
                >
                  <Grid item xs={10} md={5}>
                    <Name
                      name="sign_1"
                      label="Name"
                      preFilledvalue={history?.sign_1}
                      placeholder="Enter your Name"
                      disabled={true} // Always disabled
                    />
                  </Grid>
                  <Grid item xs={10} md={5}>
                    <Calendar
                      name="sign_date1"
                      label="Date"
                      preFilledvalue={history?.sign_date1}
                      // onDateChange={handleDateChange}
                      // preFilledvalue={
                      //   initialForm[0]?.date_1 ? initialForm[0]?.date_1 : dayjs() // Prefill with date_1 or current date
                      // }
                      // disabled={
                      //   initialForm[0]?.sign_1 && initialForm[0]?.sign_1 !== signName
                      //     ? true
                      //     : false
                      // } // Disable if date_1 exists
                      disabled
                    />
                  </Grid>
                </Grid>

                {history?.sign_2 !== "" &&
                history?.sign_1 !== history?.sign_2 ? (
                  <Grid //Second signature
                    container
                    direction="row"
                    alignItems="top"
                    sx={{
                      gap: 8,
                      p: 2,
                      borderRadius: "8px",
                      backgroundColor: "#F0FCFA",
                    }}
                  >
                    <Grid item xs={10} lg={4}>
                      <Name
                        name="sign_2"
                        label="Name"
                        placeholder="Name"
                        preFilledvalue={history?.sign_2}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={10} lg={4}>
                      <Calendar
                        label="Date"
                        name="sign_date2"
                        // onDateChange={handleDateChange}
                        preFilledvalue={history?.sign_date2}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {/* <Grid>
                        <Grid item>
                          <FormControlLabel
                            label={
                              "I hereby confirm the accuracy of the information provided above"
                            }
                            control={
                              <Checkbox
                                checked={
                                  // fetchCheckVariable &&
                                  initialForm[0]?.consent === 0 ? false : true
                                }
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                onChange={(e) => {
                                  //   handleCheckboxChange(e);
                                }}
                              />
                            }
                          />
                        </Grid>
                      </Grid> */}
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
