import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Cancelled from "../../assets/images/roster/Cancelled.svg";
import Completed from "../../assets/images/roster/Completed.svg";
import Inprogress from "../../assets/images/roster/Inprogress.svg";
import NotStarted from "../../assets/images/roster/NotStarted.svg";
import Scheduled from "../../assets/images/roster/Scheduled.svg";
import Unscheduled from "../../assets/images/roster/Unscheduled.svg";
//import Male from "../../assets/images/roster/Male.svg";
//import Female from "../../assets/images/roster/Female.svg";
import MaleG from "../../assets/images/roster/MaleG.svg";
//import FemaleG from "../../assets/images/roster/FemaleG.svg";

const RosterVisitCard = ({ visit, view }) => {
  //console.log("visit:", visit);
  return (
    <Box
      key={visit.id} // Use unique key for each card
      sx={{
        width: "100%",
        height: "100%",

        backgroundColor:
          visit.status === "Scheduled"
            ? "#A79248" // Color for "Scheduled"
            : visit.status === "Completed"
            ? "#0C6C21" // Color for "Completed"
            : visit.status === "Cancelled"
            ? "#F80A0A" // Color for "Cancelled"
            : visit.status === "In Progress"
            ? "#7F2A94" // Color for "In Progress"
            : visit.status === "Not Started"
            ? "#931010" // Color for "Not Started"
            : visit.status === "Unallocated"
            ? "#F0FCFA" // Color for "Not Started"
            : "#FFFFFF", // Default color if status is not one of the above

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "left",
        cursor: "pointer", // <-- Add this line for the pointer cursor
        padding: "2px 10px 5px 10px",
      }}
    >
      <Box
        sx={{
          height: "39px",
          overflow: "hidden",
          paddingBottom: 4,
        }}
      >
        {view === "Client View" ? (
          <Typography
            variant="body4"
            sx={{
              color: visit.userid.length === 0 ? "#279989" : "#ffffff",
            }}
          >
            {visit.status === "Unallocated"
              ? visit.status
              : visit.userName.join(", ")}
          </Typography>
        ) : (
          <Typography
            variant="body4"
            sx={{
              color: visit.status === "Unallocated" ? "#279989" : "#ffffff",
            }}
          >
            {visit.clientName}
          </Typography>
        )}
      </Box>
      <Box sx={{ height: "25px" }}>
        <Grid
          container
          justifyContent="space-between"
          sx={{ overflow: "hidden", height: "25px" }}
        >
          <Grid item>
            {visit.status === "Unallocated" && (
              <>
                {Array.from({ length: visit.numberOfCarersRequired }).map(
                  (_, index) => (
                    <img
                      key={`male-${index}`} // Unique key for each icon
                      style={{
                        width: "auto",
                        height: "18px",
                        margin: "1px",
                        //marginBottom: "5px",
                      }}
                      src={MaleG}
                      alt="Male Carer"
                    />
                  )
                )}
              </>
            )}
          </Grid>
          <Grid item>
            <img
              style={{
                width: "auto",
                height: "17px",
                width: "17px",
                margin: "1px",
                //marginBottom: "8px",
                //fill: "blue", // Only works if the SVG has a fill attribute
              }}
              src={
                visit.status === "Scheduled"
                  ? Scheduled
                  : visit.status === "Completed"
                  ? Completed
                  : visit.status === "Cancelled"
                  ? Cancelled
                  : visit.status === "In Progress"
                  ? Inprogress
                  : visit.status === "Not Started"
                  ? NotStarted
                  : visit.status === "Unallocated"
                  ? Unscheduled
                  : ""
              }
              alt="visitstatus"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default RosterVisitCard;
