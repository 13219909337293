import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import { Box, Typography } from "@mui/material";
import useStyles from "../../clients/styles/useStyles";
import { DataGrid } from "@mui/x-data-grid";
import "jspdf-autotable";
import Loader from "../Loader";
import Download from "../../billings/Download";
import dayjs from "dayjs";
import ManageColumns from "../ManageColumns";
const columns = [
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "contactNumber",
    headerName: "Contact Number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "admin",
    headerName: "Admin",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "specialist",
    headerName: "Specialist",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "address",
    headerName: "Address",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "drive",
    headerName: "Drive",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "driveExpiryDate",
    headerName: "DriveExpiryDate",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {   
          const driveExpiryDate = params;
          if (!driveExpiryDate) return "-"; 
      
          return dayjs(driveExpiryDate).format("DD-MMM-YYYY"); 
        },
        sortComparator: (v1, v2, paramOrder) => {
          const date1 = dayjs(v1, "DD-MMM-YYYY");
          const date2 = dayjs(v2, "DD-MMM-YYYY");
      
          if (!date1.isValid()) return 1;
          if (!date2.isValid()) return -1;
      
          return paramOrder === "asc"
            ? date2.unix() - date1.unix() 
            : date1.unix() - date2.unix();
         }
  },
  {
    field: "drivingLicenseNo",
    headerName: "Driving License No",
    headerAlign: "center",
    align: "center",
  },
];
const paginationModel = { page: 0, pageSize: 20 };
function TimeSpentOnTravel() {
  const classes = useStyles();
  const [TimeSpentOnTravel, setTimeSpentOnTravel] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const hiddenColumns =['driveExpiryDate','drivingLicenseNo']
   const [columnVisibilityModel, setColumnVisibilityModel] = useState(
      columns.reduce(
        (acc, col) => ({
          ...acc,
          [col.field]: !hiddenColumns.includes(col.field), // Set to true if not in hiddenColumns
        }),
        {}
      )
    );
    const handleColumnVisibilityChange = (newVisibilityModel) => {
      setColumnVisibilityModel(newVisibilityModel);
    };
  
  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/getTimeSpentOnTravel`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          setTimeSpentOnTravel(data.data);
        } else {
          console.log("get all failed");
          setTimeSpentOnTravel([]);
        }
      } catch (e) {
        console.log("error", e.message);
      } finally {
        setLoading(false); // Ensure loading is turned off regardless of success or failure
      }
    };
    fetchUsersAPI();
  }, []);
  const rows = TimeSpentOnTravel?.map((item) => ({
    id: item?.id,
    userName: item?.userName,
    status: item?.status,
    contactNumber: item?.contactNumber,
    admin: item?.admin,
    specialist: item?.specialist,
    address: item?.address,
    drive: item?.drive,
    driveExpiryDate: item?.driveExpiryDate,
    drivingLicenseNo: item?.drivingLicenseNo,
  }));
  const adjustedColumns = columns?.map((col) => {
    const isAddressColumn = col.field === "userName" || col.field === 'address' || col.field === 'drive' ;
    return {
      ...col,
      minWidth: isAddressColumn ? 80:150,
      flex:1,
      minWidth: 70, // Set a minimum width to avoid extremely narrow columns
    };
  });
  const clientColumns = [
    "UserName",
    "Status",
    "ContactNo",
    "Admin",
    " Specialist",
    "Address",
    "Drive",
    "DriveExpDate",
    "DrivingLicNo",
  ];
  const finalreports = rows?.filter((u) => rowSelectionModel?.includes(u.id));
  const body = finalreports?.map((d) => {
    return [
      d?.userName,
      d?.status,
      d?.contactNumber,
      d?.admin,
      d?.specialist,
      d?.address,
      d?.drive,
      d?.driveExpiryDate && dayjs(d?.driveExpiryDate).format("DD-MMM-YYYY"),
      d?.drivingLicenseNo,
    ];
  });
  const upadatedFinalReports = finalreports.map(
    ({
      userName,
      status,
      contactNumber,
      admin,
      specialist,
      address,
      drive,
      driveExpiryDate,
      drivingLicenseNo,
    }) => ({
      UserName: userName,
      Status: status,
      ContactNumber: contactNumber,
      Admin: admin,
      Specialist: specialist,
      Address: address,
      Drive: drive,
      DriveExpiryDate: driveExpiryDate && dayjs(driveExpiryDate).format("DD-MMM-YYYY"),
      DrivingLicenseNo: drivingLicenseNo,
    })
  );
  return (
    <Box sx={{ px: 3, pt: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 3,
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports By Time Spent On Travel
        </Typography>
        <Box sx={{display:'flex' , alignItems:'center',gap:2}}>
          <Download
            data={body}
            columns={clientColumns}
            selectToDownload={rowSelectionModel}
            heading="Users Reports By Time Travel"
            rotateMode={true}
            excelData={upadatedFinalReports}
          />
          <ManageColumns
          columns={columns}
          hiddenColumns={hiddenColumns}
          initialSelectedColumns={columns
            .filter((col) => columnVisibilityModel[col.field])
            .map((col) => col.headerName)}
          onVisibilityChange={handleColumnVisibilityChange} />
        </Box>
      </Box>

      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          className={classes.datagridmain}
          loading={loading} 
          slots={{
            loadingOverlay: Loader, // Displays Loader inside table
          }}
        />
      </Box>
    </Box>
  );
}

export default TimeSpentOnTravel;
