import { Box, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import User from "../../../assets/images/users/User.svg";
import { ViewTheme } from "../../../themes/Theme";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "react-bootstrap";
import MedicationAudit from "../../../assets/images/AuditAndConsent/MedicationAudit.svg";
import CarePlanAudit from "../../../assets/images/AuditAndConsent/CarePlanAudit.svg";
import HealthSafetyAudit from "../../../assets/images/AuditAndConsent/HealthSafetyAudit.svg";
import SafeguardingAudit from "../../../assets/images/AuditAndConsent/SafeguardingAudit.svg";
import ServiceAudit from "../../../assets/images/AuditAndConsent/ServiceAudit.svg";

export const AuditHeader = ({
  head = "",
  mode = "",
  toggleEdit = () => {},
  isHideButton = true,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 0,
        margin: 0,
        Width: "100%",
      }}
    >
      <Box
        alignItems="Top"
        style={{
          marginLeft: "-20px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          textAlign="left"
          sx={{
            display: "flex",
            alignItems: "center",
            Width: "100%",
            padding: { xs: ViewTheme.spacing(0, 4) },
            flex: 1,
          }}
        >
          <img
            style={{
              height: "40%",
              width: "auto",
            }}
            src={User}
            alt="User"
          />
          <Typography variant="h2">{head}</Typography>
        </Box>

        {isHideButton === false && (
          <Box textAlign="right" sx={{ Width: "100%", padding: 0, margin: 0 }}>
            {mode === false && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={toggleEdit}
                sx={{ margin: 0, height: "35px" }}
              >
                Edit
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const auditCardDetails = [
  {
    id: 1,
    audit_name: "Health & Safety",
    audit_api_field: "healthData",
    status: "",
    sign_date: "",
    audit_image: HealthSafetyAudit,
    sign_name: "",
    note: "This audit assesses the health and safety measures in place for clients receiving care services, ensuring their safety, well-being, and compliance with regulations. The following questions aim to ensure that the health and safety of clients are being effectively maintained",
    questions: [
      {
        ques: "1. Are risk assessments conducted for each client, and are they regularly updated?",
        ques_name: "risk_assessments",
        placeholder:
          "Has an individualized risk assessment been completed for each client, covering their health, mobility, cognitive, and environmental risks?\n\nAre these risk assessments reviewed and updated regularly to reflect any changes in the client’s health condition or environment?\n\nDo the risk assessments include measures to reduce identified risks, such as fall prevention, medication errors, or emergency protocols?",
      },
      {
        ques: "2. Is there a clear process in place for managing medication safely for clients?",
        ques_name: "manage_medications",
        placeholder:
          "Are all clients’ medications accurately recorded, and do care staff follow clear procedures for medication administration?\n\nAre medications stored securely and in accordance with safety guidelines?\n\nAre clients’ medications reviewed regularly by healthcare professionals to prevent medication errors or adverse reactions?",
      },
      {
        ques: "3. Are personal protective equipment (PPE) and infection control measures used effectively by staff when providing care?",
        ques_name: "personal_protective_equipment",
        placeholder:
          "Is appropriate PPE (e.g., gloves, masks, aprons) provided to staff to minimize the risk of cross-contamination or infection?\n\nAre infection control procedures in place, such as regular handwashing, the use of sanitizers, and proper disposal of waste?\n\nAre staff trained in the correct use and disposal of PPE to protect both themselves and clients?",
      },
      {
        ques: "4. Are emergency procedures in place for clients in case of an accident or health emergency?",
        ques_name: "emergency_procedures",
        placeholder:
          "Do clients have access to clear and well-communicated emergency procedures (e.g., fall, medical emergency, fire)?\n\nAre staff trained to recognize when a client requires immediate medical attention and how to respond?\n\nIs there a documented emergency contact list and action plan for each client?",
      },
      {
        ques: "5. Are clients’ homes assessed for safety, and are hazards identified and addressed promptly?",
        ques_name: "regular_home_assessed",
        placeholder:
          "Are client homes regularly assessed for potential health and safety hazards, such as slips, trips, or falls?\n\nAre issues such as clutter, uneven floors, or poor lighting addressed promptly to reduce the risk of accidents?\n\nAre clients provided with necessary assistive devices (e.g., grab rails, mobility aids) to enhance safety at home?",
      },
      {
        ques: "6. Is the equipment used to assist clients (e.g., mobility aids, lifting devices) safe and properly maintained?",
        ques_name: "client_assist",
        placeholder:
          "Are mobility aids, bed rails, and other equipment used for clients in good working condition?\n\nIs all equipment regularly maintained and checked for safety, and are staff trained on its correct usage?\n\nAre clients involved in the decision-making process regarding the type of equipment used in their care?",
      },
      {
        ques: "7. Are clients’ mental and emotional well-being considered as part of their health and safety care plan?",
        ques_name: "mental_emotional_wellbeing",
        placeholder:
          "Are mental health and emotional support needs assessed and addressed as part of the overall care plan?\n\nAre appropriate referrals made to mental health services when necessary, ensuring the client receives holistic care?\n\nAre staff trained to recognize and respond to signs of mental health issues, stress, or anxiety in clients?",
      },
      {
        ques: "8. Are regular health and safety audits conducted to monitor and ensure ongoing compliance?",
        ques_name: "regular_health_safety",
        placeholder:
          "Are regular audits carried out to assess the effectiveness of health and safety procedures, including risk assessments, medication safety, and emergency preparedness?\n\nAre audit findings used to implement corrective actions, such as staff retraining or home hazard mitigation?\n\nIs there a system in place to monitor any changes or improvements in health and safety practices following audits?",
      },
    ],
    action_plan: [
      {
        action:
          "1. What key health and safety concerns can be identified based on the audit responses, such as missed risk assessments, medication management issues, or safety hazards in the client’s home?",
        action_name: "key_health_safety",
      },
      {
        action:
          "2. What corrective actions can be implemented, such as updating care plans, providing additional training to staff, or addressing identified hazards in the home?",
        action_name: "corrective_actions",
      },
      {
        action:
          "3. How can progress be monitored by conducting follow-up audits and tracking improvements over time?",
        action_name: "followup_audits",
      },
    ],
  },
  {
    id: 2,
    audit_name: "Safeguarding & Well-Being",
    audit_api_field: "safeData",
    status: "",
    sign_date: "",
    audit_image: SafeguardingAudit,
    sign_name: "",
    note: "This audit is designed to ensure that the safety, well-being, and rights of service users are being effectively protected and promoted within a domiciliary care setting. Below are nine detailed questions to assess safeguarding practices and ensure compliance with safeguarding protocols.",
    questions: [
      {
        ques: "1. Are all staff members aware of and trained in safeguarding policies and procedures?",
        ques_name: "safeguarding_training_policies",
        placeholder:
          "Have all staff undergone mandatory safeguarding training?\n\nIs there a clear understanding of safeguarding roles and reporting procedures among care staff?\n\nIs training regularly updated to align with the latest safeguarding regulations?",
      },
      {
        ques: "2. Is there a clear process for reporting safeguarding concerns or incidents?",
        ques_name: "safeguarding_reporting_concerns",
        placeholder:
          "Are service users and staff aware of the process for reporting abuse or neglect?\n\nIs there a confidential reporting system for both clients and staff to use?\n\nAre safeguarding concerns promptly reported and investigated according to protocol?",
      },
      {
        ques: "3. Are all safeguarding concerns documented accurately and appropriately?",
        ques_name: "appropriate_documentation",
        placeholder:
          "Are all reports of safeguarding incidents or concerns recorded in the appropriate documentation?\n\nIs there a clear record trail for each concern, including actions taken and outcomes?\n\nAre incident reports reviewed regularly for patterns or recurring issues?",
      },
      {
        ques: "4. Are service users rights and dignity respected at all times?",
        ques_name: "service_users_rights",
        placeholder:
          "Are service users treated with respect, including their cultural, religious, and personal preferences?\n\nAre there clear protocols to prevent any form of discrimination, harassment, or abuse?\n\nAre service users encouraged to voice their opinions, make decisions about their care, and exercise their autonomy?",
      },
      {
        ques: "5. Are background checks (e.g., DBS checks) carried out for all staff and volunteers?",
        ques_name: "background_checks",
        placeholder:
          "Are staff and volunteers subject to criminal background checks (e.g., DBS checks) before employment?\n\nAre updated background checks conducted periodically for ongoing staff?\n\nAre checks in place to ensure that no one with a history of abuse or misconduct is involved in direct care?",
      },
      {
        ques: "6. Are risk assessments conducted to identify potential safeguarding risks to service users?",
        ques_name: "risk_assessments",
        placeholder:
          "Are comprehensive risk assessments conducted for each service user at regular intervals?\n\nDo these assessments account for factors such as mental capacity, vulnerability, and physical or cognitive health conditions?\n\nAre care plans tailored to address identified risks and prevent harm?",
      },
      {
        ques: "7. Are service users supported to participate in decision-making about their care and well-being?",
        ques_name: "service_users_decision_making",
        placeholder:
          "Are service users involved in developing their care plans and decision-making about their treatment or services?\n\nAre they encouraged to express concerns or preferences related to their care and daily routines?\n\nDo staff regularly seek feedback from service users regarding their satisfaction with care?",
      },
      {
        ques: "8. Is there a clear procedure in place for safeguarding children, if applicable, within the service user’s family or household?",
        ques_name: "safeguarding_children",
        placeholder:
          "For service users with children or young people in their household, is there a process for ensuring their safety and well-being?\n\nAre staff trained in identifying child safeguarding issues and aware of how to report concerns related to children?\n\nIs there regular monitoring or communication with family members regarding safeguarding concerns involving children?",
      },
      {
        ques: "9. Are regular safeguarding audits or reviews conducted to ensure ongoing compliance and improvement?",
        ques_name: "safeguarding_audits",
        placeholder:
          "Are safeguarding audits conducted at regular intervals to assess the effectiveness of current policies and practices?\n\nAre the outcomes of these audits used to improve safeguarding protocols and address any gaps or areas of concern?\n\nAre the findings from these audits shared with management, staff, and regulatory bodies as required?",
      },
    ],
    action_plan: [
      {
        action:
          "1. What weaknesses or gaps in safeguarding practices can be identified based on the responses from the audit?",
        action_name: "safeguarding_gap_practices",
      },
      {
        action:
          "2. What corrective actions, such as additional staff training, reviewing risk assessments, or updating safeguarding procedures, can be implemented to address identified weaknesses?",
        action_name: "corrective_actions",
      },
      {
        action:
          "3. How can progress be monitored by scheduling follow-up audits and reviewing changes made to safeguard the well-being of service users?",
        action_name: "scheduling_follow_up_audits",
      },
    ],
  },
  {
    id: 3,
    audit_name: "Care Plan & Documentation Audit",
    status: "",
    audit_api_field: "carePlanData",
    sign_date: "",
    audit_image: CarePlanAudit,
    sign_name: "",
    note: "A Care Plan & Documentation Audit ensures that care plans are accurate, up to date, and compliant with regulations. Below are key questions to assess the quality of care documentation.",
    questions: [
      {
        ques: "1. Is there a fully completed care plan for each service user?",
        ques_name: "completed_care_plan",
        placeholder:
          "Check if all required sections (personal details, medical history, risk assessments, support needs) are filled in.\n\nEnsure it is signed by both the service user (or representative) and the care provider.",
      },
      {
        ques: "2. Are care plans reviewed and updated regularly?",
        ques_name: "care_plans_reviewed",
        placeholder:
          "Confirm that reviews occur at least every six months or when the service user’s needs change.\n\nVerify if updates are properly documented with dates and signatures.",
      },
      {
        ques: "3. Does the care plan reflect the service user’s individual needs, preferences, and choices?",
        ques_name: "service_users_individual_needs",
        placeholder:
          "Assess if personal preferences, routines, cultural, and religious needs are recorded.\n\nCheck for person-centered care approaches tailored to the individual.",
      },
      {
        ques: "4. Are emergency contact details and next of kin information up to date?",
        ques_name: "emergency_contact_details",
        placeholder:
          "Ensure names, addresses, and phone numbers of family members or legal representatives are recorded.\n\nVerify if emergency procedures are clearly outlined",
      },
      {
        ques: "5. Are risk assessments properly documented and followed?",
        ques_name: "risk_assessments",
        placeholder:
          "Review risk assessments for mobility, falls, medication management, allergies, and home environment.\n\nCheck if risk mitigation strategies are in place and updated regularly.",
      },
      {
        ques: "6. Is there an accurate record of medication prescribed and administered?",
        ques_name: "accurate_medication_prescribed",
        placeholder:
          "Verify if the Medication Administration Record (MAR) is completed correctly.\n\nEnsure medication instructions include dosage, frequency, and administration method.\n\nCheck for missed or incorrect entries and whether errors are reported.",
      },
      {
        ques: "7. Are care workers following the care plan and documenting care provided?",
        ques_name: "care_plan_documenting",
        placeholder:
          "Compare care records with care plans to check consistency.\n\nEnsure notes reflect what care was given, when, and by whom.",
      },
      {
        ques: "8. Are daily logs and visit records completed accurately?",
        ques_name: "daily_logs_visit",
        placeholder:
          "Check that visit times, activities, and observations are documented.\n\nEnsure no missing or unclear records that could impact care quality.\n\nIs there regular monitoring or communication with family members regarding safeguarding concerns involving children?",
      },
      {
        ques: "9. Are there records of service user complaints, concerns, or incidents?",
        ques_name: "service_user_complaints",
        placeholder:
          "Verify if complaints are documented, investigated, and resolved appropriately.\n\nEnsure safeguarding incidents are reported and handled correctly.",
      },
      {
        ques: "10. Does the care plan include dietary and nutrition needs?",
        ques_name: "dietary_nutrition",
        placeholder:
          "Ensure food preferences, allergies, and special diets are recorded.\n\nCheck if hydration and meal support requirements are noted and followed.",
      },
      {
        ques: "11. Are moving and handling needs properly assessed and documented?",
        ques_name: "needs_properly_assessed_documented",
        placeholder:
          "Confirm if equipment use (hoists, wheelchairs, bed rails) is recorded.\n\nVerify staff compliance with safe manual handling procedures.",
      },
      {
        ques: "12. Is the care plan accessible to all relevant staff members?",
        ques_name: "accessible_staff_members",
        placeholder:
          "Check if staff can easily access and understand care plans.\n\nEnsure updates are communicated effectively to caregivers.",
      },
      {
        ques: "13. Are end-of-life care preferences recorded (if applicable)?",
        ques_name: "care_preferences",
        placeholder:
          "Confirm if the care plan includes Advanced Care Planning (ACP).\n\nEnsure DNAR (Do Not Attempt Resuscitation) orders or palliative care preferences are documented.",
      },
      {
        ques: "14. Are confidentiality and data protection measures in place for care records?",
        ques_name: "data_protection_measures",
        placeholder:
          "Verify compliance with GDPR and CQC regulations on data security.\n\nEnsure records are stored securely, with restricted access.",
      },
      {
        ques: "15. Are all care documents signed and dated by the appropriate individuals?",
        ques_name: "signed_dated",
        placeholder:
          "Ensure all updates, assessments, and care records include signatures and dates of relevant parties.\n\nCheck for missing or incomplete authorizations.",
      },
    ],
    action_plan: [
      {
        action: "1. Are any gaps identified in documentation?",
        action_name: "gaps_identified",
      },

      {
        action: "",
        action_name: "gaps_identified_actions",
      },
      {
        action:
          "2. What corrective actions can be implemented to address any issues found?",
        action_name: "corrective_actions",
      },
      {
        action:
          "3. What training can be provided to improve compliance and enhance care quality?",
        action_name: "compliance",
      },
    ],
  },
  {
    id: 4,
    audit_name: "Service User Satisfaction Audit",
    status: "",
    audit_api_field: "serviceUserData",
    sign_date: "",
    audit_image: ServiceAudit,
    sign_name: "",
    note: "This questionnaire is designed to assess the overall satisfaction and experience of service users in a domiciliary care setting, ensuring that their needs are met and that they receive the highest quality of care",
    questions: [
      {
        ques: "1. How satisfied are you with the quality of care you receive from the staff?",
        ques_name: "satisfaction_from_staff",
        placeholder:
          "Ask the client to rate their overall satisfaction with the care provided (e.g., on a scale of 1 to 5).\n\nExplore their perception of care quality, including aspects like professionalism, attentiveness, and respect.",
      },
      {
        ques: "2. Do you feel that the care staff are knowledgeable and competent in performing their duties?",
        ques_name: "care_staff_knowledge",
        placeholder:
          "Ask the client whether they feel that care staff are well-trained and competent in delivering the care needed.\n\nFollow-up with questions about specific tasks (e.g., medication administration, personal care) and how confident they feel about the staff’s skills.",
      },
      {
        ques: "3. Are you happy with the communication between you and your care team?",
        ques_name: "communication",
        placeholder:
          "Ask the client if they feel that communication with care staff, supervisors, and management is clear and effective.\n\nExplore whether they feel comfortable expressing concerns or preferences to their care team.",
      },
      {
        ques: "4. How would you rate the reliability and punctuality of the care staff?",
        ques_name: "reliability_punctuality",
        placeholder:
          "Inquire about the timeliness and consistency of staff arriving on time and staying for the full scheduled care session.\n\nAsk if there have been any instances where staff were late, absent, or replaced without prior notice.",
      },
      {
        ques: "5. Do you feel that your individual care needs and preferences are respected and followed?",
        ques_name: "individual_care_needs",
        placeholder:
          "Ask if the care plan accurately reflects the client’s needs, preferences, and choices.\n\nDetermine if staff make efforts to personalize care based on the client’s specific requests or cultural/religious considerations.",
      },
      {
        ques: "6. Are there any aspects of your care or service that you feel could be improved?",
        ques_name: "care_improve",
        placeholder:
          "Provide space for clients to share any suggestions or concerns regarding their care.\n\nFollow up with specific questions about improvements in areas like communication, care quality, or timeliness.",
      },
      {
        ques: "7. How satisfied are you with the way your feedback or complaints are handled?",
        ques_name: "feedback",
        placeholder:
          "Ask the client if they feel their feedback or complaints are taken seriously and addressed in a timely and professional manner.\n\nExplore whether they are aware of how to report issues and whether they feel confident that actions are taken to resolve concerns.",
      },
    ],
    action_plan: [
      {
        action:
          "1. What key areas can be identified where satisfaction can be improved based on the responses?",
        action_name: "satisfaction_improve",
      },
      {
        action:
          "2. What corrective actions, such as additional training, schedule adjustments, or improved communication, can be implemented to address areas of concern?",
        action_name: "corrective_actions",
      },
      {
        action:
          "3. How can progress be monitored, and client satisfaction be reassessed regularly through follow-up audits?",
        action_name: "client_satisfaction",
      },
    ],
  },
  {
    id: 5,
    audit_name: "Medication Audit",
    status: "",
    audit_api_field: "medicationData",
    sign_date: "",
    audit_image: MedicationAudit,
    sign_name: "",
    note: "The eMAR (Electronic Medication Administration Record) Chart Audit is designed to assess the accuracy, safety, and compliance of medication administration for a client in a domiciliary care setting. Below is a detailed questionnaire to evaluate the client’s medication management process.",
    questions: [
      {
        ques: "1. Are all prescribed medications accurately documented on the eMAR chart?",
        ques_name: "prescribed_medications",
        placeholder:
          "Confirm that each medication name, dosage, and administration schedule match the client’s prescription.\n\nCheck if medication forms (tablets, injections, liquids, etc.) are specified clearly.",
      },
      {
        ques: "2. Is the correct administration route recorded for each medication?",
        ques_name: "correct_administration",
        placeholder:
          "Ensure that the route of administration (oral, topical, subcutaneous, etc.) is clearly noted.\n\nVerify that medications requiring specific routes are correctly listed.",
      },
      {
        ques: "3. Are medication administration times adhered to and recorded?",
        ques_name: "medication_administration",
        placeholder:
          "Ensure that each medication is administered at the prescribed time.\n\nConfirm that administered times are logged accurately without discrepancies.",
      },
      {
        ques: "4. Are all medications signed off by the staff after administration?",
        ques_name: "staff_signature",
        placeholder:
          "Check that staff initials or signatures are recorded after each dose is administered.\n\nLook for any missing signatures that may indicate a dose was missed or not administered.",
      },
      {
        ques: "5. Are medication changes (new prescriptions, discontinued drugs, or dose adjustments) clearly documented?",
        ques_name: "medication_changes",
        placeholder:
          "Verify that changes to prescriptions are clearly recorded and updated.\n\nCheck if discontinued or altered medications are removed or marked on the eMAR.",
      },
      {
        ques: "6. Are PRN (as-needed) medications documented properly?",
        ques_name: "PRN_medications",
        placeholder:
          "Ensure that the reason for PRN medication administration is recorded.\n\nConfirm that effectiveness of PRN medications is documented after use.",
      },
      {
        ques: "7. Are there any discrepancies between prescribed medications and those listed on the eMAR chart?",
        ques_name: "cross_check_pescription",
        placeholder:
          "Cross-check the prescription list with the medications recorded on the eMAR.\n\nIdentify any medications that are missing or incorrectly listed.",
      },
      {
        ques: "8. Are medication allergies or contraindications clearly documented for the client?",
        ques_name: "allergies",
        placeholder:
          "Ensure that any allergies or adverse reactions are noted and highlighted for easy reference.\n\nCheck if there are any contraindications that might affect medication administration.",
      },
      {
        ques: "9. Are any missed doses or refusals properly documented and reported?",
        ques_name: "missed_doses",
        placeholder:
          "Check if missed doses are clearly recorded, with reasons for the omission.\n\nEnsure that refused medications are reported and documented.",
      },
      {
        ques: "10. Is there a clear process for administering controlled drugs (CDs) and are they logged separately?",
        ques_name: "administering_controlled_drugs",
        placeholder:
          "Verify if controlled drugs (e.g., opioids, sedatives) are recorded in a separate CD register.\n\nCheck if controlled drugs are administered with the correct dual signing procedure.",
      },
      {
        ques: "11. Are medications stored safely and according to regulations?",
        ques_name: "medications_stored_safely",
        placeholder:
          "Confirm that all medications are stored in a safe, locked place (e.g., secure cabinets for controlled drugs).\n\nEnsure that temperature-sensitive medications (like insulin) are stored according to the manufacturer’s recommendations.",
      },
      {
        ques: "12. Are there any issues with medication availability (e.g., stock shortages or incorrect dosage)?",
        ques_name: "medication_availability",
        placeholder:
          "Check if there are any medication shortages or incorrect stock levels recorded.\n\nEnsure the proper stock management process is followed to avoid running out of medications.",
      },
      {
        ques: "13. Are staff members trained and competent in medication administration?",
        ques_name: "staff_members_trained",
        placeholder:
          "Verify that staff who administer medications have received training and are competent in administering specific medications to clients.\n\nConfirm that medication competency assessments are performed regularly.",
      },
      {
        ques: "14. Are medication errors, such as incorrect dosage or timing, properly documented and addressed?",
        ques_name: "medication_errors",
        placeholder:
          "Ensure that any medication administration errors are documented accurately.\n\nConfirm that corrective actions or follow-up steps (e.g., notifying the GP, investigating the cause) are recorded.",
      },
      {
        ques: "15. Are the client’s family or legal representatives notified of any significant medication changes?",
        ques_name: "legal_representatives",
        placeholder:
          "Check if the family or representatives are informed of any major medication changes or adjustments to the medication schedule.\n\nVerify that any client-related medication concerns (e.g., side effects) are communicated to appropriate individuals.",
      },
    ],
    action_plan: [
      {
        action:
          "1. What discrepancies or gaps were identified during the audit?",
        action_name: "discrepancies",
      },
      {
        action:
          "2. What corrective actions can be implemented to resolve medication errors, documentation gaps, or training needs?",
        action_name: "corrective_actions",
      },
      {
        action:
          "3. How can staff training, medication management procedures, and care protocols be reviewed and updated to improve compliance and safety?",
        action_name: "medication_management_procedures",
      },
    ],
  },
];
