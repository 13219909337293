import React from "react";
import { Card, Typography, Box } from "@mui/material";
import { Status2 } from "../ClientTable/Components";
import dayjs from "dayjs";

export default function TempInactiveCards({ tempInactiveData }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        padding: "10px 20px",
        width: "300px",
        height: "150px",
        gap: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#279989",
            marginRight: "10px",
            paddingLeft: 0,
            paddingBottom: 0,
            fontWeight: 600,
          }}
        >
          Reason:{" "}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            paddingRight: 0,
            paddingBottom: 0,
          }}
        >
          {" "}
          {tempInactiveData.reason}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#279989",
            marginRight: "10px",
            paddingLeft: 0,
            paddingBottom: 0,
            fontWeight: 600,
          }}
        >
          Inactive Start Date:{" "}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            paddingRight: 0,
            paddingBottom: 0,
          }}
        >
          {" "}
          {dayjs(tempInactiveData.inactiveStartDate).format("DD-MM-YYYY")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#279989",
            marginRight: "10px",
            paddingLeft: 0,
            paddingBottom: 0,
            fontWeight: 600,
          }}
        >
          Inactive End Date:{" "}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            paddingRight: 0,
            paddingBottom: 0,
          }}
        >
          {" "}
          {dayjs(tempInactiveData.inactiveEndDate).format("DD-MM-YYYY")}
        </Typography>
      </Box>
      <Box
        sx={{
          fontSize: "16px",
          fontFamily: "Lora",
          fontWeight: "600",
          color: "#279989",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#279989",
            marginRight: "10px",
            paddingLeft: 0,
            fontWeight: 600,
          }}
        >
          Status:{" "}
        </Typography>

        <Status2
          status={tempInactiveData.inactiveStatus === "Upcoming" ? true : false}
          text={tempInactiveData.inactiveStatus}
        />

        {/* <Box
          sx={{
            border:
              tempInactiveData.inactiveStatus === "Ongoing"
                ? "1px solid #F5891D"
                : "1px solid #03AE71",
            borderRadius: "8px",
            padding: "4px 8px",
            color:
              tempInactiveData.inactiveStatus === "Ongoing"
                ? "#FA8733"
                : "#279989",
            backgroundColor:
              tempInactiveData.inactiveStatus === "Ongoing"
                ? "#FFF0E3"
                : "#F1FFF3",
            width: "120px ! important",
          }}
        >
          {tempInactiveData.inactiveStatus}
        </Box> */}
      </Box>
    </Card>
  );
}
