import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import { RadioInput, Dropdown } from "../../../reusableComponents";
import Admin from "../../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../../reusableComponents/ConfirmationDialog";
import { ClientHeader } from "../../../clients/clientReusableComponents/components";
import VisitDetails from "./VisitDetails";
import VisitDetailsEdit from "./VisitDetailsEdit";
import VisitTodos from "../visitDetailsDialog/VisitToDoTable";
import VisitMedications from "./VisitMedications";
import { TabButton } from "../../clientReusableComponents/components";
import VisitAlerts from "./VisitAlerts";
import VisitNotes from "./VisitNotes";
import CloseIcon from "../../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../../assets/icons/closehover.svg";
import CancelVisit from "../../../../assets/images/clients/Visits/cancelvisit.svg";
import Visits from "../../../../assets/images/clients/Visits.svg";
import { getCookie } from "../../../../utils/utils";
import dayjs from "dayjs";

export default function VisitDialogDashboard({
  openDialog,
  //visit.client_id,
  visit,
  //visit.status,
  clientMainData,
  setRefreshVisits,
  isEdit = "",
  handleCloseDialog = () => {},
}) {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [opensDialog, setOpenDialog] = useState(false);
  const [closepopup, setClosePopup] = useState(false);
  const [pendingTabId, setPendingTabId] = useState(null);

  const handlesOpenDialog = () => setOpenDialog(true);
  const handlesCloseDialog = () => {
    setOpenDialog(false);
    setPendingTabId(null);
  };

  const onClickYesDialogBtn = () => {
    if (pendingTabId !== null) {
      setActiveTab(pendingTabId);
    } else if (closepopup === true) {
      handleCloseDialog();
    }
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handlesCloseDialog();
  };

  useEffect(() => {
    if (clientMainData) {
      setName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);
  useEffect(() => {
    setEdit(isEdit === "10" ? true : false);
  }, [isEdit]);

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  //Tab functionality

  const tabsList = [
    { id: 1, text: "Visit Details" },
    { id: 2, text: "To-Do's" },
    { id: 3, text: "Medication" },
    { id: 4, text: "Notes" },
    { id: 5, text: "Alerts" },
  ];
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (tabId) => {
    if (hasUnsavedChanges) {
      setPendingTabId(tabId);
      handlesOpenDialog();
    } else {
      setActiveTab(tabId);
    }
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <div>
            {edit ? (
              <VisitDetailsEdit
                visit={visit}
                clientMainData={clientMainData}
                setRefreshVisits={setRefreshVisits}
                setHasUnsavedChanges={setHasUnsavedChanges}
                setEdit={setEdit}
              />
            ) : (
              <VisitDetails
                visit={visit}
                clientMainData={clientMainData}
                setEdit={setEdit}
              />
            )}
          </div>
        );
      case 2:
        return (
          <VisitTodos
            visitId={visit.visit_details_id}
            visitStatus={visit.status}
          />
        );
      case 3:
        return (
          <VisitMedications
            visitId={visit.visit_details_id}
            client_id={clientMainData.personalInfo.client_id}
          />
        );
      case 4:
        return (
          <VisitNotes
            visitId={visit.visit_details_id}
            visitStatus={visit.status}
          />
        );
      case 5:
        return (
          <VisitAlerts
            visitId={visit.visit_details_id}
            visitStatus={visit.status}
          />
        );
      default:
        return null; // or a default component
    }
  };

  const handleDialog = () => {
    if (hasUnsavedChanges) {
      setClosePopup(true);
      handlesOpenDialog();
    } else {
      handleCloseDialog();
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "60% ! important", // Set your desired width here
          maxWidth: "900px", // Prevent it from shrinking to smaller widths
          //height: "500px",
          borderRadius: "30px ! important",
        },
      }}
    >
      <Box
        textAlign="center"
        justifyContent="Center"
        sx={{ overflow: "hidden" }}
      >
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleDialog}
          sx={{
            position: "absolute",
            right: 4,
            top: 12,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px 15px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <div style={{ flex: 1 }}>
              <ClientHeader
                head={`${visit.session_type} visit details`}
                icon={Visits}
                isHideButton={true}
              />
            </div>
          </Box>

          <Box sx={{ marginTop: "40px" }}>
            <Box
              sx={{
                background: "white",
                width: "100%",
                position: "relative",
                borderRadius: "15px",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  height: "50px",
                  width: "100%",
                  transform: "translate(-50%, -80%)",
                  zIndex: 1,
                }}
              >
                {tabsList.map((each) => (
                  <TabButton
                    key={each.id}
                    isActive={activeTab === each.id}
                    onClick={() => handleTabSwitch(each.id)}
                    data={each}
                  />
                ))}
              </Box>

              <Card
                sx={{
                  padding: 3,
                  borderRadius: "10px",
                  //height: "calc(100vh - 590px)",
                  height: "415px",
                  boxShadow: "0px 0px 10px 0px #27998933",
                  margin: "0px",
                }}
              >
                <Box
                  sx={{ textAlign: "Left", width: "100%", overflow: "scroll" }}
                >
                  {renderActiveTabContent()}
                </Box>
              </Card>
            </Box>
          </Box>
        </DialogContent>
      </Box>
      {opensDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handlesCloseDialog()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handlesCloseDialog}
        />
      )}
    </Dialog>
  );
}
