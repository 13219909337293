import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { EditTheme } from "../../users/Theme";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { AuditHeader } from "./reusableAuditComponents";
import AuditEdit from "./AuditEdit";
import AuditView from "./AuditView";
import { ViewTheme } from "../../../themes/Theme";
import { getCookie } from "../../../utils/utils";
import HistoricalCards from "./HistoricalCards";
import dayjs from "dayjs";

const AuditFormView = ({
  clientId,
  auditName,
  selectedAuditCard,
  setSelectedAuditCard,
  clientName,
  setAuditName,
  finalAuditDetails,
  signName,
}) => {
  const [isEditToggle, setIsEditToggle] = useState(false);
  const [isNewAuditToggle, setIsNewAuditToggle] = useState(false);

  const [historyAudits, setHistoryAudits] = useState([]);

  const [auditResponseForm, setAuditResponseForm] = useState([]);

  const toggleEditMode = () => {
    setIsEditToggle(true);
  };

  useEffect(() => {
    console.log("isNew audit Toggle", isNewAuditToggle);
  }, [isNewAuditToggle]);

  const toggleReturnMode = () => {
    setIsEditToggle(false);
    setIsNewAuditToggle(false);
  };

  const handleBackButton = () => {
    setAuditName("");
  };

  const handleNewAuditButton = () => {
    setIsNewAuditToggle(true);
  };

  const fetchAuditAPI = async () => {
    try {
      const token = getCookie();
      let URL = "";

      if (auditName === "Health & Safety") {
        URL = `${process.env.REACT_APP_BASE_URL}/get-health-safety/${clientId}`;
      } else if (auditName === "Safeguarding & Well-Being") {
        URL = `${process.env.REACT_APP_BASE_URL}/get-Safeguarding-Well-Being/${clientId}`;
      }
      if (auditName === "Care Plan & Documentation Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/get-Careplan-Documentation-Audit/${clientId}`;
      }
      if (auditName === "Service User Satisfaction Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/get-Service-User-Satisfaction-Audit/${clientId}`;
      }
      if (auditName === "Medication Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/get-Medication-Audit/${clientId}`;
      }
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      const activeData = data.data.filter(
        (item) => item.status === "Active" || "Draft"
      );
      const historyData = data.data.filter((item) => item.status === "History");
      const transformedActiveData = activeData.map((item) => {
        return {
          ...item,
          client_id: item.client_id || 0,
          consent: 0,
          sign_date1:
            item.sign_1 === signName ? dayjs().toISOString() : item.sign_date1,
          sign_date2:
            item.sign_1 === signName
              ? item.sign_date2
              : item.sign_2 === signName
              ? dayjs().toISOString()
              : item.sign_2 === ""
              ? dayjs().toISOString()
              : item.sign_date2,
          sign_1: item.sign_1,
          sign_2:
            item.sign_1 === signName
              ? item.sign_2
              : item.sign_1 === item.sign_2
              ? ""
              : item.sign_2 || signName,
          // audited_on: item.audited_on ? item.audited_on : dayjs(),
          // due_on: item.due_on ? item.due_on : dayjs().add(60, "days"),

          audited_on: dayjs(),
          due_on: dayjs().add(60, "days"),
        };
      });

      if (response.ok === true) {
        if (isEditToggle) {
          setAuditResponseForm(transformedActiveData);
        } else {
          setAuditResponseForm(activeData);
        }
        setHistoryAudits(historyData);
      } else {
        console.log("get all failed");
        setAuditResponseForm([]);
        setHistoryAudits([]);
      }
    } catch (e) {
      console.log("error", e.message);
      setAuditResponseForm([]);
      setHistoryAudits([]);
    }
  };

  // useEffect(() => {
  //   finalAuditDetails.forEach((audit) => {
  //     if (audit.audit_name === auditName && audit.status === "") {
  //       setIsNewAuditToggle(true);
  //     }
  //   });
  //   if (isNewAuditToggle) {
  //     const auditCards = Array.isArray(selectedAuditCard)
  //       ? selectedAuditCard
  //       : [selectedAuditCard];

  //     const initialFormState = {};

  //     auditCards.forEach((audit) => {
  //       audit.questions.forEach((ques) => {
  //         initialFormState[ques.ques_name] = "";
  //       });

  //       audit.action_plan.forEach((action) => {
  //         initialFormState[action.action_name] = "";
  //       });
  //     });

  //     initialFormState["audited_on"] = dayjs();
  //     initialFormState["due_on"] = dayjs().add(60, "days");
  //     initialFormState["sign_1"] = signName;
  //     initialFormState["sign_2"] = "";
  //     initialFormState["sign_date1"] = dayjs().toISOString();
  //     initialFormState["sign_date2"] = "";
  //     // initialFormState["created_at"] = "";
  //     initialFormState["method_audit"] = "";
  //     initialFormState["status"] = "Active";
  //     initialFormState["client_id"] = clientId;

  //     setAuditResponseForm([initialFormState]);
  //   } else {
  //     fetchAuditAPI();
  //   }
  // }, [isNewAuditToggle, selectedAuditCard]);
  const [isNewAudit, setIsNewAudit] = useState(
    finalAuditDetails.some(
      (audit) => audit.audit_name === auditName && audit.status === ""
    )
  );

  // useEffect(() => {
  //   if (isNewAudit) {
  //     console.log("Entered isNewAudit");
  //     // Build the initial form state for a new audit
  //     const auditCards = Array.isArray(selectedAuditCard)
  //       ? selectedAuditCard
  //       : [selectedAuditCard];

  //     const initialFormState = {};

  //     auditCards.forEach((audit) => {
  //       audit.questions.forEach((ques) => {
  //         initialFormState[ques.ques_name] = "";
  //       });
  //       audit.action_plan.forEach((action) => {
  //         initialFormState[action.action_name] = "";
  //       });
  //     });

  //     initialFormState["audited_on"] = dayjs();
  //     initialFormState["due_on"] = dayjs().add(60, "days");
  //     initialFormState["sign_1"] = signName;
  //     initialFormState["sign_2"] = "";
  //     initialFormState["sign_date1"] = dayjs().toISOString();
  //     initialFormState["sign_date2"] = "";
  //     initialFormState["method_audit"] = "";
  //     initialFormState["status"] = "Active";
  //     initialFormState["client_id"] = clientId;

  //     setAuditResponseForm([initialFormState]);
  //     setIsNewAuditToggle(true);
  //   }
  // }, [finalAuditDetails, selectedAuditCard]);

  // useEffect(() => {
  //   if (isNewAuditToggle) {
  //     const auditCards = Array.isArray(selectedAuditCard)
  //       ? selectedAuditCard
  //       : [selectedAuditCard];

  //     const initialFormState = {};

  //     auditCards.forEach((audit) => {
  //       audit.questions.forEach((ques) => {
  //         initialFormState[ques.ques_name] = "";
  //       });
  //       audit.action_plan.forEach((action) => {
  //         initialFormState[action.action_name] = "";
  //       });
  //     });

  //     initialFormState["audited_on"] = dayjs();
  //     initialFormState["due_on"] = dayjs().add(60, "days");
  //     initialFormState["sign_1"] = signName;
  //     initialFormState["sign_2"] = "";
  //     initialFormState["sign_date1"] = dayjs().toISOString();
  //     initialFormState["sign_date2"] = "";
  //     initialFormState["method_audit"] = "";
  //     initialFormState["status"] = "Active";
  //     initialFormState["client_id"] = clientId;

  //     setAuditResponseForm([initialFormState]);
  //   } else {
  //     fetchAuditAPI();
  //   }
  // }, [finalAuditDetails, selectedAuditCard]);
  useEffect(() => {
    // This handles both creating the new audit form and fetching audit details
    if (isNewAudit) {
      console.log("Entered isNewAudit");
      // Build the initial form state for a new audit
      const auditCards = Array.isArray(selectedAuditCard)
        ? selectedAuditCard
        : [selectedAuditCard];

      const initialFormState = {};

      auditCards.forEach((audit) => {
        audit.questions.forEach((ques) => {
          initialFormState[ques.ques_name] = "";
        });
        audit.action_plan.forEach((action) => {
          initialFormState[action.action_name] = "";
        });
      });

      initialFormState["audited_on"] = dayjs();
      initialFormState["due_on"] = dayjs().add(60, "days");
      initialFormState["sign_1"] = signName;
      initialFormState["sign_2"] = "";
      initialFormState["sign_date1"] = dayjs().toISOString();
      initialFormState["sign_date2"] = "";
      initialFormState["method_audit"] = "";
      initialFormState["status"] = "Active";
      initialFormState["client_id"] = clientId;

      setAuditResponseForm([initialFormState]);
      setIsNewAuditToggle(true); // Toggle this flag to indicate that the new audit setup is done
    } else if (isNewAuditToggle) {
      console.log("Entered isNewAudit");
      // Build the initial form state for a new audit
      const auditCards = Array.isArray(selectedAuditCard)
        ? selectedAuditCard
        : [selectedAuditCard];

      const initialFormState = {};

      auditCards.forEach((audit) => {
        audit.questions.forEach((ques) => {
          initialFormState[ques.ques_name] = "";
        });
        audit.action_plan.forEach((action) => {
          initialFormState[action.action_name] = "";
        });
      });

      initialFormState["audited_on"] = dayjs();
      initialFormState["due_on"] = dayjs().add(60, "days");
      initialFormState["sign_1"] = signName;
      initialFormState["sign_2"] = "";
      initialFormState["sign_date1"] = dayjs().toISOString();
      initialFormState["sign_date2"] = "";
      initialFormState["method_audit"] = "";
      initialFormState["status"] = "Active";
      initialFormState["client_id"] = clientId;

      setAuditResponseForm([initialFormState]);
    } else if (!isNewAuditToggle) {
      fetchAuditAPI(); // Fetch the existing audit details if not a new audit
    }
  }, [finalAuditDetails, selectedAuditCard, isNewAuditToggle]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        <AuditHeader head={`${clientName}'s Audit`} isHideButton={true} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Align all buttons to the right
            alignItems: "center", // Vertically center the buttons
            position: "absolute",
            top: "16px",
            right: "25px",
            zIndex: 11,
          }}
        >
          {isEditToggle || isNewAuditToggle ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={toggleReturnMode}
              sx={{
                marginRight: "10px",
                height: "35px",
              }}
            >
              Return
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<EditIcon />}
              onClick={toggleEditMode}
              sx={{
                marginRight: "10px",
                height: "35px",
              }}
            >
              Edit
            </Button>
          )}
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={handleNewAuditButton}
            sx={{
              marginRight: "10px",
              height: "35px",
            }}
            // disabled={isEditToggle ? false : true}
          >
            New Audit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBackButton}
            sx={{
              marginRight: "10px",
              height: "35px",
            }}
          >
            Back
          </Button>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={9} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  paddingRight: 2,
                  margin: 0,
                  marginRight: "20px",
                  backgroundColor:
                    isEditToggle || isNewAuditToggle ? "white" : "#F0FCFA",
                  overflow: "auto", // Enable scrolling for content
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    position: "sticky",
                    top: 0,
                    padding: "10px",
                    backgroundColor:
                      isEditToggle || isNewAuditToggle ? "white" : "#F0FCFA",
                    // zIndex: 4,
                    marginRight: "20px",
                    boxShadow: "0px",
                  }}
                >
                  <Typography variant="h4">{auditName}</Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", lineHeight: "20px" }}
                  >
                    <b>Note:</b> {selectedAuditCard.note}
                  </Typography>
                </Box>
                {isEditToggle || isNewAuditToggle ? (
                  <AuditEdit
                    clientId={clientId}
                    auditName={auditName}
                    selectedAuditCard={selectedAuditCard}
                    setIsEditToggle={setIsEditToggle}
                    auditResponseForm={auditResponseForm}
                    setAuditResponseForm={setAuditResponseForm}
                    isEditToggle={isEditToggle}
                    isNewAuditToggle={isNewAuditToggle}
                    setIsNewAuditToggle={setIsNewAuditToggle}
                    signName={signName}
                    setIsNewAudit={setIsNewAudit}
                  />
                ) : (
                  <AuditView
                    auditName={auditName}
                    selectedAuditCard={selectedAuditCard}
                    auditResponseForm={auditResponseForm}
                    setAuditResponseForm={setAuditResponseForm}
                  />
                )}
              </Card>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  margin: 0,
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Audits
                  </Box>
                  <Box sx={{ paddingBlock: "15px" }}>
                    {historyAudits.length > 0
                      ? // &&
                        // historyAudits.some(
                        //   (assessment) => assessment.sign_1 || assessment.client_id
                        // )
                        historyAudits.map((history, index) => (
                          <HistoricalCards
                            index={index} // Use a unique key
                            auditName={auditName}
                            auditResponseForm={auditResponseForm}
                            history={history}
                            selectedAuditCard={selectedAuditCard}
                          />
                        ))
                      : null}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AuditFormView;
