import React, { useRef, useEffect } from "react";

import { Typography, Box } from "@mui/material";

export const MStatus = ({ status }) => {
  const statusStyles = {
    FullyTaken: {
      backgroundColor: "#F1FFF3",
      border: "1.5px solid #03AE71",
      color: "#03AE71",
    },
    PartiallyTaken: {
      backgroundColor: "#FFF0E3",
      border: "1.5px solid #F5891D",
      color: "#FA8733",
    },
    Scheduled: {
      backgroundColor: "#9D9F9F4d",
      border: "1.5px solid #9D9F9F",
      color: "#9D9F9F",
    },
    NotTaken: {
      backgroundColor: "#FF00004d",
      border: "1.5px solid #FF0000",
      color: "#FF0000",
    },
    Other: {
      backgroundColor: "#3295CC4d",
      border: "1.5px solid #3295CC",
      color: "#3295CC",
    },
  };
  const getStatusStyle = (status) => {
    const normalizeStatus = status.replace(/\s+/g, "");
    return statusStyles[normalizeStatus] || statusStyles["NotTaken"];
  };

  const styles = getStatusStyle(status);

  return (
    <Typography
      sx={{
        fontSize: "11px",
        // background: "blue",
        padding: "4px",
        width: "90px",
        textAlign: "center",
        borderRadius: "8px",
        fontWeight: 600,
        marginTop: "auto",
        marginBottom: "auto",
        fontFamily: "Lora",
        ...styles,
      }}
      variant="body1"
    >
      {status}
    </Typography>
  );
};

export const TStatus = ({ status }) => {
  const statusStyles = {
    Completed: {
      backgroundColor: "#F1FFF3",
      border: "1.5px solid #03AE71",
      color: "#03AE71",
    },
    NotCompleted: {
      backgroundColor: "#FFF0E3",
      border: "1.5px solid #F5891D",
      color: "#FA8733",
    },
    Scheduled: {
      backgroundColor: "#9D9F9F4d",
      border: "1.5px solid #9D9F9F",
      color: "#9D9F9F",
    },
  };
  const getStatusStyle = (status) => {
    const normalizeStatus = status.replace(/\s+/g, "");
    return statusStyles[normalizeStatus] || statusStyles["NotTaken"];
  };

  const styles = getStatusStyle(status);

  return (
    <Typography
      sx={{
        fontSize: "11px",
        padding: "4px",
        width: "90px",
        textAlign: "center",
        borderRadius: "8px",
        fontWeight: 600,
        marginTop: "auto",
        marginBottom: "auto",
        fontFamily: "Lora",
        ...styles,
      }}
      variant="body1"
    >
      {status}
    </Typography>
  );
};
