import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { setCookie, removeCookie } from "../../../utils/utils";
import Logoanimation from "../../../assets/images/common/logo_animation.gif";

const Verify = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccessTokenAPI = async () => {
      const URL = `${process.env.REACT_APP_BASE_URL}/dashboard/${token}`;
      try {
        const response = await fetch(URL, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        if (response.status === 200 && response.ok) {
          const data = await response.json();
          setCookie(token, data.userData.token_created_at);
          localStorage.setItem("user", JSON.stringify(data.userData));
          navigate("/users");
        } else {
          removeCookie();
          navigate("/login");
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        removeCookie();
        navigate("/login");
      }
    };
    checkAccessTokenAPI();
  }, [token, navigate]);

  return (
    <div>
      <img src={Logoanimation} alt="Verifying..." />
    </div>
  );
};

export default Verify;
