import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import CareboxLogo from "../../../assets/images/users/logo.jpg";
import CareboxLogo from "../../../assets/images/common/agencyLogo.png";
import dayjs from "dayjs";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import CloseIcon from "../../../assets/icons/close.svg";

import jsPDF from "jspdf";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: "calc(100vh - 480px)",
    overflowY: "auto",
    position: "relative",
  },
  tableHeaderCell: {
    textAlign: "center !important",
    padding: "4px !important",
    backgroundColor: "#279989 !important", // Ensure background color is applied
    border: "none !important",
    color: "#FFFFFF !important", // Ensure text color is applied
  },
  tableBodyRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#EEFEFA",
    },
    border: "none",
  },
  tableBodyCell: {
    textAlign: "center !important",
    padding: "4px !important",
    border: "none !important",
  },
  timeDifferenceText: {
    fontSize: "0.9em",
    color: "#555",
  },
  invoiceToText: {
    fontSize: "18px",
    fontFamily: "Lora",
  },
  breakWord: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    width: "280px !important",
    paddingLeft: "0px !important",
    wordWrap: "break-word !important", // Ensures long words break onto the next line
    overflowWrap: "break-word !important", // Alternative for broader compatibility
    color: "#000000 !important",
  },
});
const InvoiceDetail = ({ label, value }) => (
  <Typography
    sx={{
      fontSize: "15px",
      color: "#000000",
      fontFamily: "Lora",
      fontWeight: "600",
      display: "flex",
      gap: "5px",
      p: 0,
    }}
  >
    <span>{label} :</span>
    <span style={{ color: "#707171" }}>{value}</span>
  </Typography>
);
function ClientInvoiceView({ selectedToView, closeModal }) {
  const classes = useStyles();
  const minDate = selectedToView[0]?.minVisitDate; // Likely a string or Date object
  const maxDate = selectedToView[0]?.maxVisitDate;
  const inVoiceDate = selectedToView[0]?.invoiceCreatedDate;
  const DueDate = selectedToView[0]?.invoiceDueDate;
  const finalMinDate = minDate ? dayjs(minDate).format("DD-MM-YYYY") : null;
  const finalMaxDate = maxDate ? dayjs(maxDate).format("DD-MM-YYYY") : null;
  const finalinVoiceDate = maxDate
    ? dayjs(inVoiceDate).format("DD-MM-YYYY")
    : null;
  const finalDueDate = maxDate ? dayjs(DueDate).format("DD-MM-YYYY") : null;

  const calculateTimeDifference = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);

    const diff = Math.abs(endDate - startDate); // Difference in milliseconds
    const hours = Math.floor(diff / (1000 * 60 * 60)); // Convert to hours
    const minutes = Math.floor((diff / (1000 * 60)) % 60); // Convert to minutes

    return `${hours.toString().padStart(2, "0")} hrs ${minutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  const calculateTotalTime = (data) => {
    let totalMinutes = 0;
  
    data.forEach((item) => {
      if (item.totalPlannedTimeUserCount) {
        const [hours, minutes] = item.totalPlannedTimeUserCount.split(":").map(Number);
        totalMinutes += hours * 60 + minutes; // Convert hours to minutes and add to total
      }
    });
  
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
  
    return `${totalHours.toString().padStart(2, "0")} hrs ${remainingMinutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  
  return (
    <div sx={{ height: "100%" }}>
      <Box
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute", // Position relative to the entire Modal
          zIndex: 1000,
          right: 5, // Align with the modal's right edge
          top: 10, // Align with the modal's top padding
          width: "20px",
          height: "20px",
          backgroundImage: `url(${CloseIcon})`,
          backgroundSize: "cover",
          "&:hover": {
            backgroundImage: `url(${CloseHoverIcon})`,
            cursor: "pointer",
          },
        }}
      />
      {selectedToView && (
        <Box sx={{ overflowY: "scroll", height: "94vh", pr: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              pt: 2, // Consistent padding from the top
              px: 3,
              py: 2,
            }}
          >
            {/* Left Section */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <img
                src={CareboxLogo}
                alt="logo"
                style={{ width: "110px", height: "110px" }}
              />
              <Box
                sx={{ pl: 3, pt: 3, display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h4" className={classes.invoiceToText}>
                  Invoice to:
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontSize: "14px", fontFamily: "Lora", p: 0 }}
                >
                  Lancashire Council
                </Typography>
                <Typography sx={{ fontSize: "14px", fontFamily: "Lora", p: 0 }}>
                  11 Business center, <br /> Ribbleton Lane preston PR15EZ
                </Typography>
                <Typography
                  variant="h7"
                  sx={{ fontFamily: "Lora", p: 0, fontSize: "14px" }}
                >
                  PA Number : PA 123456
                </Typography>
                <Typography
                  variant="h7"
                  sx={{ fontFamily: "Lora", p: 0, fontSize: "14px" }}
                >
                  PA Number : PA 123456
                </Typography>
              </Box>
            </Box>

            {/* Right Section */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "36px",
                  fontWeight: "600",
                  fontFamily: "Lora",
                  p: 0,
                  ml: -6,
                }}
              >
                INVOICE
              </Typography>
              <Box sx={{ display: "grid", gap: 1, mt: 3 }}>
                <InvoiceDetail
                  label="Invoice"
                  value={selectedToView[0]?.invoiceNo || "N/A"}
                />
                <InvoiceDetail
                  label="Issue Date"
                  value={finalinVoiceDate || "N/A"}
                />
                <InvoiceDetail label="Due Date" value={finalDueDate || "N/A"} />
              </Box>
              <Button
                sx={{
                  background: "#279989",
                  p: 1.5,
                  mt: 4,
                  "&:hover": { background: "#1e7f73" },
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  mb: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", p: 0, alignSelf: "flex-start" }}
                >
                  <small style={{ fontSize: "9px" }}>Total Due :</small>
                  {` £ ${selectedToView.reduce(
                    (sum, item) => sum + item.totalPlannedTimePay,
                    0
                  )}`}
                </Typography>
              </Button>
              <Typography
                variant="h7"
                sx={{
                  py: 2,
                  fontSize: "14px",
                }}
              >{`Invoice from  ${finalMinDate} to ${finalMaxDate}`}</Typography>
            </Box>
          </Box>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    Visit Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Session
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Planned Time
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Users
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Hourly Rate
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Total Pay
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedToView?.map((item, index) => (
                  <TableRow key={item.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableBodyCell}>
                      {dayjs(item.visitDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item.sessionType}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {`${item.plannedStartTime} - ${item.plannedEndTime}`}
                      <br />
                      <span className={classes.timeDifferenceText}>
                        {calculateTimeDifference(
                          item.plannedStartTime,
                          item.plannedEndTime
                        )}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item.userCount}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item.hourlyPay}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                        {item?.totalPlannedTimePay &&  `£ ${item.totalPlannedTimePay}`} 
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ width: "69%", float: "right" }}>
            <Box>
              <Typography
                variant="h7"
                sx={{
                  fontSize: "14px",
                  p: 0,
                  fontFamily: "Lora",
                }}
              >
                <span>Total : </span> {calculateTotalTime(selectedToView)}
                <Typography
                  variant="h7"
                  sx={{ float: "right", fontFamily: "Lora",pr:5 }}
                >
                  Total: £
                  {selectedToView.reduce(
                    (sum, item) => sum + item.totalPlannedTimePay,
                    0
                  )}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ pt: 8, pl: 4 }}>
            <Typography variant="h7" sx={{ fontSize: "17px" }}>
              Terms and Conditions :
            </Typography>
            <ul style={{padding:'0px',marginLeft:'20px'}}>
              <li style={{p:0 ,fontSize:"14px",lineHeight:2}}>Payment is due within <b>15 days</b> from the invoice date.</li>
              <li style={{p:0,fontSize:"14px"}}>Payment must be made via <b>Bank transfer</b> to the account details provided on the invoice.</li>
            </ul>
            <Typography
              sx={{
                pt: 1,
                px: 0,
                fontSize: "17px",
                fontWeight: 700,
                color: "#48464D",
              }}
            >
              Payment Info : 
            </Typography>
            <Typography className={classes.breakWord}>         
              Name   <b style={{paddingLeft:'44px'}}> The Home Givers</b> 
            </Typography>
            <Typography className={classes.breakWord}>
              Account  <b style={{paddingLeft:'30px'}}> 89xxx520</b> 
            </Typography>
            <Typography className={classes.breakWord}>
              Sort Code  <b style={{paddingLeft:'20px'}}>22-xx-69</b>
            </Typography>
            <Typography className={classes.breakWord}>
              Branch  <b style={{paddingLeft:'38px'}}>Lloyds Bank</b>
            </Typography>           
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pt: 6,
              gap: 1,
            }}
          >
            <Typography variant="h7">Thank you for your business</Typography>
            <Typography variant="h7"> The Care Givers</Typography>
            <Typography variant="h7">
              # The watermark studios 9-15 Ribbleton Lane Preston PR15EZ{" "}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ClientInvoiceView;
