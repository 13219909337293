import React, { useState } from "react";
import CustomButtom from "../reusableComponents/CustomButton";
import CareboxLogo from "../../assets/images/users/logo.jpg";
import agencyLogo from "../../assets/images/users/agency2.jpg";
import { Button, Menu, MenuItem } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

function Download({
  data,
  columns,
  selectToDownload,
  heading,
  rotateMode,
  excelData,
}) {
  const doc = new jsPDF(rotateMode ? "landscape" : "portrait");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const exportClientsPdf = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableMargin = 4; // Margin for the table on left and right
    const imageHeight = 13; // Fixed height for the images
    const imageYPosition = 10; // Fixed vertical position for the images
    const logoWidthLeft = 33; // Width for the left logo
    const logoWidthRight = 33; // Width for the right logo

    doc.setFillColor("f5fcff");
    doc.rect(0, 0, pageWidth, doc.internal.pageSize.getHeight(), "F"); // Page background
    doc.addImage(
      CareboxLogo,
      "JPEG",
      tableMargin,
      imageYPosition,
      logoWidthLeft,
      imageHeight
    ); // Left logo
    doc.addImage(
      agencyLogo,
      "JPEG",
      pageWidth - tableMargin - logoWidthRight,
      imageYPosition,
      logoWidthRight,
      imageHeight
    );
    const leftDistance = rotateMode ? 110 : 70;
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`${heading}`, leftDistance, 25);
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().split("T")[0];
    const formatedDate = dayjs(today).format("DD-MM-YYYY");
    doc.text(`Date: ${formatedDate}`, 15, 30);
    doc.autoTable({
      head: [columns],
      body: data,
      startY: 33,
      tableWidth: "auto",
      margin: { top: 10, left: tableMargin, right: tableMargin },
      headStyles: {
        fillColor: "#279989",
        fontStyle: "normal",
        fontSize: 12,
        halign: "center", // Center-align header text horizontally
        valign: "middle", // Center-align header text vertically
        cellPadding: { top: 4, bottom: 4, right: 2, left: 2 }, // Adjust padding for better alignment
        textColor: "#FFFFFF", // White text for better contrast
      },
      styles: {
        fontSize: 12,
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
        overflow: "linebreak", // Ensures text wraps within the cell
      },
      bodyStyles: {
        cellPadding: { top: 4, bottom: 4, right: 2, left: 2 }, // Add padding to ensure the text doesn't touch the edges
        minCellHeight: 10, // Default minimum height for each cell
        halign: "center", // Default horizontal alignment for body cells
        valign: "middle", // Default vertical alignment for body cells
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      columnStyles: {
        0: { cellWidth: "auto", halign: "center", valign: "middle" },
        1: { cellWidth: "auto", halign: "center", valign: "middle" },
        2: { cellWidth: "auto", halign: "center", valign: "middle" },
        3: { cellWidth: "auto", halign: "center", valign: "middle" },
        4: { cellWidth: "auto", halign: "center", valign: "middle" },
        5: { cellWidth: "auto", halign: "center", valign: "middle" },
        6: { cellWidth: "auto", halign: "center", valign: "middle" },
        7: { cellWidth: "auto", halign: "center", valign: "middle" },
        8: { cellWidth: "auto", halign: "center", valign: "middle" },
        9: { cellWidth: "auto", halign: "center", valign: "middle" },
        10: { cellWidth: "auto", halign: "center", valign: "middle" },
        11: { cellWidth: "auto", halign: "center", valign: "middle" },
      },
      didDrawCell: (data) => {
        const cellContent = data.cell.raw;

        // If the column has no values (e.g., "Specialist"), enforce center alignment
        if (data.column.index === 4 && !cellContent) {
          data.cell.styles.valign = "middle";
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`${heading}_${today}.pdf`);
    handleClose();
  };

  const exportClientsExcel = () => {
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Calculate column widths dynamically
    const columnWidths = Object.keys(excelData[0]).map((key) => {
      const maxLength = excelData.reduce((max, row) => {
        const cellValue = row[key] || ""; // Fallback for empty cells
        return Math.max(max, cellValue.toString().length);
      }, key.length); // Include header length
      return { wch: maxLength + 2 }; // Add padding for better appearance
    });

    // Apply column widths to the worksheet
    worksheet["!cols"] = columnWidths;

    // Apply center alignment to all cells
    Object.keys(worksheet).forEach((cell) => {
      if (cell[0] !== "!") {
        worksheet[cell].s = {
          alignment: { horizontal: "center", vertical: "center" },
        };
      }
    });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Data");

    // Save the Excel file
    const today = new Date().toISOString().split("T")[0];
    const excelFileName = `${heading}_${today}.xlsx`;
    XLSX.writeFile(workbook, excelFileName);
    handleClose();
  }; 

  return (
    <div>
      <CustomButtom
        text="Download"
        onClick={handleClick}
        disabled={selectToDownload?.length === 0}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={exportClientsPdf}>PDF</MenuItem>
        <MenuItem onClick={exportClientsExcel}>Excel</MenuItem>
      </Menu>
    </div>
  );
}

export default Download;
