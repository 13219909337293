import React from "react";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { FormLabel } from "react-bootstrap";

import UploadDocument from "../../../assets/images/clients/Upload_document.svg";
import Add from "../../../assets/images/clients/add.svg";
import { auditCardDetails } from "./reusableAuditComponents";

const AuditsAndConsentSidebar = ({
  clientMainData,
  setConsent,
  setAuditName,
  setSelectedAuditCard,
}) => {
  const consentFormOptions = [
    { text: "Consent to Care" },
    { text: "Key Safe Consent" },
    { text: "GDPR Consent" },
    { text: "Family Member Access to Care Log" },
  ];

  const auditOptions = [
    { text: "Health & Safety" },
    { text: "Safeguarding & Well-Being" },
    { text: "Care Plan & Documentation Audit" },
    { text: "Service User Satisfaction Audit" },
    { text: "Medication Audit" },
  ];

  const handleConsentFormChange = (e, newconsent) => {
    // if (hasUnsavedChanges) {
    // setIsSidebarChanged(true);
    // setDialogOpen(true);

    // setPendingAssessment(newconsent);
    // } else {
    // setIsSidebarChanged(true);

    setConsent(newconsent.text);
    setSelectedAuditCard("");
    // setErrorMessage(""); // Clear error when a assessment is selected
  };

  const handleAuditFormChange = (e, newAudit) => {
    setAuditName(newAudit.text);
    const finalCard = auditCardDetails.find(
      (audit) => audit.audit_name === newAudit.text
    );
    setSelectedAuditCard(finalCard);
    setConsent("");
  };

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column", // Ensure children stack vertically
      }}
    >
      <ClientSideBarHeader
        clientMainData={clientMainData}
        //isEditMode={isEditMode}
      />
      <Box
        sx={{
          width: { xs: "100%" },
          gap: 1,
          overflowY: "auto",
          maxHeight: "100%", // Set a maximum height to trigger scrolling
          padding: "10px 10px",
          //boxSizing: "border-box", // Include padding and border in height calculation
        }}
      >
        <Grid container fullWidth>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "left" }}>
              <FormLabel>
                <Typography variant="h6">Audit Repository</Typography>
              </FormLabel>
              <Autocomplete
                // value={riskAssessment}
                onChange={handleAuditFormChange}
                // inputValue={inputriskassessment}
                // onInputChange={(e, newInputassessment) =>
                //   setInputRiskAssessment(newInputassessment)
                // }
                options={auditOptions}
                getOptionLabel={(option) => option?.text || ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "Center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={UploadDocument}
                        alt="Form Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {option.text}
                      </Typography>
                      <img
                        src={Add}
                        alt="Add Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Audit Form"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "0px 0px ! important",
                        paddingLeft: "0px ! important", // Space for the icon
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <img
                          src={UploadDocument}
                          alt="Form Icon"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }} // Adjust size as needed
                        />
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "left" }}>
              <FormLabel>
                <Typography variant="h6">Consent Forms</Typography>
              </FormLabel>
              <Autocomplete
                // value={riskAssessment}
                onChange={handleConsentFormChange}
                // inputValue={inputriskassessment}
                // onInputChange={(e, newInputassessment) =>
                //   setInputRiskAssessment(newInputassessment)
                // }
                options={consentFormOptions}
                getOptionLabel={(option) => option?.text || ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "Center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={UploadDocument}
                        alt="Form Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {option.text}
                      </Typography>
                      <img
                        src={Add}
                        alt="Add Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Consent Form"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "0px 0px ! important",
                        paddingLeft: "0px ! important", // Space for the icon
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <img
                          src={UploadDocument}
                          alt="Form Icon"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }} // Adjust size as needed
                        />
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AuditsAndConsentSidebar;
