import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Grid,
} from "@mui/material";

import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme, ViewTheme } from "../../users/Theme";
import Name from "../../reusableComponents/Name";
import SignatureCanvas from "react-signature-canvas";
import { Calendar, RadioInput } from "../../reusableComponents";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

export default function UploadDocumentDialog({
  open,
  onClose,
  signatureName,
  setSignatureName,
  setConsent,
  selectedConsentData,
}) {
  const theme = useTheme();

  const sigPadRef = useRef(null);
  const [selectedSignatureType, setSelectedSignatureType] = useState("Draw");
  const [name, setName] = useState("");
  const nameRef = useRef();

  const [selectedFont, setSelectedFont] = useState(null); // Tracks the selected font

  useEffect(() => {
    console.log("signature name", signatureName);
  }, [signatureName]);

  const commonStyles = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: "30px",
    borderRadius: 0,
    textTransform: "none",
    borderColor: "#2799894d",
  };
  // const isDisabled = (font) => selectedFont && selectedFont !== font;

  const handleButtonClick = (font) => {
    if (selectedFont === font) {
      setSelectedFont(null); // Unselect if already selected
    } else {
      setSelectedFont(font); // Select the clicked button
    }
  };

  const handleNameChange = (newValue) => {
    setName(newValue); // Updates name state whenever input changes
  };

  const clearSignature = () => {
    sigPadRef.current.clear();
  };

  const saveSignature = () => {
    const updatedSignatureData = {
      consentName: selectedConsentData.consentName,
      signatureType: selectedSignatureType,
    };

    if (selectedSignatureType === "Draw") {
      const dataURL = sigPadRef.current.toDataURL();
      updatedSignatureData.urlSign = dataURL;
      updatedSignatureData.date = dayjs();
      updatedSignatureData.name = ""; // Clear name for drawn signature
      updatedSignatureData.font = ""; // Clear font for drawn signature
    } else {
      updatedSignatureData.name = name; // Use the typed name
      updatedSignatureData.font = selectedFont; // Use the selected font
      updatedSignatureData.date = dayjs();
      updatedSignatureData.urlSign = ""; // Clear URL for typed signature
    }

    // Update the state for the specific consentName
    // setSignatureName((prevState) => ({
    //   ...prevState,
    //   [selectedConsentData.consentName]: updatedSignatureData,
    // }));
    setSignatureName((prevState) => {
      // Check if the consentName already exists in the array
      const existingIndex = prevState.findIndex(
        (item) => item.consentName === selectedConsentData.consentName
      );

      if (existingIndex !== -1) {
        // If it exists, update the specific item
        const updatedState = [...prevState];
        updatedState[existingIndex] = {
          ...updatedState[existingIndex],
          ...updatedSignatureData,
        };
        return updatedState;
      } else {
        // If it doesn't exist, add it as a new entry
        return [...prevState, updatedSignatureData];
      }
    });

    // Close dialog and reset any necessary states
    onClose();
    setConsent(false); // Assuming setConsent is a function to reset a particular state
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm" // Disable default max-width behavior
        sx={{
          "& .MuiDialog-paper": {
            width: "auto", // Allow width to adjust automatically
            minWidth: "700px", // Prevent exceeding viewport width
            // margin: "10px", // Add some margin around the dialog
          },
        }}
      >
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            width: "28px ! important",
            height: "28px ! important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent
          sx={{
            background: "#FFFFFF",
            paddingInline: "30px",
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ borderBottom: "1px solid #279989" }}>
              Add Signature
            </Typography>
            <Box
              sx={{
                borderBottom: "1px solid #279989",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                onClick={() => setSelectedSignatureType("Draw")}
                sx={{
                  cursor: "pointer",
                  color: selectedSignatureType === "Draw" ? "#279989" : "black",
                  background:
                    selectedSignatureType === "Draw" ? "#2799891a" : "none",
                }}
              >
                Draw
              </Typography>
              <Typography
                variant="body1"
                onClick={() => setSelectedSignatureType("Type")}
                sx={{
                  cursor: "pointer",
                  color: selectedSignatureType === "Type" ? "#279989" : "black",
                  background:
                    selectedSignatureType === "Type" ? "#2799891a" : "none",
                }}
              >
                Type
              </Typography>
            </Box>
            {selectedSignatureType === "Draw" ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #279989",
                }}
              >
                <Typography variant="body1">Sign Here:</Typography>
                <SignatureCanvas
                  ref={sigPadRef}
                  penColor="black"
                  canvasProps={{
                    width: "500px",
                    height: "200px",
                    style: {
                      border: "1px solid #279989",
                      cursor: "pencil",
                    },
                  }}
                />
                <Button
                  variant="outlinedSecondary"
                  onClick={clearSignature}
                  sx={{ marginTop: "20px" }}
                >
                  Clear Signature
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid #279989",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">Type Here:</Typography>
                  <Name
                    nolabel
                    name="name"
                    preFilledvalue={name} // Initial value
                    onValueChange={handleNameChange} // Function to handle value change
                    required={true}
                    maxLength={50}
                    ref={nameRef} // Forward ref to access validation
                  />
                </Box>

                <Grid container direction="row" spacing={0}>
                  <Grid md={6} sm={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        ...commonStyles,
                        fontFamily: "Parisienne",
                        color:
                          selectedFont === "Parisienne"
                            ? "white"
                            : name
                            ? "black"
                            : "#C0C0C0",
                        backgroundColor:
                          selectedFont === "Parisienne"
                            ? "#279989"
                            : "transparent",
                        borderRight: "0px",
                        borderBottom: "0px",
                        [theme.breakpoints.down("md")]: {
                          // Styles for small screens
                          borderRight: "1px solid #2799894d",
                          borderBottom: "0px",
                        },
                        "&:hover": name
                          ? {
                              backgroundColor: "#279989", // Hover color
                              color: "white",
                              borderColor: "#2799894d",
                              borderRight: "0px",
                              borderBottom: "0px",
                              [theme.breakpoints.down("md")]: {
                                // Styles for small screens
                                borderRight: "1px solid #2799894d",
                                borderBottom: "0px",
                              },
                            }
                          : {
                              borderColor: "#2799894d",
                              borderRight: "0px",
                              borderBottom: "0px",
                              [theme.breakpoints.down("md")]: {
                                // Styles for small screens
                                borderRight: "1px solid #2799894d",
                                borderBottom: "0px",
                              },
                            },
                      }}
                      onClick={() => handleButtonClick("Parisienne")}
                      // disabled={isDisabled("Parisienne")}
                    >
                      {name ? name : "Signature"}
                    </Button>
                  </Grid>
                  <Grid md={6} sm={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        ...commonStyles,
                        fontFamily: "Beau Rivage",
                        color:
                          selectedFont === "Beau Rivage"
                            ? "white"
                            : name
                            ? "black"
                            : "#C0C0C0",
                        backgroundColor:
                          selectedFont === "Beau Rivage"
                            ? "#279989"
                            : "transparent",
                        borderBottom: "0px",
                        "&:hover": name
                          ? {
                              backgroundColor: "#279989", // Hover color
                              color: "white",
                              borderColor: "#2799894d",
                              borderBottom: "0px",
                            }
                          : {
                              borderColor: "#2799894d",
                              borderBottom: "0px",
                            },
                      }}
                      onClick={() => handleButtonClick("Beau Rivage")}
                      // disabled={isDisabled("Beau Rivage")}
                    >
                      {name ? name : "Signature"}
                    </Button>
                  </Grid>
                  <Grid md={6} sm={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        ...commonStyles,
                        fontFamily: "Quintessential",
                        color:
                          selectedFont === "Quintessential"
                            ? "white"
                            : name
                            ? "black"
                            : "#C0C0C0",
                        backgroundColor:
                          selectedFont === "Quintessential"
                            ? "#279989"
                            : "transparent",
                        borderRight: "0px",
                        [theme.breakpoints.down("md")]: {
                          // Styles for small screens
                          borderRight: "1px solid #2799894d",
                          borderBottom: "0px",
                        },
                        "&:hover": name
                          ? {
                              backgroundColor: "#279989", // Hover color
                              color: "white",
                              borderColor: "#2799894d",
                              borderRight: "0px",
                              [theme.breakpoints.down("md")]: {
                                // Styles for small screens
                                borderRight: "1px solid #2799894d",
                                borderBottom: "0px",
                              },
                            }
                          : {
                              borderColor: "#2799894d",
                              borderRight: "0px",
                              [theme.breakpoints.down("md")]: {
                                // Styles for small screens
                                borderRight: "1px solid #2799894d",
                                borderBottom: "0px",
                              },
                            },
                      }}
                      onClick={() => handleButtonClick("Quintessential")}
                      // disabled={isDisabled("Quintessential")}
                    >
                      {name ? name : "Signature"}
                    </Button>
                  </Grid>
                  <Grid md={6} sm={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        ...commonStyles,
                        fontFamily: "Felipa",
                        color:
                          selectedFont === "Felipa"
                            ? "white"
                            : name
                            ? "black"
                            : "#C0C0C0",
                        backgroundColor:
                          selectedFont === "Felipa" ? "#279989" : "transparent",
                        "&:hover": name
                          ? {
                              backgroundColor: "#279989", // Hover color
                              color: "white",
                              borderColor: "#2799894d",
                            }
                          : {
                              borderColor: "#2799894d",
                            },
                      }}
                      onClick={() => handleButtonClick("Felipa")}
                      // disabled={isDisabled("Felipa")}
                    >
                      {name ? name : "Signature"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "20px",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1" sx={{ marginBottom: "-30px" }}>
                Sign Date:
              </Typography>
              <Calendar
                name="sign_date"
                label=""
                //   onDateChange={handleDateChange}
                preFilledvalue={dayjs()}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="customcancel"
                sx={{ marginTop: "20px" }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="customsave"
                sx={{ marginTop: "20px" }}
                onClick={saveSignature}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
