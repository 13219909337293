import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Typography } from "@mui/material";

// Map container style
const containerStyle = {
  width: "100%",
  height: "400px",
};

// Component
const MapWithGeocoding = ({ postcode, placeId, setPlaceId, cradius }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAw6CI20mwdHFwffUeQh_2mN27YvB_PxKo",
    libraries: ["places"],
  });

  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const circleRef = useRef(null); // Use useRef to track the circle
  const [formattedAddress, setFormattedAddress] = useState("");
  const [step, setStep] = useState(0);
  const [radius, setRadius] = useState(cradius ? cradius : 200); // Set radius to 200 meters

  useEffect(() => {
    if (isLoaded) {
      initMap();
      setStep(1);
    }
  }, [isLoaded, radius]);
  useEffect(() => {
    if (isLoaded && postcode && step != 0) {
      geocode(postcode);
    }
    console.log("cradius:", cradius);
  }, [postcode]);

  useEffect(() => {
    setRadius(cradius ? +cradius : 200);
    console.log("cradius:", cradius);
  }, [cradius]);

  // Initialize the map
  const initMap = () => {
    mapRef.current = new window.google.maps.Map(
      document.getElementById("map"),
      {
        zoom: 15,
        center: { lat: -34.397, lng: 150.644 },
        mapTypeControl: false,
      }
    );

    // Create a marker
    markerRef.current = new window.google.maps.Marker({
      map: mapRef.current,
      draggable: true,
    });

    // Add listener for marker drag end
    markerRef.current.addListener("dragend", () => {
      const position = markerRef.current.getPosition();
      updateCircle(position); // Update the circle position when marker is dragged
      geocodeFromPosition(position); // Geocode based on marker's new position
    });

    // If a postcode is provided, geocode it
    if (placeId) {
      geocodePlaceId(placeId);
    } else if (postcode) {
      geocode(postcode);
    }
  };

  const geocode = (postcode) => {
    clear(); // Clear previous marker and circle
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: postcode }).then((result) => {
      const { results } = result;

      if (results && results[0]) {
        const location = results[0].geometry.location;
        const formattedAddress = results[0].formatted_address;

        console.log("Result:", results[0]);
        const place = results[0].place_id;
        setPlaceId(place);

        mapRef.current.setCenter(location);
        markerRef.current.setPosition(location);
        markerRef.current.setMap(mapRef.current);

        setFormattedAddress(formattedAddress);
        updateCircle(location); // Create and display the circle
      }
    });
  };
  const geocodePlaceId = (placeId) => {
    clear(); // Clear previous marker and circle
    const geocoder = new window.google.maps.Geocoder();
    //const placeId = "GhIJbb9rhgoUMUARytDCryLsU0A";

    geocoder.geocode({ placeId: placeId }).then((result) => {
      const { results } = result;

      if (results && results[0]) {
        const location = results[0].geometry.location;
        const formattedAddress = results[0].formatted_address;

        const place = results[0].place_id;
        setPlaceId(place);

        mapRef.current.setCenter(location);
        markerRef.current.setPosition(location);
        markerRef.current.setMap(mapRef.current);

        setFormattedAddress(formattedAddress);
        updateCircle(location); // Create and display the circle
      }
    });
  };

  const geocodeFromPosition = (position) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: position }).then((result) => {
      const { results } = result;
      //console.log("Result:", results[0].navigation_points[0].location);

      if (results && results[0]) {
        console.log("Result:", results[0]);
        const place = results[0].place_id;
        setPlaceId(place);
        const formattedAddress = results[0].formatted_address;

        setFormattedAddress(formattedAddress); // Update formatted address
      }
    });
  };

  // Updated updateCircle function to manage the circle properly
  const updateCircle = (position) => {
    // Remove the old circle if it exists
    if (circleRef.current) {
      circleRef.current.setMap(null);
    }

    // Create a new circle
    const newCircle = new window.google.maps.Circle({
      strokeColor: "#279989",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#2799894d",
      fillOpacity: 0.35,
      map: mapRef.current,
      center: position,
      radius: radius,
    });

    // Store the new circle reference
    circleRef.current = newCircle;
  };

  const clear = () => {
    if (markerRef.current) {
      markerRef.current.setMap(null);
    }
    if (circleRef.current) {
      circleRef.current.setMap(null); // Remove the existing circle from the map
      circleRef.current = null; // Clear the reference
    }
    setFormattedAddress("");
  };

  if (loadError)
    return (
      <div>
        <Typography variant="body1">Error loading maps</Typography>
      </div>
    );
  if (!isLoaded)
    return (
      <div>
        <Typography variant="body1">Loading...</Typography>
      </div>
    );

  return (
    <div>
      <div id="map" style={containerStyle}></div>
      {formattedAddress && (
        <Typography variant="body1">
          <strong>Pointed Address:</strong> {formattedAddress}
          {/* <strong>place Id:</strong> {placeId} */}
        </Typography>
      )}
      {!formattedAddress && (
        <Typography variant="body1" sx={{ color: "red", fontWeight: "700" }}>
          Please Enter Correct Postcode
        </Typography>
      )}
    </div>
  );
};

export default MapWithGeocoding;
