import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  FormLabel,
  Typography,
  ThemeProvider,
  Button,
} from "@mui/material";
import { ViewTheme, EditTheme } from "../../../themes/Theme";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";
import Name from "../../reusableComponents/Name";
import Admin from "../../../assets/images/users/Admin.svg";
import LogTime from "../../reusableComponents/LogTime";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";

export default function LogVisitDetails({
  selectedVisit,
  setHasUnsavedChanges,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [form, setForm] = useState();
  const [startingForm, setStartingForm] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => {
    //fetchVisitDetails();
    setOpenDialog(false);
  };
  const onClickYesDialogBtn = () => {
    setIsEditMode(false);
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    setOpenDialog(false);
    fetchVisitDetails();
  };

  const fetchVisitDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/log-get-visit-details/${selectedVisit.visitdetailsid}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data[0];
        setForm(info);
        setStartingForm(info);
      } else {
        setForm();
        setStartingForm();
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setForm();
      setStartingForm();
    }
  };

  const convertTimes = (originalTimes) => {
    return originalTimes.map((item) => ({
      useractualtimeid: item.useractualtimeid, // Generate new IDs starting from 150
      actualStartTime: item.actualStartTime, // Set the start time as per your logic
      actualEndTime: item.actualEndTime, // Set the end time as per your logic
    }));
  };

  const putVisitDetailsAPI = async () => {
    try {
      const token = getCookie();
      const ActualTimes = convertTimes(form.actualTimes);
      const body = {
        comments: "",
        actualTimes: ActualTimes,
      };

      console.log(body, "put api body................");
      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/log-update-visitDetailsid/${form.visitdetailsid}`;
      console.log(form.visitdetailsid);
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
        setIsEditMode(false);
        scrollToTop();
      } else {
        console.log("Failed to edit Visit.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (selectedVisit?.visitdetailsid) {
      fetchVisitDetails();
      console.log("Visit Details", form);
    } else {
      setForm();
      setStartingForm();
    }
    setIsEditMode(false);
  }, [selectedVisit]);

  // Safely extract times or default to null
  const plannedStartTime = form?.plannedStarttime
    ? dayjs(`${dayjs().format("YYYY-MM-DD")} ${form.plannedStarttime}`)
    : null;
  const plannedEndTime = form?.plannedEndtime
    ? dayjs(`${dayjs().format("YYYY-MM-DD")} ${form.plannedEndtime}`)
    : null;

  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true; // Same reference, return true

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return obj1 === obj2; // Compare primitive values
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Different number of keys

    return keys1.every((key) => {
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) return false; // Check if key exists in obj2
      return areObjectsEqual(obj1[key], obj2[key]); // Recursively compare values
    });
  };

  const FormRow = ({ label, value }) => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ marginBottom: "10px" }}
      >
        <Grid item xs={3} lg={2.5}>
          <FormLabel>
            <Typography variant={"body4"}>{label}</Typography>
          </FormLabel>
        </Grid>
        <Grid item xs={0.5}>
          <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
        </Grid>
        <Grid item xs={8.5} lg={9} sx={{ marginBottom: "5px" }}>
          <Box sx={{ marginLeft: "20px" }}>
            <Typography variant={"body4"}>
              {value || ""} {/* Fallback to  if value is undefined */}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const FormRow1 = ({ label, value }) => {
    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={3} lg={2.5}>
          <FormLabel>
            <Box sx={{ marginLeft: "20px" }}>
              <Typography variant={"body4"}>{label}</Typography>
            </Box>
          </FormLabel>
        </Grid>
        <Grid item xs={0.5}>
          <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
        </Grid>
        <Grid item xs={8.5} lg={9} sx={{ marginBottom: "5px" }}>
          {" "}
          <Box sx={{ marginLeft: "20px" }}>
            <Typography variant={"body4"}>
              {value || ""} {/* Fallback to  if value is undefined */}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const statusCard = (value) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: (() => {
            const stringValue = String(value); // Convert to string for reliable comparison
            return stringValue === "Completed"
              ? "#279989"
              : stringValue === "In Progress"
              ? "#56ACE0"
              : stringValue === "Action Required"
              ? "#FF8080"
              : stringValue === "Follow Up"
              ? "#CF75E6"
              : "transparent";
          })(),
          borderRadius: "5px",
          color: "white",
          width: "auto",
          justifyContent: "center",
          padding: "5px 10px",
        }}
      >
        {value || ""}
      </Box>
    );
  };
  const calculateTimeDifferenceInMinutes = (start, end) => {
    if (start && end) {
      // Split the start and end time into hours and minutes
      const [startHours, startMinutes] = start.split(":").map(Number);
      const [endHours, endMinutes] = end.split(":").map(Number);

      // Convert the start and end times into total minutes
      const startTotalMinutes = startHours * 60 + startMinutes;
      const endTotalMinutes = endHours * 60 + endMinutes;

      // Calculate the difference in minutes
      const timeDifferenceInMinutes = endTotalMinutes - startTotalMinutes;

      // If the end time is earlier than the start time (e.g., crossing midnight), we handle that case
      return timeDifferenceInMinutes < 0
        ? timeDifferenceInMinutes + 24 * 60
        : timeDifferenceInMinutes;
    } else return 0;
  };
  const containerRef = useRef(null);
  const scrollToTop = () => {
    containerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    scrollToTop();
  };

  const handleCancelClick = () => {
    if (!areObjectsEqual(form, startingForm)) {
      handleOpen();
    } else {
      setIsEditMode(false);
      scrollToTop();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };

  const handleSaveClick = () => {
    console.log("form", form);
    putVisitDetailsAPI();
    setHasUnsavedChanges(false);
  };

  const commentCard = () => {
    return (
      <ThemeProvider theme={EditTheme}>
        <Name
          //name="comments"
          nolabel
          multiline={true}
          preFilledvalue={form?.comments ? form.comments : ""}
          rows={4}
          className="multiline-textfield"
          placeholder={isEditMode ? "Add Comments" : ""}
          sx={{ minWidth: "100%" }}
          disabled={!isEditMode}
          onValueChange={(value) => {
            setForm((prevForm) => {
              const updatedForm = {
                ...prevForm,
                comments: value,
              };
              setHasUnsavedChanges(!areObjectsEqual(updatedForm, form)); // Detect changes
              return updatedForm;
            });
          }}
        />
      </ThemeProvider>
    );
  };

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "97%",
          maxHeight: "calc(100vh - 380px)",
          margin: "0px 20px",
          overflow: "scroll",
          position: "relative",
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            textAlign: "left",
          }}
        >
          {!isEditMode && selectedVisit?.visitStatus === "Completed" && (
            <Box
              sx={{
                position: "absolute",
                right: "20px",
                top: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button variant="outlinedSecondary" onClick={handleEditClick}>
                Edit
              </Button>
            </Box>
          )}
          <Grid container direction="row" alignItems="top" gap={2}>
            <FormRow label="Name" value={form?.clientName || ""} />
            <FormRow label="Address" value={form?.clientaddress || ""} />

            <FormRow
              label="Visit Date"
              value={
                form?.visit_date
                  ? dayjs(form.visit_date).format("DD-MM-YYYY")
                  : ""
              }
            />
            <FormRow
              label="Status"
              value={statusCard(form?.alertStatus || form?.status)}
            />

            <FormRow
              label="Planned Time"
              value={
                plannedStartTime && plannedEndTime
                  ? `${plannedStartTime.format(
                      "HH:mm"
                    )} - ${plannedEndTime.format("HH:mm")}`
                  : ""
              }
            />
            {form?.actualTimes &&
              form.actualTimes.map((time, index) => (
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <FormRow
                    label={`Carer ${index + 1}`}
                    value={time?.userName || ""}
                  />
                  {!isEditMode ? (
                    <FormRow1
                      label="Start Time"
                      value={
                        time.actualStartTime
                          ? `${time.actualStartTime.split(":")[0]} : ${
                              time.actualStartTime.split(":")[1]
                            }`
                          : ""
                      }
                    />
                  ) : (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={3} lg={2.5}>
                        <Box sx={{ marginLeft: "20px" }}>
                          <Typography variant={"body4"}>Start Time</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={0.5}>
                        <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
                      </Grid>
                      <Grid item xs={8.5} lg={9} sx={{ marginBottom: "-5px" }}>
                        <Box sx={{ marginLeft: "20px" }}>
                          {time.actualStartTime && (
                            <LogTime
                              key={time.useractualtimeid}
                              initialHours={
                                time.actualStartTime
                                  ? time.actualStartTime.split(":")[0]
                                  : "00"
                              }
                              initialMinutes={
                                time.actualStartTime
                                  ? time.actualStartTime.split(":")[1]
                                  : "00"
                              }
                              onHoursChange={(value) =>
                                setForm((prevForm) => {
                                  const updatedActualTimes =
                                    prevForm.actualTimes.map((times) => {
                                      if (
                                        times.useractualtimeid ===
                                        time.useractualtimeid
                                      ) {
                                        const [currentHours, currentMinutes] =
                                          times.actualStartTime.split(":"); // Get current hours and minutes

                                        const newTime = `${value}:${currentMinutes}`; // Update only the hour
                                        return {
                                          ...times,
                                          actualStartTime: newTime, // Update either actualStartTime or actualEndTime based on `type`
                                        };
                                      }
                                      return times;
                                    });

                                  const updatedForm = {
                                    ...prevForm,
                                    actualTimes: updatedActualTimes,
                                  };
                                  console.log("form", time.actualTimes);
                                  console.log(
                                    "updatedForm",
                                    updatedActualTimes
                                  );
                                  setHasUnsavedChanges(
                                    !areObjectsEqual(updatedForm, form)
                                  ); // Detect changes
                                  return updatedForm;
                                })
                              }
                              onMinutesChange={(value) =>
                                setForm((prevForm) => {
                                  const updatedActualTimes =
                                    prevForm.actualTimes.map((times) => {
                                      if (
                                        times.useractualtimeid ===
                                        time.useractualtimeid
                                      ) {
                                        const [currentHours, currentMinutes] =
                                          times.actualStartTime.split(":"); // Get current hours and minutes
                                        const newTime = `${currentHours}:${value}`; // Update only the hour
                                        return {
                                          ...times,
                                          actualStartTime: newTime, // Update either actualStartTime or actualEndTime based on `type`
                                        };
                                      }
                                      return times;
                                    });

                                  const updatedForm = {
                                    ...prevForm,
                                    actualTimes: updatedActualTimes,
                                  };

                                  setHasUnsavedChanges(
                                    !areObjectsEqual(updatedForm, form)
                                  ); // Detect changes
                                  return updatedForm;
                                })
                              }
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {!isEditMode ? (
                    <FormRow1
                      label="End Time"
                      value={
                        time.actualEndTime
                          ? `${time.actualEndTime.split(":")[0]} : ${
                              time.actualEndTime.split(":")[1]
                            }`
                          : ""
                      }
                    />
                  ) : (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={3} lg={2.5}>
                        <Box sx={{ marginLeft: "20px" }}>
                          <Typography variant={"body4"}>End Time</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={0.5}>
                        <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
                      </Grid>
                      <Grid item xs={8.5} lg={9} sx={{ marginBottom: "-5px" }}>
                        <Box sx={{ marginLeft: "20px" }}>
                          {time.actualEndTime && (
                            <LogTime
                              key={time.useractualtimeid}
                              initialHours={
                                time.actualEndTime
                                  ? time.actualEndTime.split(":")[0]
                                  : "00"
                              }
                              initialMinutes={
                                time.actualEndTime
                                  ? time.actualEndTime.split(":")[1]
                                  : "00"
                              }
                              onHoursChange={(value) =>
                                setForm((prevForm) => {
                                  const updatedActualTimes =
                                    prevForm.actualTimes.map((times) => {
                                      if (
                                        times.useractualtimeid ===
                                        time.useractualtimeid
                                      ) {
                                        const [currentHours, currentMinutes] =
                                          times.actualEndTime.split(":"); // Get current hours and minutes

                                        const newTime = `${value}:${currentMinutes}`; // Update only the hour
                                        return {
                                          ...times,
                                          actualEndTime: newTime, // Update either actualEndTime or actualEndTime based on `type`
                                        };
                                      }
                                      return times;
                                    });

                                  const updatedForm = {
                                    ...prevForm,
                                    actualTimes: updatedActualTimes,
                                  };
                                  console.log("form", time.actualTimes);
                                  console.log(
                                    "updatedForm",
                                    updatedActualTimes
                                  );
                                  setHasUnsavedChanges(
                                    !areObjectsEqual(updatedForm, form)
                                  ); // Detect changes
                                  return updatedForm;
                                })
                              }
                              onMinutesChange={(value) => {
                                setForm((prevForm) => {
                                  const updatedActualTimes =
                                    prevForm.actualTimes.map((times) => {
                                      if (
                                        times.useractualtimeid ===
                                        time.useractualtimeid
                                      ) {
                                        const [currentHours, currentMinutes] =
                                          times.actualEndTime.split(":"); // Get current hours and minutes
                                        const newTime = `${currentHours}:${value}`; // Update only the hour
                                        return {
                                          ...times,
                                          actualEndTime: newTime, // Update either actualEndTime or actualEndTime based on `type`
                                        };
                                      }
                                      return times;
                                    });

                                  const updatedForm = {
                                    ...prevForm,
                                    actualTimes: updatedActualTimes,
                                  };

                                  setHasUnsavedChanges(
                                    !areObjectsEqual(updatedForm, form)
                                  ); // Detect changes
                                  return updatedForm;
                                });
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <FormRow1
                    label="Spent Time"
                    value={`${calculateTimeDifferenceInMinutes(
                      time?.actualStartTime,
                      time?.actualEndTime
                    )} min`}
                  />
                </Box>
              ))}
            <FormRow label="Run Name" value={form?.runName || ""} />
            {/* <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item xs={3} lg={2.5}>
                <Typography variant={ "body4"}>
                  Comments
                </Typography>
              </Grid>
              <Grid item xs={0.5}>
                <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
              </Grid>
              <Grid
                item
                xs={8.5}
                lg={9}
                sx={{ marginBottom:  "5px" }}
              >
                <Box sx={{ marginLeft: "20px" }}>{commentCard()}</Box>
              </Grid>
            </Grid> */}

            {isEditMode && (
              <ThemeProvider theme={EditTheme}>
                <Box sx={{ display: "flex", marginTop: "10px" }}>
                  <Button variant="customcancel" onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <Button variant="customsave" onClick={handleSaveClick}>
                    Save
                  </Button>
                </Box>
              </ThemeProvider>
            )}
          </Grid>
          {openDialog && (
            <ConfirmationDialog
              openDialog={openDialog}
              TitleText="Do you want to leave without saving changes?"
              paraText="Any unsaved changes will be lost."
              IconUrl={Admin}
              cancelText="Cancel"
              confirmText="Confirm"
              onCancelFun={() => handleClose()}
              onConfirmFun={onClickYesDialogBtn}
              handleCloseDialog={handleClose}
            />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
