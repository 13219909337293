import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useStyles from "../../clients/styles/useStyles";
import CustomCalendar from "../../logs/CustomCalendar";
import Loader from "../Loader";
import Download from "../../billings/Download";
const doc = new jsPDF();

const columns = [
  {
    field: "todoDescription",
    headerName: "Todo Description",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "selectDays",
    headerName: "Select Days",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "essential",
    headerName: "Essential",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sessionType",
    headerName: "Session",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "clientName",
    headerName: "Client Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "visitDate",
    headerName: "Visit Date",
    headerAlign: "center",
    align: "center",
     valueGetter: (params) => {
          const visit_date = params;
          if (!visit_date) return "-";
    
          return dayjs(visit_date).format("DD-MMM-YYYY");
        },
        sortComparator: (v1, v2, paramOrder) => {
          const date1 = dayjs(v1, "DD-MMM-YYYY");
          const date2 = dayjs(v2, "DD-MMM-YYYY");
    
          if (!date1.isValid()) return 1;
          if (!date2.isValid()) return -1;
    
          return paramOrder === "asc"
            ? date2.unix() - date1.unix()
            : date1.unix() - date2.unix();
        },
  },
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
];
const paginationModel = { page: 0, pageSize: 20 };

function ListOfTodos() {
  const [listOfTodos, setListOfTodos] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
      // setLoading(true);
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getSubmittedToDosDetails?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setListOfTodos(data.data);
      } else {
        console.error("API call failed");
        setListOfTodos([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    } finally {
      setLoading(false); // Ensure loading is turned off regardless of success or failure
    }
  };
  // Fetch data when the component mounts (initial load)
  useEffect(() => {
    const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(initialStartDate, initialEndDate);
  }, []); 

  // Fetch data when startDate or endDate changes
  useEffect(() => {
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  // if (loading) {
  //   return <Loader />;
  // }
  const rows = listOfTodos.map((todo) => {
    //const formattedVisitDate = dayjs(todo.visitDate).format("DD-MM-YYYY");
    return {
      id: todo.todoDetailsId,
      todoDescription: todo.todoDescription,
      selectDays: todo.selectDays,
      essential: todo.essential,
      sessionType: todo.sessionType,
      clientName: todo.clientName,
      visitDate: todo.visitDate,
      userName: todo.userName,
    };
  });
  const adjustedColumns = columns?.map((col) => {
    const isExpandableColumn =
      col.field === "selectDays" ||
      col.field === "userName" ||
      col.field === "todoDescription" ||
      col.field === "clientName";

    return {
      ...col,
      flex: isExpandableColumn ? 3 : 2, // Increase flex for expandable columns
      minWidth: isExpandableColumn ? 200 : 120, // Ensure minimum width for these columns
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "100%",
            whiteSpace: "normal",
            wordBreak: "break-word", // Break long words
            lineHeight: 1.2, // Adjust line spacing
          }}
        >
          {params.value || "N/A"}
        </div>
      ),
    };
  });
  const finalTodoReports = rows?.filter((u) =>
    rowSelectionModel?.includes(u.id)
  );
  const updatedTodoReports = finalTodoReports?.map(
    ({
      clientName,
      essential,
      id,
      selectDays,
      sessionType,
      todoDescription,
      userName,
      visitDate,
    }) => ({
      TodoDescription: todoDescription,
      SelectedDays: selectDays,
      Essential: essential,
      SessionType: sessionType,
      // ID: id,
      ClientName: clientName,
      VisitDate: visitDate && dayjs(visitDate).format("DD-MMM-YYYY") || "-",
      UserName: userName,
    })
  );
  const body = finalTodoReports?.map((todo) => {
    // const cleanedUserNames = todo.userName
    // .replace(/[^\w\s,]/g, '') // Remove square brackets or any unwanted characters
    // .split(',') // Split into individual names by comma
    // .map(name => name.trim()) // Trim any leading/trailing spaces from names
    return [
      todo.todoDescription,
      todo?.selectDays,
      todo?.essential,
      todo?.sessionType,
      todo?.clientName,
      todo?.visitDate && dayjs(todo?.visitDate).format("DD-MMM-YYYY") || "-",
      todo?.userName,
    ];
  });

  const clientColumns = [
    "Description",
    "Select Days",
    "Essential",
    "SessionType",
    "ClientName",
    "VisitDate",
    "UserName",
  ];
  const getDynamicRowHeight = (params) => {
    const rowContent = params?.row?.selectDays || params?.row?.userName || ""; // Combine both fields if necessary
    const estimatedLineCount = Math.ceil(rowContent.length / 30); // Estimate lines based on length
    return Math.max(70, estimatedLineCount * 25); // Minimum height is 70px
  };
  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 1,
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "45%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports By List of Todos
        </Typography>
        <Download
          data={body}
          columns={clientColumns}
          selectToDownload={rowSelectionModel}
          heading={"List of Todos"}
          excelData={updatedTodoReports}
          rotateMode={true}
        />
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          getRowHeight={getDynamicRowHeight}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "10px", // Add padding inside cells
              whiteSpace: "normal", // Allow text to wrap
              wordBreak: "break-word", // Break long words
            },
            "& .MuiDataGrid-root": {
              width: "100%", // Ensure table spans full width
            },
          }}
          loading={loading} 
          slots={{
            loadingOverlay: Loader, // Displays Loader inside table
          }}
        />
      </Box>
    </Box>
  );
}
export default ListOfTodos;
