import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  FormLabel,
  Typography,
  ThemeProvider,
  TextField,
  Button,
} from "@mui/material";
import { ViewTheme, EditTheme } from "../../../themes/Theme";
import Time from "../../reusableComponents/Time";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";
import Name from "../../reusableComponents/Name";
import Admin from "../../../assets/images/users/Admin.svg";
import LogTime from "../../reusableComponents/LogTime";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";

export default function LogVisitDetails({
  selectedVisit,
  setHasUnsavedChanges,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [alerts, setAlerts] = useState();
  const [startingAlerts, setStartingAlerts] = useState();
  const [todoAlerts, setTodoAlerts] = useState();
  const [startingTodoAlerts, setStartingTodoAlerts] = useState();
  const [medicationAlerts, setMedicationAlerts] = useState();
  const [startingMedicationAlerts, setStartingMedicationAlerts] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const onClickYesDialogBtn = () => {
    setIsEditMode(false);
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    setOpenDialog(false);
    fetchVisitAlerts();
  };

  const fetchVisitAlerts = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-Alert-Check-In-Out/1531`; //${selectedVisit.visitdetailsid}
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setAlerts(info);
        setStartingAlerts(info);
      } else {
        setAlerts();
        setStartingAlerts();
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setAlerts();
      setStartingAlerts();
    }
  };
  const fetchTodoVisitAlerts = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-Alert-Check-In-Out/1531`; //${selectedVisit.visitdetailsid}
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setTodoAlerts(info);
        setStartingTodoAlerts(info);
      } else {
        setTodoAlerts();
        setStartingTodoAlerts();
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setTodoAlerts();
      setStartingTodoAlerts();
    }
  };
  const fetchMedicationVisitAlerts = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-Alert-Check-In-Out/1531`; //${selectedVisit.visitdetailsid}
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setMedicationAlerts(info);
        setStartingMedicationAlerts(info);
      } else {
        setMedicationAlerts();
        setStartingMedicationAlerts();
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setMedicationAlerts();
      setStartingMedicationAlerts();
    }
  };
  const convertTimes = (originalTimes) => {
    return originalTimes.map((item) => ({
      useractualtimeid: item.useractualtimeid, // Generate new IDs starting from 150
      actualStartTime: item.actualStartTime, // Set the start time as per your logic
      actualEndTime: item.actualEndTime, // Set the end time as per your logic
    }));
  };

  //   const putAlerts = async () => {
  //     try {
  //       const token = getCookie();
  //       const ActualTimes = convertTimes(form.actualTimes);
  //       const body = {
  //         comments: "",
  //         actualTimes: ActualTimes,
  //       };

  //       console.log(body, "put api body................");
  //       const BASE_URL = "https://3.8.158.142:8443/";
  //       const URL = `${BASE_URL}api/log-update-visitDetailsid/${form.visitdetailsid}`;
  //       console.log(form.visitdetailsid);
  //       const options = {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(body),
  //       };

  //       const response = await fetch(URL, options);
  //       const data = await response.json();

  //       if (response.ok && data.statusCode === 200) {
  //         console.log("Data posted");
  //         setIsEditMode(false);
  //         scrollToTop();
  //       } else {
  //         console.log("Failed to edit Visit.");
  //       }
  //     } catch (error) {
  //       console.error("An error occurred:", error);
  //     }
  //   };

  useEffect(() => {
    if (selectedVisit?.visitdetailsid) {
      fetchVisitAlerts();
    }
    setIsEditMode(false);
  }, [selectedVisit]);

  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true; // Same reference, return true

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return obj1 === obj2; // Compare primitive values
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Different number of keys

    return keys1.every((key) => {
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) return false; // Check if key exists in obj2
      return areObjectsEqual(obj1[key], obj2[key]); // Recursively compare values
    });
  };

  const statusCard = (value) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: (() => {
            const stringValue = String(value); // Convert to string for reliable comparison
            return stringValue === "Completed"
              ? "#279989"
              : stringValue === "In Progress"
              ? "#56ACE0"
              : stringValue === "Action Required"
              ? "#FF8080"
              : stringValue === "Follow Up"
              ? "#CF75E6"
              : "transparent";
          })(),
          borderRadius: "5px",
          color: "white",
          width: "auto",
          justifyContent: "center",
          padding: "5px 10px",
        }}
      >
        {value || ""}
      </Box>
    );
  };
  const containerRef = useRef(null);
  const scrollToTop = () => {
    containerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    scrollToTop();
  };

  const handleCancelClick = () => {
    if (!areObjectsEqual(alerts, startingAlerts)) {
      handleOpen();
    } else {
      setIsEditMode(false);
      scrollToTop();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };

  const handleSaveClick = () => {
    setHasUnsavedChanges(false);
  };

  const AlertCard = (alert) => {
    console.log("alerts", alerts);
    return (
      <ThemeProvider theme={ViewTheme}>
        <Box>
          <Box>{statusCard(alert.alert_status)}</Box>
          <Box sx={{ marginBottom: "-10px", marginTop: "10px" }}>
            <Typography variant={"body4"} sx={{ fontSize: "14px" }}>
              {isEditMode ? "Add Comments" : " "}
            </Typography>
          </Box>

          <Name
            //name="Comments"
            nolabel
            allowCharacters
            wordsStartWithSmallLetters
            multiline={true}
            preFilledvalue={alert?.comments ? alert.comments : ""}
            rows={4}
            className="multiline-textfield"
            placeholder={isEditMode ? "Add Comments" : ""}
            sx={{ minWidth: "100%" }}
            disabled={!isEditMode}
            // onValueChange={(value) => {
            //   setAlerts((prevForm) => {
            //     const updatedForm = {
            //       ...prevForm,
            //       comments: value,
            //     };
            //     setHasUnsavedChanges(!areObjectsEqual(updatedForm, alerts)); // Detect changes
            //     return updatedForm;
            //   });
            // }}
          />
        </Box>
      </ThemeProvider>
    );
  };

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "97%",
          maxHeight: "calc(100vh - 355px)",
          margin: "0px 20px",
          overflow: "scroll",
          position: "relative",
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            textAlign: "left",
          }}
        >
          {!isEditMode && selectedVisit?.visitStatus === "Completed" && (
            <Box
              sx={{
                position: "absolute",
                right: "20px",
                top: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button variant="outlinedSecondary" onClick={handleEditClick}>
                Edit
              </Button>
            </Box>
          )}

          <Box
            sx={{
              width: "100%",
              backgroundColor: "#EEFEFA",
              borderRadius: "8px",
              paddingTop: "20px",
            }}
          >
            {alerts &&
              alerts.map((alert, index) => (
                <Grid container direction="row" alignItems="top" spacing={2}>
                  <Grid item xs={4} lg={3.5}>
                    <Box sx={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <Typography variant={"body4"} sx={{ marginTop: "10px" }}>
                        {alert.alert_type}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={0.5}>
                    <FormLabel sx={{ textAlign: "center", marginTop: "5px" }}>
                      :
                    </FormLabel>
                  </Grid>
                  <Grid item xs={7.5} lg={8}>
                    <Box sx={{ margin: "0 40px 10px 20px" }}>
                      {AlertCard(alert)}
                    </Box>
                  </Grid>
                </Grid>
              ))}
          </Box>

          {isEditMode && (
            <ThemeProvider theme={EditTheme}>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <Button variant="customcancel" onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button variant="customsave" onClick={handleSaveClick}>
                  Save
                </Button>
              </Box>
            </ThemeProvider>
          )}

          {openDialog && (
            <ConfirmationDialog
              openDialog={openDialog}
              TitleText="Do you want to leave without saving changes?"
              paraText="Any unsaved changes will be lost."
              IconUrl={Admin}
              cancelText="Cancel"
              confirmText="Confirm"
              onCancelFun={() => handleClose()}
              onConfirmFun={onClickYesDialogBtn}
              handleCloseDialog={handleClose}
            />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
