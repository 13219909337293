import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditTheme } from "../../users/Theme";
import { Calendar, Name, RadioInput } from "../../reusableComponents";
import { BpIcon } from "../../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../../reusableComponents/StyledCheckbox";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";

const AuditEdit = ({
  clientId,
  auditName,
  selectedAuditCard,
  setIsEditToggle,
  setAuditResponseForm,
  auditResponseForm,
  isEditToggle,
  isNewAuditToggle,
  signName,
  setIsNewAuditToggle,
  setIsNewAudit,
}) => {
  const [isSaveVisible, setIsSaveVisible] = useState(false);

  const [isDraftSubmit, setIsDraftSubmit] = useState(false);

  // useEffect(() => {
  //   if (isNewAuditToggle) {
  //     const auditCards = Array.isArray(selectedAuditCard)
  //       ? selectedAuditCard
  //       : [selectedAuditCard];

  //     const initialFormState = {};

  //     auditCards.forEach((audit) => {
  //       audit.questions.forEach((ques) => {
  //         initialFormState[ques.ques_name] = "";
  //       });

  //       audit.action_plan.forEach((action) => {
  //         initialFormState[action.action_name] = "";
  //       });
  //     });

  //     setAuditResponseForm(initialFormState);
  //   } else {
  //     fetchAuditAPI();
  //   }
  // }, [isNewAuditToggle, selectedAuditCard]);

  // const fetchAuditAPI = async () => {
  //   try {
  //     const token = getCookie();
  //     let URL = "";

  //     if (auditName === "Health & Safety") {
  //       URL = `${process.env.REACT_APP_BASE_URL}/get-health-safety/${clientId}`;
  //     } else if (auditName === "Safeguarding & Well-Being") {
  //       URL = `${process.env.REACT_APP_BASE_URL}/get-Safeguarding-Well-Being/${clientId}`;
  //     }
  //     // if (auditName === "Care Plan & Documentation Audit") {
  //     // }
  //     // if (auditName === "Service User Satisfaction Audit") {
  //     // }
  //     // if (auditName === "Medication Audit") {
  //     // }
  //     const options = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     const response = await fetch(URL, options);
  //     const data = await response.json();
  //     const activeData = data.data.filter((item) => item.status === "Active");

  //     if (response.ok === true) {
  //       setAuditResponseForm(activeData);
  //     } else {
  //       console.log("get all failed");
  //       setAuditResponseForm([]);
  //     }
  //   } catch (e) {
  //     console.log("error", e.message);
  //   }
  // };

  const handleCheckboxChange = (event) => {
    const newState = event.target.checked; // Toggle the checkbox state
    setIsSaveVisible(newState ? true : false);
  };

  useEffect(() => {
    console.log("audit Form", auditResponseForm);
  }, [auditResponseForm]);

  const handleValueChange = (value, name) => {
    setAuditResponseForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };

      //   setHasUnsavedChanges(!areObjectsEqual(updatedForm, invoiceDataOriginal)); // Detect changes
      return updatedForm;
    });
  };

  const postAudit = async (updatedForm) => {
    try {
      console.log("before POST auditForm", updatedForm[0]);
      const token = getCookie();

      let URL = "";

      if (auditName === "Health & Safety") {
        URL = `${process.env.REACT_APP_BASE_URL}/add-health-safety`;
      } else if (auditName === "Safeguarding & Well-Being") {
        URL = `${process.env.REACT_APP_BASE_URL}/add-Safeguarding-Well-Being`;
      }
      if (auditName === "Care Plan & Documentation Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/add-Careplan-Documentation-Audit`;
      }
      if (auditName === "Service User Satisfaction Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/add-service-user-satisfaction-audit`;
      }
      if (auditName === "Medication Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/add-Medication-Audit`;
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedForm[0]),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
      } else {
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const putAudit = async (updatedForm) => {
    try {
      const body = { ...updatedForm[0], client_id: clientId };
      console.log("BODY", body);
      const token = getCookie();
      let URL = "";

      if (auditName === "Health & Safety") {
        URL = `${process.env.REACT_APP_BASE_URL}/update-health-safety/${body.id}`;
      } else if (auditName === "Safeguarding & Well-Being") {
        URL = `${process.env.REACT_APP_BASE_URL}/update-Safeguarding-Well-Being/${body.id}`;
      }
      if (auditName === "Care Plan & Documentation Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/updated-Careplan-Documentation-Audit/${body.id}`;
      }
      if (auditName === "Service User Satisfaction Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/updated-Service-User-Satisfaction-Audit/${body.id}`;
      }
      if (auditName === "Medication Audit") {
        URL = `${process.env.REACT_APP_BASE_URL}/Update-Medication-Audit/${body.id}`;
      }

      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        console.log("Edited Successfully");
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const updatedForm = auditResponseForm.length
      ? [...auditResponseForm]
      : [{}];
    updatedForm[0] = { ...updatedForm[0], status: "Active" };
    setAuditResponseForm(updatedForm);
    if (isNewAuditToggle) {
      postAudit(updatedForm);
      setIsNewAuditToggle(false);
      setIsNewAudit(false);
    } else {
      putAudit(updatedForm);
      setIsEditToggle(false);
    }
  };

  // const handleDraftSubmit = () => {
  //   const updatedForm = auditResponseForm.length
  //     ? [...auditResponseForm]
  //     : [{}];
  //   updatedForm[0] = { ...updatedForm[0], status: "Draft" };

  //   console.log("updatedForm before post in draft", updatedForm);

  //   // Update the state
  //   setAuditResponseForm(updatedForm);

  //   // Directly call post/put without relying on isStatusUpdated
  //   if (isNewAuditToggle) {
  //     console.log("auditForm before post in draft", auditResponseForm);
  //     postAudit();
  //     setIsNewAuditToggle(false);
  //   } else {
  //     putAudit();
  //     setIsEditToggle(false);
  //   }
  // };

  const handleDraftSubmit = () => {
    const updatedForm = auditResponseForm.length
      ? [...auditResponseForm]
      : [{}];
    updatedForm[0] = { ...updatedForm[0], status: "Draft" };

    console.log("updatedForm before post in draft", updatedForm);

    setAuditResponseForm(updatedForm);

    if (isNewAuditToggle) {
      postAudit(updatedForm);
      setIsNewAuditToggle(false);
      setIsNewAudit(false);
    } else {
      putAudit(updatedForm);
      setIsEditToggle(false);
    }
  };

  // useEffect that triggers the API call after auditResponseForm updates
  // useEffect(() => {
  //   if (isDraftSubmit) {
  //     // At this point, auditResponseForm reflects the updated value
  //     console.log("auditForm after state update", auditResponseForm);
  //     if (isNewAuditToggle) {
  //       postAudit();
  //       setIsNewAuditToggle(false);
  //     } else {
  //       putAudit();
  //       setIsEditToggle(false);
  //     }
  //     // Reset the draft flag
  //     setIsDraftSubmit(false);
  //   }
  //   // }, [auditResponseForm, isDraftSubmit, isNewAuditToggle]);
  // }, [isDraftSubmit]);

  const handleCancel = () => {
    if (isNewAuditToggle) {
      setIsNewAuditToggle(false);
    } else {
      setIsEditToggle(false);
    }
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Box
        sx={{
          textAlign: "Left",
          height: "100%",
          overflow: "auto",
          paddingRight: 2,
          paddingLeft: 1,
          width: "100%",
          boxShadow: "0px 0px 19px 7px #",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <FormLabel>
            <Typography variant="h6">
              Method of Audit being carried out?
            </Typography>
          </FormLabel>
          <RadioGroup
            name="method_audit"
            onChange={(val) =>
              handleValueChange(val.target.value, "method_audit")
            }
            value={auditResponseForm[0]?.method_audit || ""}
            sx={{ marginTop: "8px" }}
          >
            {/* {options.map((option, index) => ( */}
            <FormControlLabel
              // key={index}
              value="Document Review"
              control={<Radio />}
              label="Document Review"
            />
            <FormControlLabel
              // key={index}
              value="Spot Checks/Unannounced Visits"
              control={<Radio />}
              label="Spot Checks/Unannounced Visits"
            />
            <FormControlLabel
              // key={index}
              value="Interview with Client/Next of Kin"
              control={<Radio />}
              label="Interview with Client/Next of Kin"
            />
            <FormControlLabel
              // key={index}
              value="Care Plan Reviews"
              control={<Radio />}
              label="Care Plan Reviews"
            />
            <FormControlLabel
              // key={index}
              value="Outcome-Based Audits"
              control={<Radio />}
              label="Outcome-Based Audits"
            />
            <FormControlLabel
              // key={index}
              value="Focus Group Discussions"
              control={<Radio />}
              label="Focus Group Discussions"
            />

            {/* ))} */}
          </RadioGroup>
          {/* {errorMessage && (
            <Typography variant="errmsg" sx={{ marginTop: "2px" }}>
              {errorMessage}
            </Typography>
          )} */}
        </Box>
        <Box>
          {selectedAuditCard.questions.map((question, index) => (
            <Box key={`question_${index}`}>
              <Typography
                variant="h6"
                sx={{
                  marginTop: "4px",
                }}
              >
                {question?.ques}
              </Typography>
              <Container>
                <Name
                  name={question.ques_name}
                  className="multiline-textfield"
                  label="Please Mention"
                  placeholder={question?.placeholder}
                  multiline
                  rows="8"
                  onValueChange={handleValueChange}
                  preFilledvalue={auditResponseForm[0]?.[question.ques_name]}
                ></Name>
              </Container>
            </Box>
          ))}
          <Typography variant="h4">Action Plan</Typography>
          {selectedAuditCard?.action_plan?.length > 0 ? (
            selectedAuditCard.action_plan.map((action, index) => (
              <Box key={`action_${index}`}>
                <Container>
                  {selectedAuditCard.audit_name ===
                    "Care Plan & Documentation Audit" && index === 0 ? (
                    <>
                      <RadioInput
                        name="gaps_identified"
                        label={action.action}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        onValueChange={(val) =>
                          handleValueChange(val, "gaps_identified")
                        }
                        preFilledvalue={auditResponseForm[0]?.gaps_identified}
                      />
                      {auditResponseForm[0]?.gaps_identified === "Yes" && (
                        <>
                          <Typography variant="h6" sx={{ marginTop: "4px" }}>
                            What actions are being planned?
                          </Typography>
                          <Name
                            name="gaps_identified_actions"
                            className="multiline-textfield"
                            label="Please Mention"
                            multiline
                            rows="5"
                            onValueChange={handleValueChange}
                            preFilledvalue={
                              auditResponseForm[0]?.gaps_identified_actions
                            }
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="h6" sx={{ marginTop: "4px" }}>
                        {action.action}
                      </Typography>
                      <Name
                        name={action.action_name}
                        className="multiline-textfield"
                        label="Please Mention"
                        multiline
                        rows="5"
                        onValueChange={handleValueChange}
                        preFilledvalue={
                          auditResponseForm[0]?.[action.action_name]
                        }
                      />
                    </>
                  )}
                </Container>
              </Box>
            ))
          ) : (
            <Typography>No Data Available</Typography>
          )}
        </Box>

        <Box>
          <Grid
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
            }}
          >
            <Grid item xs={10} md={5}>
              <Name
                name="audited_on"
                label="Audited on"
                placeholder="Audited on"
                //   preFilledvalue={initialForm[0]?.sign_2}
                preFilledvalue={dayjs(auditResponseForm[0]?.audited_on).format(
                  "DD-MM-YYYY"
                )}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Calendar
                name="date_2"
                label="Due on"
                //   onDateChange={handleDateChange}
                preFilledvalue={auditResponseForm[0]?.due_on}
              />
            </Grid>
          </Grid>
          <FormLabel sx={{ fontSize: 18, paddingTop: "20px" }}>
            Signatures of all involved persons in assessment Required
          </FormLabel>
          <Grid // First signature
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Grid item xs={10} md={5}>
              <Name
                name="sign_1"
                label="Name"
                preFilledvalue={auditResponseForm[0]?.sign_1 || signName}
                // preFilledvalue={initialForm[0]?.sign_1 || signName} // Prefill with sign_1 or signName
                placeholder="Enter your Name"
                disabled
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Calendar
                name="sign_date1"
                label="Date"
                // onDateChange={handleDateChange}
                preFilledvalue={
                  auditResponseForm[0]?.sign_date1
                    ? auditResponseForm[0]?.sign_date1
                    : dayjs() // Prefill with date_1 or current date
                }
                disabled={
                  auditResponseForm[0]?.sign_1 &&
                  auditResponseForm[0]?.sign_1 !== signName
                    ? true
                    : false
                } // Disable if date_1 exists
              />
            </Grid>
          </Grid>

          {/* Second signature section */}
          {auditResponseForm[0]?.sign_2 !== "" ? (
            <Grid
              container
              direction="row"
              alignItems="top"
              sx={{
                gap: 8,
                p: 2,
                borderRadius: "8px",
                backgroundColor: "#F0FCFA",
              }}
            >
              <Grid item xs={10} md={5}>
                <Name
                  name="sign_2"
                  label="Name"
                  placeholder="Name"
                  preFilledvalue={auditResponseForm[0]?.sign_2} // Prefill with sign_2 or signName
                  disabled
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <Calendar
                  name="sign_date2"
                  label="Date"
                  // onDateChange={handleDateChange} // Ensure date changes are tracked
                  preFilledvalue={
                    auditResponseForm[0]?.sign_date2 !== ""
                      ? auditResponseForm[0]?.sign_date2
                      : dayjs() // Prefill with sign_date2 or current date
                  }
                  disabled={
                    // auditResponseForm[0]?.sign_1 &&
                    auditResponseForm[0]?.sign_1 === signName ? true : false
                  }
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid>
            <Grid item>
              <FormControlLabel
                label={
                  "I hereby confirm the accuracy of the information provided above"
                }
                control={
                  <Checkbox
                    // checked={
                    //   // fetchCheckVariable &&
                    //   initialForm[0]?.consent === 0 ? false : true
                    // }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item sx={{ mt: 3, mb: 3 }}>
              <Box sx={{ width: "fit-content" }}>
                <Button
                  variant="customcancel"
                  onClick={() => {
                    // setIsEditToggle(false);
                    handleCancel();
                    // if (hasUnsavedChanges) {
                    //   setIsCancelButton(true);
                    //   handleSave();
                    // } else {
                    //   // toggleEditMode();
                    //   if (resetNewAssessmentVisibility) {
                    //     resetNewAssessmentVisibility();
                    //   } else {
                    //     toggleEditMode();
                    //   }
                    // }
                  }}
                >
                  Cancel
                </Button>
                {isSaveVisible ? (
                  <Button
                    type="submit"
                    variant="customsave"
                    onClick={() => {
                      //   // handleSignSubmit();
                      //   // if (resetNewAssessmentVisibility) {
                      //   //   resetNewAssessmentVisibility();
                      //   // }
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="customsave"
                    onClick={() => {
                      //   // if (hasUnsavedChanges) {
                      //   //   handleSave();
                      //   // } else {
                      //   // toggleEditMode();
                      //   setIsDraft(true);
                      //   if (resetNewAssessmentVisibility) {
                      //     resetNewAssessmentVisibility();
                      //   } else {
                      //     toggleEditMode();
                      //   }
                      //   // }
                      handleDraftSubmit();
                    }}
                    sx={{
                      backgroundColor: "#FFF0E3",
                      border: "1.5px solid #F5891D",
                      color: "#FA8733",
                      "&:hover": {
                        backgroundColor: "#F5891D",
                        border: "1.5px solid #F5891D",

                        color: "white",
                      },
                    }}
                  >
                    Save Draft
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AuditEdit;
