import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import { getCookie } from "../../../utils/utils";
import MangeMedicationTable from "./MangeMedicationTable";
import useStyles from "../styles/useStyles";
import CustomButton from "../../reusableComponents/CustomButton";
import Medications from "../../../assets/images/clients/Medications.svg";

function ManageMedication({
  clientMainData,
  clientId,
  setActiveTabId,
  setHasUnsavedChanges,
  medicines,
  medicationRoute,
}) {
  const [userName, setUserName] = useState("");
  const [medicationData, setMedicationData] = useState([]);
  const [selectToDownload, setSelectToDownload] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const childRef = useRef();

  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  const fetchMedicationData = async () => {
    setLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/medication?client_id=${clientId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie()}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMedicationData(data?.data?.length > 0 ? data.data : []);
      } else {
        setMedicationData([]);
        console.error(`API Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching medication data:", error);
      setMedicationData([]);
    } finally {
      setLoading(false);
    }
  };

  // Call API inside useEffect
  useEffect(() => {
    fetchMedicationData();
  }, [clientId]);

  const refreshData = async () => {
    await fetchMedicationData();
  };

  // Handle PDF download
  const handleDownloadClick = () => {
    if (childRef.current) {
      childRef.current.exportClientsPdf();
      childRef.current.setSelectedMedications([]);
      setSelectToDownload([]);
    }
  };
  return (
    <Grid container sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box className={classes.header}>
          <ClientHeader head={`Manage Medication`} icon={Medications} />
        </Box>
        <CustomButton
          text="Download"
          onClick={handleDownloadClick}
          disabled={selectToDownload.length < 1}
        />
      </Box>
      <Box sx={{ mt: 3, width: "100%" }}>
        <MangeMedicationTable
          ref={childRef}
          medicationData={medicationData}
          setActiveTabId={setActiveTabId}
          refreshData={refreshData}
          setHasUnsavedChanges={setHasUnsavedChanges}
          setSelectToDownload={setSelectToDownload}
          loading={loading}
          medicationRoute={medicationRoute}
          medicines={medicines}
        />
      </Box>
    </Grid>
  );
}
export default React.memo(ManageMedication);
