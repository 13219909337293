import React from 'react'
import { Box } from '@mui/material';
import Loader1 from '../../assets/images/common/logo_animation.gif'

function Loader() {
  return (
    <Box
        sx={{
          width: "100%",
          // height: "calc(100% - 50px)",
          // backgroundColor: "white",
          // overflowY: "auto",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Loader1} alt="Loading..." />
      </Box>
  )
}

export default Loader