import { useState, useRef, useEffect } from "react";
import React from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import {
  Name,
  Calendar,
  NumberInput,
  Currency,
  Country,
} from "../../reusableComponents";
import AccountNumber from "../../reusableComponents/AccountNumber";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { ThemeProvider } from "styled-components";
import { getCookie } from "../../../utils/utils";
import { EditTheme } from "../../users/Theme";
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/HeaderInvoiceIcon.svg";
import Admin from "../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";

export default function InvoicePopup({
  open,
  handleInvoiceDialog = () => {},
  hasUnsavedChanges,
  setHasUnsavedChanges,
  setOpenInvoiceDialog,
}) {
  // {
  // bank_name: "",
  // branch_name: "",
  // account_holder_name: "",
  // account_number: "",
  // sort_code: "",
  // company_address: "",
  // city: "",
  // postcode: "",
  // country: "",
  // invoice_due_days: "",
  // }
  const [finalInvoiceData, setFinalInvoiceData] = useState({});

  const [invoiceDataOriginal, setInvoiceDataOriginal] =
    useState(finalInvoiceData);

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const userId = user?.id;

  const fetchInvoiceData = async () => {
    if (!userId) return;
    const token = getCookie();
    const URL = `${process.env.REACT_APP_BASE_URL}/getInvoicesettingdetails/1`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.data) {
        setFinalInvoiceData(data.data);
        setInvoiceDataOriginal(data.data);
        setHasUnsavedChanges(false);
      } else {
        console.error("Invalid API response:", data);
        setFinalInvoiceData({}); // Ensure it is never undefined
        setInvoiceDataOriginal({});
      }
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      setFinalInvoiceData({}); // Avoid undefined state
      setInvoiceDataOriginal({});
    }
  };

  useEffect(() => {
    fetchInvoiceData();
  }, [userId, setHasUnsavedChanges]);

  const putInvoiceData = async () => {
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/updateInvoiceSettings/1`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(finalInvoiceData[0]),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        // fetchUsersAPI();
        // handleDeleteConfirmationClose();
        console.log("Edited Successfully");
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const banknameRef = useRef(null);
  const branchnameRef = useRef(null);
  const accholdernameRef = useRef(null);
  const accnumberRef = useRef(null);
  const sortcodeRef = useRef(null);
  const companyaddressRef = useRef(null);
  const cityRef = useRef(null);
  const postcodeRef = useRef(null);
  const invoiceduedaysRef = useRef(null);

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpen = () => {
    if (hasUnsavedChanges) {
      setOpenConfirmationDialog(true);
    }
  };
  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };
  const onClickYesDialogBtn = () => {
    handleInvoiceDialog();
    setHasUnsavedChanges(false);
  };

  const handleValueChange = (value, name) => {
    setFinalInvoiceData((prevState) => {
      const updatedForm = [...prevState];

      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };

      setHasUnsavedChanges(!areObjectsEqual(updatedForm, invoiceDataOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleCancel = () => {
    if (!areObjectsEqual(finalInvoiceData, invoiceDataOriginal)) {
      handleOpen();
    } else {
      handleInvoiceDialog();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };
  const handleSubmit = () => {
    let isValid = true;

    if (banknameRef.current && !banknameRef.current.handleValidation()) {
      isValid = false;
    }
    if (branchnameRef.current && !branchnameRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      accholdernameRef.current &&
      !accholdernameRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (accnumberRef.current && !accnumberRef.current.handleValidation()) {
      isValid = false;
    }
    if (sortcodeRef.current && !sortcodeRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      companyaddressRef.current &&
      !companyaddressRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (cityRef.current && !cityRef.current.handleValidation()) {
      isValid = false;
    }
    if (postcodeRef.current && !postcodeRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      invoiceduedaysRef.current &&
      !invoiceduedaysRef.current.handleValidation()
    ) {
      isValid = false;
    }

    if (isValid) {
      // setHasUnsavedChanges(false); // Reset unsaved state
      // Add your form submission logic here

      // console.log("Form added successfully");
      putInvoiceData();
      setOpenInvoiceDialog(false);
      setHasUnsavedChanges(false);
      // PostPayroll();
      //toggleEdit();
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };
  useEffect(() => {
    console.log("Final invoice data", finalInvoiceData);
  }, [finalInvoiceData]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Dialog
        open={open}
        onClose={handleInvoiceDialog}
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            width: "50%",
            maxWidth: "100%",
            "@media (min-width: 1350px)": {
              width: "40%",
            },
          },
        }}
      >
        <Box textAlign="center" justifyContent="Center">
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            //   onClick={() => console.log("Clicked!")}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              width: "28px ! important",
              height: "28px ! important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
            }}
          >
            {/* Empty content since the SVG icons are used in background */}
          </IconButton>
          <DialogContent
            sx={{
              //   background: "#F0FCFA",
              // paddingBottom: "35px",
              // paddingRight: "18px",
              // paddingLeft: "18px",
              paddingInline: "40px",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  width: "45px",
                  height: "45px",
                  "& svg path": {
                    fill: "#279989",
                  },
                }}
              >
                {/* Ensure InvoiceIcon takes up the full width and height */}
                <InvoiceIcon width="100%" height="100%" />
              </Box>
              <Typography variant="h3">Invoice Settings</Typography>
            </Box>
            <Grid container direction="row" alignItems="Top" marginTop={4}>
              <Grid item xs={12} md={5.75}>
                <Name
                  ref={banknameRef}
                  label="Bank Name"
                  placeholder="Bank Name"
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.bank_name}
                  name="bank_name"
                  required
                  maxLength={35}
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Name
                  ref={branchnameRef}
                  label="Branch Name"
                  placeholder="Branch Name"
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.branch_name}
                  name="branch_name"
                  maxLength={35}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Name
                  ref={accholdernameRef}
                  label="Account Holder Name"
                  placeholder="Account Holder Name"
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.account_holder_name}
                  name="account_holder_name"
                  required
                  maxLength={35}
                />
              </Grid>
              <Grid item xs={12} md={5.75}>
                <AccountNumber
                  ref={accnumberRef}
                  label="Account Number"
                  placeholder="Account Number"
                  required
                  // maxLength={8}
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.account_number}
                  name="account_number"
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <NumberInput
                  ref={sortcodeRef}
                  label="Sort Code"
                  placeholder="Sort Code"
                  sortcode
                  required
                  maxLength={9}
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.sort_code}
                  name="sort_code"
                />
              </Grid>
              <Grid item xs={12}>
                <Name
                  ref={companyaddressRef}
                  label="Company Address"
                  placeholder="Company Address"
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.company_address}
                  name="company_address"
                  required
                />
              </Grid>
              <Grid item md={3.5} xs={false}>
                <Name
                  ref={cityRef}
                  label="City"
                  placeholder="City"
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.city}
                  name="city"
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={12} />

              <Grid item md={3.5} xs={12}>
                <Name
                  ref={postcodeRef}
                  AllCapital
                  label="Postcode"
                  placeholder="Postcode"
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.postcode}
                  name="postcode"
                  required
                  //   maxLength={10}
                />
              </Grid>
              <Grid item md={0.5} xs={12} />
              <Grid item md={4} xs={12}>
                <Country
                  label="Country"
                  //   ref={clientInfo_countryRef}
                  //   required={true}
                  onCountrySelect={(countryObj) =>
                    handleValueChange(countryObj.id, "country_id")
                  }
                  name="country_id"
                  preFilledvalue={finalInvoiceData[0]?.country_name}
                />
              </Grid>
              <Grid item xs={12}>
                <NumberInput
                  ref={invoiceduedaysRef}
                  label="Invoice Due Days"
                  placeholder="Invoice Due Days"
                  required
                  maxLength={9}
                  onValueChange={handleValueChange}
                  preFilledvalue={finalInvoiceData[0]?.invoice_due_days}
                  name="invoice_due_days"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="caption text"
                  sx={{
                    color: "#279989",
                    fontWeight: "700",
                    fontSize: "12px",
                  }}
                >
                  {finalInvoiceData[0]?.invoice_due_days
                    ? `***Within ${finalInvoiceData[0]?.invoice_due_days} days from the date the invoice is issued`
                    : "***Within 0 days from the date the invoice is issued"}
                </Typography>
              </Grid>

              <Grid item justifyContent="center" sm={12} paddingTop={8}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Distribute buttons to opposite sides
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Button variant="customcancel" onClick={handleCancel}>
                    Cancel
                  </Button>

                  <Button
                    // type="submit"
                    variant="customsave"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
      <ConfirmationDialog
        openDialog={openConfirmationDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleClose}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleClose}
      />
    </ThemeProvider>
  );
}
