import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "../../../utils/utils";
import useStyles from "../../clients/styles/useStyles";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import "jspdf-autotable";
import CustomCalendar from "../../logs/CustomCalendar";
import Loader from "../Loader";
import Download from "../../billings/Download";
const paginationModel = { page: 0, pageSize: 20 };
const columns = [
  {
    field: "clientName",
    headerName: "Client Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "visit_date",
    headerName: "Visit Date",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const visit_date = params;
      if (!visit_date) return "-";

      return dayjs(visit_date).format("DD-MMM-YYYY");
    },
    sortComparator: (v1, v2, paramOrder) => {
      const date1 = dayjs(v1, "DD-MMM-YYYY");
      const date2 = dayjs(v2, "DD-MMM-YYYY");

      if (!date1.isValid()) return 1;
      if (!date2.isValid()) return -1;

      return paramOrder === "asc"
        ? date2.unix() - date1.unix()
        : date1.unix() - date2.unix();
    },
  },
  {
    field: "sessionType",
    headerName: "Session",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedStartTime",
    headerName: "Planned Starttime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedEndTime",
    headerName: "Planned Endtime",
    headerAlign: "center",
    align: "center",
  },
];
function VisitTimeLine() {
  const [careHousLogged, setcareHousLogged] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [loading, setLoading] = useState(true);
  const isFirstRender = useRef(true);
  const classes = useStyles();
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
       //setLoading(true);
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getVisitsTimeDetails?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setcareHousLogged(data.data);
      } else {
        console.error("API call failed");
        setcareHousLogged([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    } finally {
      setLoading(false); // Ensure loading is turned off regardless of success or failure
    }
  };
  // useEffect(() => {
  //   const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
  //   const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
  //   fetchUsersAPI(initialStartDate, initialEndDate);
  // }, []); // Empty dependency array ensures this runs only on the first render

  // Fetch data when startDate or endDate changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setLoading(false);
      return;
    }
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  // if (loading) {
  //   return <Loader />;
  // }
  const rows = careHousLogged?.map((item) => {
    // Clean up the userName string to remove square brackets and split names by commas if needed
    const cleanedUserNames = item.userName
      .replace(/[^\w\s,]/g, "") // Remove square brackets or any unwanted characters
      .split(",") // Split into individual names by comma
      .map((name) => name.trim()); // Trim any leading/trailing spaces from names
    // Format the visit_date to "date-month-year"
    // const formattedVisitDate = dayjs(item.visit_date).format("DD-MM-YYYY");

    return {
      id: item?.visitdetailsid,
      clientName: item?.clientName,
      userName: cleanedUserNames.join(", "), // Join the cleaned names back with a comma
      visit_date: item?.visit_date,
      sessionType: item?.sessionType,
      plannedStartTime: item?.plannedStartTime,
      plannedEndTime: item?.plannedEndTime,
    };
  });
  const adjustedColumns = columns.map((col) => {
    const isAddressColumn = col.field === "userName"; // Check if it's the `userName` column
    return {
      ...col,
      flex: isAddressColumn ? 2 : 1, // Dynamic width for `userName`
      minWidth: isAddressColumn ? 150 : 90, // Ensure minimum width for each column
      renderCell: (params) => (
        <Box className={classes.gridColumns}>{params.value || "-"}</Box>
      ),
    };
  });
  const finalVisitReports = rows?.filter((u) =>
    rowSelectionModel?.includes(u.id)
  );
  const body = finalVisitReports?.map((visit) => {
    const cleanedUserNames = visit.userName
      .replace(/[^\w\s,]/g, "") // Remove square brackets or any unwanted characters
      .split(",") // Split into individual names by comma
      .map((name) => name.trim()); // Trim any leading/trailing spaces from names
    return [
      visit?.clientName,
      cleanedUserNames.join(", "),
      visit?.visit_date && dayjs(visit?.visit_date).format("DD-MMM-YYYY"),
      visit?.session,
      visit?.plannedStartTime,
      visit?.plannedEndTime,
    ];
  });

  const clientColumns = [
    "ClientName",
    "UserName",
    "visitDate",
    "Session",
    "PlannedStarttime",
    "PlannedEndtime",
  ];
  const UpdatedFinalVisitReports = finalVisitReports?.map(
    ({
      clientName,
      userName,
      visit_date,
      sessionType,
      plannedStartTime,
      plannedEndTime,
    }) => ({
      ClientName: clientName,
      UserName: userName,
      VisitDate: visit_date && dayjs(visit_date).format("DD-MMM-YYYY"),
      Session: sessionType,
      PlannedStartTime: plannedStartTime,
      PlannedEndTime: plannedEndTime,
    })
  );
  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 1,
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "45%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports By VisistTimeLine
        </Typography>
        <Download
          data={body}
          columns={clientColumns}
          selectToDownload={rowSelectionModel}
          heading="VisitTimeline reports List"
          rotateMode={false}
          excelData={UpdatedFinalVisitReports}
        />
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          getRowHeight={(params) => {
            if (!params.row) {
              return 60;
            }
            const content = params.row.userName || "";
            const estimatedLineCount = Math.ceil(content.length / 30);
            return Math.max(60, estimatedLineCount * 20);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
          loading={loading} 
          slots={{
            loadingOverlay: Loader, // Displays Loader inside table
          }}
        />
      </Box>
    </Box>
  );
}

export default VisitTimeLine;
