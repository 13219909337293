import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Box, FormLabel, Typography, TextField } from "@mui/material";

const NumberInput = forwardRef(
  (
    {
      label,
      nolabel = false,
      preFilledvalue,
      placeholder,
      onValueChange,
      required = false,
      sortcode = false,
      disabled = false,
      maxLength,
      minValue = 0,
      name,
      enableSpinner = false,
    },
    ref
  ) => {
    const [value, setValue] = useState(preFilledvalue || "");
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
      setValue(preFilledvalue);
      setErrorMessage("");
    }, [preFilledvalue]);
    // Function to handle changes in the text field
    const handleChange = (event) => {
      if (disabled) return;
      const inputValue = event.target.value;

      // Remove all non-digit characters first
      const sanitizedValue = inputValue.replace(/[^0-9]/g, "");

      // Format the sanitized value as sort code if needed
      const formattedValue = sortcode
        ? formatAsSortcode(sanitizedValue)
        : sanitizedValue;

      if (maxLength && sanitizedValue.length > maxLength) {
        setErrorMessage(`${label} cannot exceed ${maxLength} characters.`);
        return;
      }

      setValue(formattedValue);
      setErrorMessage(""); // Clear error message
      onValueChange(formattedValue, event.target.name); // Notify parent component about value change
    };

    const formatAsSortcode = (number) => {
      if (!number) return "";
      const matches = number.match(/(\d{1,2})(\d{1,2})?(\d{1,2})?/);
      if (matches) {
        return [matches[1], matches[2], matches[3]].filter(Boolean).join("-");
      }
      return number;
    };

    // Function to validate the input
    const handleValidation = () => {
      const strValue = String(value).trim(); // Ensure value is treated as a string
      if (required && !strValue) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      if (required && !/^\d{1,2}-\d{1,2}-\d{1,2}$/.test(strValue) && sortcode) {
        setErrorMessage(
          `Please enter a valid ${label} in the format XX-XX-XX.`
        );
        return false;
      }
      if (required && !/^\d+$/.test(strValue.replace(/-/g, ""))) {
        // Check if the value contains only digits
        setErrorMessage(`Please enter a valid ${label}.`);
        return false;
      }
      if (maxLength && strValue.replace(/-/g, "").length > maxLength) {
        setErrorMessage(`${label} cannot exceed ${maxLength} digits.`);
        return false;
      }
      if (value < minValue) {
        setErrorMessage(`${label} cannot be lessthan ${minValue}.`);
        return;
      }
      setErrorMessage("");
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        {!nolabel && (
          <FormLabel>
            <Typography variant="h6">
              {label}
              {required && <span className="star">*</span>}
            </Typography>
          </FormLabel>
        )}
        <TextField
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
          inputProps={{
            maxLength: maxLength, // Restrict max length
            inputMode: enableSpinner ? "numeric" : "text",
            pattern: enableSpinner ? "[0-9]*" : undefined, // Ensure only numbers are allowed
          }}
          type={enableSpinner ? "number" : "text"} // Conditionally render type as number
          disabled={disabled}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default NumberInput;
