import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { RadioInput, Dropdown, Calendar } from "../../reusableComponents";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { BpIcon } from "../../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../../reusableComponents/StyledCheckbox";
import CancelVisit from "../../../assets/images/clients/Visits/cancelvisit.svg";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export default function HangingTodoMedicationDialog({
  openDialog,
  todos,
  medications,
  visits,
  setRefreshVisits,
  handleCloseDialog = () => {},
}) {
  const extractedTodos = todos.map(({ todo_details_id }) => ({
    todoDId: todo_details_id,
    visitDId: "",
    visitGId: "",
    todoGId: "",
    group: "",
    visitLId: [],
    todoLId: [],
  }));

  const vdate =
    todos.length > 0 ? todos[0].todo_date : medications[0].medication_date;

  const vsession =
    todos.length > 0
      ? todos[0].choose_sessions
      : medications[0].choose_sessions;

  console.log("Date44:", vdate);
  console.log("Session44:", vsession);

  const [todo, setTodo] = useState(extractedTodos);

  const [step, setStep] = useState(0);

  const extractedMedications = medications.map(
    ({
      medication_id,
      medication_type,
      blister_pack_details_id,
      scheduled_details_id,
    }) => ({
      medicationDId: medication_id,
      visitDId: "",
      visitGId: "",
      group: "",
      medication_type: medication_type,
      blister_pack_details_id: blister_pack_details_id
        ? blister_pack_details_id
        : 0,
      scheduled_details_id: scheduled_details_id ? scheduled_details_id : 0,
      med_details_id: blister_pack_details_id
        ? [blister_pack_details_id]
        : [scheduled_details_id],
      blister_pack_grp_id: "",
      scheduled_grp_id: "",
      blister_pack_list_id: [],
      scheduled_list_id: [],
      visit_list_id: [],
      med_list_id: [],
    })
  );

  const [medication, setMedication] = useState(extractedMedications);

  useEffect(() => {
    console.log("Medication-Medication", medication);
  }, [medication]);

  const [selectedCheckbox, setSelectedCheckbox] = useState({}); // Store selected checkboxes

  // Example: Setting `todoId` when selecting the first todo item

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };
  const handleTodoCheckboxChange = async (
    event,
    todoId,
    todoGId,
    todoListId,
    visitGId,
    visitId,
    group
  ) => {
    console.log(
      event.target.checked,
      todoId,
      visitId,
      group,
      todoGId,
      visitGId
    );

    let fetchedVisitData = [];
    let fetchedTodoData = [];
    let checked = event.target.checked;

    if (group === 1) {
      // console.log("group visits for multiple visits:", visitGId);
      fetchedVisitData = await fetchGroupVisits(visitGId);
      fetchedTodoData = await fetchGroupTodos(todoGId, todoListId);
      console.log("todoListId", todoListId);
      console.log("todoGId", todoGId);
      console.log("todoData", fetchedTodoData);
      console.log("visitData", fetchedVisitData);
    }
    //console.log("group visits for multiple visits:", visitData);

    setSelectedCheckbox((prev) => {
      // Clone previous state to preserve selections
      const newState = { ...prev };

      // If clicking on an already checked checkbox, clear the selection (uncheck)
      if (
        newState[todoId]?.visitId === visitId &&
        newState[todoId]?.group === group
      ) {
        delete newState[todoId]; // Remove selection, re-enabling all checkboxes
      } else {
        newState[todoId] = { visitId, group }; // Keep other selections intact
      }

      return newState;
    });

    setTodo((prev) =>
      prev.map((item) => {
        if (item.todoDId === todoId) {
          // Toggle the `visitDId` and `group` values based on the checkbox state
          const updatedVisitDId = checked ? visitId : "";
          const updatedVisitList =
            checked && group === 1 ? fetchedVisitData : [];
          const updatedTodoList = checked && group === 1 ? fetchedTodoData : [];

          const updatedGroup = checked ? group : "";
          console.log(
            "item",
            checked,
            item,
            updatedVisitDId,
            updatedGroup,
            updatedVisitList,
            updatedTodoList
          );
          return {
            ...item,
            visitDId: updatedVisitDId,
            group: updatedGroup,
            visitLId: updatedVisitList,
            todoLId: updatedTodoList,
          };
        }
        return item; // Return unchanged items
      })
    );
  };

  const TisChecked = (todoId, visitId, group) => {
    return (
      selectedCheckbox[todoId]?.visitId === visitId &&
      selectedCheckbox[todoId]?.group === group
    );
  };

  const TisDisabled = (todoDetailsId, visitDetailsId) => {
    // Disable all checkboxes if any checkbox is selected for the todo
    return (
      selectedCheckbox[todoDetailsId] &&
      selectedCheckbox[todoDetailsId]?.visitDetailsId !== visitDetailsId
    );
  };

  const handleMedicationCheckboxChange = async (
    event,
    medicationId,
    blister_pack_grp_id,
    scheduled_grp_id,
    visitId,
    visitGId,
    group
  ) => {
    console.log(
      event.target.checked,
      medicationId,
      blister_pack_grp_id,
      scheduled_grp_id,
      visitId,
      visitGId,
      group
    );

    let fetchedVisitData = [];
    let fetchedMedData = [];
    let checked = event.target.checked;

    if (group === 1) {
      // console.log("group visits for multiple visits:", visitGId);
      fetchedVisitData = await fetchGroupVisits(visitGId);
      fetchedMedData = await fetchGroupMedicines(
        blister_pack_grp_id,
        scheduled_grp_id
      );
      console.log("visitGId", visitGId);
      console.log("blister_pack_grp_id", blister_pack_grp_id);
      console.log("scheduled_grp_id", scheduled_grp_id);
      console.log("MedData", fetchedMedData);
      console.log("visitData", fetchedVisitData);
      console.log("checked", checked);
    }
    //console.log("group visits for multiple visits:", visitData);

    setSelectedCheckbox((prev) => {
      // Clone previous state to preserve selections
      const newState = { ...prev };

      // If clicking on an already checked checkbox, clear the selection (uncheck)
      if (
        newState[medicationId]?.visitId === visitId &&
        newState[medicationId]?.group === group
      ) {
        delete newState[medicationId]; // Remove selection, re-enabling all checkboxes
      } else {
        newState[medicationId] = { visitId, group }; // Keep other selections intact
      }

      return newState;
    });

    setMedication((prev) =>
      prev.map((item) => {
        if (item.medicationDId === medicationId) {
          // Toggle the `visitDId` and `group` values based on the checkbox state
          const updatedVisitDId = checked ? visitId : "";

          const updatedVisitList =
            checked && group === 1 ? fetchedVisitData : [];
          const updatedMedList = checked && group === 1 ? fetchedMedData : [];

          const updatedGroup = checked ? group : "";
          console.log("item", checked, item, updatedVisitDId, updatedGroup);
          return {
            ...item,
            visitDId: updatedVisitDId,
            group: updatedGroup,
            visit_list_id: updatedVisitList,
            med_list_id: updatedMedList,
          };
        }
        //console.log("item", item);
        return item; // Return unchanged items
      })
    );
  };

  const MisChecked = (medicationId, visitId, group) => {
    return (
      selectedCheckbox[medicationId]?.visitId === visitId &&
      selectedCheckbox[medicationId]?.group === group
    );
  };

  const MisDisabled = (medication_details_id, visitDetailsId) => {
    // Disable all checkboxes if any checkbox is selected for the todo
    return selectedCheckbox[medication_details_id];
  };

  const fetchGroupVisits = async (visit_group_id) => {
    const visit = visit_group_id;
    try {
      const token = getCookie();
      //console.log("Monday: ", mondayDate);
      const URL = `${process.env.REACT_APP_BASE_URL}/todo-todo-visit-list?visit_date=${vdate}&choose_sessions=${vsession}&visit_group_id=${visit}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        const visits = info.flatMap((item) => item.visit_details_id);
        //setVisitData(info.flatMap((item) => item.visit_details_id)); // Store the fetched visit data in the state
        return visits; // Return data for further use
      } else {
        //setError("Failed to fetch visit data.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      //setError("An error occurred while fetching data.");
      return [];
    }
  };
  const fetchGroupTodos = async (todo_id, todo_list_id) => {
    const todo = todo_id;
    const todoList = todo_list_id;
    try {
      const token = getCookie();
      //console.log("Monday: ", mondayDate);
      const URL = `${process.env.REACT_APP_BASE_URL}/todo-todo-visit-list?todo_date=${vdate}&choose_sessions=${vsession}&todo_id=${todo}&todo_list_id=${todoList}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        const todos = info.flatMap((item) => item.todo_details_id);
        //setTodoData(info.flatMap((item) => item.todo_details_id)); // Store the fetched visit data in the state
        return todos; // Return data for further use
      } else {
        //setError("Failed to fetch visit data.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      //setError("An error occurred while fetching data.");
      return [];
    }
  };

  const fetchGroupMedicines = async (blister_pack_id, scheduled_id) => {
    const med = blister_pack_id ? blister_pack_id : scheduled_id;

    try {
      const token = getCookie();
      //console.log("Monday: ", mondayDate);
      const URL = blister_pack_id
        ? `${process.env.REACT_APP_BASE_URL}/medication-visit-list?blister_pack_date=${vdate}&choose_sessions=${vsession}&blister_pack_id=${med}`
        : `${process.env.REACT_APP_BASE_URL}/medication-visit-list?scheduled_date=${vdate}&choose_sessions=${vsession}&scheduled_id=${med}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        const meds = blister_pack_id
          ? info.flatMap((item) => item.blister_pack_details_id)
          : info.flatMap((item) => item.scheduled_details_id);
        //setTodoData(info.flatMap((item) => item.todo_details_id)); // Store the fetched visit data in the state
        return meds; // Return data for further use
      } else {
        //setError("Failed to fetch visit data.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      //setError("An error occurred while fetching data.");
      return [];
    }
  };

  const postHangingTodosLinked = async () => {
    try {
      const filteredTodos = todo.filter((item) => item.group !== "");

      // Map to create the desired structure

      const list = filteredTodos.map((item) => ({
        todo_details_id: item.group === 1 ? item.todoLId : [item.todoDId], // Corrected property name
        visit_details_id: item.group === 1 ? item.visitLId : [item.visitDId],
        group: item.group,
      }));
      const body = { list };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/todo-add-group-list`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        //setSelectedUser([]);
        //handlestep();
        postHangingMedicationsLinked();
      } else {
        console.log("Failed to set todo's for selected visits");
        postHangingMedicationsLinked();
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const postHangingMedicationsLinked = async () => {
    try {
      const filteredMedications = medication.filter(
        (item) => item.group !== ""
      );
      console.log("filteredMedications", filteredMedications);
      // Map to create the desired structure

      const list = filteredMedications.map((item) => ({
        id: item.group === 1 ? item.med_list_id : item.med_details_id,
        visit_details_id:
          item.group === 1 ? item.visit_list_id : [item.visitDId],
        group: item.group,
      }));

      console.log("Medication List", list);
      const body = { list };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/medication-add-group-list`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        //setSelectedUser([]);
        //handlestep();
        const min = 1;
        const max = 100;
        const generatedNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        setRefreshVisits(generatedNumber);
        setStep(1);
      } else {
        console.log("Failed to set medication's for selected visits");
        const min = 1;
        const max = 100;
        const generatedNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        setRefreshVisits(generatedNumber);
        setStep(1);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onConfirmFun = () => {
    {
      console.log("updated Todos's ", todo);
    }

    {
      console.log("updated Medication's ", medication);
    }

    postHangingTodosLinked();

    handleCloseDialog();
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "60%", // Set your desired width here
          maxWidth: "900px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          {visits.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="h3"
                sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
              >
                Hanging Todo's and Medication's
              </Typography>
            </Box>
          )}
          {visits.length > 0 ? (
            <Grid container direction="column">
              {/* {console.log("Hanging ", todos)} */}

              {todos.map((todo) => {
                return (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #279989",
                        borderRadius: "8px",
                        backgroundColor: "#E3F2F0",
                        width: "45%",
                        height: "60px",
                        margin: "10px 0",
                        padding: "5px 10px",
                        textAlign: "Left",
                        overflow: "hidden",
                      }}
                    >
                      <Typography variant="body4">
                        {todo.todo_description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "5%",
                        borderTop: "1px dotted  #269AB9",
                        height: "1px",
                        marginLeft: "1px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "70%",
                        margin: "20px 0",
                        //paddingLeft: "80px",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        "&::before": {
                          content: '""', // This pseudo-element creates the border
                          position: "absolute",
                          left: 0,
                          top: "22.5px", // Leave 20px space from the top
                          bottom: "22.5px", // Leave 20px space from the bottom
                          width: "1px", // No width, only a vertical border
                          borderLeft: "1px dotted #269AB9", // Dotted line style
                        },
                        paddingLeft: "1.5px",
                        //margin: "5px 0",
                        //alignItems: "end",
                      }}
                    >
                      {visits.map((visit) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "5px 0",
                            }}
                          >
                            <Box
                              sx={{
                                flex: 1,
                                borderTop: "1px dotted  #269AB9",
                                height: "1px",
                              }}
                            ></Box>

                            <Box
                              sx={{
                                textAlign: "left",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "410px",
                                //border: "1px solid #2799894d",
                                borderRadius: "8px",
                                padding: "0 10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                {visit.planned_start_time} -{" "}
                                {visit.planned_end_time}
                              </Typography>
                              <Checkbox
                                color="primary"
                                onChange={(event) =>
                                  handleTodoCheckboxChange(
                                    event,
                                    todo.todo_details_id,
                                    todo.todo_id,
                                    todo.todo_list_id,
                                    visit.visit_group_id,
                                    visit.visit_details_id,
                                    0
                                  )
                                }
                                checked={TisChecked(
                                  todo.todo_details_id,
                                  visit.visit_details_id,
                                  0
                                )}
                                disabled={
                                  !TisChecked(
                                    todo.todo_details_id,
                                    visit.visit_details_id,
                                    0
                                  ) &&
                                  TisDisabled(
                                    todo.todo_details_id,
                                    visit.visit_details_id
                                  )
                                } // Keep checked one enabled
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />

                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                This visit only
                              </Typography>
                              <Checkbox
                                color="primary"
                                onChange={(event) =>
                                  handleTodoCheckboxChange(
                                    event,
                                    todo.todo_details_id,
                                    todo.todo_id,
                                    todo.todo_list_id,
                                    visit.visit_group_id,
                                    visit.visit_details_id,
                                    1
                                  )
                                }
                                checked={TisChecked(
                                  todo.todo_details_id,
                                  visit.visit_details_id,
                                  1
                                )}
                                disabled={
                                  !TisChecked(
                                    todo.todo_details_id,
                                    visit.visit_details_id,
                                    1
                                  ) &&
                                  TisDisabled(
                                    todo.todo_details_id,
                                    visit.visit_details_id
                                  )
                                } // Keep checked one enabled
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                All {visit.session_type} visits
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                );
              })}
              {medications.map((medication) => {
                return (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #279989",
                        borderRadius: "8px",
                        backgroundColor: "#E3F2F0",
                        width: "45%",
                        height: "60px",
                        margin: "10px 0",
                        padding: "5px 10px",
                        textAlign: "Left",
                        overflow: "hidden",
                      }}
                    >
                      <Typography variant="body4">
                        {medication.nhs_medicine_name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "5%",
                        borderTop: "1px dotted  #269AB9",
                        height: "1px",
                        marginLeft: "1px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "70%",
                        margin: "20px 0",
                        //paddingLeft: "80px",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        "&::before": {
                          content: '""', // This pseudo-element creates the border
                          position: "absolute",
                          left: 0,
                          top: "22.5px", // Leave 20px space from the top
                          bottom: "22.5px", // Leave 20px space from the bottom
                          width: "1px", // No width, only a vertical border
                          borderLeft: "1px dotted #269AB9", // Dotted line style
                        },
                        paddingLeft: "1.5px",
                        //margin: "5px 0",
                        //alignItems: "end",
                      }}
                    >
                      {visits.map((visit) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "5px 0",
                            }}
                          >
                            <Box
                              sx={{
                                flex: 1,
                                borderTop: "1px dotted  #269AB9",
                                height: "1px",
                              }}
                            ></Box>

                            <Box
                              sx={{
                                textAlign: "left",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "410px",
                                //border: "1px solid #2799894d",
                                borderRadius: "8px",
                                padding: "0 10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                {visit.planned_start_time} -{" "}
                                {visit.planned_end_time}
                              </Typography>
                              <Checkbox
                                color="primary"
                                onChange={(event) =>
                                  handleMedicationCheckboxChange(
                                    event,
                                    medication.medication_id,
                                    medication.blister_pack_id,
                                    medication.scheduled_id,
                                    visit.visit_details_id,
                                    visit.visit_group_id,
                                    0
                                  )
                                }
                                checked={MisChecked(
                                  medication.medication_id,
                                  visit.visit_details_id,
                                  0
                                )}
                                disabled={
                                  !MisChecked(
                                    medication.medication_id,
                                    visit.visit_details_id,
                                    0
                                  ) &&
                                  MisDisabled(
                                    medication.medication_id,
                                    visit.visit_details_id
                                  )
                                } // Disable if the other checkbox is selected
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />

                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                This visit only
                              </Typography>
                              <Checkbox
                                color="primary"
                                onChange={(event) =>
                                  handleMedicationCheckboxChange(
                                    event,
                                    medication.medication_id,
                                    medication.blister_pack_id,
                                    medication.scheduled_id,
                                    visit.visit_details_id,
                                    visit.visit_group_id,
                                    1
                                  )
                                }
                                checked={MisChecked(
                                  medication.medication_id,
                                  visit.visit_details_id,
                                  1
                                )}
                                disabled={
                                  !MisChecked(
                                    medication.medication_id,
                                    visit.visit_details_id,
                                    1
                                  ) &&
                                  MisDisabled(
                                    medication.medication_id,
                                    visit.visit_details_id
                                  )
                                } // Disable if the other checkbox is selected
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Show ellipsis for overflow
                                  maxWidth: "100%", // Adjust based on container width
                                }}
                                variant="body4"
                              >
                                All {visit.session_type} visits
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box>
              <Typography variant="h3">
                No Active Visits Available for this Session
              </Typography>
            </Box>
          )}
        </DialogContent>
        {visits.length > 0 && (
          <DialogActions>
            <Button
              variant="customcancel"
              onClick={onCancelFun}
              color="primary"
            >
              Cancel
            </Button>
            <Button variant="customsave" onClick={onConfirmFun} color="primary">
              Save
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
}
