import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Slider,
  Card,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Name, Calendar, RadioInput, Dropdown } from "../../reusableComponents"; // Adjust the path
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import { getCookie } from "../../../utils/utils";
import Admin from "../../../assets/images/users/Admin.svg";
import dayjs from "dayjs";
import { EditTheme } from "../../users/Theme";

import { BpIcon } from "../../reusableComponents/StyledCheckbox";
import { BpCheckedIcon } from "../../reusableComponents/StyledCheckbox";

export const AssessmentCardEdit = ({
  assessmentQuestions,
  assessmentName,
  assessmentId,
  user,
  toggleEditMode,
  setIsEditToggle,
  name,
  selectedCard,
  isNewAssessmentToggle,
  resetNewAssessmentVisibility,
  refreshVariableEdit,
  setRefreshVariable,
  setIsNewAssessmentVisible,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  setDialogOpen,
  setIsCancelButton,
  setIsDraft,
}) => {
  const currentUserId = `${user.id}`;

  const signName = `${user.first_name + " " + user.last_name}`;

  const [initialForm, setInitialForm] = useState([{}]);

  // const [sign1Submitted, setSign1Submitted] = useState(false); //To check if 2nd sign should be added

  const [fetchCheckVariable, setFetchCheckVariable] = useState(false);

  const [isSaveVisible, setIsSaveVisible] = useState(0);

  const fetchAssessmetDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}?client_id=${selectedCard.client_id}`;
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch assessment details");
      }

      const data = await response.json();
      const activeData = data.data.filter((item) => item.status === "Active" || item.status === "Draft" || item.status === "undefined");

      setInitialForm(activeData.length > 0 ? activeData : [{}]); // Reset or set form
       console.log("Fetched Form Data8788787878788:", activeData);

      if (selectedCard.assessment_id === 21) {
        const transformedData = activeData.map((item) => {
          // Filter and map the activityDetails
          const activities = item.activities.map((activity) => ({
            action_to_be_taken: activity.action_to_be_taken,
            activity: activity.activity,
            equipment: activity.equipment,
            range: activity.range, // Default range to 2
            risk_level: activity.risk_level,
            task_support_required: activity.task_support_required,
          }));

          // Now, construct the transformed object
          return {
            activities: activities,
            client_id: item.activities?.[0].client_id, // Default to 0 if not available
            consent: 0,
            date_1:
              item.activities?.[0].sign_1 === signName
                ? dayjs()
                : item.activities?.[0].date_1,
            date_2:
              item.activities?.[0].sign_1 === signName
                ? item.activities?.[0].date_2 // Keep the existing date_2
                : item.activities?.[0].sign_2 === signName
                ? dayjs()
                : item.activities?.[0].date_2,
            require_support: item.activities?.[0].require_support,
            sign_1: item.activities?.[0].sign_1,
            sign_2:
              item.activities?.[0].sign_1 === signName
                ? item.activities?.[0].sign_2
                : item.activities?.[0].sign_1 === item.activities?.[0].sign_2
                ? ""
                : item.activities?.[0].sign_2 || signName, // Otherwise, use existing sign_2 or signName
            id: item.activities?.[0].id,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedData);
      } else if (selectedCard.assessment_id === 27) {
        const transformedDataSelf = activeData.map((item) => {
          const medications = item.medicationDetails.map((medicine) => ({
            medicine_name: medicine.medicine_name,
            dose: medicine.dose,
            route: medicine.route,
            time_frequency: medicine.time_frequency,
            self_administration: medicine.self_administration,
          }));

          // Now, construct the transformed object
          return {
            medicationDetails: medications,
            client_id: item.medicationDetails?.[0].client_id, // Default to 0 if not available
            consent: 0,
            date_1:
              item.medicationDetails?.[0].sign_1 === signName
                ? dayjs()
                : item.medicationDetails?.[0].date_1,
            date_2:
              item.medicationDetails?.[0].sign_1 === signName
                ? item.medicationDetails?.[0].date_2 // Keep the existing date_2
                : item.medicationDetails?.[0].sign_2 === signName
                ? dayjs()
                : item.medicationDetails?.[0].date_2,
            sign_1: item.medicationDetails?.[0].sign_1,
            sign_2:
              item.medicationDetails?.[0].sign_1 === signName
                ? item.medicationDetails?.[0].sign_2
                : item.medicationDetails?.[0].sign_1 ===
                  item.medicationDetails?.[0].sign_2
                ? ""
                : item.medicationDetails?.[0].sign_2 || signName,
            self_administer_fully:
              item.medicationDetails?.[0].self_administer_fully,
            medicines: item.medicationDetails?.[0].medicines,
            time_to_take_medicines:
              item.medicationDetails?.[0].time_to_take_medicines,
            dosage: item.medicationDetails?.[0].dosage,
            take_medicines: item.medicationDetails?.[0].take_medicines,
            special_instructions:
              item.medicationDetails?.[0].special_instructions,
            side_effects: item.medicationDetails?.[0].side_effects,
            missed_dose: item.medicationDetails?.[0].missed_dose,
            difficulty_reading_label:
              item.medicationDetails?.[0].difficulty_reading_label,
            opens_medication: item.medicationDetails?.[0].opens_medication,
            safe_storage: item.medicationDetails?.[0].safe_storage,
            agrees_to_notify: item.medicationDetails?.[0].agrees_to_notify,
            responsible_for_reorder:
              item.medicationDetails?.[0].responsible_for_reorder,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedDataSelf);
      } else if (selectedCard.assessment_id === 24) {
        const transformedDataEquip = activeData.map((item) => {
          const equipments = item.equipmentDetails.map((eqp) => ({
            equipment: eqp.equipment,
            equipment_description: eqp.equipment_description,
            provided_by: eqp.provided_by,
            purpose: eqp.purpose,
            date_of_next_test: eqp.date_of_next_test,
            test_completed_on: eqp.test_completed_on,
          }));

          // Now, construct the transformed object
          return {
            equipmentDetails: equipments,
            client_id: item.equipmentDetails?.[0].client_id, // Default to 0 if not available
            consent: 0,
            date_1:
              item.equipmentDetails?.[0].sign_1 === signName
                ? dayjs()
                : item.equipmentDetails?.[0].date_1,
            date_2:
              item.equipmentDetails?.[0].sign_1 === signName
                ? item.equipmentDetails?.[0].date_2 // Keep the existing date_2
                : item.equipmentDetails?.[0].sign_2 === signName
                ? dayjs()
                : item.equipmentDetails?.[0].date_2,
            sign_1: item.equipmentDetails?.[0].sign_1,
            sign_2:
              item.equipmentDetails?.[0].sign_1 === signName
                ? item.equipmentDetails?.[0].sign_2
                : item.equipmentDetails?.[0].sign_1 ===
                  item.equipmentDetails?.[0].sign_2
                ? ""
                : item.equipmentDetails?.[0].sign_2 || signName,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedDataEquip);
      } else if (selectedCard.assessment_id === 23) {
        const transformedDataEquip = activeData.map((item) => {
          const substance = item.substanceDetails.map((subs) => ({
            name_of_product: subs.name_of_product,
            type_of_harm: subs.type_of_harm,
            description_substance: subs.description_substance,
            color: subs.color,
            details_substance: subs.details_substance,
            contact_substance: subs.contact_substance,
            may_it_occur: subs.may_it_occur,
            substance_use: subs.substance_use,
            safe_alternative: subs.safe_alternative,
            controls: subs.controls,
            emergency_procedures: subs.emergency_procedures,
            staff_aware: subs.staff_aware,
            controls_reduced: subs.controls_reduced,
            action_to_be_taken: subs.action_to_be_taken,
          }));

          // Now, construct the transformed object
          return {
            substanceDetails: substance,
            client_id: item.substanceDetails?.[0].client_id, // Default to 0 if not available
            consent: 0,
            date_1:
              item.substanceDetails?.[0].sign_1 === signName
                ? dayjs()
                : item.substanceDetails?.[0].date_1,
            date_2:
              item.substanceDetails?.[0].sign_1 === signName
                ? item.substanceDetails?.[0].date_2 // Keep the existing date_2
                : item.substanceDetails?.[0].sign_2 === signName
                ? dayjs()
                : item.substanceDetails?.[0].date_2,
            sign_1: item.substanceDetails?.[0].sign_1,
            sign_2:
              item.substanceDetails?.[0].sign_1 === signName
                ? item.substanceDetails?.[0].sign_2
                : item.substanceDetails?.[0].sign_1 ===
                  item.substanceDetails?.[0].sign_2
                ? ""
                : item.substanceDetails?.[0].sign_2 || signName,
          };
        });

        // Setting the transformed data into the state
        setInitialForm(transformedDataEquip);
      } else {
        console.log("BEHAVIOUR DEBUG SECTION", activeData);
        const transformedDataAll = activeData.map((item) => {
          // Now, construct the transformed object
          return {
            ...item,
            client_id: item.client_id, // Default to 0 if not available
             consent: item.consent ?? 0, 
            date_1: item.sign_1 === signName ? dayjs() : item.date_1,
            date_2:
              item.sign_1 === signName
                ? item.date_2 // Keep the existing date_2
                : item.sign_2 === signName
                ? dayjs()
                : item.sign_2 === ""
                ? dayjs()
                : item.date_2,
            sign_1: item.sign_1,
            sign_2:
              item.sign_1 === signName
                ? item.sign_2
                : item.sign_1 === item.sign_2
                ? ""
                : item.sign_2 || signName,
          };
        });
        // setInitialForm((prevState) => [
        //   {
        //     ...prevState[0],
        //     sign_1:
        //       initialForm[0]?.sign_1 && initialForm[0]?.sign_1 === signName
        //         ? initialForm[0]?.sign_1
        //         : signName,
        //     date_1: dayjs(),
        //     sign_2: finalSignName,
        //     date_2: finalSignDate,
        //   },
        // ]);
        setInitialForm(transformedDataAll);
      }

      console.log("Fetched Form Data:", activeData);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
      setInitialForm([{}]); // Fallback to an empty form if fetch fails
    }
    setFetchCheckVariable(true);
  };
  // useEffect(() => {}, []);

  useEffect(() => {
    console.log("isSaveVisible", isSaveVisible);
  }, [isSaveVisible]);

  useEffect(() => {
    if (isNewAssessmentToggle) {
      if (assessmentQuestions?.length > 0) {
        // Initialize form for new assessment
        const defaultForm = assessmentQuestions
          .filter((assessment) => assessment.assessment_name === assessmentName) // Filter by selected assessment name
          .reduce(
            (acc, assessment) => {
              assessment.questionslist.forEach((question, index) => {
                // Skip the status_ field for the last question
                if (index !== assessment.questionslist.length - 1) {
                  acc[`status_${question.questionid}`] = "";
                }
                acc[`comment_${question.questionid}`] = ""; // Initialize comment
                acc[`q${index + 1}`] = question.questionid; // Include questionid explicitly
              });
              return acc;
            },

            {
              client_id: selectedCard.client_id,
              sign_1: signName,

              date_1: dayjs(),
              sign_2: "",
              date_2: "",
              consent: 0,
            }
          );

        setInitialForm([defaultForm]); // Set the initialized form
        // console.log("Initialized Form with Question IDs:", initialForm); // Debugging
      }
    } else {
      fetchAssessmetDetails();
      // fetchCheckVariable &&
      //   setInitialForm((prevState) => {
      //     const updatedForm = {
      //       ...prevState[0],
      //       sign_1:
      //         prevState[0]?.sign_1 && prevState[0]?.sign_1 === signName
      //           ? prevState[0]?.sign_1
      //           : signName,
      //       date_1: dayjs(),
      //       sign_2:
      //         prevState[0]?.sign_1 && prevState[0]?.sign_1 !== signName
      //           ? signName
      //           : "",
      //       date_2: prevState[0]?.date_1 ? dayjs() : "",
      //     };

      //     return [updatedForm];
      //   });
    }
  }, [
    isNewAssessmentToggle,
    assessmentQuestions,
    selectedCard,
    signName,
    refreshVariableEdit,
  ]);

  const putAssessmentAPI = async () => {
    try {
      const token = getCookie();

      // Exclude keys starting with 'q' and specific keys like 'assessment_name_id' and 'client_id'
      const excludedKeys = [
        "assessment_name_id",
        "client_id",
        "id",
        "updated_at",
        "created_at",
      ];
      let URL;
      let filteredBody;
      let filteredBodyRisk = initialForm[0];
      console.log("POSTING FOR REGULAR ASSESSMENT", selectedCard.assessment_id);
      if (selectedCard.assessment_id < 21) {
        filteredBody = Object.keys(initialForm[0])
          .filter((key) => !key.startsWith("q") && !excludedKeys.includes(key)) // Exclude specific keys
          .reduce((acc, key) => {
            acc[key] = initialForm[0][key];
            return acc;
          }, {});
          filteredBody.status = initialForm[0]?.status || "Draft";
        URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}/${initialForm[0].client_id}/${initialForm[0].id}`;
      } else if (selectedCard.assessment_id === 25) {
        URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}/${initialForm[0].client_id}/${initialForm[0].id}`;
      } else if (selectedCard.assessment_id === 26) {
        URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}/${initialForm[0].client_id}/${initialForm[0].id}`;
      } else if (selectedCard.assessment_id === 22) {
        URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}/${initialForm[0].client_id}/${initialForm[0].id}`;
      } else {
        URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}/${selectedCard.assessment_id}`;
      }
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          selectedCard.assessment_id < 21 ? filteredBody : filteredBodyRisk
        ),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");

        if (resetNewAssessmentVisibility) {
          resetNewAssessmentVisibility();
        } else {
          toggleEditMode();
        }
      } else {
        console.log("Failed to edit Assessment.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const postAsessmentDetails = async () => {
    try {
      // const updatedInitialForm = initialForm.filter()

      const token = getCookie();

      console.log("POST initialForm", initialForm);

      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/${selectedCard.api_assessment_name}/${selectedCard.assessment_id}`;
      // const URL = `${BASE_URL}api/client/aboutMe/31`;
      let postData = {
        ...initialForm[0],
        status: initialForm[0]?.status || "Draft", // If status is missing, set it to Draft
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      console.log("Form Data Body option.body.......: ", data);

      if (response.ok && data.statusCode === 200) {
        console.log("Data uploaded successfully");
        console.log("New Assessment submitted successfully.");
        setRefreshVariable((prevState) => !prevState);
        setIsNewAssessmentVisible(true);
        if (resetNewAssessmentVisibility) {
          resetNewAssessmentVisibility();
        } else {
          toggleEditMode();
        }

        //
        //setIsEditToggle(false);
      } else {
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    console.log("Updated fetched Initial Form:ss", initialForm);
  }, [initialForm]);

  // Open the ConfirmationDialog if there are unsaved changes
  const handleSave = () => {
    if (hasUnsavedChanges) {
      setDialogOpen(true); // Show dialog
    } else {
      handleSubmit(); // No changes, proceed with save
    }
  };

  const handleRadioChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [""]; // Initialize if empty
      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const [isCheckboxTrue, setIsCheckboxTrue] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  // const handleCheckboxChange = () => {
  //   isCheckboxTrue ? setIsCheckboxTrue(false) : setIsCheckboxTrue(true);
  //   // setIsCheckboxTrue(event.target.checked); // Update state based on user interaction
  // };
  const handleCheckboxChange = (event) => {
    // setIsCheckboxTrue((prevState) => {
    const newState = event.target.checked; // Toggle the checkbox state
    setIsSaveVisible(newState ? 1 : 0);
    if (newState) {
      setCheckboxError(false); // Hide the error message if the checkbox is checked
    }

    // Update the `consent` field in `initialForm`
    setInitialForm((prevForm) => {
      const updatedForm = [...prevForm];
      updatedForm[0] = {
        ...updatedForm[0],
        consent: newState ? 1 : 0, // Set consent to 1 if checked, 0 if unchecked
        status: newState ? "Active" : "Draft",
      };
      return updatedForm;
    });

    // return newState;
    // });
  };

  const handleValueChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const handleDateChange = (date, name) => {
    setInitialForm((prevState) => {
      const updatedForm = [...prevState]; // Create a shallow copy of the array
      if (updatedForm[0]) {
        updatedForm[0] = {
          ...updatedForm[0], // Create a shallow copy of the object
          [name]: date, // Update the specific field
        };
      }
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleChange = (updatedForm) => {
    areObjectsEqual(updatedForm, initialForm)
      ? setHasUnsavedChanges(false)
      : setHasUnsavedChanges(true); // Detect changes
  };

  const handleSubmit = () => {
    // Validate checkbox
    // if (initialForm[0]?.consent === 0) {
    //   setCheckboxError(true);
    //   return; // Stop submission if checkbox is not checked
    // }

    // setCheckboxError(false); // Clear any existing checkbox errors

    console.log("Form Data before submission:", initialForm[0]);

    // Handle submission based on mode
    if (isNewAssessmentToggle) {
      postAsessmentDetails(initialForm[0])
        .then(() => {})
        .catch((error) => {
          console.error("Error submitting new assessment:", error);
        });
    } else {
      putAssessmentAPI(initialForm[0])
        .then(() => {
          console.log("Assessment updated successfully.");
          //toggleEditMode();
          // if (resetNewAssessmentVisibility) {
          //   resetNewAssessmentVisibility();
          // } else {
          //   toggleEditMode();
          // }
        })
        .catch((error) => {
          console.error("Error updating assessment:", error);
        });
    }
  };

  const removeUnwantedKeys = (form) => {
    // Use Object.keys to iterate through the keys of the form object
    Object.keys(form).forEach((key) => {
      // Check if the key starts with 'comment_', 'q', or 'status_'
      if (
        key.startsWith("comment_") ||
        key.startsWith("q") ||
        key.startsWith("status_")
      ) {
        delete form[key]; // Delete the key from the object
      }
    });
    return form;
  };

  // marks common for all Sliders
  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  // Activity Risk Assessment section begins
  const ensureActivityFieldsAreInitialized = (form) => ({
    require_support: form.require_support || "",
    activities: form.activities || [
      {
        activity: form.activity || "",
        task_support_required: form.task_support_required || "",
        risk_level: form.risk_level || "",
        range: form.range || "1",
        equipment: form.equipment || "",
        action_to_be_taken: form.action_to_be_taken || "",
      },
    ],
  });

  const handleActivityValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      const currentActivities = updatedForm[0]?.activities || [{}];

      // Ensure fields for this form are initialized
      updatedForm[0] = {
        ...ensureActivityFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      if (name === "require_support") {
        updatedForm[0] = {
          ...updatedForm[0],
          [name]: value,
        };
      } else if (name === "risk_level") {
        currentActivities[index] = {
          ...currentActivities[index],
          [name]: value,
          range: "1",
        };
        updatedForm[0] = {
          ...updatedForm[0],
          activities: currentActivities,
        };
      } else if (name === "range") {
        currentActivities[index] = {
          ...currentActivities[index],
          [name]: String(value), // Convert range to a string
        };
        updatedForm[0] = {
          ...updatedForm[0],
          activities: currentActivities,
        };
      } else {
        currentActivities[index] = {
          ...currentActivities[index],
          [name]: value,
        };
        updatedForm[0] = {
          ...updatedForm[0],
          activities: currentActivities,
        };
      }

      updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const addActivity = () => {
    setInitialForm((prevState) => {
      // Clone the current state
      const updatedForm = prevState.length ? [...prevState] : [{}];

      // Initialize activities array if not already present
      const currentActivities = updatedForm[0]?.activities || [];

      // Add a new activity object with default values
      currentActivities.push({
        activity: "",
        task_support_required: "",
        risk_level: "",
        range: "1",
        equipment: "",
        action_to_be_taken: "",
      });

      // Update the form with the initialized or updated activities array
      updatedForm[0] = {
        ...updatedForm[0],
        activities: currentActivities,
      };

      return updatedForm;
    });
  };

  const activityRiskAssessment = () => {
    const defaultActivity = {
      require_support: "",
    };
    const defaultActivities = {
      activity: "",
      task_support_required: "",
      risk_level: "",
      range: "1",
      equipment: "",
      action_to_be_taken: "",
    };

    // Ensure `financialDetails` is initialized
    const initializeActivity = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (updatedForm[0]?.require_support === undefined) {
        // Initialize financialDetails and flatten into the root object
        updatedForm[0] = {
          ...updatedForm[0], // retain any existing fields
          ...defaultActivity, // spread defaultFinancial at the root level
        };
        updatedForm[0].activities = [{ ...defaultActivities }];

        updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeActivity();
    return (
      <Box>
        <Typography variant="h4">Activity Risk Assessment</Typography>
        <Typography variant="h6"></Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label={`Does ${name} require support with activities?`}
            // name={`status_${index}`}
            name="require_support"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            onValueChange={(val) => {
              // let val;
              // if (e === "yes") {
              //   val = 1;
              // } else {
              //   val = 0;
              // }
              handleActivityValueChange(val, "require_support", null);
            }}
            preFilledvalue={
              initialForm[0]?.require_support
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.require_support === 0
              //   ? "no"
              //   : ""
            }
          />
        </Container>
        {initialForm[0]?.require_support === "yes" && (
          <>
            {initialForm[0]?.activities.map((activity, index) => (
              <Box
                key={index}
                sx={{
                  marginBlock: "20px",
                  border: "1px solid #2799894d",
                  padding: "5px 15px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline-block",
                    position: "absolute",
                    margin: "-19px 0 0 10px",
                    backgroundColor: "#FFFFFF",
                    fontWeight: "600",
                    color: "#279989",
                  }}
                >
                  Activity {index + 1}
                </Typography>
                <Grid container spacing={2} direction="row" paddingTop={4}>
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Activity"
                      name="activity"
                      onValueChange={(value) =>
                        handleActivityValueChange(value, "activity", index)
                      }
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]?.activity || ""
                      }
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Task/Support Required"
                      name="task_support_required"
                      onValueChange={(value) => {
                        handleActivityValueChange(
                          value,
                          "task_support_required",
                          index
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]
                          ?.task_support_required || ""
                      }
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12}>
                    <Dropdown
                      label="Risk"
                      name={`risk_level`}
                      list={[
                        { text: "Low" },
                        { text: "Medium" },
                        { text: "High" },
                      ]}
                      onDropdownSelect={(v) =>
                        handleActivityValueChange(
                          v?.text || "",
                          "risk_level",
                          index
                        )
                      }
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]?.risk_level || ""
                      }
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12} alignContent="flex-end">
                    <Box sx={{ paddingInline: "50px" }}>
                      <Slider
                        aria-label="Risk Level"
                        name="range"
                        value={initialForm[0]?.activities?.[index]?.range || ""}
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={5}
                        sx={{
                          color: "#279989",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-track": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-thumb": {
                            backgroundColor: "#ccc",
                          },
                          "& .MuiSlider-valueLabel": {
                            backgroundColor: "#279989",
                          },
                        }}
                        disabled={
                          initialForm[0]?.activities?.[index]?.[
                            `risk_level`
                          ] === undefined ||
                          initialForm[0]?.activities?.[index]?.[
                            `risk_level`
                          ] === ""
                        }
                        marks={marks}
                        onChange={(e) =>
                          handleActivityValueChange(
                            e.target.value,
                            "range",
                            index
                          )
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Equipment"
                      name="equipment"
                      onValueChange={(value) => {
                        handleActivityValueChange(value, "equipment", index);
                      }}
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]?.equipment || ""
                      }
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Name
                      className="multiline-textfield"
                      name="action_to_be_taken"
                      label="Action to be taken"
                      placeholder="Action to be taken"
                      multiline
                      rows="5"
                      onValueChange={(value) => {
                        handleActivityValueChange(
                          value,
                          "action_to_be_taken",
                          index
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.activities?.[index]
                          ?.action_to_be_taken ?? ""
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={addActivity}
              sx={{ marginTop: "16px" }}
            >
              Add Activity
            </Button>
          </>
        )}
      </Box>
    );
  };
  // Activity Risk Assessment section Ends

  //Self-Administration of Medication Risk Assessment Begins

  const addMedication = () => {
    setInitialForm((prevState) => {
      // Clone the current state
      const updatedForm = prevState.length ? [...prevState] : [{}];

      // Initialize activities array if not already present
      const currentMedication = updatedForm[0]?.medicationDetails || [];

      // Add a new activity object with default values
      currentMedication.push({
        medicine_name: "",
        dose: "",
        route: "",
        time_frequency: "",
        self_administration: "",
      });

      // Update the form with the initialized or updated activities array
      updatedForm[0] = {
        ...updatedForm[0],
        medicationDetails: currentMedication,
      };

      return updatedForm;
    });
  };

  const ensureSelfMedicationFieldsAreInitialized = (form) => ({
    self_administer_fully: form.self_administer_fully || "",
    medicines: form.medicines || "",
    time_to_take_medicines: form.time_to_take_medicines || "",
    dosage: form.dosage || "",
    take_medicines: form.take_medicines || "",
    special_instructions: form.special_instructions || "",
    side_effects: form.side_effects || "",
    missed_dose: form.missed_dose || "",
    difficulty_reading_label: form.difficulty_reading_label || "",
    opens_medication: form.opens_medication || "",
    safe_storage: form.safe_storage || "",
    agrees_to_notify: form.agrees_to_notify || "",
    responsible_for_reorder: form.responsible_for_reorder || "",
    medicationDetails: form.medicationDetails || [
      {
        medicine_name: form.medicine_name || "",
        dose: form.dose || "",
        route: form.route || "",
        time_frequency: form.time_frequency || "",
        self_administration: form.self_administration || "",
      },
    ],
  });

  const handleSelfMedicicationValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}]; // Ensure initial form is initialized
      // const currentMedication = updatedForm[0]?.medicationDetails || []; // Extract existing activities or initialize

      // Create a deep copy of medicationDetails
      const currentMedication = updatedForm[0]?.medicationDetails
        ? updatedForm[0].medicationDetails.map((med) => ({ ...med }))
        : [];

      updatedForm[0] = {
        ...ensureSelfMedicationFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      const validNames = [
        "self_administer_fully",
        "medicines",
        "time_to_take_medicines",
        "dosage",
        "take_medicines",
        "special_instructions",
        "side_effects",
        "missed_dose",
        "difficulty_reading_label",
        "opens_medication",
        "safe_storage",
        "agrees_to_notify",
        "responsible_for_reorder",
      ];

      if (validNames.includes(name)) {
        updatedForm[0] = {
          ...updatedForm[0],
          [name]: value,
        };
      } else {
        currentMedication[index] = {
          ...currentMedication[index],
          [name]: value,
        };
        updatedForm[0] = {
          ...updatedForm[0],
          medicationDetails: currentMedication,
        };
      }

      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);

      handleChange(updatedForm); // Ensure changes trigger relevant updates
      return updatedForm;
    });
  };

  const selfAdministrationofMedicationRiskAssessment = () => {
    const radioLabels = [
      {
        qQues: `Does ${name} know what medicines they are taking and what they are for?`,
        qName: "medicines",
      },
      {
        qQues: `Does ${name} know what time to take the medicines?`,
        qName: "time_to_take_medicines",
      },
      { qQues: `Does ${name} know what dosage to take?`, qName: "dosage" },
      {
        qQues: `Does ${name} know how to take the medicines?`,
        qName: "take_medicines",
      },
      {
        qQues: `Does ${name} know about any special instructions?`,
        qName: "special_instructions",
      },
      {
        qQues: `Does ${name} know about common, possible side effects?`,
        qName: "side_effects",
      },
      {
        qQues: `Does ${name} know what to do if they miss a dose?`,
        qName: "missed_dose",
      },
      {
        qQues: `Does ${name} have any difficulty in reading the label on the medicines?`,
        qName: "difficulty_reading_label",
      },
      {
        qQues: `Does ${name} open their medication (blister packs, bottles)?`,
        qName: "opens_medication",
      },
      {
        qQues: `Does ${name} understand the principles of safe storage for medicines, including their responsibility for safe keeping?`,
        qName: "safe_storage",
      },
      {
        qQues: `Does ${name} agree to notify staff of any changes to the prescribed medication and/or over-the-counter/homely medications?`,
        qName: "agrees_to_notify",
      },
      {
        qQues: `Would ${name} be responsible for the re-ordering of prescribed medication and its collection?`,
        qName: "responsible_for_reorder",
      },
    ];

    // const selfMedicationFields = {
    //   self_administer_fully: "",
    //   medicines: "",
    //   time_to_take_medicines: "",
    //   dosage: "",
    //   take_medicines: "",
    //   special_instructions: "",
    //   side_effects: "",
    //   missed_dose: "",
    //   difficulty_reading_label: "",
    //   opens_medication: "",
    //   safe_storage: "",
    //   agrees_to_notify: "",
    //   responsible_for_reorder: "",
    //   medicationDetails: [
    //     {
    //       medicine_name: "",
    //       dose: "",
    //       route: "",
    //       time_frequency: "",
    //       self_administration: "",
    //     },
    //   ],
    // };

    const defaultMedication = {
      medicine_name: "",
      dose: "",
      route: "",
      time_frequency: "",
      self_administration: "",
    };

    const initializemedicine_details = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (
        !updatedForm[0]?.medicationDetails ||
        !updatedForm[0].medicationDetails.length
      ) {
        updatedForm[0].medicationDetails = [{ ...defaultMedication }];
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // const initializemedicine_details = () => {
    //   if (!initialForm[0]?.medicationDetails?.length) {
    //     const updatedForm = initialForm.length ? [...initialForm] : [{}];

    //     updatedForm[0] = {
    //       ...updatedForm[0], // retain any existing fields
    //       // medicationDetails: [{...defaultMedication}], // spread defaultBehaviour at the root level
    //       ...selfMedicationFields,
    //     };

    //     updatedForm[0] = removeUnwantedKeys(updatedForm[0]);

    //     setInitialForm(updatedForm); // Ensure the state reflects the initialization
    //   }
    // };

    // const initializemedicine_details = () => {
    //   if (initialForm[0]?.self_administer_fully === undefined) {
    //     const updatedForm = initialForm.length ? [...initialForm] : [{}];

    //     updatedForm[0] = {
    //       ...updatedForm[0], // retain any existing fields
    //       ...selfMedicationFields, // spread default fields at the root level
    //     };

    //     updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
    //     setInitialForm(updatedForm); // Update the form state
    //   }
    // };

    // Call initialization logic
    initializemedicine_details();

    return (
      <Box>
        <Typography variant="h4">
          Self-Administration of Medication Risk Assessment
        </Typography>
        {/* <Name label="Name of GP" placeholder="Name of GP" disabled="true" /> */}
        <Typography variant="h5">Name of GP</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="self_administer_fully"
            label={`Does ${name} want to self-administer fully or partially?`}
            options={[
              { value: "fully", label: "Fully" },
              { value: "partially", label: "Partially" },
            ]}
            onValueChange={(val) => {
              handleSelfMedicicationValueChange(val, "self_administer_fully");
            }}
            preFilledvalue={initialForm[0]?.self_administer_fully || ""}
          />
          {radioLabels.map((eachLabel, index) => (
            <RadioInput
              name={eachLabel.qName}
              label={eachLabel.qQues}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              onValueChange={(val) => {
                handleSelfMedicicationValueChange(val, eachLabel.qName);
              }}
              preFilledvalue={initialForm[0]?.[eachLabel.qName] || ""}
            />
          ))}
        </Container>
        <Typography variant="h6">
          Please note all the medications used by {name} and Medication Details:
        </Typography>
        {initialForm[0]?.medicationDetails?.map((activity, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#FFFFFF",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Medication {index + 1}
            </Typography>
            <Grid container spacing={3} paddingTop={3}>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="medicine_name"
                  label="Name of Medicine"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(
                      val,
                      "medicine_name",
                      index
                    );
                  }}
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.medicine_name ||
                    ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="dose"
                  label="Dose"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(val, "dose", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.dose || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="route"
                  label="Route"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(val, "route", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.route || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="time_frequency"
                  label="Time/Frequency"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(
                      val,
                      "time_frequency",
                      index
                    );
                  }}
                  preFilledvalue={
                    initialForm[0]?.medicationDetails[index]?.frequency || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Container sx={{ marginLeft: "-10px" }}>
                  <RadioInput
                    name="self_administration"
                    label="Self-Administration"
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    onValueChange={(val) => {
                      handleSelfMedicicationValueChange(
                        val,
                        "self_administration",
                        index
                      );
                    }}
                    preFilledvalue={
                      initialForm[0]?.medicationDetails[index]
                        ?.self_administration || ""
                    }
                  />
                </Container>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Button
          variant="contained"
          color="primary"
          onClick={addMedication}
          sx={{ marginTop: "16px" }}
        >
          Add Medication
        </Button>
      </Box>
    );
  };
  //Self-Administration of Medication Risk Assessment Ends

  //Financial Risk Assessment Begins

  const defaultFinancial = {
    requires_assistance: "",
    responsible_party: "",
    family_member_name: "",
    agency_name: "",
    local_authority_name: "",
    other: "",
    spending_limit: "",
    spending_details: "",
    money_spent_by: "",
    activities_finances: "",
    activities_finances_details: "",
    safe_location: "",
    provide_details: "",
  };

  const financialRiskAssessment = () => {
    // Ensure `financialDetails` is initialized
    const initializeFinancialDetails = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (updatedForm[0]?.requires_assistance === undefined) {
        // Initialize financialDetails and flatten into the root object
        updatedForm[0] = {
          ...updatedForm[0], // retain any existing fields
          ...defaultFinancial, // spread defaultFinancial at the root level
        };
        updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeFinancialDetails();

    // const ensureFinancialFieldsAreInitialized = (form) => ({
    //   requires_assistance: form.requires_assistance || 2,
    //   responsible_party: form.responsible_party || "",
    //   family_member_name: form.family_member_name || "",
    //   agency_name: form.agency_name || "",
    //   local_authority_name: form.local_authority_name || "",
    //   other: form.other || "",
    //   spending_limit: form.spending_limit || 2,
    //   spending_details: form.spending_details || "",
    //   money_spent_by: form.money_spent_by || "",
    //   activities_finances: form.activities_finances || 2,
    //   activities_finances_details: form.activities_finances_details || "",
    //   safe_location: form.safe_location || 2,
    //   provide_details: form.provide_details || "",
    // });
    const handleFinancialValueChange = (value, name) => {
      setInitialForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : [{}];

        // Ensure fields for this form are initialized
        // updatedForm[0] = {
        //   ...ensureFinancialFieldsAreInitialized(updatedForm[0]),
        //   ...updatedForm[0],
        // };

        updatedForm[0] = {
          ...updatedForm[0],
          [name]: value,
        };

        // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
        handleChange(updatedForm);
        return updatedForm;
      });
    };

    return (
      <Box>
        <Typography variant="h4">Financial Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="requires_assistance"
            label={`Does ${name} require assistance with managing your finances?`}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            onValueChange={(val) => {
              // let val;
              // if (v === "yes") {
              //   val = 1;
              // } else if (v === "no") {
              //   val = 0;
              // }
              handleFinancialValueChange(val, "requires_assistance");
            }}
            preFilledvalue={
              initialForm[0]?.requires_assistance
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.requires_assistance === 0
              //   ? "no"
              //   : ""
            }
          />
        </Container>
        {initialForm[0]?.requires_assistance === "yes" && (
          <Grid container spacing={3} direction="row">
            <Grid item md={5.75} xs={12}>
              <Name
                label="Family Member"
                name="family_member_name"
                onValueChange={(v) => {
                  handleFinancialValueChange(v, "family_member_name");
                }}
                preFilledvalue={initialForm[0]?.family_member_name || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <Name
                label="Local Authority"
                name="local_authority_name"
                onValueChange={(v) => {
                  handleFinancialValueChange(v, "local_authority_name");
                }}
                preFilledvalue={initialForm[0]?.local_authority_name || ""}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5">Agency Name</Typography>
            </Grid>
            <Grid item md={5.75} xs={12}>
              <Name
                label="Other "
                name="other"
                onValueChange={(v) => {
                  handleFinancialValueChange(v, "other");
                }}
                preFilledvalue={initialForm[0]?.other || ""}
              />
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" sx={{ marginBlock: "15px" }}>
          Where /AGENCY NAME/ has responsibility please complete
        </Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a spending budget/ limit in place?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="spending_limit"
            onValueChange={(val) => {
              // let val;
              // if (v === "yes") {
              //   val = 1;
              // } else if (v === "no") {
              //   val = 0;
              // }
              handleFinancialValueChange(val, "spending_limit");
            }}
            preFilledvalue={
              initialForm[0]?.spending_limit
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.spending_limit === 0
              //   ? "no"
              //   : ""
            }
          />
        </Container>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              label="Details"
              name="spending_details"
              onValueChange={(v) => {
                handleFinancialValueChange(v, "spending_details");
              }}
              preFilledvalue={initialForm[0]?.spending_details || ""}
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              label="Who has responsibility for deciding how the money is spent?"
              name="responsible_party"
              onValueChange={(v) => {
                handleFinancialValueChange(v, "responsible_party");
              }}
              preFilledvalue={initialForm[0]?.responsible_party || ""}
            />
          </Grid>
        </Grid>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Are there any risks associated with the Service User or client who requires additional help with day to day general routine activities,’s finances?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="activities_finances"
            onValueChange={(val) => {
              // let val;
              // if (v === "yes") {
              //   val = 1;
              // } else if (v === "no") {
              //   val = 0;
              // }
              handleFinancialValueChange(val, "activities_finances");
            }}
            preFilledvalue={
              initialForm[0]?.activities_finances
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.activities_finances === 0
              //   ? "no"
              //   : ""
            }
          />
        </Container>
        {initialForm[0]?.activities_finances === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="activities_finances_details"
                onValueChange={(v) => {
                  handleFinancialValueChange(v, "activities_finances_details");
                }}
                preFilledvalue={
                  initialForm[0]?.activities_finances_details || ""
                }
              />
            </Grid>
          </Grid>
        )}
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a safe location to store cash and receipts? "
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="safe_location"
            onValueChange={(val) => {
              // let val;
              // if (v === "yes") {
              //   val = 1;
              // } else if (v === "no") {
              //   val = 0;
              // }
              handleFinancialValueChange(val, "safe_location");
            }}
            preFilledvalue={
              initialForm[0]?.safe_location
              // === 1
              //   ? "yes"
              //   : initialForm[0]?.safe_location === 0
              //   ? "no"
              //   : ""
            }
          />
        </Container>
        {initialForm[0]?.safe_location === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="provide_details"
                onValueChange={(v) => {
                  handleFinancialValueChange(v, "provide_details");
                }}
                preFilledvalue={initialForm[0]?.provide_details || ""}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  //Financial Risk Assessment Ends

  //Equipment Register Begins
  const [equipment, setEquipment] = useState([
    {
      equipment: "",
      equipment_description: "",
      provided_by: "",
      purpose: "",
      date_of_next_test: "",
      test_completed_on: "",
    },
  ]);
  const addEquipment = () => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      const currentEquipment = updatedForm[0]?.equipmentDetails || [];

      // Push the default substance object
      currentEquipment.push({ ...defaultEquipment });

      updatedForm[0] = {
        ...updatedForm[0],
        equipmentDetails: currentEquipment,
      };

      return updatedForm;
    });
  };

  // const handleEquipmentValueChange = (value, name, index) => {
  //   setInitialForm((prevState) => {
  //     const updatedForm = prevState.length ? [...prevState] : [{}]; // Ensure initial form is initialized
  //     const currentEquipment = updatedForm[0]?.[`equipment_${index}`] || {}; // Extract existing activities or initialize
  //     updatedForm[0] = {
  //       ...updatedForm[0],
  //       [`equipment_${index}`]: {
  //         ...currentEquipment,
  //         [name]: value, // Dynamically add or update the activity field
  //       },
  //     };
  //     handleChange(updatedForm); // Ensure changes trigger relevant updates
  //     return updatedForm;
  //   });
  // };

  const ensureEquipmentFieldsAreInitialized = (form = {}) => ({
    equipment: form.equipment || "",
    equipment_description: form.equipment_description || "",
    provided_by: form.provided_by || "",
    purpose: form.purpose || "",
    date_of_next_test: form.date_of_next_test || "",
    test_completed_on: form.test_completed_on || "",
  });

  const handleEquipmentValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      // Access the substance array or initialize it if not present
      const currentEquipment = updatedForm[0]?.equipmentDetails
        ? [...updatedForm[0].equipmentDetails]
        : [];

      // Ensure fields for this form are initialized
      currentEquipment[index] = {
        ...ensureEquipmentFieldsAreInitialized(currentEquipment[index] || {}),
      };
      // Update the specific field for the selected substance
      currentEquipment[index][name] = value;

      // Update the form
      updatedForm[0] = {
        ...updatedForm[0],
        equipmentDetails: currentEquipment,
      };

      updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm); // Assuming handleChange updates the state or performs additional actions
      return updatedForm;
    });
  };

  const defaultEquipment = {
    equipment: "",
    equipment_description: "",
    provided_by: "",
    purpose: "",
    date_of_next_test: "",
    test_completed_on: "",
  };

  const equipmentRegister = () => {
    // Ensure `substance` is initialized for COSHH logic
    const initializeEquipmentDetails = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (
        !updatedForm[0]?.equipmentDetails ||
        !updatedForm[0].equipmentDetails.length
      ) {
        updatedForm[0].equipmentDetails = [{ ...defaultEquipment }];
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeEquipmentDetails();
    return (
      <Box>
        <Typography variant="h4">Equipment Register</Typography>
        <p>
          As part of delivering care, our Care Workers will often have to use
          care equipment, such as hoists or stand aids. These will usually be
          supplied and serviced by the Local Authority or other third party. It
          is an expectation that the Care Workers will routinely check that the
          equipment 'looks' suitable for use before carrying out any activity
          using that equipment. However, under legislation we are required to
          ensure any equipment being used by our employees is fit for use.
          <br />
          <br />
          <b>Note: </b>This form must be used to record all equipment that will
          be used during the provision of care, and where appropriate, the
          equipment service/test dates.
        </p>
        {initialForm[0]?.equipmentDetails?.map((activity, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "25px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#FFFFFF",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Equipment {index + 1}
            </Typography>
            <Box sx={{ paddingTop: "10px" }}>
              <Name
                name="equipment"
                label="Equipment"
                placeholder="Equipment Name"
                onValueChange={(val) => {
                  handleEquipmentValueChange(val, "equipment", index);
                }}
                preFilledvalue={
                  initialForm[0]?.equipmentDetails?.[index]?.equipment || ""
                }
              />
            </Box>
            <Name
              name="equipment_description"
              className="multiline-textfield"
              label="Equipment Description"
              placeholder="Equipment Description"
              multiline
              rows="5"
              onValueChange={(val) => {
                handleEquipmentValueChange(val, "equipment_description", index);
              }}
              preFilledvalue={
                initialForm[0]?.equipmentDetails?.[index]
                  ?.equipment_description || ""
              }
            />
            <Name
              name="provided_by"
              label="Provided by"
              placeholder="Provided by"
              onValueChange={(val) => {
                handleEquipmentValueChange(val, "provided_by", index);
              }}
              preFilledvalue={
                initialForm[0]?.equipmentDetails?.[index]?.provided_by || ""
              }
            />
            <Name
              name="purpose"
              className="multiline-textfield"
              label="Purpose"
              placeholder="Purpose"
              multiline
              rows="5"
              onValueChange={(val) => {
                handleEquipmentValueChange(val, "purpose", index);
              }}
              preFilledvalue={
                initialForm[0]?.equipmentDetails?.[index]?.purpose || ""
              }
            />
            <Grid container spacing={3}>
              <Grid item md={5.75} xs={12}>
                <Calendar
                  name="date_of_next_test"
                  label="Date of next test"
                  onDateChange={(val) => {
                    handleEquipmentValueChange(val, "date_of_next_test", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.equipmentDetails?.[index]
                      ?.date_of_next_test || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Calendar
                  name="test_completed_on"
                  label="Test completed on"
                  onDateChange={(val) => {
                    handleEquipmentValueChange(val, "test_completed_on", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.equipmentDetails?.[index]
                      ?.test_completed_on || ""
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ))}

        <Button
          variant="contained"
          color="primary"
          onClick={addEquipment}
          sx={{ marginTop: "16px" }}
        >
          Add Equipment
        </Button>
      </Box>
    );
  };
  //Equipment Register Ends

  //Medication Risk Assessment Begins

  // const ensureMedicationFieldsAreInitialized = (form) => ({
  //   ordering: form.ordering || 2,
  //   collecting: form.collecting || 2,
  //   collecting_comments: form.collecting_comments || "",
  //   ordering_comments: form.ordering_comments || "",
  //   verbal_prompt: form.verbal_prompt || 2,
  //   verbal_prompt_comments: form.verbal_prompt_comments || "",
  //   assisting: form.assisting || 2,
  //   assisting_comments: form.assisting_comments || "",
  //   administering: form.administering || 2,
  //   administering_comments: form.administering_comments || "",
  //   specialized_support: form.specialized_support || 2,
  //   specialized_support_comments: form.specialized_support_comments || "",
  //   time_specific: form.time_specific || 2,
  //   time_specific_comments: form.time_specific_comments || "",
  //   controlled_drugs: form.controlled_drugs || 2,
  //   controlled_drugs_details: form.controlled_drugs_details || "",
  //   agency_notification: form.agency_notification || "",
  //   medication_collection_details: form.medication_collection_details || "",
  //   prn_medication: form.prn_medication || "",
  //   safe_storage: form.safe_storage || 2,
  //   storage_location: form.storage_location || "",
  // });
  const handleMedicationValueChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];

      // Ensure fields for this form are initialized
      // updatedForm[0] = {
      //   ...ensureMedicationFieldsAreInitialized(updatedForm[0]),
      //   ...updatedForm[0],
      // };

      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };

      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const medicationRiskAssessment = () => {
    const defaultMedication = {
      ordering: 2,
      collecting: 2,
      collecting_comments: "",
      ordering_comments: "",
      verbal_prompt: 2,
      verbal_prompt_comments: "",
      assisting: 2,
      assisting_comments: "",
      administering: 2,
      administering_comments: "",
      specialized_support: 2,
      specialized_support_comments: "",
      time_specific: 2,
      time_specific_comments: "",
      controlled_drugs: 2,
      controlled_drugs_details: "",
      agency_notification: "",
      medication_collection_details: "",
      prn_medication: "",
      safe_storage: 2,
      storage_location: "",
    };
    // Ensure `financialDetails` is initialized
    const initializeMedication = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (!updatedForm[0]?.administering) {
        // Initialize financialDetails and flatten into the root object
        updatedForm[0] = {
          ...updatedForm[0], // retain any existing fields
          ...defaultMedication, // spread defaultFinancial at the root level
        };
        updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeMedication();
    return (
      <Box>
        <Typography variant="h4">Medication Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="ordering"
                label="Ordering"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "ordering");
                }}
                preFilledvalue={initialForm[0]?.ordering || ""}
              />
              <Name
                name="ordering_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "ordering_comments");
                }}
                preFilledvalue={initialForm[0]?.ordering_comments || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="collecting"
                label="Collecting"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "collecting");
                }}
                preFilledvalue={initialForm[0]?.collecting || ""}
              />
              <Name
                name="collecting_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "collecting_comments");
                }}
                preFilledvalue={initialForm[0]?.collecting_comments || ""}
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="verbal_prompt"
                label="Verbal Prompt"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "verbal_prompt");
                }}
                preFilledvalue={initialForm[0]?.verbal_prompt || ""}
              />
              <Name
                name="verbal_prompt_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "verbal_prompt_comments");
                }}
                preFilledvalue={initialForm[0]?.verbal_prompt_comments || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="assisting"
                label="Assisting (physically opening boxes/bottles)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "assisting");
                }}
                preFilledvalue={initialForm[0]?.assisting || ""}
              />
              <Name
                name="assisting_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "assisting_comments");
                }}
                preFilledvalue={initialForm[0]?.assisting_comments || ""}
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="administering"
                label="Administering* (including creams, patches or lotions)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "administering");
                }}
                preFilledvalue={initialForm[0]?.administering || ""}
              />
              <Name
                name="administering_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "administering_comments");
                }}
                preFilledvalue={initialForm[0]?.administering_comments || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="specialized_support"
                label="Specialized Support*"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "specialized_support");
                }}
                preFilledvalue={initialForm[0]?.specialized_support || ""}
              />
              <Name
                name="specialized_support_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(
                    v,
                    "specialized_support_comments"
                  );
                }}
                preFilledvalue={
                  initialForm[0]?.specialized_support_comments || ""
                }
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="time_specific"
                label="Time Specific"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "time_specific");
                }}
                preFilledvalue={initialForm[0]?.time_specific || ""}
              />
              <Name
                name="time_specific_comments"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "time_specific_comments");
                }}
                preFilledvalue={initialForm[0]?.time_specific_comments || ""}
              />
            </Grid>
          </Grid>
        </Container>
        <p>
          *Where /AGENCY NAME/ is providing verbal, physical or specialized
          support or administering, a <b>MAR chart must be completed.</b> Where
          prescribed external medication (e.g. creams, patches or lotions)
          support is provided by /AGENCY NAME/, a{" "}
          <b>TMAR must also be completed.*(Notes)</b>
        </p>
        <Typography variant="h5">Controlled Drugs:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="controlled_drugs"
                label="Are any of the medicines a Controlled Drug?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "controlled_drugs");
                }}
                preFilledvalue={initialForm[0]?.controlled_drugs || ""}
              />
              {initialForm[0]?.controlled_drugs === 1 && (
                <Name
                  name="controlled_drugs_details"
                  label="If yes, give details:"
                  onValueChange={(v) => {
                    handleMedicationValueChange(v, "controlled_drugs_details");
                  }}
                  preFilledvalue={
                    initialForm[0]?.controlled_drugs_details || ""
                  }
                />
              )}
            </Grid>
          </Grid>
        </Container>

        <Typography variant="h5">Ordering and Collecting:</Typography>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              name="agency_notification"
              label="Where <Agency name> is responsible for the collecting and/or ordering how will #Agency be notified of this? "
              onValueChange={(v) => {
                handleMedicationValueChange(v, "agency_notification");
              }}
              preFilledvalue={initialForm[0]?.agency_notification || ""}
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              name="medication_collection_details"
              label="Where not responsible, when should <Agency name> expect your medication to be collected/ordered?"
              onValueChange={(v) => {
                handleMedicationValueChange(v, "medication_collection_details");
              }}
              preFilledvalue={
                initialForm[0]?.medication_collection_details || ""
              }
            />
          </Grid>
        </Grid>

        <Typography variant="h5">PRN Medication:</Typography>
        <Grid container spacing={3}>
          <Grid item md={5.75} xs={12}>
            <Name
              name="prn_medication"
              label="How will you notify the Care Worker when a dose of PRN medication is required?"
              onValueChange={(v) => {
                handleMedicationValueChange(v, "prn_medication");
              }}
              preFilledvalue={initialForm[0]?.prn_medication || ""}
            />
          </Grid>
        </Grid>

        <Typography variant="h5">Storage:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="safe_storage"
                label="Is there a safe place to store medication? "
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleMedicationValueChange(val, "safe_storage");
                }}
                preFilledvalue={initialForm[0]?.safe_storage || ""}
              />
              {initialForm[0]?.safe_storage === "yes" && (
                <Name
                  name="storage_location"
                  label="If yes, please specify where:"
                  onValueChange={(v) => {
                    handleMedicationValueChange(v, "storage_location");
                  }}
                  preFilledvalue={initialForm[0]?.storage_location || ""}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };
  //Medication Risk Assessment Ends

  //Behaviours That May Challenge Risk Assessment Begins
  const ensureBehaviourFieldsAreInitialized = (form) => ({
    affected_by_behaviour: form?.affected_by_behaviour || "",
    other_affected_by_behaviour: form?.other_affected_by_behaviour || "",
    potential_triggers: form?.potential_triggers || new Array(10).fill(""),
    other_potential_triggers: form?.other_potential_triggers || "",
    potential_hazards: form?.potential_hazards || new Array(19).fill(""),
    other_potential_hazards: form?.other_potential_hazards || "",
    frequency_potential: form?.frequency_potential || "",
    other_frequency_potential: form?.other_frequency_potential || "",
    level_of_risk: form?.level_of_risk || new Array(19).fill(""),
    risk_range: form?.risk_range || new Array(19).fill(""),
    support_methods: form?.support_methods || new Array(19).fill(""),
    controls_adequate: form?.controls_adequate || new Array(19).fill(""),
    regulatory_measures: form?.regulatory_measures || new Array(19).fill(""),
    identified_hazard: form?.identified_hazard || "",
  });

  const handleBehaviourValueChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty

      // Ensure fields for this form are initialized
      updatedForm[0] = {
        ...ensureBehaviourFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      if (name === "other_affected_by_behaviour") {
        updatedForm[0].affected_by_behaviour = "Other";
        updatedForm[0].other_affected_by_behaviour = value;
      } else {
        updatedForm[0].affected_by_behaviour = name;
        updatedForm[0].other_affected_by_behaviour = "";
      }
      // Remove unwanted keys
      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const handlePotentialTrigger = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty

      // Ensure fields for this form are initialized
      updatedForm[0] = {
        ...ensureBehaviourFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      const potentialTriggers = updatedForm[0]?.potential_triggers;

      if (name === "other_potential_triggers") {
        updatedForm[0].other_potential_triggers = value;
      } else {
        if (value) {
          // Add or update the trigger for the given index
          potentialTriggers[index] = name;
        } else {
          // Remove the trigger if unchecked
          delete potentialTriggers[index];
        }
        updatedForm[0].potential_triggers = potentialTriggers;
      }
      // Remove unwanted keys
      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const handlePotentialHazard = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
      // Ensure fields for this form are initialized
      updatedForm[0] = {
        ...ensureBehaviourFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      const potentialHazards = updatedForm[0]?.potential_hazards;

      if (name === "other_potential_hazards") {
        updatedForm[0].other_potential_hazards = value;
      } else {
        if (value) {
          potentialHazards[index] = name;
        } else {
          potentialHazards[index] = ""; // Set to empty if unchecked
        }

        updatedForm[0].potential_hazards = potentialHazards;
        updatedForm[0].other_potential_hazards = "";
      }
      // Remove unwanted keys
      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const handlePotentialHazardSub = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : []; // Ensure the form exists

      // Ensure fields for this form are initialized
      updatedForm[0] = {
        ...ensureBehaviourFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      // Initialize sub-fields if not present
      const levelOfRisk = [...updatedForm[0]?.level_of_risk];
      const riskRange = [...updatedForm[0]?.risk_range];
      const supportMethods = [...updatedForm[0]?.support_methods];
      const regulatoryMeasures = [...updatedForm[0]?.regulatory_measures];
      const identifiedHazard = [...updatedForm[0]?.identified_hazard];
      const controlsAdequate = [...updatedForm[0]?.controls_adequate];

      // Update based on the name
      switch (name) {
        case "level_of_risk":
          levelOfRisk[index] = value;
          riskRange[index] = 1;
          updatedForm[0].level_of_risk = levelOfRisk;
          updatedForm[0].risk_range = riskRange;
          break;
        case "risk_range":
          riskRange[index] = value;
          updatedForm[0].risk_range = riskRange;
          break;
        case "controls_adequate":
          controlsAdequate[index] = value;
          updatedForm[0].controls_adequate = controlsAdequate;
          break;
        case "support_methods":
          supportMethods[index] = value;
          updatedForm[0].support_methods = supportMethods;
          break;
        case "regulatory_measures":
          regulatoryMeasures[index] = value;
          updatedForm[0].regulatory_measures = regulatoryMeasures;
          break;
        case "identified_hazard":
          identifiedHazard[index] = value;
          updatedForm[0].identified_hazard = identifiedHazard;
          break;
        default:
          console.error(`Unknown field name: ${name}`);
      }
      // Remove unwanted keys
      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const handleFrequencyValueChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
      // Ensure fields for this form are initialized
      updatedForm[0] = {
        ...ensureBehaviourFieldsAreInitialized(updatedForm[0]),
        ...updatedForm[0],
      };

      if (name === "other_frequency_potential") {
        updatedForm[0].other_frequency_potential = value;
        updatedForm[0].frequency_potential = "Other";
      } else {
        updatedForm[0].frequency_potential = name;
        updatedForm[0].other_frequency_potential = "";
      }
      // Remove unwanted keys
      // updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm);
      return updatedForm;
    });
  };
  const behavioursThatMayChallengeRiskAssessment = () => {
    const defaultBehaviour = {
      affected_by_behaviour: "",
      other_affected_by_behaviour: "",
      potential_triggers: new Array(10).fill(""),
      other_potential_triggers: "",
      potential_hazards: new Array(19).fill(""),
      other_potential_hazards: "",
      frequency_potential: "",
      other_frequency_potential: "",
      level_of_risk: new Array(19).fill(""),
      risk_range: new Array(19).fill(""),
      support_methods: new Array(19).fill(""),
      controls_adequate: new Array(19).fill(""),
      regulatory_measures: new Array(19).fill(""),
      identified_hazard: "",
    };

    const initializeBehaviour = () => {
      if (initialForm[0]?.affected_by_behaviour === undefined) {
        const updatedForm = initialForm.length ? [...initialForm] : [{}];

        updatedForm[0] = {
          ...updatedForm[0], // retain any existing fields
          ...defaultBehaviour, // spread defaultBehaviour at the root level
        };

        updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
        setInitialForm(updatedForm); // Update the form state
      }
    };

    initializeBehaviour();

    const frequencyArray = [
      "Occassionally",
      "Weekly",
      "Daily",
      "Constantly",
      "In Specific environments",
      "Other",
    ];
    const behaviourArray = ["Staff", "Relatives", "Individual", "Other"];
    const triggerArray = [
      "Personality clash",
      "Frustration",
      "Drugs/Alcohol",
      "Lack of food/water",
      "Attention seeking",
      "Domestic provocation",
      "Lack of medication",
      "Incontinence",
      "Sexual Awareness Allergic Reaction",
      "Other",
    ];
    const hazardArray = [
      "Running Away",
      "Threatening",
      "Anxiety",
      "Spitting",
      "Biting",
      "Kicking",
      "Throwing Objectives",
      "Self-harm",
      "Verbal Abuse",
      "Unpredictability",
      "Scratching",
      "Punching",
      "Head Butting",
      "Weapons",
      "Bullying",
      "Sexualised Behaviour",
      "Involuntary Movement",
      "Shouting/Screaming",
      "Other",
    ];

    // const ensureBehaviourFieldsAreInitialized = (form) => ({
    //   affected_by_behaviour: form.affected_by_behaviour || "",
    //   other_affected_by_behaviour: form.other_affected_by_behaviour || "",
    //   potential_triggers: form.potential_triggers || new Array(10).fill(""),
    //   other_potential_triggers: form.other_potential_triggers || "",
    //   potential_hazards: form.potential_hazards || new Array(19).fill(""),
    //   other_potential_hazards: form.other_potential_hazards || "",
    //   frequency_potential: form.frequency_potential || "",
    //   other_frequency_potential: form.other_frequency_potential || "",
    //   level_of_risk: form.level_of_risk || new Array(19).fill(""),
    //   risk_range: form.risk_range || new Array(19).fill(""),
    //   support_methods: form.support_methods || new Array(19).fill(""),
    //   controls_adequate: form.controls_adequate || new Array(19).fill(""),
    //   regulatory_measures: form.regulatory_measures || new Array(19).fill(""),
    //   identified_hazard: form.identified_hazard || "",
    //   // id: selectedCard.assessment_id,
    // });

    return (
      <Box>
        <Typography variant="h4">
          Behaviours That May Challenge Risk Assessment
        </Typography>
        <p>
          Describe the Service User or client who requires additional help with
          day to day general routine activities’ behaviours as per the
          Behaviours That May Challenge assessment.(Note)
        </p>
        <Typography variant="h6">Frequency Potential</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {frequencyArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                name={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.frequency_potential === each || false
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handleFrequencyValueChange(isChecked, each); // Pass the value and name correctly
                    }}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.frequency_potential === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_frequency_potential"
                onValueChange={(v) => {
                  handleFrequencyValueChange(v, "other_frequency_potential");
                }}
                preFilledvalue={
                  initialForm[0]?.frequency_potential
                    ?.other_frequency_potential || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">
          Who may be affected by the behaviour
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {behaviourArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.affected_by_behaviour === each || false
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handleBehaviourValueChange(isChecked, each);
                    }}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.affected_by_behaviour === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_affected_by_behaviour"
                onValueChange={(v) => {
                  handleBehaviourValueChange(v, "other_affected_by_behaviour");
                }}
                preFilledvalue={
                  initialForm[0]?.affected_by_behaviour
                    ?.other_affected_by_behaviour || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Triggers</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {triggerArray.map((each, index) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={Object.values(
                      initialForm[0]?.potential_triggers || {}
                    ).includes(each)}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handlePotentialTrigger(isChecked, each, index);
                    }}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_triggers || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_triggers"
                onValueChange={(v) => {
                  handlePotentialTrigger(v, "other_potential_triggers", null);
                }}
                preFilledvalue={
                  initialForm[0]?.potential_triggers
                    ?.other_potential_triggers || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Hazards</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {hazardArray.map((each, index) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={Object.values(
                      initialForm[0]?.potential_hazards || {}
                    ).includes(each)}
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handlePotentialHazard(isChecked, each, index);
                    }}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_hazards || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_hazards"
                onValueChange={(v) => {
                  handlePotentialHazard(v, "other_potential_hazards", null);
                }}
                preFilledvalue={
                  initialForm[0]?.potential_trigger?.if_other || ""
                }
              />
            </Grid>
          </Grid>
        )}

        {Object.keys(initialForm[0]?.potential_hazards || {})
          .filter((key) => initialForm[0]?.potential_hazards[key] !== "") // Filter out empty hazards
          .map((hazardArrayIndex) => (
            <Box
              key={hazardArrayIndex}
              sx={{
                marginBlock: "30px",
                border: "1px solid #2799894d",
                padding: "5px 15px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "inline-block",
                  position: "absolute",
                  margin: "-19px 0 0 10px",
                  backgroundColor: "#FFFFFF",
                  fontWeight: "600",
                  color: "#279989",
                }}
              >
                {initialForm[0]?.potential_hazards?.[hazardArrayIndex]}
              </Typography>
              <Grid container spacing={2} direction="row">
                <Grid item md={5.75} xs={12}>
                  <Dropdown
                    label="Level of Risk"
                    name={`level_of_risk`}
                    list={[
                      { text: "Low" },
                      { text: "Medium" },
                      { text: "High" },
                    ]}
                    onDropdownSelect={(v) => {
                      // let val;
                      // if (v?.text === "Low") {
                      //   val = 0;
                      // } else if (v?.text === "Medium") {
                      //   val = 1;
                      // } else if (v?.text === "High") {
                      //   val = 2;
                      // }
                      handlePotentialHazardSub(
                        v?.text,
                        "level_of_risk",
                        hazardArrayIndex
                      );
                    }}
                    preFilledvalue={
                      initialForm[0]?.level_of_risk?.[hazardArrayIndex] || ""
                    }
                  />
                </Grid>

                <Grid item md={0.5} xs={false} />
                <Grid item md={5.75} xs={12} alignContent="flex-end">
                  <Box sx={{ paddingInline: "50px" }}>
                    <Slider
                      // aria-label="Risk Level"
                      value={
                        initialForm[0]?.risk_range?.[hazardArrayIndex] || 1
                      }
                      valueLabelDisplay="auto"
                      step={1}
                      marks={marks}
                      min={1}
                      max={5}
                      name="risk_range"
                      sx={{
                        color: "#279989",
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "#ccc",
                        },

                        "&.Mui-disabled .MuiSlider-track": {
                          backgroundColor: "#ccc",
                        },
                        "&.Mui-disabled .MuiSlider-thumb": {
                          backgroundColor: "#ccc",
                        },
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "#279989",
                        },
                      }}
                      disabled={
                        initialForm[0]?.level_of_risk?.[hazardArrayIndex] ===
                          "" ||
                        initialForm[0]?.level_of_risk?.[hazardArrayIndex] ===
                          undefined
                      }
                      onChange={(event) =>
                        handlePotentialHazardSub(
                          event.target.value,
                          "risk_range",
                          hazardArrayIndex
                        )
                      }
                    />
                  </Box>
                </Grid>
                <Grid item md={5.75} xs={12}>
                  <Name
                    label="Methods of support/management"
                    name="support_methods"
                    onValueChange={(value) => {
                      handlePotentialHazardSub(
                        value,
                        "support_methods",
                        hazardArrayIndex
                      );
                    }}
                    preFilledvalue={
                      initialForm[0]?.support_methods?.[hazardArrayIndex] || ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid spacing={3}>
                <Grid item md={5.75} xs={12}>
                  <Container sx={{ marginLeft: "-10px" }}>
                    <RadioInput
                      name="controls_adequate"
                      label="Are the controls adequate?"
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      onValueChange={(value) => {
                        handlePotentialHazardSub(
                          value,
                          "controls_adequate",
                          hazardArrayIndex
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.controls_adequate?.[hazardArrayIndex] ||
                        ""
                      }
                    />
                  </Container>
                  {initialForm[0]?.controls_adequate?.[hazardArrayIndex] ===
                    "yes" && (
                    <Name
                      name="regulatory_measures"
                      label="Regulatory Measures"
                      onValueChange={(value) => {
                        handlePotentialHazardSub(
                          value,
                          "regulatory_measures",
                          hazardArrayIndex
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.regulatory_measures?.[
                          hazardArrayIndex
                        ] || ""
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
    );
  };
  //Behaviours That May Challenge Risk Assessment Ends

  //COSHH Risk Assessment Begins

  const addSubstance = () => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      const currentSubstances = updatedForm[0]?.substanceDetails || [];

      // Push the default substance object
      currentSubstances.push({ ...defaultSubstance });

      updatedForm[0] = {
        ...updatedForm[0],
        substanceDetails: currentSubstances,
      };

      return updatedForm;
    });
  };

  const ensureCoshhFieldsAreInitialized = (form = {}) => ({
    name_of_product: form.name_of_product || "",
    type_of_harm: form.type_of_harm || "",
    description_substance: form.description_substance || "",
    color: form.color || "",
    details_substance: form.details_substance || "",
    contact_substance: form.contact_substance || "",
    may_it_occur: form.may_it_occur || "",
    substance_use: form.substance_use || "",
    safe_alternative: form.safe_alternative || "",
    controls: form.controls || "",
    emergency_procedures: form.emergency_procedures || "",
    staff_aware: form.staff_aware || "",
    controls_reduced: form.controls_reduced || "",
    action_to_be_taken: form.action_to_be_taken || "",
  });

  const handleCoshhValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      // Access the substance array or initialize it if not present
      const currentSubstance = updatedForm[0]?.substanceDetails
        ? [...updatedForm[0].substanceDetails]
        : [];

      // Ensure fields for this form are initialized
      currentSubstance[index] = {
        ...ensureCoshhFieldsAreInitialized(currentSubstance[index] || {}),
      };

      // Update the specific field for the selected substance
      currentSubstance[index][name] = value;

      // Additional logic for specific fields
      if (name === "description_substance") {
        currentSubstance[index].range = 1;
      }

      // Update the substance in the form
      updatedForm[0] = {
        ...updatedForm[0],
        substanceDetails: currentSubstance,
      };

      updatedForm[0] = removeUnwantedKeys(updatedForm[0]);
      handleChange(updatedForm); // Assuming handleChange updates the state or performs additional actions
      return updatedForm;
    });
  };
  const defaultSubstance = {
    name_of_product: "",
    type_of_harm: "",
    description_substance: "",
    color: "",
    details_substance: "",
    contact_substance: "",
    may_it_occur: "",
    substance_use: "",
    safe_alternative: "",
    controls: "",
    emergency_procedures: "",
    staff_aware: "",
    controls_reduced: "",
    action_to_be_taken: "",
  };

  const coshhRiskAssessment = () => {
    const substanceArray = ["Liquid", "Solid", "Vapor", "Gas"];
    // Ensure `substance` is initialized for COSHH logic
    const initializeSubstanceDetails = () => {
      const updatedForm = initialForm.length ? [...initialForm] : [{}];

      if (
        !updatedForm[0]?.substanceDetails ||
        !updatedForm[0].substanceDetails.length
      ) {
        updatedForm[0].substanceDetails = [{ ...defaultSubstance }];
        setInitialForm(updatedForm); // Ensure the state reflects the initialization
      }
    };

    // Call initialization logic
    initializeSubstanceDetails();
    return (
      <Box>
        <Typography variant="h4">COSHH Risk Assessment</Typography>
        <p>
          This assessment will only address the risk to health from the
          substances listed. Additional risk assessments may be required to
          control the risk from other hazards associated with the work being
          conducted.(Note)
        </p>
        {initialForm[0]?.substanceDetails?.map((sub, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#FFFFFF",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Substance {index + 1}
            </Typography>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Name of product/substance"
                  name="name_of_product"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "name_of_product", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]
                      ?.name_of_product || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Type of harm the substance could cause"
                  name="type_of_harm"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "type_of_harm", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.type_of_harm ||
                    ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Typography variant="h6">
                  Description of the substance
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {substanceArray.map((each) => (
                    <FormControlLabel
                      key={each}
                      label={each}
                      name="description_substance"
                      style={{ marginRight: "40px" }}
                      control={
                        <Checkbox
                          checked={
                            initialForm[0]?.substanceDetails?.[index]
                              ?.description_substance == each || false
                          }
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                          onChange={(e) => {
                            const checkedValue = e.target.checked ? each : "";
                            handleCoshhValueChange(
                              checkedValue,
                              "description_substance",
                              index
                            );
                          }}
                        />
                      }
                    />
                  ))}
                </Box>
                <Name
                  label="Color"
                  name="color"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "color", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.color || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Detail how the substance causes harm (i.e. being breathed in, being swallowed etc)"
                  name="details_substance"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "details_substance", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]
                      ?.details_substance || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Who is likely to come into contact with the substance?"
                  name="contact_substance"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "contact_substance", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]
                      ?.contact_substance || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="How often is it used, or may it occur?"
                  name="may_it_occur"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "may_it_occur", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.may_it_occur ||
                    ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="What will the substance be used for or what circumstances/activity may produce the substance?"
                  name="substance_use"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "substance_use", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.substanceDetails?.[index]?.substance_use ||
                    ""
                  }
                />
              </Grid>
            </Grid>

            <Container sx={{ marginLeft: "-10px" }}>
              <RadioInput
                name="safe_alternative"
                label="Can the hazardous substance, circumstances or activity be eliminated, or a safe alternative be used?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleCoshhValueChange(val, "safe_alternative", index);
                }}
                preFilledvalue={
                  initialForm[0]?.substanceDetails?.[index]?.safe_alternative ||
                  ""
                }
              />
            </Container>

            {initialForm[0]?.substanceDetails?.[index]?.safe_alternative ===
              "no" && (
              <Box>
                <Grid container spacing={3} alignItems="flex-end">
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Controls (list control measures for storage, use and handling, as appropriate)"
                      name="controls"
                      onValueChange={(val) => {
                        handleCoshhValueChange(val, "controls", index);
                      }}
                      preFilledvalue={
                        initialForm[0]?.substanceDetails?.[index]?.controls ||
                        ""
                      }
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Detail emergency procedures in case of accidental spillage or contact"
                      name="emergency_procedures"
                      onValueChange={(val) => {
                        handleCoshhValueChange(
                          val,
                          "emergency_procedures",
                          index
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.substanceDetails?.[index]
                          ?.emergency_procedures || ""
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="staff_aware"
                        label="Are all staff aware of this assessment, in particular the controls and emergency procedures?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onValueChange={(val) => {
                          handleCoshhValueChange(val, "staff_aware", index);
                        }}
                        preFilledvalue={
                          initialForm[0]?.substanceDetails?.[index]
                            ?.staff_aware || ""
                        }
                      />
                    </Container>
                    {initialForm[0]?.substanceDetails?.[index]?.staff_aware ===
                      "no" && (
                      <Typography sx={{ marginTop: "10px", fontWeight: 600 }}>
                        ***NOTE: Train all staff***
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="controls_reduced"
                        label="Have controls reduced the risk of harm to an acceptable level?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onValueChange={(val) => {
                          handleCoshhValueChange(
                            val,
                            "controls_reduced",
                            index
                          );
                        }}
                        preFilledvalue={
                          initialForm[0]?.substanceDetails?.[index]
                            ?.controls_reduced || ""
                        }
                      />
                    </Container>
                    {initialForm[0]?.substanceDetails?.[index]
                      ?.controls_reduced === "no" && (
                      <Box sx={{ marginBottom: "-15px" }}>
                        <Name
                          name="action_to_be_taken"
                          className="multiline-textfield"
                          label="Action to be taken where required"
                          placeholder="Action to be taken"
                          multiline
                          rows="5"
                          onValueChange={(val) => {
                            handleCoshhValueChange(
                              val,
                              "action_to_be_taken",
                              index
                            );
                          }}
                          preFilledvalue={
                            initialForm[0]?.substanceDetails?.[index]
                              ?.action_to_be_taken || ""
                          }
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={addSubstance}
          sx={{ marginTop: "16px" }}
        >
          Add Substance
        </Button>
      </Box>
    );
  };

  //COSHH Risk Assessment Ends
  // Determine the next question ID dynamically
  const lastQuestionId = Math.max(
    ...Object.keys(initialForm[0] || {})
      .filter((key) => key.startsWith("status_")) // Find all keys that start with "status_"
      .map((key) => parseInt(key.split("_")[1], 10)) // Extract the numeric part of the keys
  );

  // Use the next question ID for "comment"
  const finalNoteName = `comment_${lastQuestionId + 1}`
  console.log(' initialForm[0]?.consent', initialForm[0]?.consent);
  return (
    <ThemeProvider theme={EditTheme}>
      <Box
        sx={{
          textAlign: "Left",
          height: "100%",
          overflow: "auto",
          paddingRight: 2,
          paddingLeft: 1,
          width: "100%",
          boxShadow: "0px 0px 19px 7px #",
        }}
      >
        {assessmentId < 21 ? (
          <Box>
            {assessmentQuestions.length > 0 ? (
              assessmentQuestions
                .filter(
                  (assessment) => assessment.assessment_name === assessmentName
                )
                .map((assessment, index) => (
                  <Box key={index}>
                    <Typography variant="h4">
                      {assessment.assessment_name}
                    </Typography>
                    {assessment.questionslist.map((question, index, arr) => (
                      <>
                        <Typography
                          variant="h6"
                          sx={{
                            marginTop: "4px",
                            marginBottom: "-20px",
                          }}
                        >
                          {question.questionname}
                        </Typography>

                        {index === arr.length - 1 ? null : (
                          <Container sx={{ marginLeft: "-10px" }}>
                            <RadioInput
                              name={`status_${question.questionid}`}
                              options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" },
                              ]}
                              onValueChange={handleRadioChange}
                              preFilledvalue={
                                initialForm[0]?.[
                                  `status_${question.questionid}`
                                ]?.toLowerCase() || "" // Dynamically access the status field
                              }
                            />

                            {initialForm[0]?.[
                              `status_${question.questionid}`
                            ]?.toLowerCase() === "yes" ? (
                              <Name
                                name={`comment_${question.questionid}`}                               
                                className="multiline-textfield"
                                label="Please Mention"
                                placeholder="Please Mention"
                                allowCharacters
                                multiline
                                rows="7"
                                onValueChange={handleValueChange}
                                preFilledvalue={
                                  initialForm[0]?.[
                                    `comment_${question.questionid}`
                                  ] ?? "" // Dynamically access the status field
                                }
                              ></Name>
                            ) : null}
                          </Container>
                        )}
                      </>
                    ))}

                    <Name
                      name={finalNoteName}
                      className="multiline-textfield"
                      placeholder="Care properties and safety concerns"                    
                      allowCharacters
                      wordsStartWithSmallLetters
                      multiline
                      rows="7"
                      onValueChange={handleValueChange}
                      preFilledvalue={initialForm[0]?.[finalNoteName] || ""}
                    />
                  </Box>
                ))
            ) : (
              <p>No assessment questions available</p>
            )}
          </Box>
        ) : assessmentId === 21 ? (
          <Box>{activityRiskAssessment()}</Box>
        ) : assessmentId === 22 ? (
          <Box>{behavioursThatMayChallengeRiskAssessment()}</Box>
        ) : assessmentId === 23 ? (
          <Box>{coshhRiskAssessment()}</Box>
        ) : assessmentId === 24 ? (
          <Box>{equipmentRegister()}</Box>
        ) : assessmentId === 25 ? (
          <Box>{financialRiskAssessment()}</Box>
        ) : assessmentId === 26 ? (
          <Box>{medicationRiskAssessment()}</Box>
        ) : assessmentId === 27 ? (
          <Box>{selfAdministrationofMedicationRiskAssessment()}</Box>
        ) : null}
        <Box>
          <FormLabel sx={{ fontSize: 18, paddingTop: "20px" }}>
            Signatures of All Involved Admins in the Assessment
          </FormLabel>
          <Grid // First signature
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
              mb: -2,
            }}
          >
            <Grid item xs={10} md={5}>
              <Name
                name="sign_1"
                label="Name"
                preFilledvalue={initialForm[0]?.sign_1 || signName} // Prefill with sign_1 or signName
                placeholder="Signature1"
                disabled={true} // Always disabled
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Calendar
                name="date_1"
                label="Date"
                onDateChange={handleDateChange}
                preFilledvalue={
                  initialForm[0]?.date_1 ? initialForm[0]?.date_1 : dayjs() // Prefill with date_1 or current date
                }
                disabled={
                  initialForm[0]?.sign_1 && initialForm[0]?.sign_1 !== signName
                    ? true
                    : false
                } // Disable if date_1 exists
              />
            </Grid>
          </Grid>

          {/* {fetchCheckVariable &&
            console.log("SIGN2 VLAUE", initialForm[0]?.sign_2)} */}

          {/* Second signature section */}
          {initialForm[0]?.sign_2 !== "" ? (
            <Grid
              container
              direction="row"
              alignItems="top"
              sx={{
                gap: 8,
                p: 2,
                borderRadius: "8px",
                backgroundColor: "#F0FCFA",
              }}
            >
              <Grid item xs={10} md={5}>
                <Name
                  name="sign_2"
                  label="Name"
                  placeholder="Name"
                  preFilledvalue={initialForm[0]?.sign_2} // Prefill with sign_2 or signName
                  disabled
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <Calendar
                  name="date_2"
                  label="Date"
                  onDateChange={handleDateChange} // Ensure date changes are tracked
                  preFilledvalue={
                    initialForm[0]?.date_2 !== ""
                      ? initialForm[0]?.date_2
                      : dayjs() // Prefill with date_2 or current date
                  }
                  disabled={
                    // initialForm[0]?.sign_1 &&
                    initialForm[0]?.sign_1 === signName ? true : false
                  }
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid>
            <Grid item>
              <FormControlLabel
                label={
                  "I hereby confirm the accuracy of the information provided above"
                }
                control={
                  <Checkbox
                    checked={
                      // fetchCheckVariable &&
                      initialForm[0]?.consent === 0 ? false : true
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(e) => {
                      handleCheckboxChange(e);
                    }}
                  />
                }
              />
              {/* {checkboxError && (
                <Typography variant="errmsg">Please check this box</Typography>
              )} */}
            </Grid>
            <Grid item sx={{ mt: 3, mb: 3 }}>
              <Box sx={{ width: "fit-content" }}>
                <Button
                  variant="customcancel"
                  onClick={() => {
                    if (hasUnsavedChanges) {
                      setIsCancelButton(true);

                      handleSave();
                    } else {
                      // toggleEditMode();
                      if (resetNewAssessmentVisibility) {
                        resetNewAssessmentVisibility();
                      } else {
                        toggleEditMode();
                      }
                    }
                  }}
                >
                  Cancel
                </Button>

                {/* {isSaveVisible === 0 ? (
                  <Button
                    variant="customsave"
                    onClick={() => {
                      // if (hasUnsavedChanges) {
                      //   handleSave();
                      // } else {
                      // toggleEditMode();
                      setIsDraft(true);
                      if (resetNewAssessmentVisibility) {
                        resetNewAssessmentVisibility();
                      } else {
                        toggleEditMode();
                      }
                      // }
                    }}
                    sx={{
                      backgroundColor: "#FFF0E3",
                      border: "1.5px solid #F5891D",
                      color: "#FA8733",
                      "&:hover": {
                        backgroundColor: "#F5891D",
                        border: "1.5px solid #F5891D",

                        color: "white",
                      },
                    }}
                  >
                    Save Draft
                  </Button>
                ) : ( */}
                  <Button
                    type="submit"
                    variant="customsave"
                    onClick={() => {
                      // handleSignSubmit();
                      // if (resetNewAssessmentVisibility) {
                      //   resetNewAssessmentVisibility();
                      // }
                      handleSubmit();
                    }}
                    sx={{
                      backgroundColor:initialForm[0]?.consent === 0 ? "#FFF0E3" :'',
                      border:initialForm[0]?.consent === 0 ?"1.5px solid #F5891D":'',
                      color:initialForm[0]?.consent === 0 ? "#FA8733":'',
                      "&:hover": {
                        backgroundColor:initialForm[0]?.consent === 0 ? "#F5891D":'',
                        border:initialForm[0]?.consent === 0 ? "1.5px solid #F5891D":'',

                        color: "white",
                      },
                    }}
                  >
                { initialForm[0]?.consent === 0 ? "Save Draft" : 'Save'}
                  </Button>
                 {/* )} */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
