import React, { useState, useEffect, useMemo } from "react";
import { Container, Grid, FormLabel, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../Theme";
import { getCookie } from "../../../utils/utils";

export default function UserNextToKinView({ userId }) {
  const initialKinInfo = useMemo(
    () => ({
      prefix: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      contact_number: "",
      telephone_codes: "",
      country_code: "",
      email: "",
      dob: "",
      age: "",
      gender: "",
      address: "",
      city: "",
      postcode: "",
      country: "",
      country_name: "",
      relation: "",
    }),
    []
  );
  const [kinInfo, setKinInfo] = useState(initialKinInfo);
  useEffect(() => {
    const fetchKin = async () => {
      if (!userId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/next-to-kin/${userId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok) {
          setKinInfo(data.data);
        } else {
          setKinInfo(initialKinInfo);
        }
      } catch (e) {
        console.error("Error fetching kin info:", e.message);
        setKinInfo(initialKinInfo);
      }
    };
    fetchKin();
  }, [userId, initialKinInfo]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box
          sx={{
            padding: 0,
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            margin: "15px 20px",
            textAlign: "left",
          }}
        >
          <Grid container direction="row" alignItems="top" spacing={"10px"}>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Name</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">
                {kinInfo.prefix +
                  " " +
                  kinInfo.first_name +
                  " " +
                  kinInfo.middle_name +
                  " " +
                  kinInfo.last_name}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Date of Birth</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">
                {kinInfo.dob ? formatDate(kinInfo.dob) : null}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Age</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.age}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Gender</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.gender}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Contact Number</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">
                {kinInfo.country_code && kinInfo.contact_number
                  ? `${kinInfo.country_code} `
                  : ""}
                {kinInfo.contact_number ? kinInfo.contact_number : ""}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Email</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.email}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Address</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.address}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">City</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.city}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Postcode</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.postcode}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Country</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.country_name}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Relation</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="h7">{kinInfo.relation}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
