import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import StyledCheckBox from '../reusableComponents/StyledCheckbox';
// import StyledCheckBox from './StyledCheckBox'; // Assuming you have a custom styled checkbox component

const ManageColumns = ({
  columns,
  initialSelectedColumns,
  hiddenColumns,
  onVisibilityChange,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns);
  const [previousSelection, setPreviousSelection] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () =>
      columns.reduce(
        (acc, col) => ({
          ...acc,
          [col.field]: !hiddenColumns.includes(col.field),
        }),
        {}
      )
  );

  // Handle individual column toggle
  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedColumns(value);

    const updatedVisibility = columns.reduce((acc, col) => {
      acc[col.field] = value.includes(col.headerName);
      return acc;
    }, {});

    setColumnVisibilityModel(updatedVisibility);
    onVisibilityChange(updatedVisibility);
  };

  // Handle "Select All" toggle
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedColumns(columns.map((col) => col.headerName));
      const updatedVisibility = columns.reduce(
        (acc, col) => ({ ...acc, [col.field]: true }),
        {}
      );
      setColumnVisibilityModel(updatedVisibility);
      onVisibilityChange(updatedVisibility);
    } else {
      setSelectedColumns([]);
      const updatedVisibility = columns.reduce(
        (acc, col) => ({ ...acc, [col.field]: false }),
        {}
      );
      setColumnVisibilityModel(updatedVisibility);
      onVisibilityChange(updatedVisibility);
    }
  };

  // Handle "Reset All" checkbox (Restores Initial State)
  const handleResetAll = (event) => {
    if (event.target.checked) {
      setPreviousSelection([...selectedColumns]);

      setSelectedColumns(
        columns.filter((col) => !hiddenColumns.includes(col.field)).map((col) => col.headerName)
      );
      const updatedVisibility = columns.reduce(
        (acc, col) => ({
          ...acc,
          [col.field]: !hiddenColumns.includes(col.field),
        }),
        {}
      );
      setColumnVisibilityModel(updatedVisibility);
      onVisibilityChange(updatedVisibility);
    } else {
      if (previousSelection.length > 0) {
        setSelectedColumns(previousSelection);

        const restoredVisibility = columns.reduce((acc, col) => {
          acc[col.field] = previousSelection.includes(col.headerName);
          return acc;
        }, {});

        setColumnVisibilityModel(restoredVisibility);
        onVisibilityChange(restoredVisibility);
      }
    }
  };

  useEffect(() => {
    // Update selected columns whenever columnVisibilityModel changes
    setSelectedColumns(
      columns.filter((col) => columnVisibilityModel[col.field]).map((col) => col.headerName)
    );
  }, [columnVisibilityModel, columns]);

  return (
    <FormControl sx={{ width: 200, height: '34px' }}>
      <Select
        multiple
        displayEmpty
        value={selectedColumns}
        onChange={handleChange}
        renderValue={(selected) =>
          selected.length === 0 ? <em>Manage Columns</em> : selected.join(', ')
        }
        sx={{ p: 0, width: '200px', m: 0 }}
      >
        {/* ✅ Select All Option */}
        <MenuItem sx={{ p: 0, m: 0 }}>
          <StyledCheckBox
            checked={selectedColumns.length === columns.length}
            onChange={handleSelectAllChange}
          />
          <Typography sx={{ p: 0 }}>Select All</Typography>
        </MenuItem>

        {/* ✅ Reset All Option */}
        <MenuItem sx={{ p: 0, m: 0 }}>
          <StyledCheckBox onChange={handleResetAll} />
          <Typography sx={{ p: 0 }}>Reset All</Typography>
        </MenuItem>

        {/* ✅ Individual Column Checkboxes */}
        {columns.map((col) => (
          <MenuItem key={col.field} value={col.headerName} sx={{ p: 0, m: 0 }}>
            <StyledCheckBox checked={columnVisibilityModel[col.field]} />
            <Typography sx={{ p: 0, fontSize: '14px' }}>{col.headerName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ManageColumns;
