import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton, Button, Box } from "@mui/material";
import CloseIcon from "../../../assets/icons/close.svg";
import { getCookie } from "../../../utils/utils";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
import Loader from "../../../assets/images/common/logo_animation.gif";
import AgencyLogo from "../../../assets/images/clients/ConsentFormTemporaryLogo.png";
import watermarkImage from "../../../assets/images/common/WatermarkCareesteemLogo.png";

import felipaUrl from "../../../assets/fonts/Felipa-Regular.ttf";
import beauUrl from "../../../assets/fonts/BeauRivage-Regular.ttf";
import parisienneUrl from "../../../assets/fonts/Parisienne-Regular.ttf";
import quintessentialUrl from "../../../assets/fonts/Quintessential-Regular.ttf";
import dayjs from "dayjs";

import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";

Font.register({
  family: "Felipa", // This is the name you'll use in your styles
  src: felipaUrl,
});
Font.register({
  family: "Beau Rivage",
  src: beauUrl,
});

Font.register({
  family: "Parisienne",
  src: parisienneUrl,
});

Font.register({
  family: "Quintessential",
  src: quintessentialUrl,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  pageContainer: {
    // border: "2px solid black", // Border around the content
    padding: "20px",
    // height: "100%", // Ensure it takes up full height
    display: "flex",
    flexDirection: "column", // Stack the content vertically
    alignItems: "center", // Center content horizontally
    justifyContent: "center", // Center content vertically
  },

  logoLeft: {
    position: "absolute",
    top: 10,
    left: 10,
    height: 50,
  },
  heading: {
    fontSize: 15,
    fontFamily: "Lora",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 5,
    marginTop: "50px",
  },
  subheading: {
    fontSize: 14,
    fontFamily: "Lora",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  content: {
    fontSize: 13,
    lineHeight: "20px",
    textAlign: "left",
    whiteSpace: "pre-line", // For handling new lines and preserving spaces
  },
  bold: {
    fontFamily: "Lora",
    fontWeight: "bold",
  },
  signatureContainer: {
    flexDirection: "row", // Arrange items side by side
    alignItems: "center", // Align items to the left side
    marginTop: 20, // Add margin for spacing
    position: "relative", // Make it positionable
    width: "100%", // Make it take up full width
  },
  signatureLabel: {
    fontSize: 13,
    fontFamily: "Lora",
    fontWeight: "bold",
    marginRight: 10, // Add spacing between label and signature
    top: "50%",
    bottom: "50%",
  },
  signatureImage: {
    width: 100, // Adjust size of the signature image
    height: "auto", // Adjust size of the signature image
    top: "50%",
    bottom: "50%",
  },
});

export default function ConsentPdfViewer({
  open,
  onClose,
  signatureName,
  selectedSignatureNameIndex,
  isDownload,
  setIsDownload,
}) {
  const [viewPdf, setViewPdf] = useState(true);

  // useEffect(() => {
  //   console.log("isDownload", isDownload);
  //   console.log("viewPdf", viewPdf);
  // }, [isDownload, viewPdf]);

  const agencyName = "Lifeline Carers PVT LTD";

  const ConsentContent = ({ consentName }) => {
    switch (consentName) {
      case "Consent to Care":
        return (
          <Text style={styles.content}>
            I understand that as an individual using the services at{" "}
            {agencyName}, this form provides authorisation for the Care Staff to
            provide me with the care and support described and outlined in my
            care plan.
            {"\n\n"}I have contributed to the development of this plan and I
            confirm that the assessor from {agencyName} has explained how I can
            contact {agencyName} at any time to discuss my care and support.
            {"\n\n"}
            <Text style={styles.bold}>
              I confirm that I am happy for {agencyName} to share information
              that is relevant to my care and support with other professionals
              involved in my care.{"\n\n"}I confirm that the complaints
              procedure has been explained to me and I have been given
              information on how to make a complaint.
            </Text>
            {"\n\n"}
            Where the individual is unable to gain consent, an authorised
            representative can sign on their behalf in accordance with the
            Lasting Power of Attorney or in their best interests after a best
            interests meeting has taken place.
          </Text>
        );
      case "Key Safe Consent":
        return (
          <Text style={styles.content}>
            I agree that {agencyName} may hold the keys or the access code to my
            home, for the purposes of access to carry out regular and authorised
            visits by Care Workers. I understand that {agencyName} will take all
            reasonable steps to ensure the security of my home, and will
            immediately notify me of any concerns they may have with respect to
            security.{"\n\n"}
            If I have given my consent for you to share information about me
            with my family or named others, I understand you may contact them
            too.{"\n\n"}I understand that I must immediately report to the
            management of {agencyName} any access to my home using the keys by
            an employee of {agencyName} who has not been pre-booked as part of
            my Care Plan.
          </Text>
        );
      case "GDPR Consent":
        return (
          <Text style={styles.content}>
            {agencyName} uses your personal data for a number of different
            reasons. Personal data is any information that identifies you or, in
            some cases, information that is about you such as an opinion. It
            includes your name, email address, postal address, job role,
            photographs, CCTV, and more sensitive types of information such as
            medical and health records, your care plan, information about your
            religious beliefs, origin and race, your sexual orientation, and
            your political views.{"\n\n"}
            We comply with the law in place in the UK around data protection
            when we use your personal data, including "UK GDPR" (short for the
            United Kingdom General Data Protection Regulation) and the Data
            Protection Act 2018. It allows us to use your personal data for a
            number of reasons without checking with you that it is ok for us to
            do so. For example, where we can show that we have legitimate
            reasons to use your personal data or where we need to use your
            personal data to provide you with the services you have requested
            from us, or to meet a legal obligation placed on us.{"\n\n"}
            However, in some situations, we need you to confirm that you are
            happy for us to use your personal data. Please sign to confirm that
            you consent to this.
          </Text>
        );
      case "Family Member Access to Care Log":
        return (
          <Text style={styles.content}>
            <Text style={styles.bold}>Purpose of Consent:</Text> This form seeks
            consent to allow designated family members or representatives to
            access care information via the Birdie app. The app provides an
            overview of care visits, including tasks completed, notes from care
            professionals, and any relevant updates regarding the wellbeing of
            the service user.{"\n\n"}
            <Text style={styles.bold}>Scope of Information Shared:</Text> The
            following types of information will be accessible
            {"\n"}
            {"\n"}
            {/* Simulated bullet points */}
            <Text>
              {" "}
              {"\u2022"} Care visit schedules{"\n"}
            </Text>
            <Text>
              {" "}
              {"\u2022"} Details of completed tasks (e.g., personal care,
              medication administration){"\n"}
            </Text>
            <Text>
              {" "}
              {"\u2022"} Notes and observations made by care staff{"\n\n"}
            </Text>
            <Text style={styles.bold}>Consent Statement:</Text> By signing this
            form, consent is granted for designated family members or
            representatives to access care information through the Birdie app.
            {"\n\n"}
            <Text style={styles.bold}>It is confirmed that:</Text> The purpose
            and scope of the information shared have been explained. Consent can
            be withdrawn at any time by providing written notice to {agencyName}
            . {agencyName} will take reasonable measures to ensure that access
            is secure and limited to authorised individuals.{"\n\n"}
            <Text style={styles.bold}>Withdrawal of Consent:</Text> Should you
            wish to withdraw consent, please contact {agencyName} directly.
          </Text>
        );

      default:
        return null;
    }
  };

  const handleDownload = async () => {
    const blob = await pdf(
      <Document>
        <Page style={styles.page}>
          <View style={styles.pageContainer}>
            <View
              style={{
                position: "absolute", // Make the container positioned absolutely

                width: "250px", // Set the width of the watermark container
                height: "250px", // Set the height of the watermark container
                zIndex: -1, // Ensure it is behind other content
                //   transform: [
                //     { translateX: "-50%" }, // Shift the container by half of its width to center it horizontally
                //     { translateY: "-50%" }, // Shift the container by half of its height to center it vertically
                //   ],
              }}
            >
              <Image
                style={{
                  width: "100%", // Ensure the image fills the container
                  height: "100%", // Ensure the image fills the container
                }}
                src={watermarkImage} // Path or URL to the watermark image
              />
            </View>
            <Image style={styles.logoLeft} src={AgencyLogo} />
            <Text style={styles.heading}>{agencyName}</Text>
            <Text style={styles.subheading}>
              {signatureName[selectedSignatureNameIndex]?.consentName}
            </Text>
            <ConsentContent
              consentName={
                signatureName[selectedSignatureNameIndex]?.consentName
              }
            />
            {/* Signature Section */}
            <View style={styles.signatureContainer}>
              <Text style={styles.signatureLabel}>Signature:</Text>
              {signatureName[selectedSignatureNameIndex]?.signatureType ===
              "Type" ? (
                <Text
                  style={{
                    fontFamily: signatureName[selectedSignatureNameIndex]?.font,
                    top: "50%",
                    bottom: "50%",
                  }}
                >
                  {signatureName[selectedSignatureNameIndex]?.name}
                </Text>
              ) : (
                <Image
                  style={styles.signatureImage}
                  src={signatureName[selectedSignatureNameIndex]?.urlSign}
                />
              )}
            </View>
            <View style={styles.signatureContainer}>
              <Text style={[styles.content, { marginLeft: "-4px" }]}>
                {"\n"} <Text style={styles.bold}>Updated:</Text>{" "}
                {dayjs(signatureName[selectedSignatureNameIndex]?.date).format(
                  "DD-MM-YYYY"
                )}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    ).toBlob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${
      signatureName[selectedSignatureNameIndex]?.consentName || "default"
    }.pdf`;
    link.click();
  };
  useEffect(() => {
    if (isDownload) {
      setViewPdf(false); // Hide the Dialog when download starts
      handleDownload().then(() => {
        setIsDownload(null); // Reset the state after download is done
      });
      setViewPdf(true); // Reset the state after download is done
    }
  }, [isDownload, setIsDownload]);

  return (
    <>
      {viewPdf && isDownload === false && (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth={false}
          sx={{
            "& .MuiDialog-paper": {
              width: "70%",
              maxWidth: "100%",
              height: "90%",
              "@media (min-width: 1350px)": {
                width: "47%",
              },
            },
          }}
        >
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              width: "28px ! important",
              height: "28px ! important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
            }}
          />

          <DialogContent
            sx={{
              background: "#FFFFFF",
              paddingInline: "60px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "90%",
                display: "flex", // Enable flexbox
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
                margin: "0 auto",
              }}
            >
              <PDFViewer width="100%" height="100%" showToolbar={false}>
                <Document>
                  <Page style={styles.page}>
                    <View style={styles.pageContainer}>
                      <View
                        style={{
                          position: "absolute", // Make the container positioned absolutely

                          width: "250px", // Set the width of the watermark container
                          height: "250px", // Set the height of the watermark container
                          zIndex: -1, // Ensure it is behind other content
                          //   transform: [
                          //     { translateX: "-50%" }, // Shift the container by half of its width to center it horizontally
                          //     { translateY: "-50%" }, // Shift the container by half of its height to center it vertically
                          //   ],
                        }}
                      >
                        <Image
                          style={{
                            width: "100%", // Ensure the image fills the container
                            height: "100%", // Ensure the image fills the container
                          }}
                          src={watermarkImage} // Path or URL to the watermark image
                        />
                      </View>
                      <Image style={styles.logoLeft} src={AgencyLogo} />
                      <Text style={styles.heading}>{agencyName}</Text>
                      <Text style={styles.subheading}>
                        {signatureName[selectedSignatureNameIndex]?.consentName}
                      </Text>
                      <ConsentContent
                        consentName={
                          signatureName[selectedSignatureNameIndex]?.consentName
                        }
                      />
                      {/* Signature Section */}
                      <View style={styles.signatureContainer}>
                        <Text style={styles.signatureLabel}>Signature:</Text>
                        {signatureName[selectedSignatureNameIndex]
                          ?.signatureType === "Type" ? (
                          <Text
                            style={{
                              fontFamily:
                                signatureName[selectedSignatureNameIndex]?.font,
                              top: "50%",
                              bottom: "50%",
                            }}
                          >
                            {signatureName[selectedSignatureNameIndex]?.name}
                          </Text>
                        ) : (
                          <Image
                            style={styles.signatureImage}
                            src={
                              signatureName[selectedSignatureNameIndex]?.urlSign
                            }
                          />
                        )}
                      </View>
                      <View style={styles.signatureContainer}>
                        <Text style={[styles.content, { marginLeft: "-4px" }]}>
                          {"\n"} <Text style={styles.bold}>Updated:</Text>{" "}
                          {dayjs(
                            signatureName[selectedSignatureNameIndex]?.date
                          ).format("DD-MM-YYYY")}
                        </Text>
                      </View>
                    </View>
                  </Page>
                </Document>
              </PDFViewer>
            </Box>
            <Box sx={{ marginTop: 4, textAlign: "center" }}>
              <Button variant="contained" onClick={handleDownload}>
                Download PDF
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
