import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import incrementIcon from "../../../../assets/images/common/incrementIcon.svg";

import {
  Typography,
  // Box
} from "@mui/material";
import ascDescIcon from "../../../../assets/images/common/ascDescIcon.svg";
import { IMG, TablePopup } from "./Components";

const styles = {
  tableCell: {
    backgroundColor: "#279989",
    zIndex: 1,
  },
  paragraph: {
    color: "#FFF",
    // paddingLeft: "0px",
    padding: "0px",
    position: "relative",
  },
};

export default function TodoTableHeader({
  toggleAllIDs,
  setOrderByName,
  orderByName,
  isAdminOpen,
  setIAdminOpen,
  orderByAdminList,
  setOrderByAdminList,
  orderByStatusList,
  setOrderByStatusList,
  isStatusOpen,
  setIsStatusOpen,
  sessionsList,

  orderBySessionList,
  setOrderBySessionList,
  isSessionOpen,
  setIsSessionOpen,
}) {
  const essentialsFilterList = [
    {
      id: 1,
      text: "Yes",
      onChange: () => {
        if (orderByAdminList.includes("Yes")) {
          setOrderByAdminList(
            orderByAdminList.filter((each) => each !== "Yes")
          );
        } else {
          setOrderByAdminList([...orderByAdminList, "Yes"]);
        }
        setIAdminOpen(false);
      },
      checked: orderByAdminList.includes("Yes"),
    },
    {
      id: 2,
      text: "No",
      onChange: () => {
        if (orderByAdminList.includes("No")) {
          setOrderByAdminList(orderByAdminList.filter((each) => each !== "No"));
        } else {
          setOrderByAdminList([...orderByAdminList, "No"]);
        }
        setIAdminOpen(false);
      },
      checked: orderByAdminList.includes("No"),
    },
  ];

  // Forming the sessions filter list
  const sessionsFiltList = sessionsList.map((session) => ({
    id: session.id,
    text: session.session_type,
    onChange: () => {
      if (orderBySessionList.includes(session.session_type)) {
        setOrderBySessionList(
          orderBySessionList.filter((item) => item !== session.session_type)
        );
      } else {
        setOrderBySessionList([...orderBySessionList, session.session_type]);
      }
      setIsSessionOpen(false);
    },
    checked: orderBySessionList.includes(session.session_type),
  }));

  return (
    <TableHead
      sx={{
        height: "45px",
        // boxShadow: "0px 4px 10px 0px #27998975",
        borderRadius: "15px",
        backgroundColor: "#279989",
      }}
    >
      <TableRow>
        <TableCell sx={styles.tableCell}>
          <Typography variant="h6" sx={styles.paragraph}>
            Todo Name
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Frequency
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={styles.paragraph}
            style={{ flexShrink: 0, minWidth: "100px" }}
          >
            Sessions
            <IMG
              onClick={() => setIsSessionOpen(!isSessionOpen)}
              isDec={false}
              imgUrl={incrementIcon}
            />
            {isSessionOpen === true && (
              <TablePopup
                items={sessionsFiltList}
                onClose={() => setIsSessionOpen(false)}
              />
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Start Date
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            End Date
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={styles.paragraph}
            style={{ flexShrink: 0, minWidth: "100px" }}
          >
            Essentials
            <IMG
              onClick={() => setIAdminOpen(!isAdminOpen)}
              isDec={false}
              imgUrl={incrementIcon}
            />
            {isAdminOpen === true && (
              <TablePopup
                items={essentialsFilterList}
                onClose={() => setIAdminOpen(false)}
              />
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Action
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
