import React, { useEffect, useState, useRef } from "react";
import { Container, Grid, FormLabel, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../../themes/Theme";
import { useParams } from "react-router-dom";
import { getCookie } from "../../../utils/utils";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import Personal from "../../../assets/images/clients/Personal.svg";
import NexttoKin from "../../../assets/images/clients/NexttoKin.svg";
import Fundingstatus from "../../../assets/images/clients/Fundingstatus.svg";

export default function ClientAboutMeView({
  activeUserID,
  isEditMode,
  tabsListConstants,
  clientMainData,
  toggleEditMode,
}) {
  const [clientData, setClientData] = useState({});
  const { userID } = useParams();
  const [userName, setUserName] = useState("");
  const topRef = useRef(null); // Create a ref for the top element

  useEffect(() => {
    // Scroll to the top of the element referenced by topRef
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [clientMainData]); // Trigger on data load

  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  // const clientName =  clientMainData

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setClientData({});
        const token = getCookie();

        const BASE_URL = "https://3.8.158.142:8443/";
        // const URL = `${BASE_URL}api/user/${activeUserID}`;
        const URL = `${BASE_URL}api/client/aboutMe/${userID}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setClientData(data.data);
        } else {
          console.log("get all failed");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersAPI();
  }, [userID]);

  const getRow = (leftText = "", rightText = "") => {
    return (
      <>
        <Grid item xs={3} lg={5}>
          <FormLabel>
            <Typography variant="h7">{leftText}</Typography>
          </FormLabel>
        </Grid>
        <Grid item xs={0.5}>
          <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
        </Grid>
        <Grid item xs={8.5} lg={6.5}>
          <Typography variant="h7">{rightText}</Typography>
        </Grid>
      </>
    );
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const renderSuccesView = () => {
    return (
      <Box sx={{ paddingTop: "15px" }}>
        {/*  start Personal  */}
        <div id={tabsListConstants[0].id} ref={tabsListConstants[0].ref}>
          <ClientHeader
            toggleEdit={toggleEditMode}
            head={`Personal Info`}
            icon={Personal}
            mode={isEditMode}
            isHideButton={true}
          />

          <Grid container alignItems="top" spacing={"10px"}>
            {getRow(
              "Name",
              `${clientData.clientInfo?.prefix || ""} ${
                clientData.clientInfo?.first_name || ""
              } ${clientData.clientInfo?.middle_name || ""} ${
                clientData.clientInfo?.last_name || ""
              }`
            )}
            {getRow(
              "Preferred Name",
              `${clientData.personalInfo?.preferred_name || ""}`
            )}
            {getRow("Address", `${clientData.clientInfo?.address || ""}`)}
            {getRow("City", `${clientData.clientInfo?.city || ""}`)}
            {getRow("Postcode", `${clientData.clientInfo?.postcode || ""}`)}
            {getRow("Country", `${clientData.clientInfo?.country_name || ""}`)}
            {getRow(
              "Contact Number",
              `${clientData.clientInfo?.country_code || ""} ${
                clientData.clientInfo?.contact_number || ""
              }`
            )}
            {getRow("Email", `${clientData.clientInfo?.email || ""}`)}
            {getRow(
              "Preferred Contact Method",
              `${clientData.personalInfo?.preferred_contact_method[0] || ""}${
                clientData.personalInfo?.preferred_contact_method[0] &&
                clientData.personalInfo?.preferred_contact_method[1]
                  ? ", "
                  : ""
              }${clientData.personalInfo?.preferred_contact_method[1] || ""}`
            )}
            {getRow(
              "Date of Birth",
              `${
                clientData.personalInfo?.dob
                  ? formatDate(clientData.personalInfo.dob)
                  : ""
              }`
            )}
            {getRow("Age", `${clientData.personalInfo?.age || ""}`)}
            {getRow("Gender", `${clientData.personalInfo?.gender || ""}`)}
            {getRow(
              "Religion",
              `${clientData.personalInfo?.religion_name || ""}`
            )}
            {getRow(
              "Marital Status",
              `${clientData.personalInfo?.marital_status || ""}`
            )}
            {getRow(
              "Ethnicity",
              `${clientData.personalInfo?.ethnicity_name || ""}`
            )}
          </Grid>
        </div>

        {/*  start Next to kin  */}
        <div id={tabsListConstants[1].id} ref={tabsListConstants[1].ref}>
          <Grid sx={{ padding: "15px 0px" }}>
            <ClientHeader
              head={`Next to Kin`}
              icon={NexttoKin}
              isHideButton={true}
            />
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="top"
            spacing={"10px"}
            sx={{ marginTop: "-20px" }}
          >
            {getRow(
              "Name",
              `${clientData.nextToKinInfo?.prefix || ""} ${
                clientData.nextToKinInfo?.name || ""
              }`
            )}
            {getRow(
              "Relationship to Service User",
              `${clientData.nextToKinInfo?.relationship_service_user || ""}`
            )}
            {getRow("Address", `${clientData.nextToKinInfo?.address || ""}`)}
            {getRow("City", `${clientData.nextToKinInfo?.city || ""}`)}
            {getRow("Postcode", `${clientData.nextToKinInfo?.postcode || ""}`)}
            {getRow(
              "Country",
              `${clientData.nextToKinInfo?.country_name || ""}`
            )}
            {getRow(
              "Contact Number",
              `${clientData.nextToKinInfo?.country_code || ""} ${
                clientData.nextToKinInfo?.contact_number || ""
              }`
            )}
            {getRow("Email", `${clientData.nextToKinInfo?.email || ""}`)}
            {getRow(
              "Preferred Contact Method",
              `${clientData.nextToKinInfo?.preferred_contact_method[0] || ""}${
                clientData.nextToKinInfo?.preferred_contact_method[0] &&
                clientData.nextToKinInfo?.preferred_contact_method[1]
                  ? ", "
                  : ""
              }${clientData.nextToKinInfo?.preferred_contact_method[1] || ""}`
            )}
          </Grid>
        </div>
        {/*  start Funding Status  */}

        <div id={tabsListConstants[2].id} ref={tabsListConstants[2].ref}>
          <Grid sx={{ padding: "15px 0px" }}>
            <ClientHeader
              head={`Funding Status`}
              icon={Fundingstatus}
              isHideButton={true}
            />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="top"
            spacing={"10px"}
            sx={{ marginTop: "-20px" }}
          >
            {getRow(
              "Privately Funded",
              `${clientData.fundingYes?.privately_funded ? "Yes" : "No" || ""}`
            )}
            {clientData.fundingYes?.privately_funded === 0
              ? ""
              : getRow(
                  ` Does ${userName} have capacity to take Financial Decision?`,
                  `${
                    clientData.fundingYes?.take_financial_decision
                      ? "Yes"
                      : "No" || ""
                  }`
                )}

            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Name",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.name
                      ? clientData.fundingYes?.name
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Date of Birth",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.dob
                      ? formatDate(clientData.fundingYes?.dob)
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Contact Number",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.country_code
                      ? clientData.fundingYes?.country_code
                      : ""
                  } ${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.contact_number
                      ? clientData.fundingYes?.contact_number
                      : ""
                  }`
                )
              : ""}

            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Email",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.email
                      ? clientData.fundingYes?.email
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Address",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.address
                      ? clientData.fundingYes?.address
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "City",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.city
                      ? clientData.fundingYes?.city
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Postcode",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.postcode
                      ? clientData.fundingYes?.postcode
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Country",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.country_name
                      ? clientData.fundingYes?.country_name
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "NHS Number",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.nhs_number
                      ? clientData.fundingYes?.nhs_number
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Hourly Rate",
                  ` £ ${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.hourly_rate
                      ? clientData.fundingYes?.hourly_rate
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded
              ? getRow(
                  "Invoice Frequency",
                  `${
                    clientData.fundingYes?.privately_funded &&
                    clientData.fundingYes?.invoice_frequency
                      ? clientData.fundingYes?.invoice_frequency
                      : ""
                  }`
                )
              : ""}

            {clientData.fundingYes?.privately_funded === 0
              ? getRow(
                  "Name of The Funding Organization",
                  `${
                    clientData.fundingYes?.privately_funded === 0 &&
                    clientData.fundingNo?.funding_organization_name
                      ? clientData.fundingNo?.funding_organization_name
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded === 0
              ? getRow(
                  "PA Namber (Patient Access)",
                  `${
                    clientData.fundingYes?.privately_funded === 0 &&
                    clientData.fundingNo?.patient_access
                      ? clientData.fundingNo?.patient_access
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded === 0
              ? getRow(
                  "PO Namber (Purchase Order)",
                  `${
                    clientData.fundingYes?.privately_funded === 0 &&
                    clientData.fundingNo?.purchase_order
                      ? clientData.fundingNo?.purchase_order
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded === 0
              ? getRow(
                  "SA Number (Service Agreement)",
                  `${
                    clientData.fundingYes?.privately_funded === 0 &&
                    clientData.fundingNo?.service_agreement
                      ? clientData.fundingNo?.service_agreement
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded === 0
              ? getRow(
                  "NHS Number",
                  `${
                    clientData.fundingYes?.privately_funded === 0 &&
                    clientData.fundingNo?.nhs_number
                      ? clientData.fundingNo?.nhs_number
                      : ""
                  }`
                )
              : ""}
            {clientData.fundingYes?.privately_funded === 0
              ? getRow(
                  "Hourly Rate",
                  `${
                    clientData.fundingYes?.privately_funded === 0 &&
                    clientData.fundingNo?.hourly_rate
                      ? `£ ${clientData.fundingNo?.hourly_rate}`
                      : ""
                  }`
                )
              : ""}
          </Grid>
        </div>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={ViewTheme}>
      <Box
        ref={topRef}
        sx={{
          padding: 0,
          width: { xxl: "50%", lg: "70%", xs: "98%" },

          textAlign: "left",
        }}
      >
        {renderSuccesView()}
      </Box>
    </ThemeProvider>
  );
}
