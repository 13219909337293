export const prefixs = [
  {
    id: 1,
    text: "Mr.",
  },
  {
    id: 2,
    text: "Mrs.",
  },
  {
    id: 3,
    text: "Master.",
  },
  {
    id: 4,
    text: "Miss.",
  },
  {
    id: 5,
    text: "Ms",
  },
  {
    id: 6,
    text: "Sir",
  },
  {
    id: 7,
    text: "Lady",
  },
  {
    id: 8,
    text: "Lord",
  },
  {
    id: 9,
    text: "Dame",
  },
  {
    id: 10,
    text: "Dr.",
  },
  {
    id: 11,
    text: "Prof.",
  },
];

export const specialists = [
  {
    id: 1,
    text: "Care Assistant",
  },
  {
    id: 2,
    text: "Senior Carer",
  },
  {
    id: 3,
    text: "Nurse",
  },
];

export const risklevel = [
  {
    id: 1,
    text: "High",
  },
  {
    id: 2,
    text: "Moderate",
  },
  {
    id: 3,
    text: "Low",
  },
];
export const maritalStatus = [
  {
    id: 1,
    text: "Single",
  },
  {
    id: 2,
    text: "Married/Civil Partner",
  },
  {
    id: 3,
    text: "Divorced/Person whose Civil Partnership has been dissolved",
  },
  {
    id: 4,
    text: "Widowed/Surviving Civil Partner",
  },
  {
    id: 5,
    text: "Separated",
  },
  {
    id: 6,
    text: "Not disclosed",
  },
];

export const invoiceFrequency = [
  {
    id: 1,
    text: "Weekly",
  },
  {
    id: 2,
    text: "Monthly",
  },
  {
    id: 3,
    text: "Quarterly",
  },
  {
    id: 4,
    text: "Yearly",
  },
];
export const ChooseSessions = [
  {
    id: 1,
    text: "Morning",
  },
  {
    id: 2,
    text: "Afternoon",
  },
  {
    id: 3,
    text: "Tea",
  },
  {
    id: 4,
    text: "Dinner",
  },
  {
    id: 5,
    text: "Night",
  },
];

export const RosterViews = [
  {
    id: 1,
    text: "Carer View",
  },
  {
    id: 2,
    text: "Client View",
  },
];

export const RosterRuns = [
  {
    id: 1,
    text: "Run1",
  },
  {
    id: 2,
    text: "Run2",
  },
  {
    id: 3,
    text: "Run3",
  },
  {
    id: 4,
    text: "Run4",
  },
  {
    id: 5,
    text: "Run5",
  },
];

export const RosterTeams = [
  {
    id: 1,
    text: "Team1",
  },
  {
    id: 2,
    text: "Team2",
  },
  {
    id: 3,
    text: "Team3",
  },
  {
    id: 4,
    text: "Team4",
  },
  {
    id: 5,
    text: "Team5",
  },
];
export const medicationPrnTime = [
  {
    id: 1,
    text: "Hours",
  },
  {
    id: 2,
    text: "Minutes",
  },
  {
    id: 3,
    text: "Days",
  },
];

export const BlisterSesiions = [
  {
    id: 1,
    text: "Morning",
  },
  {
    id: 2,
    text: "Afternoon",
  },
  {
    id: 3,
    text: "Tea",
  },
  {
    id: 4,
    text: "Dinner",
  },
  {
    id: 5,
    text: "Night",
  },
];
export const ones = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];
export const teens = [
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];
export const tens = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];
export const numberToText = (num) => {
  if (num === 0) return "zero";
  if (num < 10) return ones[num];
  if (num < 20) return teens[num - 10];
  if (num < 100) {
    const ten = Math.floor(num / 10);
    const one = num % 10;
    return `${tens[ten]}${one ? "-" + ones[one] : ""}`;
  }
  if (num === 100) return "one hundred";
  return "";
};

export const sampleData1 = [
  {
    client_id: 130,
    type: "Blister Pack",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Dinner",
        time: "18:00-22:00",
        date: "2024-12-10",
        status: "Not Taken",
      },
    ],
  },
  {
    client_id: 130,
    type: "Blister Pack",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Dinner",
        time: "18:00-22:00",
        date: "2024-12-11",
        status: "Not Taken",
      },
    ],
  },
  {
    client_id: 130,
    type: "Blister Pack",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Dinner",
        time: "18:00-22:00",
        date: "2024-12-12",
        status: "Not Taken",
      },
    ],
  },
  {
    client_id: 130,
    type: "Blister Pack",
    medication: "Bisoprolol",
    sessions: [
      {
        session: "Morning",
        time: "7:00-11:00",
        date: "2024-11-19",
        status: "Scheduled",
      },
    ],
  },
  {
    client_id: 130,
    type: "Blister Pack",
    medication: "Bisoprolol",
    sessions: [
      {
        session: "Morning",
        time: "7:00-11:00",
        date: "2024-11-20",
        status: "Scheduled",
      },
    ],
  },
  {
    client_id: 130,
    type: "PRN",
    medication: "Bisoprolol",
    sessions: [
      {
        session: "",
        time: "02:30",
        date: "",
        status: "",
      },
    ],
  },
  {
    client_id: 130,
    type: "Scheduled",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Afternoon",
        time: "11:00-15:00",
        date: "2024-12-16",
        status: "Prepared & Left Out",
      },
    ],
  },
  {
    client_id: 130,
    type: "Scheduled",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Afternoon",
        time: "11:00-15:00",
        date: "2024-12-23",
        status: "Prepared & Left Out",
      },
    ],
  },
  {
    client_id: 130,
    type: "Scheduled",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Afternoon",
        time: "11:00-15:00",
        date: "2024-12-30",
        status: "Prepared & Left Out",
      },
    ],
  },
  {
    client_id: 130,
    type: "Scheduled",
    medication: "Amlodipine",
    sessions: [
      {
        session: "Afternoon",
        time: "11:00-15:00",
        date: "2025-01-06",
        status: "Prepared & Left Out",
      },
    ],
  },
  {
    client_id: 130,
    type: "Scheduled",
    medication: "Bisoprolol",
    sessions: [
      {
        session: "Morning",
        time: "7:00-11:00",
        date: "",
        status: "Scheduled",
      },
    ],
  },
  {
    client_id: 130,
    type: "Scheduled",
    medication: "Bisoprolol",
    sessions: [
      {
        session: "Afternoon",
        time: "11:00-15:00",
        date: "",
        status: "Scheduled",
      },
    ],
  },
  {
    client_id: 130,
    type: "PRN",
    medication: "Amoxicillin",
    sessions: [
      {
        session: 2,
        time: "Hours",
        date: "",
        status: "FullyTaken",
      },
    ],
  },
];
const sampleData = [
  {
    medication: "Amlodipine",
    type: "Blister Pack",
    sessions: [
      {
        session: "Morning",
        time: "7:00-11:00",
        days: ["2024-12-19", "2024-12-20", "2024-12-22", "2024-12-21"],
        status: ["Scheduled", "Scheduled", "Scheduled", "Scheduled"],
      },
      {
        session: "Afternoon",
        time: "11:00-15:00",
        days: ["2024-12-19", "2024-12-20", "2024-12-21"],
        status: ["Scheduled", "Scheduled", "Scheduled"],
      },
      {
        session: "Tea",
        time: "15:00-18:00",
        days: ["2024-12-19", "2024-12-21", "2024-12-20"],
        status: ["Scheduled", "Scheduled", "Scheduled"],
      },
      {
        session: "Dinner",
        time: "18:00-22:00",
        days: ["2024-12-19", "2024-12-21", "2024-12-20"],
        status: ["Scheduled", "Scheduled", "Scheduled"],
      },
      {
        session: "Night",
        time: "22:00-07:00",
        days: ["2024-12-19", "2024-12-21", "2024-12-20"],
        status: ["Scheduled", "Scheduled", "Scheduled"],
      },
    ],
  },
  {
    medication: "Amlodipine",
    type: "sheduled",
    sessions: [
      {
        session: "Morning",
        time: "7:00-11:00",
        days: ["2024-12-19", "2024-12-20", "2024-12-22", "2024-12-21"],
        status: ["Scheduled", "Scheduled", "Scheduled", "Scheduled"],
      },
      {
        session: "Afternoon",
        time: "11:00-15:00",
        days: ["2024-12-19", "2024-12-20", "2024-12-21"],
        status: ["Scheduled", "Scheduled", "Scheduled"],
      },
    ],
  },
];
export const BillingData = [
  {
    id: 1,
    name: "Vamsi",
    date: "Jun 16 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "85 miles",
    TotalMilesPay: "£42.50",
  },
  {
    id: 2,
    name: "Vamsi",
    date: "Jul 01 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "17 miles",
    TotalMilesPay: "£8.50",
  },
  {
    id: 3,
    name: "Vamsi",
    date: "Aug 10 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "59 miles",
    TotalMilesPay: "£29.50",
  },
  {
    id: 4,
    name: "Vamsi",
    date: "Oct 11 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "55 miles",
    TotalMilesPay: "£27.50",
  },
  {
    id: 5,
    name: "Naveen",
    date: "Dec 10 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "58 miles",
    TotalMilesPay: "£29.00",
  },
  {
    id: 6,
    name: "Naveen",
    date: "Jan 09 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "49 miles",
    TotalMilesPay: "£24.50",
  },
  {
    id: 7,
    name: "Naveen",
    date: "Feb 15 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "36 miles",
    TotalMilesPay: "£18.00",
  },
  {
    id: 8,
    name: "Naveen",
    date: "Mar 20 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "61 miles",
    TotalMilesPay: "£30.50",
  },
  {
    id: 9,
    name: "Naveen",
    date: "Apr 15 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "73 miles",
    TotalMilesPay: "£36.50",
  },
  {
    id: 10,
    name: "Naveen",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
  {
    id: 11,
    name: "Padma",
    date: "Jun 16 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "85 miles",
    TotalMilesPay: "£42.50",
  },
  {
    id: 12,
    name: "Padma",
    date: "Jul 01 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "17 miles",
    TotalMilesPay: "£8.50",
  },
  {
    id: 13,
    name: "Padma",
    date: "Aug 10 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "59 miles",
    TotalMilesPay: "£29.50",
  },
  {
    id: 14,
    name: "Padma",
    date: "Oct 11 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "55 miles",
    TotalMilesPay: "£27.50",
  },
  {
    id: 15,
    name: "Kavya",
    date: "Dec 10 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "58 miles",
    TotalMilesPay: "£29.00",
  },
  {
    id: 16,
    name: "Kavya",
    date: "Jan 09 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "49 miles",
    TotalMilesPay: "£24.50",
  },
  {
    id: 17,
    name: "Kavya",
    date: "Feb 15 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "36 miles",
    TotalMilesPay: "£18.00",
  },
  {
    id: 18,
    name: "Kavya",
    date: "Mar 20 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "61 miles",
    TotalMilesPay: "£30.50",
  },
  {
    id: 19,
    name: "Kavya",
    date: "Apr 15 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "73 miles",
    TotalMilesPay: "£36.50",
  },
  {
    id: 20,
    name: "Harish Thallada",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
  {
    id: 21,
    name: "Harish Thallada",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
  {
    id: 22,
    name: "Harish Thallada",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
  {
    id: 23,
    name: "Harish Thallada",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
  {
    id: 24,
    name: "Harish Thallada",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
  {
    id: 25,
    name: "Harish Thallada",
    date: "May 05 2024",
    totalPt: "10 hrs 00 mins",
    totalAt: "10 hrs 00 mins",
    HourlyRate: "£12",
    totalPtPay: "£120",
    totalAtPay: "£120",
    milesDrive: "44 miles",
    TotalMilesPay: "£22.00",
  },
];
