import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "../../../utils/utils";
import useStyles from "../../clients/styles/useStyles";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import "jspdf-autotable";
import CustomCalendar from "../../logs/CustomCalendar";
import Download from "../../billings/Download";
import Loader from "../Loader";

const columns = [
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "clientName",
    headerName: "Client Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sessionType",
    headerName: "Session",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "visit_date",
    headerName: "Visit Date",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const visit_date = params;
      if (!visit_date) return "-";

      return dayjs(visit_date).format("DD-MMM-YYYY");
    },
    sortComparator: (v1, v2, paramOrder) => {
      const date1 = dayjs(v1, "DD-MMM-YYYY");
      const date2 = dayjs(v2, "DD-MMM-YYYY");

      if (!date1.isValid()) return 1;
      if (!date2.isValid()) return -1;

      return paramOrder === "asc"
        ? date2.unix() - date1.unix()
        : date1.unix() - date2.unix();
    },
  },
  {
    field: "actualStartTime",
    headerName: "Actual StartTime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actualEndTime",
    headerName: "Actual EndTime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedStarttime",
    headerName: "Planned Starttime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedEndtime",
    headerName: "Planned Endtime",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "actualTime",
    headerName: "Actual Time",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "plannedTime",
    headerName: "planned Time",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "percOfLoggedHours",
    headerName: "PercOfLoggedHours",
    headerAlign: "center",
    align: "center",
  },
];
const paginationModel = { page: 0, pageSize: 20 };
function CareHoursLogged() {
  const [careHousLogged, setcareHousLogged] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const isFirstRender = useRef(true);
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
       setLoading(true);
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getVisitsReportDetails?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setcareHousLogged(data.data);
      } else {
        console.error("API call failed");
        setcareHousLogged([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Skip the first render, as we don't want to trigger an API call
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // setLoading(false);
      return;
    }

    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  const rows = careHousLogged?.map((item, index) => {
    // Clean up the userName string to remove square brackets and split names by commas if needed
    const cleanedUserNames = item?.userName
      .replace(/[^\w\s,]/g, "") // Remove square brackets or any unwanted characters
      .split(",") // Split into individual names by comma
      .map((name) => name.trim()); // Trim any leading/trailing spaces from names
    //const formattedVisitDate = dayjs(item?.visit_date).format("DD-MM-YYYY");

    return {
      id: index + 1, // Assuming visitdetailsid is present in item
      userName: cleanedUserNames.join(", "), // Join the cleaned names back with a comma
      clientName: item?.clientName,
      sessionType: item?.sessionType,
      visit_date: item?.visit_date,
      actualStartTime: item?.actualStartTime,
      actualEndTime: item?.actualEndTime,
      plannedStarttime: item?.plannedStarttime,
      plannedEndtime: item?.plannedEndtime,
      actualTime: item?.actualTime,
      plannedTime: item?.plannedTime,
      percOfLoggedHours:  Number(item?.percOfLoggedHours).toFixed(2) ,
    };
  });
  const adjustedColumns = columns?.map((col) => {
    const isAddressColumn = col.field === "userName"; // Check if it's the `userName` column
    return {
      ...col,
      flex: isAddressColumn ? 3 : 2, // Dynamic width for `userName`
      minWidth: isAddressColumn ? 260 : 130, // Ensure minimum width for each column
      renderCell: (params) => (
        <Box className={classes.gridColumns}>
          {params.value || "-"} {/* Display placeholder if value is empty */}
        </Box>
      ),
    };
  });
  const getDynamicRowHeight = (params) => {
    const rowContent = params?.row?.selectDays || params?.row?.userName || ""; // Combine both fields if necessary
    const estimatedLineCount = Math.ceil(rowContent.length / 30); // Estimate lines based on length
    return Math.max(70, estimatedLineCount * 25); // Minimum height is 70px
  };

  const clientColumns = [
    "UserName",
    "ClientName",
    "Session",
    "Visit Date",
    "ActualStartTime",
    "ActualEndTime",
    "PlannedStarttime",
    "PlannedEndtime",
  ];
  const finalClients = rows?.filter((u) => rowSelectionModel?.includes(u.id));
  const body = finalClients?.map((d) => {
    const cleanedUserNames = d?.userName
      .replace(/[^\w\s,]/g, "") // Remove square brackets or any unwanted characters
      .split(",") // Split into individual names by comma
      .map((name) => name.trim());
    return [
      cleanedUserNames?.join(","),
      d?.clientName,
      d?.session,
      d?.visit_date && dayjs(d?.visit_date).format("DD-MMM-YYYY") || "-",
      d?.actualStartTime,
      d?.actualEndTime,
      d?.plannedStarttime,
      d?.plannedEndtime,
    ];
  });
  const updatedFinalReports = finalClients?.map(
    ({
      userName,
      clientName,
      sessionType,
      visit_date,
      actualStartTime,
      actualEndTime,
      plannedStarttime,
      plannedEndtime,
      actualTime,
      plannedTime,
      percOfLoggedHours,
    }) => ({
      UserName: userName,
      ClientName: clientName,
      SessionType: sessionType,
      VisitDate: visit_date && dayjs(visit_date).format("DD-MMM-YYYY") || "-",
      ActualStartTime: actualStartTime,
      actualEndTime: actualEndTime,
      PlannedStarttime: plannedStarttime,
      PlannedEndtime: plannedEndtime,
      ActualTime: actualTime,
      PlannedTime: plannedTime,
      percOfLoggedHours: percOfLoggedHours,
    })
  );
  return (
    <Box sx={{ px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 1,
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "45%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Users Reports By CareHoursLogged
        </Typography>
        {/* <CustomButtom
          text="Download"
          disabled={rowSelectionModel.length === 0}
          onClick={exportClientsPdf}
        /> */}
        <Download
          data={body}
          columns={clientColumns}
          selectToDownload={rowSelectionModel}
          heading=" Reports By CareHoursLogged"
          rotateMode={true}
          excelData={updatedFinalReports}
        />
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          getRowHeight={getDynamicRowHeight}
          className={classes.datagridmain}
            loading={loading} 
          slots={{
            loadingOverlay: Loader, // Displays Loader inside table
          }}
        />
      </Box>
    </Box>
  );
}

export default CareHoursLogged;
