import React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../users/Theme";
import OpenForm from "../../../assets/images/clients/Open_Form.svg";
import { Tooltip } from "@mui/material";

import ConsentFormLogo from "../../../assets/icons/ConsentFormLogo.svg";
import View from "../../../assets/images/clients/view_eye.svg";
import User from "../../../assets/images/users/User.svg";
import Delete from "../../../assets/images/clients/Delete.svg";
import ConsentToCare from "../../../assets/images/AuditAndConsent/ConsentToCare.svg";
import KeySafeConsent from "../../../assets/images/AuditAndConsent/KeySafeConsent.svg";
import GDPRConsent from "../../../assets/images/AuditAndConsent/GDPRConsent.svg";
import FamilyConsent from "../../../assets/images/AuditAndConsent/FamilyConsent.svg";
import DownloadIcon from "../../../assets/icons/DownloadIcon.svg";

import { auditCardDetails, AuditHeader } from "./reusableAuditComponents";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";

const AuditsAndConsentEdit = ({
  clientMainData,
  signatureName,
  clientName,
  setSignatureName,
  setSelectedSignatureNameIndex,
  selectedSignatureNameIndex,
  setOpenConsentPdf,
  setIsDownload,
  setSelectedAuditCard,
  setAuditName,
  setConsent,
  clientId,
  finalAuditDetails,
  setFinalAuditDetails,
}) => {
  const consentCardDetails = [
    {
      id: 1,
      consentName: "Consent to Care",
      image: ConsentToCare,
    },
    {
      id: 2,
      consentName: "Key Safe Consent",
      image: KeySafeConsent,
    },
    {
      id: 3,
      consentName: "GDPR Consent",
      image: GDPRConsent,
    },
    {
      id: 4,
      consentName: "Family Member Access to Care Log",
      image: FamilyConsent,
    },
  ];

  useEffect(() => {
    console.log("fnal Audit Details", finalAuditDetails);
  }, [finalAuditDetails]);

  const fetchAllAudits = async () => {
    try {
      //setAssessmentCard({});
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-All-Audits/${clientId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      console.log("data", data);
      if (response.ok) {
        const updatedAudit = finalAuditDetails.map((audit) => {
          const auditData = data?.[audit.audit_api_field]?.[0];

          console.log("auditData", auditData);

          // Extract sign names and dates
          const sign1 = auditData?.sign_1 || "";
          const sign2 = auditData?.sign_2 || "";
          const signDate1 = auditData?.sign_date1
            ? dayjs(auditData.sign_date1)
            : null;
          const signDate2 = auditData?.sign_date2
            ? dayjs(auditData.sign_date2)
            : null;

          // Determine the latest sign name and date
          let sign_name = "";
          let sign_date = "";

          if (signDate1 && signDate2) {
            if (signDate1.isAfter(signDate2)) {
              sign_name = sign1;
              sign_date = signDate1.toISOString();
            } else {
              sign_name = sign2;
              sign_date = signDate2.toISOString();
            }
          } else if (signDate1) {
            sign_name = sign1;
            sign_date = signDate1.toISOString();
          } else if (signDate2) {
            sign_name = sign2;
            sign_date = signDate2.toISOString();
          }

          return {
            ...audit,
            status: auditData?.status || "",
            sign_name,
            sign_date,
          };
        });

        setFinalAuditDetails(updatedAudit);
      } else {
        console.log("Failed to fetch assessments");
      }
    } catch (error) {
      console.error("Error fetching assessments:", error);
    }
  };

  useEffect(() => {
    fetchAllAudits();
  }, []);

  // const activeCardsCount = finalAuditDetails.filter(
  //   (c) => c.status === "Active"
  // ).length;

  const renderAuditCard = (card) => {
    // const showDetails =
    //   card.status === "Active" ||
    //   (card.status === "Draft" && activeCardsCount !== 1);

    return (
      <Tooltip
        title={card.audit_name}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: { offset: [0, -8] }, // Adjust vertical spacing
            },
          ],
        }}
        componentsProps={{
          tooltip: { sx: { fontFamily: "Lora" } },
        }}
      >
        <Box
          key={card.id} // Unique key for each card
          textAlign="left"
          onClick={() => {
            setAuditName(card.audit_name);
            setSelectedAuditCard(card);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            padding: "10px 20px",
            margin: "10px 0",
            width: "100%",
            height: "80px",
            backgroundColor: "#F0FCFA",
            // borderRadius: showDetails ? "10px 0 10px 10px" : "10px",
            borderRadius:
              card.status === "Active" || card.status === "Draft"
                ? "10px 0 10px 10px"
                : "10px",
            marginLeft: "auto",
            transition: "background-color 0.3s, color 0.3s",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#279989",
              "& img": { filter: "brightness(0) invert(1)" },
              "& .cardText": { color: "white" },
              "& .statusBox": {
                backgroundColor: "#279989",
                transition: "background-color 0.3s, color 0.3s",
              },
            },
          }}
        >
          {/* Render the status box only if showDetails is true */}
          {/* {showDetails && ( */}
          {(card.status === "Active" || card.status === "Draft") && (
            <Box
              className="statusBox"
              sx={{
                flexGrow: 1,
                maxWidth: "70%",
                position: "absolute",
                top: "-23px",
                right: "0px",
                backgroundColor: "#F0FCFA",
                borderRadius: "10px 10px 0px 0px",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              <Typography
                variant="body2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  textAlign: "right",
                  fontSize: "11px",
                  paddingInline: "7px",
                }}
              >
                Last Updated: {dayjs(card.sign_date).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          )}

          <Box sx={{ height: "55px", width: "55px" }}>
            <img
              style={{ height: "90%", width: "50px" }}
              src={card.audit_image}
              alt="Assess Icon"
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "70%",
              marginTop: card.status === "Active" ? "18px" : 0,
            }}
          >
            {card.status === "Draft" && (
              <Box
                sx={{
                  position: "absolute",
                  top: card.status === "Draft" ? "2px" : "8px",
                  right: card.status === "Draft" ? "7px" : "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    padding: "2px",
                    width: "50px",
                    textAlign: "center",
                    borderRadius: "8px",
                    fontWeight: 500,
                    fontFamily: "Lora",
                    backgroundColor: "#FFF0E3",
                    border: "1.5px solid #F5891D",
                    color: "#FA8733",
                  }}
                  variant="body1"
                >
                  Draft
                </Typography>
              </Box>
            )}

            {/* Audit name and sign name */}
            <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
              <Typography
                variant="h2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  marginBottom: 0,
                  paddingBottom: 0,
                  fontSize: "15px",
                }}
              >
                {card.audit_name}
              </Typography>
              {/* {showDetails && ( */}
              {(card.status === "Active" || card.status === "Draft") && (
                <Typography
                  variant="body2"
                  className="cardText"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginBottom:
                      card.status === "Active" || card.status === "Draft"
                        ? 3
                        : 0,
                    maxWidth: "100%",
                  }}
                >
                  {card.sign_name}
                </Typography>
              )}
            </Box>

            {/* OpenForm image positioned at the bottom right */}
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "7px",
                height: "auto",
              }}
            >
              <img
                style={{ height: "20%", width: "auto" }}
                src={OpenForm}
                alt="Form Icon"
              />
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  };

  const renderConsentCard = (signature, index) => {
    return (
      //   <Box
      //     key={signature.consentName} // Use unique key for each card
      //     textAlign="left"
      //     sx={{
      //       display: "flex",
      //       alignItems: "center",
      //       position: "relative", // Relative position to position the OpenForm icon inside
      //       padding: "10px 20px",
      //       margin: "10px 0",
      //       width: "100%",
      //       height: "90px",
      //       backgroundColor: "#F0FCFA",
      //       borderRadius: "10px",
      //       marginLeft: "auto", // Push the box to the right
      //       transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
      //       "&:hover": {
      //         backgroundColor: "#279989", // Hover background color
      //         "& img": {
      //           filter: "brightness(0) invert(1)", // Change icon color to white (invert colors)
      //         },
      //         "& .cardText": {
      //           color: "white", // Change text color to white
      //         },
      //       },
      //     }}
      //   >
      //     <Box sx={{ height: "55px", width: "55px" }}>
      //       <img
      //         style={{
      //           height: "100%",
      //           width: "auto",
      //         }}
      //         src={ConsentFormLogo} // Dynamically load the associated image
      //         alt="Consent Form"
      //       />
      //     </Box>
      //     <Box
      //       sx={{
      //         flexGrow: 1,
      //         maxWidth: "70%",
      //         // marginTop: card.status === "active" ? "18px" : 0,
      //         // marginTop: "18px",
      //       }}
      //     >
      //       {/* Ensure this container doesn't expand too much */}
      //       {/* Assessment */}
      //       <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
      //         <Typography
      //           variant="h2"
      //           className="cardText"
      //           sx={{
      //             whiteSpace: "nowrap", // Prevent text from wrapping
      //             overflow: "hidden", // Hide overflowing text
      //             textOverflow: "ellipsis", // Show ellipsis for overflow
      //             maxWidth: "100%", // Adjust based on container width
      //             marginBottom: 0,
      //             paddingBottom: 0,
      //           }}
      //         >
      //           {signature.consentName}
      //           {/* {card.assessment_name} */}
      //         </Typography>

      //         <Typography
      //           variant="body2"
      //           className="cardText"
      //           sx={{
      //             whiteSpace: "nowrap", // Prevent text from wrapping
      //             overflow: "hidden", // Hide overflowing text
      //             textOverflow: "ellipsis", // Show ellipsis for overflow
      //             maxWidth: "100%", // Adjust based on container width
      //           }}
      //         >
      //           {`Updated: ${dayjs(signature.date).format("DD-MM-YYYY")}`}
      //         </Typography>
      //       </Box>

      //       {/* Position the OpenForm image at the bottom right */}
      //       <Box
      //         sx={{
      //           position: "absolute", // Absolute position inside the box
      //           bottom: "10px", // Distance from the bottom
      //           right: "10px", // Distance from the right
      //           display: "flex", // Arrange items in a row
      //           gap: "2px", // Space between the images
      //           alignItems: "center", // Align items vertically in the center
      //         }}
      //       >
      //         <IconButton
      //           onClick={() => {
      //             setOpenConsentPdf(true);
      //             setSelectedSignatureNameIndex(index);
      //             setIsDownload(false);
      //           }}
      //         >
      //           <img src={View} alt="Form Icon" />
      //         </IconButton>
      //         <IconButton
      //           onClick={() => {
      //             setOpenConsentPdf(true);
      //             setSelectedSignatureNameIndex(index);
      //             setIsDownload(true);
      //           }}
      //         >
      //           {/* <PDFDownloadLink
      //             document={
      //               <ConsentPdfViewer
      //                 signatureName={signatureName}
      //                 selectedSignatureNameIndex={index}
      //               />
      //             }
      //             fileName="ConsentForm.pdf"
      //           >
      //             {({ blob, url, loading, error }) =>
      //               loading ? "Loading document..." : "Download now!"
      //             }
      //           </PDFDownloadLink> */}
      //           <img src={DownloadIcon} alt="Form Icon" />
      //         </IconButton>
      //       </Box>

      //       <IconButton
      //         sx={{
      //           position: "absolute", // Absolute position inside the box
      //           top: "10px", // Distance from the bottom
      //           right: "10px", // Distance from the right
      //           height: "auto", // Adjust height and width for positioning
      //         }}
      //         onClick={() => {
      //           setSelectedSignatureNameIndex(index);

      //           setOpenDialog(true);
      //         }}
      //       >
      //         <img src={Delete} alt="Form Icon" />
      //       </IconButton>
      //     </Box>
      //   </Box>
      <Tooltip
        title={signature.consentName}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8], // Adjusts vertical spacing (reduce from default 8px)
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              fontFamily: "Lora",
            },
          },
        }}
      >
        <Box
          key={index} // Use unique key for each card
          textAlign="left"
          onClick={() => {
            setConsent(signature.consentName);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            padding: "10px 20px",
            margin: "10px 0",
            width: "100%",
            height: "80px",
            backgroundColor: "#F0FCFA",
            borderRadius:
              //   card.status === "Active" ? "10px 0 10px 10px" : "10px",
              "10px",
            marginLeft: "auto",
            transition: "background-color 0.3s, color 0.3s",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#279989",
              "& img": {
                filter: "brightness(0) invert(1)",
              },
              "& .cardText": {
                color: "white",
              },
              "& .statusBox": {
                backgroundColor: "#279989",
                transition: "background-color 0.3s, color 0.3s",
              },
            },
          }}
        >
          {/* {card.status === "Active" && (
            <Box
              className="statusBox"
              sx={{
                flexGrow: 1,
                maxWidth: "70%",
                position: "absolute",
                top: "-23px",
                right: "0px",
                backgroundColor: "#F0FCFA",
                borderRadius: "10px 10px 0px 0px",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              <Typography
                variant="body2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  textAlign: "right",
                  fontSize: "11px",
                  paddingInline: "7px",
                }}
              >
                Last Updated: {dayjs(card.sign_date).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          )} */}
          <Box sx={{ height: "55px", width: "55px" }}>
            <img
              style={{
                height: "90%",
                width: "50px",
              }}
              src={signature.image}
              alt="Assess Icon"
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "70%",
              //   marginTop: card.status === "active" ? "18px" : 0,
              marginTop: 0,
            }}
          >
            {/* {isDraft && (
              <Box
                sx={{
                  position: "absolute",
                  top: card.status === "active" ? "2px" : "8px",
                  right: card.status === "active" ? "7px" : "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    // background: "blue",
                    padding: "2px",
                    width: "50px",
                    textAlign: "center",
                    borderRadius: "8px",
                    fontWeight: 500,
                    // marginLeft: "20px",
                    // marginTop: "auto",
                    // marginBottom: "6px",
                    fontFamily: "Lora",
                    backgroundColor: "#FFF0E3",
                    border: "1.5px solid #F5891D",
                    color: "#FA8733",
                    // ...styles,
                  }}
                  variant="body1"
                >
                  {isDraft ? "Draft" : ""}
                </Typography>
              </Box>
            )} */}

            {/* Assessment */}
            <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
              <Typography
                variant="h2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  marginBottom: 0,
                  paddingBottom: 0,
                  fontSize: "15px",
                }}
              >
                {signature.consentName}
              </Typography>
              {/* {card.status === "Active" && (
                <Typography
                  variant="body2"
                  className="cardText"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                >
                  {card.sign_name}
                </Typography>
              )} */}
            </Box>

            {/* Position the OpenForm image at the bottom right */}
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "7px",
                height: "auto",
              }}
            >
              <img
                style={{
                  height: "20%",
                  width: "auto",
                }}
                src={OpenForm}
                alt="Form Icon"
              />
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  };
  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        <Box>
          <AuditHeader head={`${clientName}'s Audits`} isHideButton={false} />
          <Card
            sx={{
              paddingLeft: 0,
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 6,
            }}
          >
            <Typography variant="body2" sx={{ textAlign: "left" }}>
              Complete the initial assessment of the client by determining the
              key fundamental principles.
              <br />
              Evaluate the following assessments for {`${clientName}`}
            </Typography>
          </Card>
          <Grid container direction="row" spacing={3}>
            {finalAuditDetails.map((card) => (
              <Grid
                item
                key={card.id}
                xxl={3} // 4 cards per row in XXL
                lg={4} // 3 cards per row in LG
                md={6} // 2 cards per row in MD
                xs={12} // 1 card per row in XS (mobile)
                sx={{}}
              >
                {renderAuditCard(card)}
              </Grid>
            ))}
          </Grid>

          <Box
            textAlign="left"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
              margin: "10px 0",
              width: "100%",
              height: "150%",
            }}
          >
            <img
              style={{
                height: "30%",
                width: "auto",
              }}
              src={User}
              alt="Consent Form"
            />
            <Typography variant="h2">{`${clientName}'s Consent Forms`}</Typography>
          </Box>
          <Grid container direction="row" spacing={3}>
            {consentCardDetails.map((signature, index) => (
              <Grid
                item
                key={signature.consentName}
                xxl={3} // 4 cards per row in XXL
                lg={4} // 3 cards per row in LG
                md={6} // 2 cards per row in MD
                xs={12} // 1 card per row in XS (mobile)
                sx={{}}
              >
                {renderConsentCard(signature, index)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AuditsAndConsentEdit;
