import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import dayjs from "dayjs";
import Download from "../Download";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "../../clients/styles/useStyles";
import { getCookie } from "../../../utils/utils";
import CustomCalendar from "../../logs/CustomCalendar";
import UserTableEdit from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import UserTableEditHover from "../../../assets/images/userTableIcons/UserTableEditHover.svg";
import { Status2 } from "../../users/userTable/Components";
import { Time } from "../../reusableComponents";
import CustomButtom from "../../reusableComponents/CustomButton";
import { toast, ToastContainer } from "react-toastify";
const paginationModel = { page: 0, pageSize: 20 };
const columnsDownload = [
  "Client Name",
  "Visit Date",
  "Session",
  "Planned Duration",
  // "Actual Duration",
  "Hourly Pay",
  "TotalPTPay",
  // "TotalATPay",
  "Status",
];
const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "23px",
        height: "23px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};
function ToBeInvoiced() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [billingData, setBillingData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedBilling, setSelectedBilling] = useState(null); // State for selected billing data
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    PlannedSH: "",
    plannedSM: "",
    plannedEH: "",
    plannedEM: "",
    actualSH: "",
    actualSM: "",
    actualEH: "",
    actualEM: "",
    status: "",
  });
  const isFirstRender = useRef(true);
  const classes = useStyles();

  const showToastMessage = () => {
    toast.success("Visits Confirmed to ClientInvoice Successfully", {
      position: "top-right",
      autoClose: 2000, // Duration in milliseconds
    });
  };

  const showToastFailureMessage = () => {
    toast.error("Failed to add visit", {
      position: "top-right",
    });
  };
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/billingtobeinvoiced?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setBillingData(data.data);
      } else {
        console.error("API call failed");
        setBillingData([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  // useEffect(() => {
  //   const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
  //   const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
  //   fetchUsersAPI(initialStartDate, initialEndDate);
  // }, []);
  useEffect(() => {
    
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip the first render
      return;
    }
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };
  const rows = billingData?.map((billing) => ({
    id: billing?.visitDetailsId,
    userName: billing?.clientName,
    visitDate: billing?.visitDate,
    session: billing?.session,
    totalPlannedTime: billing?.totalPlannedTime,
    // totalActualTime: billing.totalActualTime,
    plannedStartTime: billing?.plannedStartTime,
    plannedEndTime: billing?.plannedEndTime,
    actualStartTime: billing?.actualStartTime,
    actualEndTime: billing?.actualEndTime,
    hourlyPay: billing?.hourlyPay && `£ ${billing?.hourlyPay}`,
    totalPlannedTimePay: billing?.totalPlannedTimePay && `£ ${billing?.totalPlannedTimePay}`,
  // totalActualTimePay: billing.totalActualTimePay,
    billingStatus: billing?.billingStatus,
    action: null,
    visitDetailsId:billing?.visitDetailsId,
    clientId:billing?.clientId
  }));
  const handleActionClick = (row) => {
    setSelectedBilling(row);
    setOpenDialog(true);
    setFormData((prev) => ({
      ...prev,
      status: row?.billingStatus || "", // Ensure a default value
      id: row?.id || "", // Default to empty string if id is missing
      PlannedSH: row?.plannedStartTime?.split(":")[0] || "", // Default to empty string if PlannedSH is missing
      plannedSM: row?.plannedStartTime?.split(":")[1] || "",
      plannedEH: row?.plannedEndTime?.split(":")[0] || "",
      plannedEM: row?.plannedEndTime?.split(":")[1] || "",
      actualSH: row?.actualStartTime?.split(":")[0] || "",
      actualSM: row?.actualStartTime?.split(":")[1] || "",
      actualEH: row?.actualEndTime?.split(":")[0] || "",
      actualEM: row?.actualEndTime?.split(":")[1] || "",
    }));
    console.log(formData, "===============================");
  };
  const adjustedColumns = [
    {
      field: "userName",
      headerName: "Client Name",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
        valueGetter: (params) => {
                    const visitDate = params;
                    if (!visitDate) return "-";
              
                    return dayjs(visitDate).format("DD-MMM-YYYY");
                    
                  },
            sortComparator: (v1, v2, paramOrder) => {
                    const date1 = dayjs(v1, "DD-MMM-YYYY");
                    const date2 = dayjs(v2, "DD-MMM-YYYY");
              
                    if (!date1.isValid()) return 1;
                    if (!date2.isValid()) return -1;
              
                    return paramOrder === "asc"
                      ? date2.unix() - date1.unix()
                      : date1.unix() - date2.unix();
                  },
    },
    {
      field: "session",
      headerName: "Session",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "totalPlannedTime",
      headerName: "Planned Duration",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 115,
    },
    // {
    //   field: "totalActualTime",
    //   headerName: "Actual Duration",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   minWidth: 110,
    // },
    {
      field: "hourlyPay",
      headerName: "Hourly Pay",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "totalPlannedTimePay",
      headerName: "Total PT Pay",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    // {
    //   field: "totalActualTimePay",
    //   headerName: "Total AT Pay",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   minWidth: 110,
    // },
    {
      field: "billingStatus",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        // Convert value to lowercase for case-insensitive comparison
        const statusText =
          params.value?.toLowerCase() === "confirmed"
            ? "Confirmed"
            : "Unconfirmed";
        const statusValue = params.value?.toLowerCase() === "confirmed" ? 1 : 0;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start", // Align content to the top
              justifyContent: "center",
              height: "100%", // Ensure it takes the full height of the cell
              padding: "0 4px",
            }}
          >
            <Status2 status={statusValue}>{statusText}</Status2>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <Box
          onClick={() => handleActionClick(params.row)}
          sx={{ cursor: "pointer" }}
        >
          <HoverImg
            imgBeforeHover={UserTableEdit}
            imgAfterHover={UserTableEditHover}
          />
        </Box>
      ),
    },
  ];
  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      status: value, // Update the status in formData
    }));
  };
  const finalDataToDownLoad = rows?.filter((item) =>
    rowSelectionModel?.includes(item?.id)
  );
  const bodyRows = finalDataToDownLoad?.map((item) => {
    return [
      item.clientName,
      dayjs(item?.visitDate).format("DD-MMM-YYYY"),
      item.session,
      item.totalPlannedTime,
      // item.totalActualTime,
      item.hourlyPay,
      `£  ${item.totalPlannedTimePay}`,
      // item.totalActualTimePay,
      ` £ ${item.billingStatus}`,
    ];
  });
  const visitIds = finalDataToDownLoad?.map((item) => {
    return item.visitDetailsId;
  });
  const clientId = finalDataToDownLoad?.map((item) => {
    return item.clientId;
  });
  console.log(finalDataToDownLoad,'finalDataToDownLoad')
  console.log(visitIds, "visitIds");
  console.log(clientId, "clientId");
  const finalDownloadToExcell = finalDataToDownLoad?.map(({
    userName,visitDate,session,totalPlannedTime,plannedStartTime,
    plannedEndTime,actualStartTime,actualEndTime,hourlyPay,totalPlannedTimePay,
    billingStatus

  })=>({
    UserName:userName,
    VisitDate:visitDate && dayjs(visitDate).format("DD-MMM-YYYY"),
    Session:session,
    PlannedDuration:totalPlannedTime,
    // PlannedStartTime:plannedStartTime,
    // PlannedEndTime:plannedEndTime,
    // ActualStartTime:actualStartTime,
    // ActualEndTime:actualEndTime,
    HourlyPay:hourlyPay,
    TotalPlannedTimePay:totalPlannedTimePay,
    BillingStatus:billingStatus


  }))
  const handleSave = async () => {
    console.log(formData.status, "===============================");
    console.log("formData", formData);
  
    try {
      const visitDetailsId = formData.id;
      const token = getCookie();
      // https://3.8.158.142:8443/api/editto-beinvoiced/1517
      const URL = `https://3.8.158.142:8443/api/editto-beinvoiced/${formData.id}`;
  
      // Format the start and end times or set to empty string if they are empty
      const formatTime = (hours, minutes) => {
        if (hours && minutes) {
          return `${hours}:${minutes}`;
        }
        return ""; // Return empty string if either hours or minutes is missing
      };
      const plannedStartTime = formatTime(formData.PlannedSH, formData.plannedSM);
      const plannedEndTime = formatTime(formData.plannedEH, formData.plannedEM);
      // const actualStartTime = formatTime(formData.actualSH, formData.actualSM);
      // const actualEndTime = formatTime(formData.actualEH, formData.actualEM);
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          planned_start_time: plannedStartTime,
          planned_end_time: plannedEndTime,
          // actual_start_time: actualStartTime,
          // actual_end_time: actualEndTime,
          billing_status: formData.status,
        }),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log("data saved successfully");
        // showToastMessage();
        const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
        await fetchUsersAPI(updatedStartDate, updatedEndDate);
        setOpenDialog(false);
      } else {
        console.log("error updating the medication");
        // showToastFailureMessage();
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const addToClientInvoice = async () => {
    try {
      const URL = "https://3.8.158.142:8443/api/billinggenerateinvoice";
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ visitIds: visitIds, clientIds: clientId }),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log("data saved sucessfully");
        // showToastMessage();
        const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");
        fetchUsersAPI(updatedStartDate, updatedEndDate);
      } else {
        // showToastFailureMessage();
        console.log("there was an error while updating");
      }
    } catch (error) {
      console.log("there was an error", error);
    }
  };
  return (
    <Paper
      sx={{
        background: "#FFFFFF",
        borderRadius: "8px",
        px: 3,
        py: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1.5,
        }}
      >
        <Box sx={{ width: "33%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            alignSelf: "center",
          }}
        >
          <CustomButtom
            text="Generate Invoice"
            disabled={rowSelectionModel.length === 0}
            onClick={addToClientInvoice}
          />
          <Download
            data={bodyRows}
            columns={columnsDownload}
            selectToDownload={rowSelectionModel}
            heading="Client Invoice Billing"
            rotateMode ={true}
            excelData ={finalDownloadToExcell}
          />
        </Box>
      </Box>
      <Box
        className={classes.tableMainContainer}
        sx={{ height: "calc(100vh - 270px) !important" }}
      >
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
        />
      </Box>
      <Dialog
        open={openDialog}
        sx={{
          "& .MuiDialog-paper": {
            // Targets the dialog's paper (content area)
            padding: 2, // Adds padding on all sides
            minWidth: "580px", // Increases the dialog width
          },
        }}
      >
        <DialogContent>
          {selectedBilling && (
            <>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
                  Planned Time
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1,
                  }}
                >
                  <Time
                    label="Start Time"
                    initialHours={formData.PlannedSH}
                    initialMinutes={formData.plannedSM}
                    onHoursChange={(value) =>
                      setFormData((prev) => ({
                        ...prev,
                        PlannedSH: value || "", // Fallback to an empty string
                      }))
                    }
                    onMinutesChange={(value) =>
                      setFormData((prev) => ({
                        ...prev,
                        plannedSM: value || "",
                      }))
                    }
                  />
                  <Time
                    label="End Time"
                    initialHours={formData.plannedEH}
                    initialMinutes={formData.plannedEM}
                    onHoursChange={(value) =>
                      setFormData((prev) => ({
                        ...prev,
                        plannedEH: value || "",
                      }))
                    }
                    onMinutesChange={(value) =>
                      setFormData((prev) => ({
                        ...prev,
                        plannedEM: value || "",
                      }))
                    }
                  />
                </Box>
                {/* <Typography variant="h5" sx={{ pt: 8, m: 0, color: "#279989" }}>
                  Actual Time
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Time
                    label="Start Time"
                    initialHours={formData.actualSH}
                    initialMinutes={formData.actualSM}
                    onHoursChange={(value) =>
                      setFormData({ ...formData, actualSH: value })
                    }
                    onMinutesChange={(value) =>
                      setFormData({ ...formData, actualSM: value })
                    }
                  />
                  <Time
                    label="End Time"
                    initialHours={formData.actualEH}
                    initialMinutes={formData.actualEM}
                    onHoursChange={(value) =>
                      setFormData({ ...formData, actualEH: value })
                    }
                    onMinutesChange={(value) =>
                      setFormData({ ...formData, actualEM: value })
                    }
                  />
                </Box> */}
                <Grid mt={6}>
                  <Typography
                    variant="h5"
                    sx={{ pt: 0, m: 0, color: "#279989" }}
                  >
                    Status
                  </Typography>
                  <RadioGroup
                    row
                    value={formData.status.toLowerCase()} // Controlled value from formData
                    onChange={handleRadioChange} // Update formData on change
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                  >
                    <FormControlLabel
                      value="confirmed"
                      control={<Radio disabled={false} />}
                      label="Confirmed"
                    />
                    <FormControlLabel
                      value="unconfirmed"
                      control={<Radio disabled={false} />}
                      label="Unconfirmed"
                    />
                  </RadioGroup>
                </Grid>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="customcancel"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="customsave" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Paper>
  );
}

export default ToBeInvoiced;
