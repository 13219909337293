import { useState, useRef, useEffect } from "react";
import React from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Grid,
  FormControlLabel,
  FormLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {
  Name,
  Calendar,
  NumberInput,
  Currency,
  Country,
} from "../../reusableComponents";
import AccountNumber from "../../reusableComponents/AccountNumber";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { ThemeProvider } from "styled-components";
import { getCookie } from "../../../utils/utils";
import AssessmentHeaderLogo from "../../../assets/images/clients/Assessments/AssessmentsHeaderLogo.svg";
import { EditTheme } from "../../users/Theme";
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/HeaderInvoiceIcon.svg";
import Admin from "../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";

export default function AddAssessmentPopup({
  open,
  handleDialog = () => {},
  isRiskAssessment,
  clientId,
  setFetchRefreshVariable,
  assessmentCard,
}) {
  const [finalCheckedForm, setFinalCheckedForm] = useState({ careplan: [] });
  const assessmentList = [
    {
      assessment_name: "Environment Assessment",
      assessment_id: 9,
    },
    {
      assessment_name: "Mental Health Assessment",
      assessment_id: 13,
    },
    {
      assessment_name: "Financial Assessment",
      assessment_id: 11,
    },
    {
      assessment_name: "Communication Assessment",
      assessment_id: 5,
    },
    {
      assessment_name: "Activity Assessment",
      assessment_id: 1,
    },
    {
      assessment_name: "Medication Assessment",
      assessment_id: 12,
    },
    {
      assessment_name: "Personal Hygiene Assessment",
      assessment_id: 18,
    },
    {
      assessment_name: "Behaviours That May Challenge Assessment",
      assessment_id: 2,
    },
    {
      assessment_name: "Breathing Assessment",
      assessment_id: 3,
    },
    {
      assessment_name: "Clinical Assessment",
      assessment_id: 4,
    },
    {
      assessment_name: "Continence Assessment",
      assessment_id: 6,
    },
    {
      assessment_name:
        "Cultural, Spiritual, Social and Relationships Assessment",
      assessment_id: 7,
    },
    {
      assessment_name: "Domestic Assessment",
      assessment_id: 8,
    },
    {
      assessment_name: "Equipment Assessment",
      assessment_id: 10,
    },
    {
      assessment_name: "Moving and Handling Assessment",
      assessment_id: 14,
    },
    {
      assessment_name: "Nutrition and Hydration Assessment",
      assessment_id: 15,
    },
    {
      assessment_name: "Oral Care Assessment",
      assessment_id: 16,
    },
    { assessment_name: "Pain Assessment", assessment_id: 17 },
    { assessment_name: "Skin Assessment", assessment_id: 19 },
    {
      assessment_name: "Sleeping Assessment",
      assessment_id: 20,
    },
  ];

  const riskAssessmentList = [
    {
      assessment_name: "Activity Risk Assessment",
      assessment_id: 21,
    },
    {
      assessment_name: "Behaviours That May Challenge Risk Assessment",
      assessment_id: 22,
    },
    {
      assessment_name: "COSHH Risk Assessment",
      assessment_id: 23,
    },
    {
      assessment_name: "Equipment Register",
      assessment_id: 24,
    },
    {
      assessment_name: "Financial Risk Assessment",
      assessment_id: 25,
    },
    {
      assessment_name: "Medication Risk Assessment",
      assessment_id: 26,
    },
    {
      assessment_name: "Self-Administration of Medication Risk Assessment",
      assessment_id: 27,
    },
  ];

  const postAudit = async () => {
    try {
      console.log("before POST auditForm", finalCheckedForm);
      const token = getCookie();

      const URL = `${process.env.REACT_APP_BASE_URL}/careplan-add-assessment`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(finalCheckedForm),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        setFetchRefreshVariable((prevState) => !prevState);
        handleDialog();
      } else {
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const listToRender = isRiskAssessment ? riskAssessmentList : assessmentList;

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const userId = user?.id;

  const handleCheckBoxChange = (event, index) => {
    const { checked } = event.target;
    const selectedAssessment = isRiskAssessment
      ? riskAssessmentList[index]
      : assessmentList[index];

    setFinalCheckedForm((prevState) => {
      const currentCareplan = prevState.careplan || [];
      if (checked) {
        // Add the selected assessment in the desired format
        return {
          ...prevState,
          careplan: [
            ...currentCareplan,
            {
              client_id: clientId, // or selectedAssessment.client_id if available
              assessment_id: selectedAssessment.assessment_id,
              assessment_name: selectedAssessment.assessment_name,
            },
          ],
        };
      } else {
        // Remove the selected assessment if unchecked
        return {
          ...prevState,
          careplan: currentCareplan.filter(
            (item) => item.assessment_id !== selectedAssessment.assessment_id
          ),
        };
      }
    });
  };

  useEffect(() => {
    console.log("finalCheckedForm", finalCheckedForm);
  }, [finalCheckedForm]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Dialog
        open={open}
        onClose={handleDialog}
        sx={{
          "& .MuiDialog-paper": {
            padding: "20px",
            width: "50%",
            maxWidth: "100%",
            "@media (min-width: 1350px)": {
              width: "40%",
            },
          },
        }}
      >
        <Box textAlign="center" justifyContent="Center">
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={handleDialog}
            //   onClick={() => console.log("Clicked!")}
            sx={{
              position: "absolute",
              right: 0,
              top: 10,
              width: "28px ! important",
              height: "28px ! important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
            }}
          >
            {/* Empty content since the SVG icons are used in background */}
          </IconButton>
          <DialogContent
            sx={
              {
                //   background: "#F0FCFA",
                // paddingBottom: "35px",
                // paddingRight: "18px",
                // paddingLeft: "18px",
                //   paddingInline: "40px",
              }
            }
          >
            <Box
              textAlign="left"
              sx={{
                display: "flex",
                alignItems: "center",

                padding: "10px 0px 25px 0px",
                // margin: "10px 0px 0px 0px",
                // width: "100%",
                // height: "150%",
              }}
            >
              <img
                style={
                  {
                    // height: "30%",
                    // width: "auto",
                  }
                }
                src={AssessmentHeaderLogo}
                alt="Assessments"
              />
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Arvo",
                  fontWeight: 400,
                  fontSize: "20px",
                  marginLeft: "-10px",
                }}
              >
                {isRiskAssessment
                  ? "List of Risk Assessments"
                  : "List of Assessments"}
              </Typography>
            </Box>
            <FormControl sx={{ width: "100%" }}>
              {/* <FormLabel component="legend">List of Assessments</FormLabel> */}
              {listToRender.map((assessment, index) => {
                const isAlreadyAdded = assessmentCard.some(
                  (card) => card.assessment_id === assessment.assessment_id
                );
                return (
                  <Box
                    key={index}
                    sx={{
                      border: isAlreadyAdded
                        ? "1px solid #2799894D"
                        : "1px solid #e0e0e0",
                      borderRadius: "6px",
                      padding: "4px 10px",
                      marginBottom: "20px",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "#7f7f7f",
                          },
                        }}
                        control={
                          <Checkbox
                            onChange={(event) =>
                              handleCheckBoxChange(event, index)
                            }
                            name={assessment.assessment_name}
                            disabled={isAlreadyAdded}
                            checked={isAlreadyAdded || finalCheckedForm.careplan.some((item) => item.assessment_id === assessment.assessment_id)}
                          />
                        }
                        label={assessment.assessment_name}
                      />
                    </FormGroup>
                  </Box>
                );
              })}
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Distribute buttons to opposite sides
                gap: 2,
                width: "100%",
                marginTop: "25px",
              }}
            >
              <Button
                variant="customcancel"
                onClick={() => setFinalCheckedForm({ careplan: [] })}
              >
                Clear
              </Button>

              <Button
                // type="submit"
                variant="customsave"
                onClick={() => {
                  postAudit();
                  // setFetchRefreshVariable((prevState) => !prevState);
                  // handleDialog();
                }}
              >
                Add
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      {/* <ConfirmationDialog
        openDialog={openConfirmationDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleClose}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleClose}
      /> */}
    </ThemeProvider>
  );
}
