import { Box, Menu, MenuItem, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "../../clients/styles/useStyles";
import UserTableView from "../../../assets/images/userTableIcons/UserTableView.svg";
import UserTableViewHover from "../../../assets/images/userTableIcons/UserTableViewHover.svg";
import hoverDeleteBilling from "../../../assets/images/userTableIcons/hoverdelete.png";
import deleteBilling from "../../../assets/images/userTableIcons/viewdelete.png";
import hoverDownload from "../../../assets/images/userTableIcons/hoverdownload.png";
import viewDownload from "../../../assets/images/userTableIcons/viewdownload.png";
import { Status3 } from "../../users/userTable/Components";
import ClientInvoiceView from "./ClientInvoiceView";
import CustomCalendar from "../../logs/CustomCalendar";
import dayjs from "dayjs";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import CareboxLogo from "../../../assets/images/common/agencyLogo.png";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import DeleteConfirmationIcon from "../../../assets/images/roster/deleteInvoice.svg";
const paginationModel = { page: 0, pageSize: 20 };
const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "26px",
        height: "26px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};
function ClientInVoices() {
  const [billingData, setBillingData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedToView, setSelectedToView] = useState([]);
  const [selectedToDownload, setSelectedToDownload] = useState([]);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [modalOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [filteredData, setFilteredData] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportType, setExportType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const classes = useStyles();
  const fetchUsersAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getClientInvoice`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setBillingData(data.data);
      } else {
        console.error("API call failed");
        setBillingData([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  const filterBillingData = () => {
    const filtered = billingData?.filter((billing) => {
      const minVisitDate = new Date(billing?.minVisitDate);
      const maxVisitDate = new Date(billing?.maxVisitDate);
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Check if the date ranges overlap
      return minVisitDate <= end && maxVisitDate >= start;
    });

    setFilteredData(filtered);
  };
  useEffect(() => {
    fetchUsersAPI();
  }, []);
  useEffect(() => {
    filterBillingData();
  }, [billingData, startDate, endDate]);
  const rows = filteredData?.map((billing, index) => ({
    id: index,
    invoiceNo: billing?.invoiceNo ? billing?.invoiceNo : "-",
    clientName: billing?.clientName ? billing?.clientName : "-",
    //dayjs(DueDate).format("DD-MM-YYYY")
    minVisitDate: `${billing?.minVisitDate} - ${billing?.maxVisitDate}`,
    invoiceCreatedDate: billing?.invoiceCreatedDate,
    invoiceDueDate: billing?.invoiceDueDate,
    totalActualTime: billing?.totalPlannedTimeUserCount ? billing?.totalPlannedTimeUserCount : "-",
    totalActualTimePay: billing?.totalPlannedTimePay ? `£ ${billing?.totalPlannedTimePay}`: "-",
    billingStatus: billing?.billingStatus ? billing?.billingStatus : "-",
    action: null,
  }));
  const handleViewClick = async (row) => {
    console.log(row.invoiceNo);
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/getInvoiceDetails?invoice_no=${row.invoiceNo}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setSelectedToView(data.data);
        setModalIsOpen(true);
      } else {
        console.error("API call failed");
        setSelectedToView([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
    
    console.log("handleview", selectedToView);
  };
  // useEffect(() => {
  //   if (setSelectedToView.length > 0) {
  //     downloadPDF();
  //   }
  // }, [selectedToDownload]);
  // const handleDownload = async (event ,row) => {
  //   setAnchorEl(event.currentTarget);
  //   try {
  //     const token = getCookie();
  //     const URL = `https://3.8.158.142:8443/api/getInvoiceDetails?invoice_no=${row.invoiceNo}`;
  //     const options = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     const response = await fetch(URL, options);
  //     const data = await response.json();

  //     if (response.ok) {
  //       setSelectedToDownload(data.data);
  //       // setSelectedToDownload(row);
  //     } else {
  //       console.error("API call failed");
  //       setSelectedToDownload([]);
  //     }
  //   } catch (e) {
  //     console.error("Error fetching data:", e.message);
  //   }
  //   // downloadPDF();

  //   console.log("download", selectedToDownload);
  // };
  const handleDownload = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleExport = async (type) => {
    setExportType(type);
    handleClose();
  
    if (!selectedRow) return;
  
    try {
      const token = getCookie();
      const URL = `https://3.8.158.142:8443/api/getInvoiceDetails?invoice_no=${selectedRow.invoiceNo}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await fetch(URL, options);
      const data = await response.json();
  
      if (response.ok) {
        setSelectedToDownload(data.data);
      } else {
        console.error("API call failed");
        setSelectedToDownload([]);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  
  // Trigger export when data is ready
  useEffect(() => {
    if (selectedToDownload.length > 0) {
      if (exportType === "pdf") {
        downloadPDF();
      } else if (exportType === "excel") {
        exportClientsExcel();
      }
    }
  }, [selectedToDownload, exportType]);

  const handleDelete = (row) => {
    console.log("delete");
    setConfirmationDialogOpen(true);
    // confirmDeleteInvoice(row.invoiceNo);
    setSelectedToDelete(row?.invoiceNo);
  };
  const confirmDeleteInvoice = async () => {
    console.log(selectedToDelete);
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/deleteInvoice?invoice_no=${selectedToDelete}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        fetchUsersAPI();
        handleDeleteConfirmationClose();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteConfirmationClose = () => setConfirmationDialogOpen(false);
  const adjustedColumns = [
    {
      field: "invoiceNo",
      headerName: "INV-ID",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 110,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      headerAlign: "center",
      align: "center",
      flex: 1.6,
      minWidth: 110,
    },
    {
      field: "minVisitDate",
      headerName: "Date Range",
      headerAlign: "center",
      align: "center",
      flex: 1.9,
      minWidth: 140,
      valueGetter: (params) => {
        console.log("params minVisitDate", params);
        const dateRange = params;
        if (!dateRange) return "-";
      
        // Extract minVisitDate and maxVisitDate
        const [minVisitDate, maxVisitDate] = dateRange.split(" - ");
      
        // Format both dates
        const formattedMinDate = dayjs(minVisitDate).format("DD-MMM-YYYY");
        const formattedMaxDate = dayjs(maxVisitDate).format("DD-MMM-YYYY");
      
        return `${formattedMinDate} - ${formattedMaxDate}`;
      },
      sortComparator: (v1, v2, paramOrder) => {
        const date1 = dayjs(v1.split(" - ")[0], "DD-MMM-YYYY"); // Extract first date
        const date2 = dayjs(v2.split(" - ")[0], "DD-MMM-YYYY"); // Extract first date
      
        if (!date1.isValid()) return 1;
        if (!date2.isValid()) return -1;
      
        return paramOrder === "asc"
          ? date2.unix() - date1.unix()
          : date1.unix() - date2.unix();
      },
      
      
    },
    {
      field: "invoiceCreatedDate",
      headerName: "Created On",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
        valueGetter: (params) => {
                          const invoiceCreatedDate = params;
                          if (!invoiceCreatedDate) return "-";
                    
                          return dayjs(invoiceCreatedDate).format("DD-MMM-YYYY");
                          
                        },
        sortComparator: (v1, v2, paramOrder) => {
                          const date1 = dayjs(v1, "DD-MMM-YYYY");
                          const date2 = dayjs(v2, "DD-MMM-YYYY");
                    
                          if (!date1.isValid()) return 1;
                          if (!date2.isValid()) return -1;
                    
                          return paramOrder === "asc"
                            ? date2.unix() - date1.unix()
                            : date1.unix() - date2.unix();
                        },
    },
    {
      field: "invoiceDueDate",
      headerName: "Due Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
        valueGetter: (params) => {
                          const invoiceDueDate = params;
                          if (!invoiceDueDate) return "-";
                    
                          return dayjs(invoiceDueDate).format("DD-MMM-YYYY");
                          
                        },
        sortComparator: (v1, v2, paramOrder) => {
                          const date1 = dayjs(v1, "DD-MMM-YYYY");
                          const date2 = dayjs(v2, "DD-MMM-YYYY");
                    
                          if (!date1.isValid()) return 1;
                          if (!date2.isValid()) return -1;
                    
                          return paramOrder === "asc"
                            ? date2.unix() - date1.unix()
                            : date1.unix() - date2.unix();
                        },
    },
    {
      field: "totalActualTime",
      headerName: "Total Hours",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "totalActualTimePay",
      headerName: "Total Pay",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
    },

    {
      field: "billingStatus",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => {
        // Convert value to lowercase for case-insensitive comparison
        const statusValue = params.value === "Invoiced" ? 1 : 0;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start", // Align content to the top
              justifyContent: "center",
              height: "100%", // Ensure it takes the full height of the cell
              padding: "0 4px",
            }}
          >
            <Status3 status={statusValue}></Status3>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Box onClick={() => handleViewClick(params?.row)}>
            <HoverImg
              imgBeforeHover={UserTableView}
              imgAfterHover={UserTableViewHover}
            />
          </Box>
          <Box onClick={(event) => handleDownload(event,params?.row)}>
            <HoverImg
              imgBeforeHover={viewDownload}
              imgAfterHover={hoverDownload}
            />
          </Box>
          <Box onClick={() => handleDelete(params?.row)}>
            <HoverImg
              imgBeforeHover={deleteBilling}
              imgAfterHover={hoverDeleteBilling}
            />
          </Box>
        </Box>
      ),
    },
  ];
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const calculateTimeDifference = (startTime, endTime) => {
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const [endHour, endMinute] = endTime?.split(":").map(Number);

    const startDate = new Date();
    const endDate = new Date();

    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);

    const diff = Math.abs(endDate - startDate); // Difference in milliseconds
    const hours = Math.floor(diff / (1000 * 60 * 60)); // Convert to hours
    const minutes = Math.floor((diff / (1000 * 60)) % 60); // Convert to minutes

    return `${hours.toString().padStart(2, "0")} hrs ${minutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  const calculateTotalTime = (data) => {
    let totalMinutes = 0;
  
    data.forEach((item) => {
      if (item.totalPlannedTimeUserCount) {
        const [hours, minutes] = item.totalPlannedTimeUserCount.split(":").map(Number);
        totalMinutes += hours * 60 + minutes; // Convert hours to minutes and add to total
      }
    });
  
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
  
    return `${totalHours.toString().padStart(2, "0")} hrs ${remainingMinutes
      .toString()
      .padStart(2, "0")} mins`;
  };
  const minDate = selectedToDownload[0]?.minVisitDate; // Likely a string or Date object
  const maxDate = selectedToDownload[0]?.maxVisitDate;
  const inVoiceDate = selectedToDownload[0]?.invoiceCreatedDate;
  const DueDate = selectedToDownload[0]?.invoiceDueDate;
  const finalMinDate = minDate ? dayjs(minDate).format("DD-MM-YYYY") : null;
  const finalMaxDate = maxDate ? dayjs(maxDate).format("DD-MM-YYYY") : null;
  const finalinVoiceDate = maxDate
    ? dayjs(inVoiceDate).format("DD-MM-YYYY")
    : null;
  const finalDueDate = maxDate ? dayjs(DueDate).format("DD-MM-YYYY") : null;
  const downloadPDF = () => {
    const doc = new jsPDF();
    // Adding the Logo
    doc.addImage(CareboxLogo, "JPEG", 5, 10, 50, 20);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice :", 10, 42);
    doc.setFont("helvetica", "normal");
    doc.text("Lancashire Council ", 10, 50);
    doc.text("11 Business center,", 10, 58);
    doc.text("Ribbleton Lane", 10, 65);
    doc.text("Preston PR15EZ", 10, 72);
    doc.setFont("helvetica", "bold");
    doc.text("PA Number : PA 123456", 10, 80);
    doc.text("PA Number : PA 123456", 10, 88);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toISOString().split("T")[0];
    const pdfdate = dayjs(today)?.format("DD-MM-YYYY");
    doc.text(`Date: ${pdfdate}`, 10, 100);

    // Adjust the x p2osition to move everything to the right
    const invoiceStartX = 110; // Increased x value to move invoice boxes to the right
    const invoiceStartY = 27; // Keep the same gap as befor

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(42);
    doc.text("Invoice", invoiceStartX, invoiceStartY);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold"); // Use bold font
    doc.text(
      `Invoice : ${selectedToDownload[0]?.invoiceNo}`,
      invoiceStartX + 15,
      invoiceStartY + 17
    );
    doc.text(
      `Issue Date : ${finalinVoiceDate}`,
      invoiceStartX + 15,
      invoiceStartY + 26
    );
    doc.text(
      `Invoice Due Date : ${finalDueDate}`,
      invoiceStartX + 15,
      invoiceStartY + 35
    );
    doc.setTextColor(255, 255, 255);
    const total = `£ ${selectedToDownload?.reduce(
      (sum, item) => sum + item.totalPlannedTimePay,
      0
    )}`;
    const totaltext = "Total Due :";
    const addressBoxWidth = 72;
    const addressBoxHeight = 12;
    function drawRoundedRect(doc, x, y, width, height, radius) {
      doc.roundedRect(x, y, width, height, radius, radius, "F");
    }
    doc.setFillColor(39, 153, 137); // Light grey background
    drawRoundedRect(
      doc,
      5 + addressBoxWidth + 48,
      invoiceStartY + 45,
      addressBoxWidth,
      addressBoxHeight,
      3
    );
    doc.text(totaltext, invoiceStartX + 18, invoiceStartY + 53);
    doc.getFontSize(30);
    doc.text(total, invoiceStartX + 50, invoiceStartY + 53);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Invoice from ${finalMinDate} to ${finalMaxDate}`,
      invoiceStartX,
      invoiceStartY + 73
    );

    // Adding Table
    doc.setFontSize(12);
    const headers = [
      "VisitDate",
      "Session",
      "Planned Time",
      "Users",
      "Hours Rate",
      "Total Pay",
    ];
    const rows = selectedToDownload?.map((item) => {
      const timeDifference = calculateTimeDifference(
        item?.plannedStartTime,
        item?.plannedEndTime
      );

      return [
        dayjs(item?.visitDate).format("DD-MM-YYYY"),
        item?.sessionType,
        `${item?.plannedStartTime} - ${item?.plannedEndTime} \n ${timeDifference}`,
        item?.userCount,
        item?.hourlyPay,
        item?.totalPlannedTimePay &&`£ ${item?.totalPlannedTimePay}`,
      ];
    });

    doc.autoTable({
      head: [headers],
      body: rows,
      startY: invoiceStartY + 82, // Adjusted to start after the invoice date and address info
      margin: { left: 5, right: 5 }, // Small gaps from the start and end
      tableWidth: "auto", // Automatically adjust table width to fit within the page width
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: "#279989",
        textColor: "#FFFFFF",
        cellPadding: { top: 4, right: 1.5, bottom: 4, left: 1.5 },
      }, // Centering the header cells
      bodyStyles: {
        halign: "center",
        valign: "middle",
        cellPadding: { top: 4, right: 5, bottom: 4, left: 5 },
      }, // Centering the body cells
      alternateRowStyles: { fillColor: "#EEFEFA" },
    });
    // Adding the total row at the end
    doc.setTextColor(0, 0, 0);
    doc.text(
      ` Total : ${calculateTotalTime(selectedToDownload)}`,
      89,
      doc.lastAutoTable.finalY + 12
    ); // Position (x: 60, y: doc.lastAutoTable.finalY + 15)
    doc.text(` Total : ${total}`, 176, doc.lastAutoTable.finalY + 12);

    // Check available space after table
    const startY = doc.lastAutoTable.finalY + 20; // Position after the table with some padding
    const pageHeight = doc.internal.pageSize.height; // Total height of the page
    const marginBottom = 20; // Bottom margin

    // Function to handle adding text and checking page space
    const addTextWithSpaceCheck = (textArray, startX, initialY, lineHeight) => {
      let currentY = initialY;
    
      textArray.forEach((line) => {
        // Check if there is enough space on the current page
        if (currentY + lineHeight > pageHeight) {
          doc.addPage(); // Add a new page
          currentY = marginBottom; // Reset Y to start at the top of the new page
        }
        doc.text(line, startX, currentY); // Add the text line
        currentY += lineHeight; // Move Y position for the next line
      });
    
      return currentY; // Return the last Y position for further use
    };
    
    // Terms and Conditions Section
    doc.setFontSize(18);
    doc.setFont("Lora", "bold");
    
    doc.text("Terms and Conditions:", 10, startY); // Title
    doc.setFontSize(13);
    doc.setTextColor(100, 100, 100);
    
    const termsText = [
      "Payment is due within 15 days from the invoice date.",
      "Payment must be made via Bank transfer to the account details provided on the invoice."
    ];
    
    let nextY = startY + 8; // Start below the title
    termsText.forEach((text) => {
      nextY = addTextWithSpaceCheck(doc.splitTextToSize(text, 140), 10, nextY, 5) + 5; // 5px extra space between texts
    });

    // Payment Info Section
    const paymentStartY = doc.lastAutoTable.finalY + 55; // Update starting Y based on the previous section
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text("Payment Info:", 10, paymentStartY); // Title
    doc.setFontSize(14);
    doc.setTextColor(100, 100, 100);

    const paymentText = [
      "Name : The Home Givers",
      "Account: 89XXX520",
      "Sort Code: 22-XX-69",
      "Branch: Lloyds Bank",
    ];
    addTextWithSpaceCheck(paymentText, 10, paymentStartY + 10, 8);

    // Final Section
    const finalSectionStartY = paymentStartY + 48; // Adjust starting Y for the final section
    const lineHeight = 10; // Define the line height for the text lines

    // Define the final section texts with their relative positions
    const finalSectionTexts = [
      { text: "Thank you for your business", x: 60 },
      { text: "The Care Givers", x: 80 },
      {
        text: "# The watermark studios 9-15 Ribbleton Lane Preston PR15EZ",
        x: 30,
      },
    ];

    // Initialize the Y position
    let currentY = finalSectionStartY;

    // Loop through each text and check for space
    finalSectionTexts.forEach((content, index) => {
      // Check if the current Y position exceeds the page height
      if (currentY + marginBottom > pageHeight) {
        doc.addPage(); // Add a new page
        currentY = marginBottom; // Reset Y for new page
      }

      // Set the font size and color
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);

      // Render the text at the calculated position
      doc.text(content.text, content.x, currentY);

      // Increment Y position for the next line
      currentY += lineHeight;
    });

    // Page Numbers
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }

    // Save the PDF
    doc.save("Invoice.pdf");
  };
  console.log(selectedToDownload,'selectedToDownload');
 
  const finalDataToDownloadtoExcel = selectedToDownload?.map((item) => ({
    InvoiceNo:item?.invoiceNo,
    ClientName:item?.clientName,
    Date: dayjs(item?.visitDate).format("DD-MMM-YYYY"),
    Session:item?.sessionType,
    PlannedTime:`${item.plannedStartTime} - ${item.plannedEndTime}`,
    Users:item?.userCount,
    HourlyPay:item?.hourlyPay,
    TotalPay:`£ ${item.totalPlannedTimePay}`,
    GrandTotal:`£ ${selectedToDownload.reduce((sum, item) => sum + item.totalPlannedTimePay, 0)}`
  }))
  const exportClientsExcel = () => {
      // Create a new workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(finalDataToDownloadtoExcel);
  
      // Calculate column widths dynamically
      const columnWidths = Object.keys(finalDataToDownloadtoExcel[0]).map((key) => {
        const maxLength = finalDataToDownloadtoExcel.reduce((max, row) => {
          const cellValue = row[key] || ""; // Fallback for empty cells
          return Math.max(max, cellValue.toString().length);
        }, key.length); // Include header length
        return { wch: maxLength + 2 }; // Add padding for better appearance
      });
  
      // Apply column widths to the worksheet
      worksheet["!cols"] = columnWidths;
  
      // Apply center alignment to all cells
      Object.keys(worksheet).forEach((cell) => {
        if (cell[0] !== "!") {
          worksheet[cell].s = {
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
      });
  
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Data");
  
      // Save the Excel file
      const today = new Date().toISOString().split("T")[0];
      const excelFileName = `${'Final invoice of client'}_${today}.xlsx`;
      XLSX.writeFile(workbook, excelFileName);
    
    };

  return (
    <Box sx={{ px: 3, py: 2, width: "100%" }}>
      <Box sx={{ width: "35%" }}>
        <CustomCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
      <Box
        className={classes.tableMainContainer}
        sx={{ height: "calc(100vh - 260px) !important" }}
      >
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 50, 100]}
          // checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
        />
      </Box>
      <Modal open={modalOpen} sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            // transform: "translate(-50%, 15%)",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            width: "794px",
            backgroundColor: "white",
            py: 2,
            pl: 2,
            borderRadius: "8px",
            boxShadow: 24,
            top: 10,
          }}
        >
          <ClientInvoiceView
            selectedToView={selectedToView}
            closeModal={handleCloseModal}
          />
        </Box>
        {/* Close Icon */}
      </Modal>
      <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuItem onClick={() => handleExport("pdf")}>PDF</MenuItem>
      <MenuItem onClick={() => handleExport("excel")}>Excel</MenuItem>
    </Menu>
      {confirmationDialogOpen && (
        <ConfirmationDialog
          openDialog={confirmationDialogOpen}
          TitleText={`Are you sure  you want to Delete the Invoice ${selectedToDelete}.?`}
          paraText="The deleted invoice cannot be retrieved."
          IconUrl={DeleteConfirmationIcon}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleDeleteConfirmationClose()}
          onConfirmFun={confirmDeleteInvoice}
          handleCloseDialog={handleDeleteConfirmationClose}
        />
      )}
    </Box>
  );
}

export default ClientInVoices;
