import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Name1 from "../../reusableComponents/Name1";
import Notes from "../../../assets/icons/Notes.svg";
import Admin from "../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import ActionIcon from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import HoverActionIcon from "../../../assets/images/userTableIcons/UserTableEditHover.svg";
import Todo_Right_Tic_Icon from "../../../assets/images/clients/todoList_Icon/Todo_Right_Tic_Icon.svg";
import Todo_Close_Icon from "../../../assets/images/clients/todoList_Icon/Todo_Close_Icon.svg";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";

export default function LogCarerNotes({ selectedVisit, setHasUnsavedChanges }) {
  const [notesList, setNotesList] = useState([]);
  const [notesListOriginal, setNotesListOriginal] = useState([]);
  const [editableNoteId, setEditableNoteId] = useState(null);

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const Username = `${user.first_name + " " + user.last_name}`;
  const UserId = `${user.id}`;

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const onClickYesDialogBtn = () => {
    setEditableNoteId(null);
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    setOpenDialog(false);
  };
  const handleEdit = (id) => {
    setEditableNoteId((prevId) => (prevId === id ? null : id));
  };
  // Handle saving changes
  const handleSave = (id) => {
    putNotes(id);
    setHasUnsavedChanges(false);
  };

  // Handle canceling changes
  const handleCancel = () => {
    if (!areObjectsEqual(notesList, notesListOriginal)) {
      handleOpen();
    } else {
      setEditableNoteId(null); // Exit edit mode without saving
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };

  const areObjectsEqual = (obj1, obj2) => {
    if (
      !Array.isArray(obj1) ||
      !Array.isArray(obj2) ||
      obj1.length !== obj2.length
    ) {
      return false;
    }

    const notes1 = obj1.map((item) => item.visitNotes).sort();
    const notes2 = obj2.map((item) => item.visitNotes).sort();

    return notes1.every((note, index) => note === notes2[index]);
  };

  const putNotes = async (id) => {
    try {
      const Notesid = id;
      const token = getCookie();
      const VisitNotes =
        notesList.find((item) => item.id === Notesid)?.visitNotes || null;

      const body = {
        visit_notes: VisitNotes,
        updatedby_userid: UserId,
      };

      console.log(body, "put api body................");
      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/update-log-Client-VisitNotes/${Notesid}`;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
        setEditableNoteId(null);
      } else {
        console.log("Failed to edit VisitNotes.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchNotes = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-log-client-visitnotes-details/1517`; //1517   ${selectedVisit.visitdetailsid}

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setNotesList(data.data);
        setNotesListOriginal(data.data);
      } else {
        setNotesList([]);
        setNotesListOriginal([]);
        console.log("API request failed with status:", response.status);
      }
    } catch (e) {
      console.log("Error fetching data:", e.message);
    }
  };

  useEffect(() => {
    fetchNotes();
    setEditableNoteId(null);
  }, [selectedVisit]);
  return (
    <Box
      sx={{
        //margin: "15px 20px",
        width: "100%",
        overflow: "auto",
        height: "100%",
      }}
    >
      {notesList.length > 0 &&
        notesList.map((item) => (
          <Box
            sx={{
              border: "1px solid #279989",
              borderRadius: "8px",
              marginBottom: "45px",
              textAlign: "Left",
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
                padding: "10px 20px 0 10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Notes}
                  alt="CarerNotes Icon"
                  style={{
                    width: "22px",
                    height: "22px",
                    margin: "6px 6px",
                  }} // Adjust size as needed
                />
                <Typography
                  variant="h9"
                  sx={{
                    fontFamily: "Lora",
                    margin: "6px 6px",
                    fontWeight: 500,
                  }}
                >
                  {dayjs(item?.createdAt).format("DD-MM-YYYY [at] HH:mm")}
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  |
                </Typography>
                <Typography
                  variant="h9"
                  sx={{
                    fontFamily: "Lora",
                    margin: "6px 6px",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  Updated by : {item?.createdByUserName}
                </Typography>
              </Box>
              {selectedVisit.visitStatus === "Completed" &&
                (editableNoteId === item.id ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer", // Ensure cursor changes to pointer
                        position: "relative", // Make the box position relative
                        marginRight: "10px",
                        paddingTop: "1px",
                      }}
                      onClick={() => handleSave(item.id)} // Call the click handler
                    >
                      <img
                        src={Todo_Right_Tic_Icon}
                        alt="Todo_Right_Tic_Icon"
                        style={{ width: "100%", height: "100%" }} // Fill the container
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        cursor: "pointer", // Ensure cursor changes to pointer
                        position: "relative", // Make the box position relative
                        marginRight: "5px",
                      }}
                      onClick={() => handleCancel()} // Call the click handler
                    >
                      <img
                        src={Todo_Close_Icon}
                        alt="Todo_Close_Icon"
                        style={{ width: "100%", height: "100%" }} // Fill the container
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer", // Ensure cursor changes to pointer
                      "&:hover img": {
                        content: `url(${HoverActionIcon})`, // Change the image on hover
                      },
                    }}
                    onClick={() => handleEdit(item.id)} // Call the click handler
                  >
                    <img
                      src={ActionIcon}
                      alt="Action Icon"
                      style={{ width: "100%", height: "100%" }} // Fill the container
                    />
                  </Box>
                ))}
            </Box>
            {/* Note text area */}
            <Box sx={{ margin: "0 20px" }}>
              <Name1
                className="multiline-textfield"
                preFilledvalue={item.visitNotes} // Show the current note value
                onValueChange={(value) => {
                  setNotesList((prevNotes) => {
                    const updatedNotes = prevNotes.map((items) =>
                      items.id === item.id
                        ? { ...items, visitNotes: value }
                        : items
                    );

                    setHasUnsavedChanges(
                      !areObjectsEqual(updatedNotes, notesList)
                    );

                    return updatedNotes;
                  });
                }} // Handle change
                multiline
                nolabel
                rows="4"
                sx={{
                  marginTop: "0px",
                }} // Adjust margin if needed
                disabled={editableNoteId !== item.id} // Keep it disabled when not editing
              />{" "}
            </Box>
          </Box>
        ))}
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
    </Box>
  );
}
