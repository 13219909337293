import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import { ViewTheme } from "../../users/Theme";
import { Calendar, Name, RadioInput } from "../../reusableComponents";
import dayjs from "dayjs";

const AuditView = ({
  auditName,
  selectedAuditCard,
  auditResponseForm,
  setAuditResponseForm,
}) => {
  return (
    <ThemeProvider theme={ViewTheme}>
      <Box
        sx={{
          textAlign: "Left",
          height: "100%",
          overflow: "auto",
          paddingRight: 2,
          paddingLeft: 1,
          width: "100%",
          boxShadow: "0px 0px 19px 7px #",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <FormLabel>
            <Typography variant="h6">
              Method of Audit being carried out?
            </Typography>
          </FormLabel>
          <RadioGroup
            // name={name}
            // onChange={handleChange}
            value={auditResponseForm[0]?.method_audit || ""}
            sx={{ marginTop: "8px" }}
          >
            {/* {options.map((option, index) => ( */}
            <FormControlLabel
              // key={index}
              value="Document Review"
              control={<Radio disabled />}
              label="Document Review"
            />
            <FormControlLabel
              // key={index}
              value="Spot Checks/Unannounced Visits"
              control={<Radio disabled />}
              label="Spot Checks/Unannounced Visits"
            />
            <FormControlLabel
              // key={index}
              value="Interview with Client/Next of Kin"
              control={<Radio disabled />}
              label="Interview with Client/Next of Kin"
            />
            <FormControlLabel
              // key={index}
              value="Care Plan Reviews"
              control={<Radio disabled />}
              label="Care Plan Reviews"
            />
            <FormControlLabel
              // key={index}
              value="Outcome-Based Audits"
              control={<Radio disabled />}
              label="Outcome-Based Audits"
            />
            <FormControlLabel
              // key={index}
              value="Focus Group Discussions"
              control={<Radio disabled />}
              label="Focus Group Discussions"
            />

            {/* ))} */}
          </RadioGroup>
          {/* {errorMessage && (
                   <Typography variant="errmsg" sx={{ marginTop: "2px" }}>
                     {errorMessage}
                   </Typography>
                 )} */}
        </Box>
        <Box>
          {selectedAuditCard?.questions?.map((question, index) => (
            <>
              <Typography
                variant="h6"
                sx={{
                  marginTop: "4px",
                }}
              >
                {question?.ques}
              </Typography>
              <Container>
                <Name
                  name={question.ques_name}
                  className="multiline-textfield"
                  label="Please Mention"
                  placeholder={question?.placeholder}
                  multiline
                  rows="8"
                  // onValueChange={handleValueChange}
                  preFilledvalue={auditResponseForm[0]?.[question.ques_name]}
                  disabled
                ></Name>
              </Container>
            </>
          ))}
          <Typography variant="h4">Action Plan</Typography>

          {selectedAuditCard?.action_plan?.length > 0 ? (
            selectedAuditCard.action_plan.map((action, index) => (
              <Box key={`action_${index}`}>
                <Container>
                  {selectedAuditCard.audit_name ===
                    "Care Plan & Documentation Audit" && index === 0 ? (
                    <>
                      <RadioInput
                        name="gaps_identified"
                        label={action.action}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        preFilledvalue={
                          auditResponseForm[0]?.[action.action_name]
                        }
                        disabled
                      />
                      {auditResponseForm[0]?.gaps_identified === "Yes" && (
                        <>
                          <Typography variant="h6" sx={{ marginTop: "4px" }}>
                            What actions are being planned?
                          </Typography>
                          <Name
                            name="gaps_identified_actions"
                            className="multiline-textfield"
                            label="Please Mention"
                            multiline
                            rows="5"
                            preFilledvalue={
                              auditResponseForm[0]?.gaps_identified_actions
                            }
                            disabled
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="h6" sx={{ marginTop: "4px" }}>
                        {action.action}
                      </Typography>
                      <Name
                        name={action.action_name}
                        className="multiline-textfield"
                        label="Please Mention"
                        multiline
                        rows="5"
                        preFilledvalue={
                          auditResponseForm[0]?.[action.action_name]
                        }
                        disabled
                      />
                    </>
                  )}
                </Container>
              </Box>
            ))
          ) : (
            <Typography>No Data Available</Typography>
          )}
        </Box>

        <Box>
          <Grid
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
            }}
          >
            <Grid item xs={10} md={5}>
              <Name
                name="audited_on"
                label="Audited on"
                placeholder="Audited on"
                preFilledvalue={dayjs(auditResponseForm[0]?.audited_on).format(
                  "DD-MM-YYYY"
                )}
                disabled
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Calendar
                name="due_on"
                label="Due on"
                //   onDateChange={handleDateChange}
                preFilledvalue={auditResponseForm[0]?.due_on}
                disabled
              />
            </Grid>
          </Grid>
          <FormLabel sx={{ fontSize: 18, paddingTop: "20px" }}>
            Signatures of all involved persons in assessment Required
          </FormLabel>
          <Grid // First signature
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
              mb: -2,
            }}
          >
            <Grid item xs={10} md={5}>
              <Name
                name="sign_1"
                label="Name"
                preFilledvalue={auditResponseForm[0]?.sign_1}
                placeholder="Enter your Name"
                disabled={true} // Always disabled
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Calendar
                name="date_1"
                label="Date"
                preFilledvalue={auditResponseForm[0]?.sign_date1}
                // onDateChange={handleDateChange}
                // preFilledvalue={
                //   initialForm[0]?.date_1 ? initialForm[0]?.date_1 : dayjs() // Prefill with date_1 or current date
                // }
                // disabled={
                //   initialForm[0]?.sign_1 && initialForm[0]?.sign_1 !== signName
                //     ? true
                //     : false
                // } // Disable if date_1 exists
                disabled
              />
            </Grid>
          </Grid>

          {auditResponseForm[0]?.sign_2 !== "" &&
          auditResponseForm[0]?.sign_1 !== auditResponseForm[0]?.sign_2 ? (
            <Grid //Second signature
              container
              direction="row"
              alignItems="top"
              sx={{
                gap: 8,
                p: 2,
                borderRadius: "8px",
                backgroundColor: "#F0FCFA",
              }}
            >
              <Grid item xs={10} lg={4}>
                <Name
                  name="sign_2"
                  label="Name"
                  placeholder="Name"
                  preFilledvalue={auditResponseForm[0]?.sign_2}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={10} lg={4}>
                <Calendar
                  label="Date"
                  name="date_2"
                  // onDateChange={handleDateChange}
                  preFilledvalue={auditResponseForm[0]?.sign_date2}
                  disabled={true}
                />
              </Grid>
            </Grid>
          ) : null}

          {/* <Grid>
            <Grid item>
              <FormControlLabel
                label={
                  "I hereby confirm the accuracy of the information provided above"
                }
                control={
                  <Checkbox
                    checked={
                      // fetchCheckVariable &&
                      initialForm[0]?.consent === 0 ? false : true
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={(e) => {
                      //   handleCheckboxChange(e);
                    }}
                  />
                }
              />
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AuditView;
