import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/utils";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "../../clients/styles/useStyles";
import "jspdf-autotable";
import Loader from "../Loader";
import dayjs from "dayjs";
import Download from "../../billings/Download";
import ManageColumns from "../ManageColumns";
const columns = [
  {
    field: "clientName",
    headerName: "Client Name",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "contactNumber",
    headerName: "Contact Number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Email",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "gender",
    headerName: "Gender",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dob",
    headerName: "Date of Birth",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const dob = params;
      if (!dob) return "-";

      return dayjs(dob).format("DD-MMM-YYYY");
    },
    sortComparator: (v1, v2, paramOrder) => {
      const date1 = dayjs(v1, "DD-MMM-YYYY");
      const date2 = dayjs(v2, "DD-MMM-YYYY");

      if (!date1.isValid()) return 1;
      if (!date2.isValid()) return -1;

      return paramOrder === "asc"
        ? date2.unix() - date1.unix()
        : date1.unix() - date2.unix();
    },
  },
  { field: "age", headerName: "Age", headerAlign: "center", align: "center" },
  {
    field: "serviceStartDate",
    headerName: "ServiceStartDate",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const serviceStartDate = params;
      if (!serviceStartDate) return "-";

      return dayjs(serviceStartDate).format("DD-MMM-YYYY");
    },
    sortComparator: (v1, v2, paramOrder) => {
      const date1 = dayjs(v1, "DD-MMM-YYYY");
      const date2 = dayjs(v2, "DD-MMM-YYYY");

      if (!date1.isValid()) return 1;
      if (!date2.isValid()) return -1;

      return paramOrder === "asc"
        ? date2.unix() - date1.unix()
        : date1.unix() - date2.unix();
    },
  },
  {
    field: "serviceEndDate",
    headerName: "ServiceEndDate",
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      const serviceEndDate = params;
      if (!serviceEndDate) return "-";

      return dayjs(serviceEndDate).format("DD-MMM-YYYY");
    },
    sortComparator: (v1, v2, paramOrder) => {
      const date1 = dayjs(v1, "DD-MMM-YYYY");
      const date2 = dayjs(v2, "DD-MMM-YYYY");

      if (!date1.isValid()) return 1;
      if (!date2.isValid()) return -1;

      return paramOrder === "asc"
        ? date2.unix() - date1.unix()
        : date1.unix() - date2.unix();
    },
  },
  {
    field: "riskLevel",
    headerName: "RiskLevel",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "NHSNo",
    headerName: "NHSNo",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "address",
    headerName: "Address",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "reason",
    headerName: "Reason",
    headerAlign: "center",
    align: "center",
  },
];
const paginationModel = { page: 0, pageSize: 20 };
function ClientsOverview() {
  const [clientReports, setClientReports] = React.useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const hiddenColumns = [
    "NHSNo",
    "serviceStartDate",
    "serviceEndDate",
    "reason",
  ];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columns.reduce(
      (acc, col) => ({
        ...acc,
        [col.field]: !hiddenColumns.includes(col.field), // Set to true if not in hiddenColumns
      }),
      {}
    )
  );
  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };
  const classes = useStyles();
  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/getclientoverview`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        // setmedicationroute(transformedData);

        if (response.ok === true) {
          setClientReports(data.data);
        } else {
          console.log("get all failed");
          setClientReports([]);
        }
      } catch (e) {
        console.log("error", e.message);
      } finally {
        setLoading(false); // Ensure loading is turned off regardless of success or failure
      }
    };
    fetchUsersAPI();
  }, []);
  const rows = clientReports?.map((item, index) => ({
    id: item?.id,
    clientName: item?.clientName,
    status: item?.status,
    contactNumber: item?.contactNumber,
    email: item?.email,
    gender: item?.gender,
    dob: item?.dob,
    age: item?.age,
    serviceStartDate: item?.serviceStartDate,
    serviceEndDate: item?.serviceEndDate,
    riskLevel: item?.riskLevel,
    NHSNo: item?.NHSNo,
    address: item?.address,
    reason: item?.reason,
  }));
  const adjustedColumns = columns?.map((col) => {
    const isAddressColumn =
      col.field === "address" ||
      col.field === "clientName" ||
      col.field === "email"; // Check if it's the `address` column

    return {
      ...col,
      flex: isAddressColumn ? 3 : 1,
      minWidth: isAddressColumn ? 200 : 120, // Set a minimum width to avoid extremely narrow columns
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "100%", // Take full cell height
            paddingTop: "13px",
            paddingBottom: "13px",
            overflow: "hidden", // Prevent content overflow
            whiteSpace: "normal", // Allow text to wrap
            wordBreak: "break-word", // Break long words if necessary
            lineHeight: 1.2,
          }}
        >
          {params.value}
        </div>
      ),
    };
  });
  const clientColumns = [
    "ClientName",
    "Status",
    "ContactNo",
    "Gender",
    " DOB",
    "Age",
    // "ServiceSD",
    // "ServiceED",
    "RiskLevel",
    "NHSNo",
    "Address",
  ];

  const finalClients = rows?.filter((u) => rowSelectionModel?.includes(u.id));
  const body = finalClients?.map((d) => {
    return [
      d.clientName,
      d.status,
      d.contactNumber,
      d.gender,
      d.dob && dayjs(d.dob).format("DD-MMM-YYYY") || "-",
      d.age,
      // d.serviceStartDate,
      // d.serviceEndDate,
      d.riskLevel,
      d.NHSNo,
      d.address,
    ];
  });
  const updatedFinalClients = finalClients?.map(
    ({
      clientName,
      status,
      contactNumber,
      gender,
      dob,
      age,
      serviceStartDate,
      serviceEndDate,
      riskLevel,
      NHSNo,
      address,
    }) => ({
      ClientName: clientName,
      Status: status,
      ContactNumber: contactNumber,
      Gender: gender,
      DateOfBirth: dob && dayjs(dob).format("DD-MMM-YYYY") || "-",
      Age: age,
      ServiceStartDate: serviceStartDate && dayjs(serviceStartDate).format("DD-MMM-YYYY") || "-",
      ServiceEndDate: serviceEndDate && dayjs(serviceEndDate).format("DD-MMM-YYYY") || "-",
      RiskLevel: riskLevel,
      NHSNo: NHSNo,
      Address: address,
    })
  );
  return (
    <Box sx={{ px: 3, pt: 4 }} className="custom-datagrid-container">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pb: 3,
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ p: 0, m: 0, color: "#279989" }}>
          Clients Reports By Clients Overview
        </Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Download
            data={body}
            columns={clientColumns}
            selectToDownload={rowSelectionModel}
            heading="Client Overview Reports"
            rotateMode={true}
            excelData={updatedFinalClients}
          />
          <ManageColumns
            columns={columns}
            hiddenColumns={hiddenColumns}
            initialSelectedColumns={columns
              .filter((col) => columnVisibilityModel[col.field])
              .map((col) => col.headerName)}
            onVisibilityChange={handleColumnVisibilityChange}
          />
        </Box>
      </Box>
      <Box className={classes.tableMainContainer}>
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          className={classes.datagridmain}
          getRowHeight={() => "auto"}
          loading={loading} 
          slots={{
            loadingOverlay: Loader, // Displays Loader inside table
          }}
        />
      </Box>
    </Box>
  );
}

export default ClientsOverview;
