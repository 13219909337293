import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  Button,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { getCookie } from "../../../utils/utils";
import Send_invite from "../../../assets/images/users/Send_Invite.svg";
import Admin from "../../../assets/images/users/Admin.svg";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../Theme";

export default function UserAccessibility({ userId, toggleEdit, toastMsg }) {
  const [radioValue, setRadioValue] = useState(""); // State for radio buttons
  const [role, setRole] = useState("");

  const handleRadioChange = (event) => setRadioValue(event.target.value);

  useEffect(() => {
    const fetchKin = async () => {
      if (!userId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/user/${userId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setRadioValue(info.admin === 1 ? "yes" : "no");
          setRole(info.role);
        } else {
          setRole("");
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchKin();
  }, [userId]);

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%", // Ensures the Container spans full width
        }}
        maxWidth={false} // Disables the default maximum width
      >
        {/* <UserHeader toggleEdit={toggleEdit} head={head} mode={mode} /> */}

        <Box sx={{ width: "98%", padding: 0 }}>
          <Card
            variant="elevated"
            elevation={0}
            sx={{ width: "100%", padding: 0 }}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} md={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    marginLeft: "20px",
                    height: "100px",
                    justifyContent: { xs: "center", md: "left" },
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    src={Send_invite}
                    alt="Send invitation email"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  textAlign="left"
                  sx={{ padding: ViewTheme.spacing(3), width: "100%" }}
                >
                  <Typography variant="h3">Send Invitation Email</Typography>
                  <Typography variant="body2">
                    The User will receive SMS and an Email encouraging them to
                    download the Mobile Application and Login instructions for
                    both the Mobile & Web Applications.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    padding: ViewTheme.spacing(0, 4),
                    width: "100%",
                    textAlign: { xs: "center", md: "Right" },
                  }}
                >
                  <Button variant="contained" color="primary">
                    Send Invite
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>

        <Box sx={{ width: "98%", mt: 2, padding: 0 }}>
          <Card
            variant="elevated"
            elevation={0}
            sx={{ width: "100%", padding: 0 }}
          >
            <Grid container alignItems="Top">
              <Grid item xs={12} md={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    marginLeft: "20px",
                    height: "100px",
                    justifyContent: { xs: "center", md: "left" },
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    src={Admin}
                    alt="Admin"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box
                  textAlign="left"
                  sx={{ padding: ViewTheme.spacing(3), width: "100%" }}
                >
                  <FormControl sx={{ padding: "5px 20px", width: "100%" }}>
                    <FormLabel sx={{ fontSize: "20px" }}>Admin</FormLabel>
                    <RadioGroup
                      row
                      sx={{ padding: 0, marginTop: "5px" }}
                      value={radioValue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio disabled />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio disabled />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  {radioValue === "yes" && (
                    <Typography
                      variant="body2"
                      sx={{ marginTop: "0", paddingTop: "0" }}
                    >
                      <span style={{ color: "#88878A", fontSize: "15px" }}>
                        Role:
                      </span>{" "}
                      {role}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
