import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Avatar,
  Grid,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie, slicedText } from "../../../utils/utils";

import ImageCrop from "../../common/ImageCrop";

import { useState, useRef, useEffect } from "react";

import ProfilePhotoEdit from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEdit.svg";
import ProfilePhotoAddPhoto from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhoto.svg";
import ProfilePhotoSave from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSave.svg";
import ProfilePhotoDelete from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDelete.svg";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";

import ProfilePhotoEditHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEditHover.svg";
import ProfilePhotoAddPhotoHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhotoHover.svg";
import ProfilePhotoSaveHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSaveHover.svg";
import ProfilePhotoDeleteHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDeleteHover.svg";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";

export const TopSection = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "20px auto",
      }}
    >
      {props.children}
    </Box>
  );
};

export const TabButton = ({ data, isActive, onClick = () => {} }) => {
  return (
    <Button
      onClick={onClick}
      key={data.id}
      variant="customtab"
      size="small"
      sx={{
        backgroundColor: isActive ? "#279989" : "#DFF1F8",
        color: isActive ? "#fff" : "#1E303799",
        fontSize: "15px",
        // boxShadow: "none",
        // padding: "6px 10px",
        // minWidth: "120px",
        // borderRadius: "8px 8px 0px 0px",
        position: "relative",
        // margin: "0px 10px",
        // "&:hover": {
        //   color: "#fff",
        // },
      }}
    >
      {data.text}
      <span
        style={{
          position: "absolute",
          left: "45%",

          bottom: "-18%",

          width: 0,
          height: 0,
          //paddingTop: "px",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: isActive ? "6px solid #279989" : "6px solid transparent",
        }}
      ></span>
    </Button>
  );
};

export const UserNameWithArrow = ({ eachUser, isActiveUser, onClick }) => {
  return (
    <Typography
      onClick={onClick}
      key={eachUser.id}
      variant="body0"
      sx={{
        fontSize: "15px",
        fontWeight: isActiveUser ? 600 : 400,
        color: isActiveUser ? "#279989" : "#1E3037",
        margin: "7.5px 0px",
        display: "flex",
        alignItems: "center",
        padding: "0px",
        "&:hover": {
          fontWeight: 600,
          color: "#279989",
          cursor: "pointer",
        },
      }}
    >
      <ChevronRightIcon
        sx={{
          color: "#279989",

          width: "20px",
        }}
      />
      {/* {eachUser.name} */}
      {slicedText(`${eachUser.first_name} ${eachUser.last_name}`)}
    </Typography>
  );
};

export const SearchBox = ({ updateSetValue, searchValue }) => {
  return (
    <TextField
      value={searchValue}
      onChange={(e) => updateSetValue(e.target.value)}
      size="small"
      placeholder="Search by user name"
      type="search"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={{ color: "#269988", width: "18px", marginLeft: "10px" }}
            />
          </InputAdornment>
        ),
      }}
      sx={{
        width: "100%",
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
        margin: "0px",
        // margin:"auto",  // centering the search bar
        // marginRight: "auto", // for left align of search bar
      }}
    />
  );
};

export const UserListBox = ({
  activeUserID,
  updateActiveUserID,
  isEditMode,
}) => {
  // const [activeUserID, setActiveUserID] = useState();
  const [originalUserList, setOriginalUserList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { userID } = useParams();

  const [searchValue, setSearchValue] = useState("");

  const updateSetValue = (textVal) => {
    setSearchValue(textVal);

    if (textVal === "") {
      // If search box is cleared, show all users and scroll to the active user
      setFilteredList(originalUserList);
      if (activeUserID && originalUserList.length) {
        const activeUser = originalUserList.find(
          (user) => user.id.toString() === activeUserID.toString()
        );
        if (activeUser) {
          setTimeout(() => {
            activeUserRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0); // Delay to ensure the DOM updates
        }
      }
    } else {
      // Filter users based on search query
      const lowerText = textVal.toLowerCase();
      setFilteredList(
        originalUserList.filter(
          (each) =>
            each.first_name.toLowerCase().includes(lowerText) ||
            each.middle_name.toLowerCase().includes(lowerText) ||
            each.last_name.toLowerCase().includes(lowerText)
        )
      );
    }
  };

  // Ref for scrolling to active user
  const activeUserRef = useRef(null);

  // Effect to scroll to the active user when activeUserID or filteredList changes
  useEffect(() => {
    if (activeUserRef.current) {
      activeUserRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [activeUserID, originalUserList]); // Dependency array includes both activeUserID and filteredList

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();

        const BASE_URL = "https://3.8.158.142:8443/";
        const URL = `${BASE_URL}api/user`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setOriginalUserList(data.data);
          setFilteredList(data.data);
          setLoading(false);
          setError(false);

          // converting into string for comparing the IDs, getting in different data types
          const filtData = data.data.filter(
            (each) => each.id.toString() === activeUserID.toString()
          );
          // initially setting the active id and active name
          // updateActiveUserID(
          //   activeUserID,
          //   `${filtData[0].first_name}`
          //   //  `${filtData[0].first_name} ${filtData[0].middle_name} ${filtData[0].last_name}`
          // );
        } else {
          console.log("get all failed");
          setLoading(false);
          setError(true);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
        setError(true);

        // Handle the error appropriately, e.g., show an error message
      }
    };

    fetchUsersAPI();
  }, [isEditMode]);

  if (loading) {
    return <Typography variant="body0">Fetching users ....</Typography>;
  }

  if (error) {
    return <Typography variant="body0">Failed to fetch users</Typography>;
  }

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "15px",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          marginBottom: "10px",
          display: "flex",
          // width: "fit-content",
        }}
      >
        <SearchBox
          searchValue={searchValue}
          updateSetValue={updateSetValue}
          sx={{ paddingLeft: "-15px" }}
        />
      </div>

      <div
        style={{
          overflow: "auto",
          height: "calc(100% - 47px)",
        }}
      >
        {filteredList.map((each) => (
          <div
            key={each.id}
            ref={each.id === activeUserID ? activeUserRef : null}
          >
            <UserNameWithArrow
              onClick={() => {
                updateActiveUserID(
                  each.id,
                  `${each.first_name}`,
                  `${each.first_name} ${each.last_name}`
                );
                setSearchValue("");
                setFilteredList(originalUserList);
                navigate(`/users/user-dashboard/${each.id}`);
              }}
              isActiveUser={activeUserID === each.id}
              eachUser={each}
            />
          </div>
        ))}
      </div>
    </Box>
  );
};

export const ProfilePhotoButton = ({
  buttonText = "",
  onClickButton = () => {},
  imgUrlBeforeHover = "", // url before hove the img
  imgUrlAfterHover = "", // url after hover imge
  styles = {},
  isDisable = false,
  sxIcon = {},
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      disabled={isDisable}
      onClick={onClickButton}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        textTransform: "none",
        color: "#969999",
        display: "flex",
        flexDirection: "column", // Stack the icon and text vertically
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arvo",
        "&:hover": {
          color: "#279989", // apply the hover color of button
        },
        ...styles,
      }}
    >
      {/* change th margin, heights, width, as per requirements in styles of img */}

      <img
        alt="profile icon"
        src={isHover ? imgUrlAfterHover : imgUrlBeforeHover}
        style={{
          width: "20px",
          height: "20px",
          gap: 1,
          opacity: isDisable ? 0.5 : 1,
          ...sxIcon,
        }}
      />

      {buttonText}
    </Button>
  );
};

const ProfilePhotoPopup = ({
  image,
  setImage = () => {},
  onClose = () => {},
  openDialogue = false,
  textShow,
  userData,
  setReload,
  reload,
  fileName,
}) => {
  const [selectedImage, setSelectedImage] = useState(image);
  const [onHoverAddPhoto, setOnHoverAddPhoto] = useState(false);
  const [open, setOpen] = useState(false);

  const [isFileChanged, setIsFileChanged] = useState(false);

  const [imageValidate, setImageValidate] = useState(false);
  const [errorImageText, setErrorImageText] = useState("");
  const [imageName, setImageName] = useState("");

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedImage(image);
    setImageName(fileName);
  }, [image]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (should be less than 5MB)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      // const maxSizeInBytes = 100 * 1024; // 100kb
      if (file.size > maxSizeInBytes) {
        setErrorImageText("File size must be less than 5MB.");
        setImageValidate(true);
        return;
      }

      // Validate file type (should be png or jpeg)
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        setErrorImageText(
          // "Invalid file type. Please upload a PNG or JPEG image."
          "Invalid file type. Upload PNG or JPEG"
        );
        setImageValidate(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        // setImage(reader.result);
        setImageName(file.name);
        setIsFileChanged(true);

        const extension = file.name.split(".").pop();
        const imageFileName = generateImageName(file.name, extension);
        setImageName(imageFileName);
        setErrorImageText(""); // Clear error message on successful upload
        setImageValidate(false); // Set error to inactive
      };
      reader.readAsDataURL(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = null;
  };
  const generateImageName = (originalName, extension) => {
    return `${Date.now()}_${originalName}`;
  };

  const postImage = async () => {
    const userID = userData?.user_id || "";
    // const url = `https://3.8.158.142:8443/api/personal/image/${userID}`;
    const url = `https://3.8.158.142:8443/api/personal/image/${userID}`;

    const token = getCookie();

    const body = {
      filename: imageName || "image",
      base64: selectedImage,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (!response.ok) {
      setImage(null);
      setImageName("");
    }
    setReload(!reload);
  };

  const deleteImage = async () => {
    const userID = userData?.user_id || "";
    // const url = `http://3.8.158.142:3000/api/personal/image/${userID}`;
    const url = `https://3.8.158.142:8443/api/personal/image/${userID}`;

    const token = getCookie();

    const body = {
      filename: imageName || "image",
      base64: selectedImage,
    };
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (response.ok) {
      setImage(null);
      setImageName("");
      setReload(!reload);
    }
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: "#279989",
            fontFamily: "Arvo",
            marginRight: "auto",
          }}
        >
          Profile Photo
        </Typography>
        <ProfilePhotoButton
          buttonText=""
          onClickButton={handleClose}
          imgUrlBeforeHover={ProfilePhotoClose} // apply ProfilePhotoClose svg icon url
          imgUrlAfterHover={ProfilePhotoCloseHover} // apply ProfilePhotoCloseHover svg icon url
          styles={{
            position: "absolute",
            right: "0",
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: 150,
              height: 150,
              borderRadius: "50%",
              background: "#269989",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden", // To ensure the image is clipped to the circle
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <Typography
                variant="h2"
                sx={{
                  fontSize: "44px",
                  fontWeight: 700,
                  color: "white",
                  fontFamily: "Arvo",
                  textAlign: "center",
                }}
              >
                {textShow}
              </Typography>
            )}
          </Box>
        </Grid>
      </DialogContent>

      {errorImageText && (
        <Typography
          component="p"
          sx={{
            color: "red",
            textAlign: "center",
            fontFamily: "Lora",
            fontSize: "12px",
            marginTop: "-9px",
            marginBottom: "10px",
          }}
        >
          {errorImageText}
        </Typography>
      )}
      <Divider
        sx={{
          bgcolor: "#2799894D",
          height: "2px",
        }}
      />
      <DialogActions
        sx={{
          marginBottom: "4px",
          justifyContent: "space-between",
          padding: "0",
        }}
      >
        <Grid container justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {open && (
              <ImageCrop
                open={open}
                setOpen={setOpen}
                selectedImage={selectedImage}
                setImage={setImage}
                setSelectedImage={setSelectedImage}
              />
            )}

            <ProfilePhotoButton
              isDisable={selectedImage ? false : true}
              buttonText="Edit"
              onClickButton={() => setOpen(true)}
              imgUrlBeforeHover={ProfilePhotoEdit} // apply ProfilePhotoEdit svg icon url
              imgUrlAfterHover={ProfilePhotoEditHover} // apply ProfilePhotoEditHover svg icon url
            />
            <Button
              onMouseEnter={() => setOnHoverAddPhoto(true)}
              onMouseLeave={() => setOnHoverAddPhoto(false)}
              component="label" // This allows us to use the button to trigger the file input
              //   startIcon={<AddPhotoAlternateIcon />}
              sx={{
                textTransform: "none",
                color: "#969999",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Arvo",
                "&:hover": {
                  color: "#279989", // change the hover color of button
                },
              }}
            >
              <img
                alt="add icon"
                src={
                  onHoverAddPhoto
                    ? ProfilePhotoAddPhotoHover
                    : ProfilePhotoAddPhoto
                }
                style={{
                  textTransform: "none",
                  color: "#969999",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Arvo",
                  "&:hover": {
                    color: "#279989", // change the hover color of button
                  },
                }}
                // style={{
                //   width: "20px",
                //   height: "20px",
                //   gap: 1,
                //   color:"#279989"
                // }}
              />
              Add Photo
              <input
                type="file"
                // accept="image/*"
                // accept=".jpeg,.jpg,.png" // Restrict to JPEG and PNG formats
                accept="image/jpeg,image/jpg,image/png" // Restrict to JPEG and PNG formats
                onChange={handleImageChange}
                hidden
              />
            </Button>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginRight: "10px" }}
          >
            <ProfilePhotoButton
              buttonText="Save"
              sxIcon={{ height: "16px", width: "16px", marginBottom: "2px" }}
              onClickButton={() => {
                if (selectedImage) {
                  postImage();
                }

                // setImage(selectedImage);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoSave} // apply ProfilePhotoSave svg icon url
              imgUrlAfterHover={ProfilePhotoSaveHover} // apply ProfilePhotoSaveHover svg icon url
            />

            <ProfilePhotoButton
              isDisable={selectedImage ? false : true}
              buttonText="Delete"
              onClickButton={() => {
                deleteImage();
                setSelectedImage(null);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoDelete} // apply ProfilePhotoDelete svg icon url
              imgUrlAfterHover={ProfilePhotoDeleteHover} // apply ProfilePhotoDeleteHover svg icon url
            />
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default ProfilePhotoPopup;

export const UserAvatarForm = ({
  isEditMode,
  activeUserName,
  userData,
  image,
  setImage = () => {},
  fileName,
  setFileName = () => {},
}) => {
  // const [image, setImage] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [reload, setReload] = useState(false);
  // const [fileName, setFileName] = useState("");
  // Function to handle file selection
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getTwoUpperCaseLetters = () => {
    if (activeUserName) {
      let strList = activeUserName.split(" ");

      if (strList.length > 1) {
        return `${strList[0][0]}${strList[1][0]}`.toUpperCase();
      }

      return strList[0][0].toUpperCase();
    }

    return " ";
  };

  useEffect(() => {
    const getImage = async () => {
      const userID = userData.user_id || "";
      const url = `https://3.8.158.142:8443/api/personal/${userID}`;
      // const url = `https://3.8.158.142:8443/api/personal/${userID}`;

      const token = getCookie();

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        // setImage(data.data.profile_photo);
        setImage(data?.personalData?.base64);
        setFileName(data?.personalData?.filename);
      } else {
        setImage(null);
      }
    };
    if (userData?.user_id) {
      getImage();
    }
  }, [userData, reload]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          height: "150px",
          width: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          background: "#269988",
        }}
      >
        {image ? (
          image && (
            <img
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "inherit",
                border: 0,
              }}
              alt="user avatar"
              src={image}
            />
          )
        ) : (
          <Typography
            sx={{
              fontSize: "44px",
              fontWeight: 700,
              color: "white",
              fontFamily: "Arvo",
            }}
          >
            {getTwoUpperCaseLetters()}
          </Typography>
        )}
      </Box>
      {isEditMode === "Edit" && (
        <Button
          variant="outlinedSecondary"
          size="small"
          sx={{
            marginTop: 2,
            position: "relative",
          }}
          onClick={() => setOpenDialogue(true)}
        >
          {/* <input
            type="file"
            id="upload-button"
            accept="image/*"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              background: "red",
              opacity: 0,
              cursor: "pointer",
            }}
            onChange={handleImageUpload}
          /> */}
          Upload Photo
        </Button>
      )}

      {openDialogue && (
        <ProfilePhotoPopup
          openDialogue={openDialogue}
          onClose={() => setOpenDialogue(false)}
          onClickAddPhoto={handleImageUpload}
          textShow={getTwoUpperCaseLetters()}
          image={image}
          setImage={setImage}
          userData={userData}
          setReload={setReload}
          reload={reload}
          fileName={fileName}
        />
      )}
    </div>
  );
};

export const UserAvatarSection = ({
  isEditMode,
  activeUserName,
  userData,
  image,
  setImage,
  fileName,
  setFileName,
}) => {
  return (
    <UserAvatarForm
      activeUserName={activeUserName}
      isEditMode={isEditMode}
      userData={userData}
      image={image}
      setImage={setImage}
      fileName={fileName}
      setFileName={setFileName}
    />
  );
};
