import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import CustomCalendar from "../logs/CustomCalendar";
import Billingicon1 from "../../assets/icons/billing1.svg";
import Billingicon2 from "../../assets/icons/billing2.svg";
import Billingicon3 from "../../assets/icons/billing3.svg";
import useStyles from "../clients/styles/useStyles";
import Download from "./Download";
import { getCookie } from "../../utils/utils";
import { DataGrid } from "@mui/x-data-grid";
const paginationModel = { page: 0, pageSize: 20 };
const data = [
  { text: "Total PT(Plan Time) Pay", icon: Billingicon1, pay: "£ 1,206" },
  { text: "Total AT (Actual Time) Pay", icon: Billingicon2, pay: "£ 1,005.5" },
  { text: "Total Miles Pay", icon: Billingicon3, pay: "£ 500" },
];
const columnsDownload = [
  "Name",
  "Date",
  "Total PT",
  "Total AT ",
  "Hourly Rate",
  "Total PT Pay",
  "Total AT Pay",
  "Miles Drive",
  "TotalMilesPay",
];
const columns = [
  {
    field: "userName",
    headerName: "User Name",
    headerAlign: "center",
    align: "center",
  },
  { field: "date", headerName: "Date", headerAlign: "center", align: "center" },
  {
    field: "totalPlannedTime",
    headerName: "Total PT",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalActualTime",
    headerName: "Total AT",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "hourlyPay",
    headerName: "Hourly Rate",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalPlannedTimePay",
    headerName: "Total PT Pay",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalActualTimePay",
    headerName: "Total AT Pay",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "milesDrive",
    headerName: "Miles Drive",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totalMilesPay",
    headerName: "Total Miles Pay",
    headerAlign: "center",
    align: "center",
  },
];
function UserPayRoll() {
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [billingData, setBillingData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [totalPlanPay, setTotalPlanPay] = useState(0);
  const [totalactualPay, setactualPlanPay] = useState(0);
  const [totalMilesPay, setTotalMilesPay] = useState(0);
  const classes = useStyles();
  const fetchUsersAPI = async (fromDate, toDate) => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/userbilling?fromDate=${fromDate}&toDate=${toDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setBillingData(data?.data);
        // Calculate total planned time pay and actual time pay
       const totalPlanPay = parseFloat(data?.data?.reduce((sum, item) => {
        if (item?.totalPlannedTimePay && item?.totalPlannedTimePay > 0) {
    return sum + item.totalPlannedTimePay;
  }
  return sum;
}, 0).toFixed(2));


        const totalActualPlanPay = data?.data?.reduce((sum, item) => {
          // Only add if the value is a valid number (not empty, null, or 0)
          if (item?.totalActualTimePay && item?.totalActualTimePay > 0) {
            return sum + item?.totalActualTimePay;
          }
          return sum;
        }, 0).toFixed(2);
       
        const totalMiles =  parseFloat(
         data?.data?.reduce((sum, item) => {
          if (item?.totalMilesPay && item?.totalMilesPay > 0) {
            return sum + item?.totalMilesPay;
          }
          return sum;
        }, 0).toFixed(2));
          
        // Update state with the total values
        setTotalPlanPay(totalPlanPay );
        setactualPlanPay(totalActualPlanPay );
        setTotalMilesPay(totalMiles);
      } else {
        console.error("API call failed");
        setBillingData([]);
        setTotalPlanPay(0);
        setactualPlanPay(0);
        setTotalMilesPay(0);
      }
    } catch (e) {
      console.error("Error fetching data:", e.message);
    }
  };
  useEffect(() => {
    const initialStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const initialEndDate = dayjs(endDate).format("YYYY-MM-DD");
    fetchUsersAPI(initialStartDate, initialEndDate);
  }, []);
  useEffect(() => {
    const updatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const updatedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    fetchUsersAPI(updatedStartDate, updatedEndDate);
  }, [startDate, endDate]);
  const finalDate =
    startDate === endDate
      ? dayjs(startDate).format("DD-MMM-YYYY")
      : `${dayjs(startDate).format("DD-MMM-YYYY")} - ${dayjs(endDate).format(
          "DD-MMM-YYYY"
        )}`;
    const rows = billingData?.map((billing) => ({
    id: billing?.userId,
    userName: billing?.userName,
    date: finalDate,
    totalPlannedTime: billing?.totalPlannedTime,
    totalActualTime: billing?.totalActualTime,
    hourlyPay: billing?.hourlyPay,
    totalPlannedTimePay:
    billing?.totalPlannedTimePay && `£ ${billing?.totalPlannedTimePay}`,
    totalActualTimePay:
    billing?.totalActualTimePay && `£ ${billing?.totalActualTimePay}`,
    milesDrive: billing?.milesDrive,
    totalMilesPay: ` £ ${billing?.totalMilesPay}`,
  }));
  const adjustedColumns = columns?.map((col) => {
    const isaddressColumn = col?.field === "userName" || col?.field === "date";
    return {
      ...col,
      flex: isaddressColumn ? 2.5 : 1, // Dynamic width for `userName`
      minWidth: isaddressColumn ? 185 : 110, // Ensure minimum width for each column
      renderCell: (params) => (
        <Box className={classes.gridColumns}>
          {params.value || "-"} {/* Display placeholder if value is empty */}
        </Box>
      ),
    };
  });
  const finalDataToDownLoad = billingData?.filter((item) =>
    rowSelectionModel?.includes(item?.userId)
  );
  console.log("finalDataToDownLoad", finalDataToDownLoad);
  const bodyRows = finalDataToDownLoad?.map((item) => {
    return [
      item?.userName,
      finalDate,
      item?.totalPlannedTime,
      item?.totalActualTime,
      item?.hourlyPay,
      item?.totalPlannedTimePay,
      item?.totalActualTimePay,
      item?.milesDrive,
      item?.totalMilesPay,
    ];
  });
  const finalDataToExcel = finalDataToDownLoad?.map(
    ({
      userName,
      date,
      totalPlannedTime,
      totalActualTime,
      hourlyPay,
      totalPlannedTimePay,
      totalActualTimePay,
      milesDrive,
      totalMilesPay,
    }) => ({
      UserName: userName,
      Date: finalDate,
      TotalPlannedTime: totalPlannedTime,
      TotalActualTIme: totalActualTime,
      HourlyPay: hourlyPay,
      TotalPlannedTimePay: totalPlannedTimePay,
      TotalActualTimePay: totalActualTimePay,
      MilesDrive: milesDrive,
      TotalMilesPay: totalMilesPay,
    })
  );
  return (
    <Paper
      sx={{
        background: "#FFFFFF",
        borderRadius: "8px",
        px: 3,
        py: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1.5,
        }}
      >
        <Box sx={{ width: "33%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Box sx={{ display: "flex", gap: "25px" }}>
          {data?.map((item, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    border: "1.5px solid #279989",
                    borderRadius: "10px",
                    p: 1,
                    height: "48px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={item.icon}
                      alt={item.text}
                      style={{
                        width: "30px",
                        height: "30px",
                        alignSelf: "center",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          padding: "0px",
                          color: "#969999",
                          fontFamily: "Arvo",
                          fontWieght: 500,
                        }}
                      >
                        {item.text}
                      </Typography>
                      <Typography
                        sx={{
                          padding: 0,
                          alignSelf: "end",
                          fontFamily: "Lora",
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: 700,
                        }}
                      >
                        £
                        {item.text === "Total PT(Plan Time) Pay"
                          ? totalPlanPay
                          : item.text === "Total AT (Actual Time) Pay"
                          ? totalactualPay
                          : totalMilesPay}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}
          <Box sx={{ alignSelf: "center" }}>
            <Download
              data={bodyRows}
              columns={columnsDownload}
              selectToDownload={rowSelectionModel}
              heading="User Payroll Billing"
              rotateMode={true}
              excelData={finalDataToExcel}
            />
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.tableMainContainer}
        sx={{ height: "calc(100vh - 240px) !important" }}
      >
        <DataGrid
          rows={rows}
          columns={adjustedColumns}
          getRowId={(row) => row.id}
          initialState={{ pagination: { paginationModel } 
        }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          className={classes.datagridmain}
        />
      </Box>
    </Paper>
  );
}

export default UserPayRoll;
