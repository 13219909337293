import React, { useState, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import { RadioInput, Dropdown, Calendar } from "../../reusableComponents";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import CancelVisit from "../../../assets/images/clients/Visits/cancelvisit.svg";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export default function CancelVisitDialog({
  openDialog,
  clientId,
  visit,
  setRefreshVisits,
  handleCloseDialog = () => {},
}) {
  const initialCancelVisits = {
    authorisation: "",
    invoiceVisit: "",
    payCarer: "",
    cancelOptions: "",
    fromDate: visit.visit_date,
    untilDate: "",
  };
  const [form, setForm] = useState(initialCancelVisits);
  const [startingForm, setStartingForm] = useState(form);
  const currentDate = dayjs().format("YYYY-MM-DD");

  const authorisationRef = useRef();
  const invoiceVisitRef = useRef();
  const payCarerRef = useRef();
  const cancelOptionsRef = useRef();
  const fromDateRef = useRef();
  const untilDateRef = useRef();

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const checkIsValidsGiven = () => {
    if (authorisationRef.current) {
      authorisationRef.current.handleValidation();
    }

    if (payCarerRef.current) {
      payCarerRef.current.handleValidation();
    }

    if (invoiceVisitRef.current) {
      invoiceVisitRef.current.handleValidation();
    }

    if (cancelOptionsRef.current) {
      cancelOptionsRef.current.handleValidation();
    }

    if (
      form.cancelOptions === "All Visits" ||
      form.cancelOptions === "Recurring Visits"
    ) {
      if (fromDateRef.current) {
        fromDateRef.current.handleValidation();
      }

      if (untilDateRef.current) {
        untilDateRef.current.handleValidation();
      }

      return (
        authorisationRef.current.handleValidation() &&
        payCarerRef.current.handleValidation() &&
        invoiceVisitRef.current.handleValidation() &&
        cancelOptionsRef.current.handleValidation() &&
        fromDateRef.current.handleValidation() &&
        untilDateRef.current.handleValidation()
      );
    }

    return (
      authorisationRef.current.handleValidation() &&
      payCarerRef.current.handleValidation() &&
      invoiceVisitRef.current.handleValidation() &&
      cancelOptionsRef.current.handleValidation()
    );
  };

  const postCancelVisitAPI = async () => {
    try {
      const token = getCookie();

      const body = {
        //client_id: clientId,
        shift_cancellation_authorized_by: form.authorisation.text,
        invoice_visit: form.invoiceVisit,
        pay_carer: form.payCarer,
        cancel_options: form.cancelOptions,
        from_visit_date: form.fromDate,
        until_visit_date: form.untilDate,
      };

      console.log(body, "post api body................");
      const BASE_URL = "https://3.8.158.142:8443/";
      const URL1 = `${BASE_URL}api/visits-cancel?visit_details_id=${visit.visit_details_id}`;
      const URL2 = `${BASE_URL}api/visits-cancel?visit_id=${visit.visit_id}`;
      const URL3 = `${BASE_URL}api/visits-cancel?visit_group_id=${visit.visit_group_id}`;
      const URL =
        form.cancelOptions === "All Visits"
          ? URL3
          : form.cancelOptions === "Recurring Visits"
          ? URL2
          : URL1;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
        const min = 1;
        const max = 100;
        const generatedNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        setRefreshVisits(generatedNumber);
        handleCloseDialog();
      } else {
        console.log("Failed to create Visit.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const postCancelVisit = () => {
    if (checkIsValidsGiven() === true) {
      postCancelVisitAPI();
    }
  };

  const handleDropdown = (value) => {
    setForm({ ...form, authorisation: value });
  };

  const handlePayCarer = (value) => {
    setForm({ ...form, payCarer: value });
  };

  const handleInvoiceVisit = (value) => {
    setForm({ ...form, invoiceVisit: value });
  };

  const handleCancelOptions = (value) => {
    setForm({ ...form, cancelOptions: value });
  };

  const handleFromDate = (value) => {
    setForm({ ...form, fromDate: value });
  };

  const handleUntilDate = (value) => {
    setForm({ ...form, untilDate: value });
  };

  const onConfirmFun = () => {
    postCancelVisit();

    //handleCloseDialog();
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "50%", // Set your desired width here
          maxWidth: "700px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={CancelVisit}
              alt="Add Visit"
            />
            <Typography variant="h3">Cancel Visit</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12}>
                <Dropdown
                  ref={authorisationRef}
                  onDropdownSelect={handleDropdown}
                  label="Shift Cancellation Authorized by"
                  list={[
                    { id: "1", text: "Family" },
                    { id: "2", text: "Manager" },
                    { id: "3", text: "Next to Kin" },
                  ]}
                  placeholder="Shift Cancellation Authorized by"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <RadioInput
                  ref={invoiceVisitRef}
                  label="Invoice the Visit"
                  onValueChange={handleInvoiceVisit}
                  name="invoiceVisit"
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <RadioInput
                  ref={payCarerRef}
                  label="Pay the Carer"
                  name="payCarer"
                  onValueChange={handlePayCarer}
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  required
                />
              </Grid>
              <Grid item xs={12} md={8} lg={6}>
                <RadioInput
                  ref={cancelOptionsRef}
                  label="Cancel Options"
                  onValueChange={handleCancelOptions}
                  options={[
                    {
                      value: "This Planned Visit Only",
                      label: "This Planned Visit Only",
                    },
                    {
                      value: "Recurring Visits",
                      label:
                        "All Recurring " +
                        visit.day_name +
                        " " +
                        visit.session_type +
                        " Visits",
                    },
                    {
                      value: "All Visits",
                      label: "All " + visit.session_type + " Visits",
                    },
                  ]}
                  required
                />
              </Grid>
              {(form.cancelOptions === "All Visits" ||
                form.cancelOptions === "Recurring Visits") && (
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <Calendar
                    ref={fromDateRef}
                    label="Cancel Visit Effective From"
                    onDateChange={handleFromDate}
                    preFilledvalue={visit.visit_date}
                    minDate={currentDate}
                  />
                  <Calendar
                    ref={untilDateRef}
                    label="Cancel Visit Effective Until"
                    onDateChange={handleUntilDate}
                    minDate={form.fromDate}
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
