import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  Grid,
  Switch,
  Typography,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import { SettingsSideBar } from "../settings/SettingsSideBar";
import { EditTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ServicePopup from "../../../assets/images/clients/settingspopup.svg";
import Settings from "../../../assets/images/clients/Settings.svg";
import ServiceEditDialog from "./ServiceEditDialog";
import { QRCodeCanvas } from "qrcode.react";
// import { QRCodeSVG } from "qrcode.react";
import TemporaryInactiveDialog from "./TemporaryInactiveDialog";
import TempInactiveCards from "./TempInactiveCards";
import { getCookie } from "../../../utils/utils";
import Admin from "../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import dayjs from "dayjs";
import { Status2 } from "../ClientTable/Components";
import CustomButton from "../../reusableComponents/CustomButton";

const ClientSetting = ({ clientId, clientMainData, setTabId, activeTabID }) => {
  const [isInactive, setIsInactive] = useState(false);
  const [isNotificationEnabled, setNotificationEnabled] = useState(false);
  const [isGeoLocationEnabled, setGeoLocationEnabled] = useState(false);
  const [clientName, setClientName] = useState("");
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [openInactiveDialog, setOpenInactiveDialog] = useState(false);
  const [openQrConfirmation, setOpenQrConfirmation] = useState(false);
  const [qrCodeData, setQRCodeData] = useState("");
  const [tempInactiveData, setTempInactiveData] = useState([]);
  const [selectedInactiveData, setSelectedInactiveData] = useState(null);
  const [serviceData, setServiceData] = useState();
  const [isQrGenertated, setIsQrGenerated] = useState(false);
  const [qrContent, setQrContent] = useState("");

  const companyName = "Care Esteem Ltd";

  // Function to generate random data
  const generateRandomData = () => {
    // Generate a random string (could be changed to any format like random numbers, URLs, etc.)
    const randomString = Math.random().toString(36).substring(2, 15);
    setQRCodeData(randomString);
  };

  const hiddenCanvasRef = useRef(null);

  useEffect(() => {
    console.log("client main DAta", clientMainData);
  }, [clientMainData]);

  const handleDownload = () => {
    const originalCanvas = hiddenCanvasRef.current.querySelector("canvas");
    if (!originalCanvas) {
      console.error("Hidden QR code canvas not found!");
      return;
    }

    const qrWidth = originalCanvas.width;
    const qrHeight = originalCanvas.height;

    const padding = 20; // Left & Right Padding
    const textSpace = 100;
    const topPadding = 20;

    const newCanvas = document.createElement("canvas");
    newCanvas.width = qrWidth + padding * 2;
    newCanvas.height = qrHeight + textSpace + topPadding + 2;

    const ctx = newCanvas.getContext("2d");

    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);

    ctx.drawImage(originalCanvas, padding, topPadding + 35); // Adjusted position

    const name = `${clientMainData?.clientInfo?.first_name} ${clientMainData?.clientInfo?.last_name} `;
    const address = `${clientMainData?.clientInfo?.address}`;
    const company = companyName;
    const fontSize = 20;
    ctx.font = `${fontSize}px Lora`;
    ctx.fillStyle = "#000";
    ctx.textBaseline = "top";

    const nameX = padding;
    const textY = qrHeight + textSpace / 2 + topPadding;

    const addressWidth = ctx.measureText(address).width;
    const addressX = newCanvas.width - padding - addressWidth;

    const companyWidth = ctx.measureText(company).width;
    const companyX = (newCanvas.width - companyWidth) / 2;
    const companyY = topPadding;

    ctx.fillText(company, companyX, companyY);
    ctx.fillText(name, nameX, textY);
    ctx.fillText(address, addressX, textY);

    const pngUrl = newCanvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode_${clientMainData?.clientInfo?.first_name}.png`;
    downloadLink.click();
  };

  const fetchServiceStatus = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-clientservicestatus/${clientId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setServiceData(data.data[0]);
      } else {
        console.log("API request failed with status:", response.status);
        setServiceData();
      }
    } catch (e) {
      console.log("Error fetching data:", e.message);
      setServiceData();
    }
  };

  useEffect(() => {
    fetchServiceStatus();
  }, [openInactiveDialog, openServiceDialog]);

  const fetchTempInactive = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/get-clienttempinactivestatus/${clientId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setTempInactiveData(data.data);
      } else {
        console.log("API request failed with status:", response.status);
        setTempInactiveData([]);
      }
    } catch (e) {
      console.log("Error fetching data:", e.message);
    }
  };

  useEffect(() => {
    fetchTempInactive();
  }, [openInactiveDialog]);

  const handleServiceDialogOpen = () => {
    setOpenServiceDialog(true);
  };
  const handleServiceDialogClose = () => {
    setOpenServiceDialog(false);
  };

  const handleInactiveDialogOpen = () => {
    setOpenInactiveDialog(true);
  };
  const handleInactiveDialogClose = () => {
    setOpenInactiveDialog(false);
  };

  const handleTempInactiveClick = (data) => {
    setSelectedInactiveData(data);
    setOpenInactiveDialog(true);
  };

  useEffect(() => {
    if (clientMainData) {
      setClientName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  const handleNotificationToggle = (event) => {
    setNotificationEnabled(event.target.checked);
  };

  const handleGeoLocationToggle = (event) => {
    setGeoLocationEnabled(event.target.checked);
  };

  const handleQrCloseDialog = () => {
    setOpenQrConfirmation(false);
  };

  const onClickYes = () => {
    //regenerateQrCode();
    putQrDetails();
    setOpenQrConfirmation(false);
  };

  const fetchQrDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/setting-get-qrcode/${clientId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        setQrContent(data.data.qrcode_token);
      } else {
        console.log("API request failed with status:", response.status);
        setQrContent();
      }
    } catch (e) {
      console.log("Error fetching data:", e.message);
      setQrContent();
    }
  };

  useEffect(() => {
    fetchQrDetails();
  }, []);

  const putQrDetails = async () => {
    try {
      const randomHex = generateRandomHex(24);
      const finalQr = `${clientId}${randomHex}`;
      setQrContent(finalQr);
      const URL = `${process.env.REACT_APP_BASE_URL}/setting-generate-qrcode/${clientId}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ qrcode_token: finalQr }),
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        console.log("Edited Successfully");
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateRandomHex = (length) => {
    return [...Array(length)]
      .map(() => Math.floor(Math.random() * 16).toString(16)) // Generate hex values
      .join("")
      .toUpperCase(); // Convert to uppercase
  };

  useEffect(() => {
    console.log("Random QR", qrContent);
  }, [qrContent]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Grid container direction="row" alignItems="top">
        <Box
          sx={{
            padding: 3,
            borderRadius: "10px",
            height: "calc(100vh - 220px)",
            boxShadow: "0px 0px 10px 0px #27998933",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ width: "20%", height: "100%" }}>
              <SettingsSideBar
                clientId={clientId}
                clientMainData={clientMainData}
                setTabId={setTabId}
                activeTabID={activeTabID}
              />
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                width: "80%",
                paddingTop: "15px",
                paddingLeft: "35px",
                paddingRight: "15px",
                position: "relative",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <ClientHeader
                  head={`Settings`}
                  icon={Settings}
                  isHideButton={false}
                />
              </Box>
              <Box>
                {/* Service Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName} Service Start Date / End Date
                      </Typography>
                      <Typography variant="body2">
                        The Service Start Date is when the client's homecare
                        services begin, and the Service End Date is when the
                        services are concluded or the care plan is completed.
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Service Start Date:{" "}
                        {dayjs(serviceData?.serviceStartTime).format(
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "center",
                          gap: "5px",
                          alignItems: "center",
                          color: "#279989",
                          fontWeight: "bold",
                        }}
                      >
                        Client Status:
                        <Status2
                          status={
                            serviceData?.clientStatus === "Active"
                              ? true
                              : false
                          }
                          text={serviceData?.clientStatus}
                        />
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <img
                        src={ServicePopup}
                        alt="ServicePopup"
                        onClick={handleServiceDialogOpen}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* Inactive Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName} Temporary Inactive
                      </Typography>
                      <Typography variant="body2">
                        This status indicates that the homecare services are
                        paused for a period of time due to reasons such as
                        hospitalization, family support, vacation, reassessment
                        of care needs, or any other temporary circumstances.
                        Services are expected to resume once the situation is
                        resolved.
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <img
                        src={ServicePopup}
                        alt="InactivePopup"
                        onClick={handleInactiveDialogOpen}
                        style={{ cursor: "pointer" }}
                      />
                      {/* <Switch
                        checked={isInactive}
                        onChange={handleInactiveDialogOpen}
                        variant="ios" // Apply the custom iOS variant here
                        inputProps={{ "aria-label": "notification toggle" }}
                        color="success"
                      /> */}
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {tempInactiveData.length > 0 &&
                      tempInactiveData.map((data, index) => (
                        <Box
                          key={index}
                          sx={{
                            cursor: "pointer", // Changes cursor to a hand on hover
                          }}
                          onClick={() => handleTempInactiveClick(data)} // Set the selected data to a constant
                        >
                          <TempInactiveCards tempInactiveData={data} />
                        </Box>
                      ))}
                  </Grid>
                </Card>

                {/* Notification Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName}'s Notification
                      </Typography>
                      <Typography variant="body2">
                        Notifications of late or missing visits for Nolan
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isNotificationEnabled}
                        onChange={handleNotificationToggle}
                        variant="ios" // Apply the custom iOS variant here
                        inputProps={{ "aria-label": "notification toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* Geo Location Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                    display: "none",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        Enable Geo Location Check-in and check-out
                      </Typography>
                      <Typography variant="body2">
                        Confirmation of carers location for check-in and
                        check-out to a visit
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isGeoLocationEnabled}
                        onChange={handleGeoLocationToggle}
                        inputProps={{ "aria-label": "geo location toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* QR Code Section */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        QR Code check-in and check-out
                      </Typography>
                      <Typography variant="body2">
                        Check-in and check-out for a visit by scanning the QR
                        code
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "10px 20px",
                        }}
                      >
                        <CustomButton
                          text="Regenerate QR Code"
                          onClick={() => setOpenQrConfirmation(true)}
                        />
                        {/* <Button
                          type="submit"
                          variant="outlinedSecondary"
                          onClick={() => setOpenQrConfirmation(true)}
                          sx={{ height: "33px", fontSize: "13px" }}
                          // onClick={regenerateQrCode}
                        >
                          Regenerate QR Code
                        </Button> */}
                        {/*
                        <Button
                          variant="outlinedSecondary"
                          onClick={handleDownload}
                        >
                          Print QR Code
                        </Button> */}
                        <Box sx={{ marginLeft: "20px" }}>
                          <CustomButton
                            text="Print QR Code"
                            onClick={handleDownload}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {qrContent && (
                          <QRCodeCanvas
                            value={qrContent}
                            size={80}
                            fgColor="#269989"
                            bgColor="transparent"
                          />
                        )}
                        {/* Hidden container for the high-resolution QR Code */}
                        <div
                          ref={hiddenCanvasRef}
                          style={{
                            position: "absolute",
                            left: "-9999px",
                            top: "-9999px",
                            opacity: 0,
                            pointerEvents: "none",
                          }}
                        >
                          {qrContent && (
                            <QRCodeCanvas
                              value={qrContent}
                              size={350} // larger size for a high-res download
                              fgColor="#269989"
                              bgColor="transparent"
                            />
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </div>
          </div>
          {openServiceDialog && (
            <ServiceEditDialog
              openDialog={openServiceDialog}
              handleCloseDialog={handleServiceDialogClose}
              clientId={clientId}
            />
          )}
          {openInactiveDialog && (
            <TemporaryInactiveDialog
              openDialog={openInactiveDialog}
              handleCloseDialog={handleInactiveDialogClose}
              tempSelectedInactiveData={selectedInactiveData}
              setSelectedInactiveData={setSelectedInactiveData}
              clientId={clientId}
            />
          )}
          {openQrConfirmation && (
            <ConfirmationDialog
              openDialog={openQrConfirmation}
              TitleText="Are you sure  you want to regenerate the QR code?"
              paraText=""
              IconUrl={Admin}
              cancelText="Cancel"
              confirmText="Confirm"
              onCancelFun={() => handleQrCloseDialog()}
              onConfirmFun={onClickYes}
              handleCloseDialog={handleQrCloseDialog}
            />
          )}
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default ClientSetting;
