import React, { useState, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import {
  Prefix,
  Name,
  Email,
  ContactNumber,
  Calendar,
  Days,
  Time,
  RadioInput,
  NumberInput,
  Country,
} from "../../reusableComponents";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import AddVisit from "../../../assets/images/clients/Visits/AddVisit.svg";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export default function AddVisitDialog({
  openDialog,
  clientId,
  setRefreshVisits,
  handleCloseDialog = () => {},
}) {
  const initialVisits = {
    startdate: "",
    enddate: "",
    days: [],
    carers: "",
    starthtime: "00",
    startmtime: "00",
    endhtime: "00",
    endmtime: "00",
  };
  const [form, setForm] = useState(initialVisits);
  const [startingForm, setStartingForm] = useState(form);

  const [isClicked, setIsClicked] = useState(false); // Track button click state

  const startdateRef = useRef();
  const daysRef = useRef();
  const enddateRef = useRef();
  const carersRef = useRef();
  const starttimeRef = useRef();
  const endtimeRef = useRef();
  const currentDate = dayjs();
  const formattedDate = dayjs().format("YYYY-MM-DD");
  // Custom handler for closing the dialog

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const checkIsValidsGiven = () => {
    // let errorData = {
    //   prefixError: "",
    //   firstNameError: "",
    //   middleNameError: "",
    //   lastNameError: "",
    //   phoneNumberError: "",
    //   emailError: "",
    //   risklevelError: "",
    //   addressError: "",
    //   cityError: "",
    //   postcodeError: "",
    //   countryError: "",
    // };

    // Call the handleValidation method from the child components
    // prefix validation
    if (startdateRef.current) {
      startdateRef.current.handleValidation();
    }

    // first name validation
    if (enddateRef.current) {
      enddateRef.current.handleValidation();
    }

    // last name validation
    if (daysRef.current) {
      daysRef.current.handleValidation();
    }

    // phone number validation
    if (carersRef.current) {
      carersRef.current.handleValidation();
    }

    // email validation
    if (starttimeRef.current) {
      starttimeRef.current.handleValidation();
    }
    // risklevel validation
    if (endtimeRef.current) {
      endtimeRef.current.handleValidation();
    }

    return (
      startdateRef.current.handleValidation() &&
      enddateRef.current.handleValidation() &&
      daysRef.current.handleValidation() &&
      carersRef.current.handleValidation() &&
      starttimeRef.current.handleValidation() &&
      endtimeRef.current.handleValidation()
    );
  };

  const postVisitAPI = async () => {
    try {
      const token = getCookie();

      const body = {
        client_id: clientId,
        start_date: form.startdate,
        end_date: form.enddate,
        select_days: form.days,
        number_of_carers_required: form.carers,
        start_time: `${form.starthtime}:${form.startmtime}`,
        end_time: `${form.endhtime}:${form.endmtime}`,
      };

      console.log(body, "post api body................");
      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/visits`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
        const min = 1;
        const max = 100;
        const generatedNumber =
          Math.floor(Math.random() * (max - min + 1)) + min;
        setRefreshVisits(generatedNumber);
        handleCloseDialog();
      } else {
        console.log("Failed to create Visit.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onConfirmFun = () => {
    if (!isClicked) {
      if (checkIsValidsGiven() === true) {
        setIsClicked(true);
        postVisitAPI();
      }
    }
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  const handleDateChange = (date, name) => {
    setForm({ ...form, [name]: date });
    //setForm({ ...form, startdate: value });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "50%", // Set your desired width here
          maxWidth: "900px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          disabled={isClicked ? true : false}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={AddVisit}
              alt="Add Visit"
            />
            <Typography variant="h3">Add Visits</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12} md={5.75}>
                <Calendar
                  ref={startdateRef}
                  label="Start Date"
                  onDateChange={(value) =>
                    setForm({ ...form, startdate: value })
                  }
                  name="startdate"
                  minDate={formattedDate}
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Calendar
                  ref={enddateRef}
                  label="End Date"
                  onDateChange={handleDateChange}
                  name="enddate"
                  //preFilledvalue={}
                  minDate={form.startdate}
                  //required
                />
              </Grid>
              <Grid item xs={12} md={12} lg={5.75} sx={{ marginTop: "5px" }}>
                <Days
                  ref={daysRef}
                  //label="Select your preferred days"
                  //initialSelectedDays={["Mon", "Wed"]}
                  onDaysChange={(value) => setForm({ ...form, days: value })}
                  //   selectAllLabel="Select all days"
                  //ref={daysRef} // Attach ref
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={12} lg={5.75}>
                <NumberInput
                  ref={carersRef}
                  label="No.of Carers Required"
                  onValueChange={(value) => setForm({ ...form, carers: value })}
                  maxLength={1}
                  minValue={1}
                  //preFilledvalue={kinInfo.dob}
                  //maxDate={currentDate}
                  required
                  enableSpinner
                />
              </Grid>
              <Grid item xs={12} md={5.75}>
                <Time
                  ref={starttimeRef}
                  label="Start Time"
                  required
                  onHoursChange={(value) =>
                    setForm({ ...form, starthtime: value })
                  }
                  onMinutesChange={(value) =>
                    setForm({ ...form, startmtime: value })
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Time
                  ref={endtimeRef}
                  label="End Time"
                  required
                  minTime={{ hours: form.starthtime, minutes: form.startmtime }}
                  onHoursChange={(value) =>
                    setForm({ ...form, endhtime: value })
                  }
                  onMinutesChange={(value) =>
                    setForm({ ...form, endmtime: value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="customcancel"
            disabled={isClicked ? true : false}
            onClick={onCancelFun}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="customsave"
            disabled={isClicked ? true : false}
            onClick={onConfirmFun}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
