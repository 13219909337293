import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Container,
  Grid,
  Card,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { getCookie } from "../../../utils/utils";
import { EditTheme } from "../../users/Theme";
import { ToastContainer } from "react-toastify";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import UploadDocument from "../../../assets/images/clients/Upload_document.svg";
import Careplan from "../../../assets/images/clients/Careplan.svg";
import ConsentFormLogo from "../../../assets/icons/ConsentFormLogo.svg";
import DownloadIcon from "../../../assets/icons/DownloadIcon.svg";
import OpenForm from "../../../assets/images/clients/Open_Form.svg";
import Activity from "../../../assets/images/clients/Assessments/Activity.svg";
import Environmental from "../../../assets/images/clients/Assessments/Environmental.svg";
import MentalHealth from "../../../assets/images/clients/Assessments/Mental Health.svg";
import View from "../../../assets/images/clients/view_eye.svg";
import Delete from "../../../assets/images/clients/Delete.svg";
import OralCareAssessment from "../../../assets/images/clients/Assessments/OralCareAssessment.svg";
import SleepingAssessment from "../../../assets/images/clients/Assessments/SleepingAssessment.svg";
import ClinicalAssessment from "../../../assets/images/clients/Assessments/ClinicalAssessment.svg";
import BreathingAssessment from "../../../assets/images/clients/Assessments/BreathingAssessment.svg";
import ContinenceAssessment from "../../../assets/images/clients/Assessments/ContinenceAssessment.svg";
import DomesticAssessment from "../../../assets/images/clients/Assessments/DomesticAssessment.svg";
import EquipmentAssessment from "../../../assets/images/clients/Assessments/EquipmentAssessment.svg";
import MovingandHandlingAssessment from "../../../assets/images/clients/Assessments/MovingandHandlingAssessment.svg";
import NutritionandHydrationAssessment from "../../../assets/images/clients/Assessments/NutritionandHydrationAssessment.svg";
import PainAssessment from "../../../assets/images/clients/Assessments/PainAssessment.svg";
import SkinAssessment from "../../../assets/images/clients/Assessments/SkinAssessment.svg";
import ActivityRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import COSHHRiskAssessment from "../../../assets/images/clients/Assessments/COSHHRiskAssessment.svg";
import EquipmentRegister from "../../../assets/images/clients/Assessments/EquipmentRegister.svg";
import FinancialRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import MedicationRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import SelfAdministrationofMedicationRiskAssessment from "../../../assets/images/clients/Assessments/SelfAdministrationofMedicationRiskAssessment.svg";
import CulturalSpiritualSocialandRelationshipsAssessment from "../../../assets/images/clients/Assessments/CulturalSpiritualSocialandRelationshipsAssessment.svg";
import BehavioursThatMayChallengeAssessment from "../../../assets/images/clients/Assessments/BehavioursThatMayChallengeAssessment.svg";
import BehavioursThatMayChallengeRiskAssessment from "../../../assets/images/clients/Assessments/BehavioursThatMayChallengeAssessment.svg";
import AssessmentHeaderLogo from "../../../assets/images/clients/Assessments/AssessmentsHeaderLogo.svg";
import RiskAssessmentHeaderLogo from "../../../assets/images/clients/Assessments/RiskAssessmentsHeaderLogo.svg";
import dayjs from "dayjs";
import Financial from "../../../assets/images/clients/Assessments/Financial.svg";
import Communication from "../../../assets/images/clients/Assessments/Communication.svg";
import Medication from "../../../assets/images/clients/Assessments/Medication.svg";
import PersonalHygiene from "../../../assets/images/clients/Assessments/Personal Hygiene.svg";
import Tooltip from "@mui/material/Tooltip";
import DeleteConfirmationIcon from "../../../assets/images/roster/deleteInvoice.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import ConsentPdfViewer from "./ConsentPdfViewer";
import AddAssessmentPopup from "./AddAssessmentPopup";
// import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";

export default function CarePlanEdit({
  clientId,
  toggleEditMode,
  clientMainData,
  setAssessmentName,
  setAssessmentId,
  // setAssessmentCard,
  setSelectedCard,
  uploadFiles,
  setUploadFiles,
  isDraft,
  signatureName,
  setSignatureName,
  setOpenConsentPdf,
  setSelectedSignatureNameIndex,
  selectedSignatureNameIndex,
  setIsDownload,
}) {
  const assessmentImageMap = {
    MentalHealth: MentalHealth,
    Environmental: Environmental,
    Activity: Activity,
    Financial: Financial,
    Communication: Communication,
    Medication: Medication,
    PersonalHygiene: PersonalHygiene,
    BehavioursThatMayChallengeAssessment: BehavioursThatMayChallengeAssessment,
    OralCareAssessment: OralCareAssessment,
    SleepingAssessment: SleepingAssessment,
    ClinicalAssessment: ClinicalAssessment,
    CulturalSpiritualSocialandRelationshipsAssessment:
      CulturalSpiritualSocialandRelationshipsAssessment,
    BreathingAssessment: BreathingAssessment,
    ContinenceAssessment: ContinenceAssessment,
    DomesticAssessment: DomesticAssessment,
    EquipmentAssessment: EquipmentAssessment,
    MovingandHandlingAssessment: MovingandHandlingAssessment,
    NutritionandHydrationAssessment: NutritionandHydrationAssessment,
    PainAssessment: PainAssessment,
    SkinAssessment: SkinAssessment,
    ActivityRiskAssessment: ActivityRiskAssessment,
    COSHHRiskAssessment: COSHHRiskAssessment,
    EquipmentRegister: EquipmentRegister,
    FinancialRiskAssessment: FinancialRiskAssessment,
    BehavioursThatMayChallengeRiskAssessment:
      BehavioursThatMayChallengeRiskAssessment,
    MedicationRiskAssessment: MedicationRiskAssessment,
    SelfAdministrationofMedicationRiskAssessment:
      SelfAdministrationofMedicationRiskAssessment,

    // Add other mappings here as needed
  };
  const [assessmentCard, setAssessmentCard] = useState([]);
  const [clientName, setClientName] = useState("");
  const [selectedAssessment, setSelectedAssessment] = useState(""); // State for selected dropdown value

  const [openDialog, setOpenDialog] = useState(false);

  const [addAssessmentDialog, setAddAssessmentDialog] = useState(false);
  const [addRiskAssessmentDialog, setAddRiskAssessmentDialog] = useState(false);

  const [fetchRefreshVariable, setFetchRefreshVariable] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (clientMainData) {
      setClientName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  useEffect(() => {
    console.log("assessmentCard in home", assessmentCard);
  }, [assessmentCard]);

  const fetchUsersAPI = async () => {
    try {
      //setAssessmentCard({});
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment/${clientId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        // Map the fetched data to include the appropriate assessment_image
        const assessmentsWithImages = data.data.map((assessment) => ({
          ...assessment,
          assessment_image: assessmentImageMap[assessment.assessment_image], // Use the mapped image or a default
        }));

        console.log("Before seting assessment with image in fetch", data.data);

        setAssessmentCard(assessmentsWithImages);

        // setAssessmentCards(data.data);
      } else {
        console.log("Failed to fetch assessments");
      }
    } catch (error) {
      console.error("Error fetching assessments:", error);
    }
  };

  useEffect(() => {
    fetchUsersAPI();
  }, [fetchRefreshVariable]);

  const handleCloseAddAssessmentDialog = () => {
    setAddAssessmentDialog(false);
  };

  const handleCloseAddRiskAssessmentDialog = () => {
    setAddRiskAssessmentDialog(false);
  };

  // Function to handle setting an assessment to active
  const handleAddAssessment = () => {
    if (selectedAssessment) {
      setAssessmentCard(
        assessmentCard.map((card) =>
          card.assessment_name === selectedAssessment
            ? { ...card, status: "active" } // Set status to 'active'
            : card
        )
      );
      setSelectedAssessment(""); // Reset the dropdown after adding
    }
  };

  // Function to render assessment cards (only active ones)
  const renderAssessmentCard = (card) => {
    return (
      <Tooltip
        title={card.assessment_name}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8], // Adjusts vertical spacing (reduce from default 8px)
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              fontFamily: "Lora",
            },
          },
        }}
      >
        <Box
          key={card.id} // Use unique key for each card
          textAlign="left"
          onClick={() => {
            setAssessmentName(card.assessment_name);
            setAssessmentId(card.assessment_id);
            setSelectedCard(card);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            padding: "10px 20px",
            margin: "10px 0",
            width: "100%",
            height: "80px",
            backgroundColor: "#F0FCFA",
            borderRadius:
              card.status === "Active" ? "10px 0 10px 10px" : "10px",
            marginLeft: "auto",
            transition: "background-color 0.3s, color 0.3s",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#279989",
              "& img": {
                filter: "brightness(0) invert(1)",
              },
              "& .cardText": {
                color: "white",
              },
              "& .statusBox": {
                backgroundColor: "#279989",
                transition: "background-color 0.3s, color 0.3s",
              },
            },
          }}
        >
          {card.status === "Active" && (
            <Box
              className="statusBox"
              sx={{
                flexGrow: 1,
                maxWidth: "70%",
                position: "absolute",
                top: "-23px",
                right: "0px",
                backgroundColor: "#F0FCFA",
                borderRadius: "10px 10px 0px 0px",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              <Typography
                variant="body2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  textAlign: "right",
                  fontSize: "11px",
                  paddingInline: "7px",
                }}
              >
                Last Updated: {dayjs(card.sign_date).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          )}
          <Box sx={{ height: "55px", width: "55px" }}>
            <img
              style={{
                height: "90%",
                width: "50px",
              }}
              src={card.assessment_image}
              alt="Assess Icon"
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "70%",
              marginTop: card.status === "active" ? "18px" : 0,
            }}
          >
            {isDraft && (
              <Box
                sx={{
                  position: "absolute",
                  top: card.status === "active" ? "2px" : "8px",
                  right: card.status === "active" ? "7px" : "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    // background: "blue",
                    padding: "2px",
                    width: "50px",
                    textAlign: "center",
                    borderRadius: "8px",
                    fontWeight: 500,
                    // marginLeft: "20px",
                    // marginTop: "auto",
                    // marginBottom: "6px",
                    fontFamily: "Lora",
                    backgroundColor: "#FFF0E3",
                    border: "1.5px solid #F5891D",
                    color: "#FA8733",
                    // ...styles,
                  }}
                  variant="body1"
                >
                  {isDraft ? "Draft" : ""}
                </Typography>
              </Box>
            )}

            {/* Assessment */}
            <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
              <Typography
                variant="h2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  marginBottom: 0,
                  paddingBottom: 0,
                  fontSize: "15px",
                }}
              >
                {card.assessment_name}
              </Typography>
              {card.status === "Active" && (
                <Typography
                  variant="body2"
                  className="cardText"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                >
                  {card.sign_name}
                </Typography>
              )}
            </Box>

            {/* Position the OpenForm image at the bottom right */}
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "7px",
                height: "auto",
              }}
            >
              <img
                style={{
                  height: "20%",
                  width: "auto",
                }}
                src={OpenForm}
                alt="Form Icon"
              />
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  };

  const renderDocumentCard = (card, index) => {
    return (
      <Box
        key={card.id} // Use unique key for each card
        textAlign="left"
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative", // Relative position to position the OpenForm icon inside
          padding: "10px 20px",
          margin: "10px 0",
          width: "100%",
          height: "90px",
          backgroundColor: "#F0FCFA",
          borderRadius: "10px",
          marginLeft: "auto", // Push the box to the right
          transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
          "&:hover": {
            backgroundColor: "#279989", // Hover background color
            "& img": {
              filter: "brightness(0) invert(1)", // Change icon color to white (invert colors)
            },
            "& .cardText": {
              color: "white", // Change text color to white
            },
          },
        }}
      >
        <Box sx={{ height: "55px", width: "55px" }}>
          <img
            style={{
              height: "100%",
              width: "auto",
            }}
            src={UploadDocument} // Dynamically load the associated image
            alt="Upload Document"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: "70%",
            marginTop: card.status === "active" ? "18px" : 0,
          }}
        >
          {/* Ensure this container doesn't expand too much */}
          {/* Assessment */}
          <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
            <Typography
              variant="h2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              {card.name}
              {/* {card.assessment_name} */}
            </Typography>

            <Typography
              variant="body2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
              }}
            >
              {card.status}
            </Typography>
          </Box>

          {/* Position the OpenForm image at the bottom right */}
          <IconButton
            sx={{
              position: "absolute", // Absolute position inside the box
              bottom: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              height: "auto", // Adjust height and width for positioning
            }}
            onClick={() => {
              const url = URL.createObjectURL(card); // Create a Blob URL for the file
              const link = document.createElement("a"); // Create a temporary <a> element
              link.href = url;
              link.download = card.name; // Set the filename for download
              document.body.appendChild(link); // Append the link to the DOM
              link.click(); // Programmatically click the link to start the download
              document.body.removeChild(link); // Remove the link from the DOM
              URL.revokeObjectURL(url); // Release the Blob URL for memory cleanup
            }}
          >
            <img src={View} alt="Form Icon" />
          </IconButton>
          <IconButton
            sx={{
              position: "absolute", // Absolute position inside the box
              top: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              height: "auto", // Adjust height and width for positioning
            }}
            onClick={() => {
              setUploadFiles((prevState) =>
                prevState.filter((f, ind) => ind !== index)
              );
            }}
          >
            <img src={Delete} alt="Form Icon" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickYesDelete = () => {
    setSignatureName((prevState) => {
      return prevState.filter(
        (item, idx) => idx !== selectedSignatureNameIndex
      );
    });
    setOpenDialog(false);
  };

  const renderConsentCard = (signature, index) => {
    return (
      <Box
        key={signature.consentName} // Use unique key for each card
        textAlign="left"
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative", // Relative position to position the OpenForm icon inside
          padding: "10px 20px",
          margin: "10px 0",
          width: "100%",
          height: "90px",
          backgroundColor: "#F0FCFA",
          borderRadius: "10px",
          marginLeft: "auto", // Push the box to the right
          transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
          "&:hover": {
            backgroundColor: "#279989", // Hover background color
            "& img": {
              filter: "brightness(0) invert(1)", // Change icon color to white (invert colors)
            },
            "& .cardText": {
              color: "white", // Change text color to white
            },
          },
        }}
      >
        <Box sx={{ height: "55px", width: "55px" }}>
          <img
            style={{
              height: "100%",
              width: "auto",
            }}
            src={ConsentFormLogo} // Dynamically load the associated image
            alt="Consent Form"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: "70%",
            // marginTop: card.status === "active" ? "18px" : 0,
            // marginTop: "18px",
          }}
        >
          {/* Ensure this container doesn't expand too much */}
          {/* Assessment */}
          <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
            <Typography
              variant="h2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              {signature.consentName}
              {/* {card.assessment_name} */}
            </Typography>

            <Typography
              variant="body2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
              }}
            >
              {`Updated: ${dayjs(signature.date).format("DD-MM-YYYY")}`}
            </Typography>
          </Box>

          {/* Position the OpenForm image at the bottom right */}
          <Box
            sx={{
              position: "absolute", // Absolute position inside the box
              bottom: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              display: "flex", // Arrange items in a row
              gap: "2px", // Space between the images
              alignItems: "center", // Align items vertically in the center
            }}
          >
            <IconButton
              onClick={() => {
                setOpenConsentPdf(true);
                setSelectedSignatureNameIndex(index);
                setIsDownload(false);
              }}
            >
              <img src={View} alt="Form Icon" />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenConsentPdf(true);
                setSelectedSignatureNameIndex(index);
                setIsDownload(true);
              }}
            >
              {/* <PDFDownloadLink
                document={
                  <ConsentPdfViewer
                    signatureName={signatureName}
                    selectedSignatureNameIndex={index}
                  />
                }
                fileName="ConsentForm.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download now!"
                }
              </PDFDownloadLink> */}
              <img src={DownloadIcon} alt="Form Icon" />
            </IconButton>
          </Box>

          <IconButton
            sx={{
              position: "absolute", // Absolute position inside the box
              top: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              height: "auto", // Adjust height and width for positioning
            }}
            onClick={() => {
              setSelectedSignatureNameIndex(index);

              setOpenDialog(true);
            }}
          >
            <img src={Delete} alt="Form Icon" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        <Box>
          <ClientHeader
            head={`Care Plan`}
            icon={Careplan}
            isHideButton={false}
          />
          <Card
            elevation={0}
            sx={{
              paddingLeft: 0,
              marginLeft: 0,
              marginRight: 0,
              boxShadow: "none",
              "&.MuiPaper-root": {
                boxShadow: "none",
              },
            }}
          >
            <Typography variant="body2" sx={{ textAlign: "left" }}>
              Complete the initial assessment of the client by determining the
              key fundamental principles.
              <br />
              Evaluate the following assessments for {`${clientName}`}
            </Typography>
          </Card>

          {/* Dropdown and Add Button */}
          {/* <Box sx={{ display: "flex", marginTop: "20px" }}>
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel>Select Assessment</InputLabel>
              <Select
                value={selectedAssessment}
                onChange={(e) => setSelectedAssessment(e.target.value)} // Update selected assessment
                label="Select Assessment"
              >
                {assessmentCard
                  .filter((card) => card.status === "") // Show only assessments with empty status
                  .map((card) => (
                    <MenuItem key={card.id} value={card.assessment_name}>
                      {card.assessment_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleAddAssessment}>
              ADD
            </Button>
          </Box> */}

          {/* Render Active Assessment Cards */}
          <Box
            textAlign="left"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              padding: "10px 0px",
              // margin: "10px 0",
              // width: "100%",
              // height: "150%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={
                  {
                    // height: "30%",
                    // width: "auto",
                  }
                }
                src={AssessmentHeaderLogo}
                alt="Assessments"
              />
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Arvo",
                  fontWeight: 400,
                  fontSize: "20px",
                  marginLeft: "-10px",
                }}
              >
                Assessments
              </Typography>
            </Box>
            {(assessmentCard?.filter((card) => card.assessment_id < 21)
              .length ?? 0) > 0 && (
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setAddAssessmentDialog(true)}
                  sx={{
                    marginRight: "10px", // Space between buttons
                    height: "35px",
                  }}
                >
                  + New Assessment
                </Button>
              </Box>
            )}
          </Box>
          <Grid
            container
            direction="row"
            spacing={3}
            sx={{
              height: "auto",
              alignItems: "top",
              marginTop:
                (assessmentCard?.filter((card) => card.assessment_id < 21)
                  .length ?? 0) === 0
                  ? "-10px"
                  : "-5px",
            }}
          >
            {/* <Grid item xs={12}>
              
            </Grid> */}
            {(assessmentCard?.filter((card) => card.assessment_id < 21)
              .length ?? 0) === 0 ? (
              <Grid item xs={12} sx={{ height: "40vh" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "20px 0",
                    backgroundColor: "#F0FCFA", // You can change this color to your liking
                    borderRadius: "10px",
                    border: "2px dashed #AAAAAA",
                    height: "100%",
                  }}
                >
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        sx={{
                          color: "#707171",
                          fontFamily: "Arvo",
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        No assessments are currently added
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setAddAssessmentDialog(true);
                        }}
                        sx={{
                          margin: 0,
                          minHeight: "33px",
                          // height: "auto",
                          // width: "auto",
                        }}
                      >
                        + Add Assessments
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              assessmentCard
                .filter(
                  (card) =>
                    (card.status === "Open" || card.status === "Active") &&
                    card.assessment_id < 21
                )
                .sort((a, b) => {
                  // Sorting by assessment_name in alphabetical order (A-Z)
                  const nameA = a.assessment_name.toLowerCase();
                  const nameB = b.assessment_name.toLowerCase();
                  return nameA.localeCompare(nameB); // Compare strings alphabetically
                })
                .map((card) => (
                  <Grid
                    item
                    key={card.id}
                    xxl={3} // 4 cards per row in XXL
                    lg={4} // 3 cards per row in LG
                    md={6} // 2 cards per row in MD
                    xs={12} // 1 card per row in XS (mobile)
                    sx={{}}
                  >
                    {renderAssessmentCard(card)}
                  </Grid>
                ))
            )}
          </Grid>

          <Box
            textAlign="left"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              padding: "10px 0px",
              margin: "20px 0px 0px 0px",
              // width: "100%",
              // height: "150%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                style={
                  {
                    // height: "30%",
                    // width: "auto",
                  }
                }
                src={RiskAssessmentHeaderLogo}
                alt="Assessments"
              />
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Arvo",
                  fontWeight: 400,
                  fontSize: "20px",
                  marginLeft: "-10px",
                }}
              >
                Risk Assessments
              </Typography>
            </Box>
            {(assessmentCard?.filter((card) => card.assessment_id >= 21)
              .length ?? 0) > 0 && (
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setAddRiskAssessmentDialog(true)}
                  sx={{
                    marginRight: "10px", // Space between buttons
                    height: "35px",
                  }}
                >
                  + New Risk Assessment
                </Button>
              </Box>
            )}
          </Box>
          <Grid
            container
            direction="row"
            spacing={3}
            sx={{
              height: "auto",
              alignItems: "top",
              marginTop:
                (assessmentCard?.filter((card) => card.assessment_id >= 21)
                  .length ?? 0) === 0
                  ? "-10px"
                  : "-5px",
            }}
          >
            {/* <Grid item xs={12}>
              
            </Grid> */}
            {(assessmentCard?.filter((card) => card.assessment_id >= 21)
              .length ?? 0) === 0 ? (
              <Grid item xs={12} sx={{ height: "40vh" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "20px 0",
                    backgroundColor: "#F0FCFA", // You can change this color to your liking
                    borderRadius: "10px",
                    border: "2px dashed #AAAAAA",
                    height: "100%",
                  }}
                >
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        sx={{
                          color: "#707171",
                          fontFamily: "Arvo",
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        No assessments are currently added
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setAddRiskAssessmentDialog(true);
                        }}
                        sx={{
                          margin: 0,
                          minHeight: "33px",
                          // height: "auto",
                          // width: "auto",
                        }}
                      >
                        + Add Risk Assessments
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              assessmentCard
                .filter(
                  (card) =>
                    (card.status === "Open" || card.status === "Active") &&
                    card.assessment_id >= 21
                )
                .sort((a, b) => {
                  // Sorting by assessment_name in alphabetical order (A-Z)
                  const nameA = a.assessment_name.toLowerCase();
                  const nameB = b.assessment_name.toLowerCase();
                  return nameA.localeCompare(nameB); // Compare strings alphabetically
                })
                .map((card) => (
                  <Grid
                    item
                    key={card.id}
                    xxl={3} // 4 cards per row in XXL
                    lg={4} // 3 cards per row in LG
                    md={6} // 2 cards per row in MD
                    xs={12} // 1 card per row in XS (mobile)
                    sx={{}}
                  >
                    {renderAssessmentCard(card)}
                  </Grid>
                ))
            )}
          </Grid>

          <Box
            textAlign="left"
            sx={{
              display: "flex",
              alignItems: "center",

              padding: "10px 0px",
              margin: "20px 0px 0px 0px",
              // width: "100%",
              // height: "150%",
            }}
          >
            <img
              style={
                {
                  // height: "30%",
                  // width: "auto",
                }
              }
              src={UploadDocument}
              alt="Assessments"
            />
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Arvo",
                fontWeight: 400,
                fontSize: "20px",
                marginLeft: "-10px",
              }}
            >
              Uploaded Documents
            </Typography>
          </Box>

          <Grid container direction="row" spacing={3}>
            {uploadFiles.length === 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    // alignItems: "center",
                    // width: "100%",
                    // padding: "20px 0",
                    // backgroundColor: "#F0FCFA", // You can change this color to your liking
                    // borderRadius: "10px",
                    display: "flex",
                    // justifyContent: "flex-start",
                    alignItems: "center",
                    width: "30%",
                    padding: "20px 0",
                    borderRadius: "10px",
                    border: "2px dashed #2799894D",
                  }}
                >
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12}>
                      <img
                        style={{
                          height: "50px",
                          width: "auto",
                        }}
                        src={UploadDocument} // Dynamically load the associated image
                        alt="Upload Document"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        sx={{
                          color: "#279989", // Text color
                          fontFamily: "Arvo",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        No Documents Yet
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              uploadFiles
                .sort((a, b) => {
                  // Sorting by assessment_name in alphabetical order (A-Z)
                  const nameA = a.name.toLowerCase();
                  const nameB = b.name.toLowerCase();
                  return nameA.localeCompare(nameB); // Compare strings alphabetically
                })
                .map((card, index) => (
                  <Grid
                    item
                    key={card.id}
                    xxl={3} // 4 cards per row in XXL
                    lg={4} // 3 cards per row in LG
                    md={6} // 2 cards per row in MD
                    xs={12} // 1 card per row in XS (mobile)
                    sx={{}}
                  >
                    {renderDocumentCard(card, index)}
                  </Grid>
                ))
            )}
          </Grid>
          {openDialog && (
            <ConfirmationDialog
              openDialog={openDialog}
              TitleText={`Are you sure  you want to Delete the ${signatureName[selectedSignatureNameIndex]?.consentName}?`}
              paraText="Deleted consent file cannot be retrieved."
              IconUrl={DeleteConfirmationIcon}
              cancelText="Cancel"
              confirmText="Confirm"
              onCancelFun={() => handleCloseDialog()}
              onConfirmFun={onClickYesDelete}
              handleCloseDialog={handleCloseDialog}
            />
          )}
          {addAssessmentDialog && (
            <AddAssessmentPopup
              open={addAssessmentDialog}
              handleDialog={handleCloseAddAssessmentDialog}
              isRiskAssessment={false}
              clientId={clientId}
              setFetchRefreshVariable={setFetchRefreshVariable}
              assessmentCard={assessmentCard}
            />
          )}
          {addRiskAssessmentDialog && (
            <AddAssessmentPopup
              open={addRiskAssessmentDialog}
              handleDialog={handleCloseAddRiskAssessmentDialog}
              isRiskAssessment={true}
              clientId={clientId}
              setFetchRefreshVariable={setFetchRefreshVariable}
              assessmentCard={assessmentCard}
            />
          )}
          <ToastContainer />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
