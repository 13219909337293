import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import AddMedicationForm from "./AddMedicationForm";
import useStyles from "../styles/useStyles";
import Medications from "../../../assets/images/clients/Medications.svg";
import { getCookie } from "../../../utils/utils";

function AddMedication({
  clientId,
  setActiveTabId,
  setHasUnsavedChanges,
  medicines,
  medicationRoute,
}) {
  // const [medicines , setmedicines] = useState([]);
  // const [medicationroute, setmedicationroute] = useState([]);
  const classes = useStyles();
  // useEffect(()=>{
  //   const fetchUsersAPI = async () => {
  //     try {
  //       const token = getCookie();
  //       const URL = 'https://3.8.158.142:8443/api/medication-nhs-medicine-list';
  //       const options = {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //       const response = await fetch(URL, options);
  //       const data = await response.json();
  //       const transformedData = data.data.map(item => ({
  //         id: item.id,
  //         text: item.nhs_medicine_name
  //        }));
  //        console.log(transformedData,"transformedData converted to my requirement NHS medicine");
  //       setmedicines(transformedData);
  //       console.log()
  //       if (response.ok === true) {
  //        setmedicines(transformedData);
  //       } else {
  //         console.log("get all failed");
  //         setmedicines([]);
  //       }
  //     } catch (e) {
  //       console.log("error", e.message);
  //     }
  //   };
  //   fetchUsersAPI();
  // },[])
  // useEffect(()=>{
  //   const fetchUsersAPI = async () => {
  //     try {
  //       const token = getCookie();
  //       const URL = 'https://3.8.158.142:8443/api/medication-route';
  //       const options = {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //       const response = await fetch(URL, options);
  //       const data = await response.json();
  //       const transformedData = data.data.map(item => ({
  //         id: item.id,
  //         text: item.medication_route_name||'',
  //       }))
  //       console.log(transformedData,"=========transformedData converted to my requirement of routes====");
  //       setmedicationroute(transformedData);
  //       console.log()
  //       if (response.ok === true) {
  //         setmedicationroute(transformedData);
  //       } else {
  //         console.log("get all failed");
  //         setmedicationroute([]);
  //       }
  //     } catch (e) {
  //       console.log("error", e.message);
  //     }
  //   };
  //   fetchUsersAPI();
  // },[])

  return (
    <Grid >
      <Box className={classes.header}>
        <ClientHeader head={"Add New Medication"} icon={Medications} />
      </Box>
      <Box className={classes.content}>
        <AddMedicationForm
          clientId={clientId}
          medicines={medicines}
          medicationroute={medicationRoute}
          setActiveTabId={setActiveTabId}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      </Box>
    </Grid>
  );
}

export default AddMedication;
