import { createTheme } from "@mui/material/styles";
import { border, height, margin, padding } from "@mui/system";

export const ScreenHeights = {
  userTableHeight: "calc(100vh - 190px - 62px)",
  contentContainerHeight: "calc(100vh - 190px)",
};

export const EditTheme = createTheme({
  typography: {
    h2: {
      fontFamily: "Lora",
      fontWeight: 500,
      fontSize: "22px",
      padding: "5px 20px",
      color: "#000000",
    },
    h3: {
      fontFamily: "Arvo",
      fontWeight: 400,
      fontSize: "20px",
      padding: "5px 20px",
      color: "#000000",
    },
    h4: {
      fontFamily: "Arvo",
      fontWeight: 700,
      fontSize: "22px",
      paddingBottom: "10px",
      color: "#000000",
    },
    h5: {
      fontFamily: "Arvo",
      fontWeight: 700,
      fontSize: "18px",
      paddingBottom: "10px",
      color: "#000000",
    },
    h7: {
      fontFamily: "Arvo",
      fontWeight: 700,
      fontSize: "16px",
      color: "#000000",
    },
    h6: {
      fontFamily: "Arvo",
      fontWeight: 400,
      fontSize: "16px",
      color: "#000000",
    },
    // h7: {
    //   fontFamily: "Arvo",
    //   fontWeight: 400,
    //   fontSize: "14px",
    //   color: "#88878A",
    // },
    body0: {
      fontSize: "14px",
      padding: "2px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body1: {
      fontSize: "16px",
      padding: "2px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body2: {
      fontSize: "14px",
      padding: "5px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body3: {
      fontSize: "14px",
      fontFamily: "Lora",
      color: "#000000",
      textAlign: "left",
    },
    body4: {
      fontSize: "14px",
      fontFamily: "Arvo",
      color: "#000000",
      textAlign: "left",
    },
    body6: {
      fontSize: "12px",
      fontFamily: "Arvo",
      color: "#279989",
      textAlign: "left",
    },
    body7: {
      fontSize: "12px",
      fontFamily: "Lora",
      color: "#ffffff",
      textAlign: "left",
    },
    body8: {
      fontSize: "16px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body9: {
      fontSize: "15px",
      padding: "2px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "errmsg" },
          style: {
            fontFamily: "Lora",

            fontSize: "10px",
            color: "red",
            marginTop: "-9px",
            marginBottom: "10px",
            //border:'1.5px solid #2799894d',
            display: "block", // Ensure that the element behaves like a block element
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Remove shadow
          border: "none",
          backgroundColor: "#F0FCFA",
          display: "flex",
          borderRadius: "10px",
          padding: "10px 20px",
          margin: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          textTransform: "none",
          fontFamily: "Arvo",
          fontSize: "16px",
          backgroundColor: "#279989",
          boxShadow: "none",
          color: "white",
          borderRadius: "8px",
          padding: "8px 35px",
          "&:hover": {
            backgroundColor: "#1e7b6c", // Hover color
          },
        },
        outlinedSecondary: {
          textTransform: "none",
          fontFamily: "Arvo",
          fontSize: "16px",
          backgroundColor: "white",
          color: "#279989",
          boxShadow: "none",
          borderRadius: "8px",
          border: "1px solid #279989",
          padding: "5px 14px",
          "&:hover": {
            backgroundColor: "#279989", // Hover color
            color: "#ffffff",
            border: "1px solid #279989",
          },
          "&:disabled": {
            backgroundColor: "#E8E8E8", // Hover color
            color: "#279989",
            border: "1px solid #279989",
          },
        },
        customtab: {
          textTransform: "none",
          fontFamily: "Arvo",
          fontSize: "16px",
          //backgroundColor: "#279989",
          boxShadow: "none",
          //color: "white",
          minWidth: "130px",
          borderRadius: "8px 8px 0px 0px",
          padding: "6px 10px",
          margin: "0px 10px",
          "&:hover": {
            backgroundColor: "#279989", // Hover color
            color: "#fff",
          },
        },
        customcancel: {
          textTransform: "none",
          width: "140px",
          fontFamily: "Arvo",
          fontSize: "16px",
          marginRight: "10px",
          backgroundColor: "#FAE2E2",
          color: "#D84141",
          borderRadius: "8px",
          border: "1px solid #D84141",
          padding: "5px 10px",
          "&:hover": {
            backgroundColor: "#D84141", // Hover color
            color: "#ffffff",
            border: "1px solid #D84141",
          },
        },
        customsave: {
          textTransform: "none",
          width: "140px",
          fontFamily: "Arvo",
          fontSize: "16px",
          marginLeft: "10px",
          backgroundColor: "#F1FFF3",
          color: "#279989",
          borderRadius: "8px",
          border: "1px solid #279989",
          padding: "5px 10px",
          "&:hover": {
            backgroundColor: "#279989", // Hover color
            color: "#ffffff",
            border: "1px solid #279989",
          },
        },
        startIcon: {
          marginRight: "4px", // Reduce the margin between the icon and text
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          border: "none",
          boxShadow: "none",
          margin: "0",
          "& .MuiSvgIcon-root": {
            width: "16px", // Smaller width
            height: "16px", // Smaller height
          },
          "&.Mui-checked": {
            color: "#279989",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          border: "none",
          boxShadow: "none",
          margin: "0",
          "& .MuiSvgIcon-root": {
            width: "16px", // Smaller width
            height: "16px", // Smaller height
            "& path": {
              backgroundColor: "white", // Inner circle color when checked (white)
            },
          },
          "&:hover": {
            backgroundColor: "none", // Adjust hover effect if needed
          },
          "&.Mui-checked": {
            color: "#279989",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          padding: "0px",
          fontSize: "15px", // Custom font size
          fontFamily: "Lora",
          color: "#000000", // Custom font color
          "&.Mui-disabled": {
            color: "#000000",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Arvo",
          fontWeight: 400,
          fontSize: "16px",
          color: "#000000",
          textAlign: "left",
          width: "100%",
          marginTop: "5px",
          "&.Mui-focused": {
            color: "#000", // Ensure the color remains the same when focused
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // General text field styles
          margin: "10px 0", // Adjust margin
          caretColor: "#279989",
          "& .MuiInputBase-root": {
            fontSize: "14px",
            fontFamily: "Lora",
            color: "#000000", // Input text color
            backgroundColor: "#ffffff", // Input background color
            border: "1.5px solid #2799894d",
            borderRadius: "8px",
            margin: 0,
            padding: "0px 0px",
            height: "33px",
            "&.Mui-disabled": {
              backgroundColor: "#DDDDDD",
              "& .MuiInputBase-input": {
                WebkitTextFillColor: "#000000", // Ensure text color stays consistent for disabled field
              },
            },
          },

          "& .MuiSvgIcon-root": {
            color: "#279989 ! important",
          },

          "& .MuiInputBase-input": {
            padding: "10px 10px",
          },
          "& .MuiInputBase-root.Mui-focused": {
            border: "1.5px solid #27998999",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
          "&.multiline-textfield .MuiInputBase-root": {
            height: "auto", // Default height for multiline
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
        },
        switchBase: {
          padding: 0,
          margin: 2,
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: "translateX(16px)", // Thumb position when checked
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: "#269989", // Track color when checked
              opacity: 1,
              border: 0,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#279989",
            border: "6px solid #fff",
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
            backgroundColor: "#BFE1DC",
          },
        },
        thumb: {
          boxSizing: "border-box",
          width: 22,
          height: 22,
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: "#E9E9EA", // Track color when unchecked
          opacity: 1,
          transition: "background-color 500ms ease",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          display: "flex",
          width: "100%",
        },
        clearIndicator: {
          display: "none",
        },
        input: {
          marginRight: "-45px",
        },
        // Style the endAdornment
        endAdornment: {
          margin: 0,
          padding: 0,
          width: "0%", // Takes up the remaining 10% of the width
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        paper: {
          // Style the dropdown menu
          backgroundColor: "#ffffff", // Background color of the dropdown menu
          border: "1.5px solid #27998999", // Border of the dropdown menu
          boxShadow: "none", // Shadow for dropdown menu
          borderRadius: "8px",
          padding: 0,
          margin: 0,
          //width: 500,
          "& .MuiAutocomplete-listbox": {
            maxHeight: "150px",
            fontFamily: "Lora",

            padding: 0,
            "& li.Mui-focused": {
              backgroundColor: "#27998926 ! important",
              color: "#279989",
              cursor: "pointer",
            },

            "& .MuiAutocomplete-option:not(:last-child)": {
              borderBottom: "0.5px solid #2799891A", // Add green divider between options
            },
          },

          "& .MuiAutocomplete-option": {
            // Style individual options
            fontSize: "14px",
            color: "#000000",
            // '&:hover': {
            //   backgroundColor: '#2799891A ! important', // Hover background color
            // },
            // '&.Mui-focused': {
            //   backgroundColor: '#27998926', // Background color when focused
            // },

            // '&.Mui-focusVisible': {
            //   backgroundColor: 'red', // Background color when focused
            // },

            // '& :active': {
            //   backgroundColor: '#279989 ! important', // Selected item background color
            //   color: 'white', // Selected item text color
            // },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#000000", // Text color for the selected item
        },
        root: {
          fontSize: "15px",
          fontFamily: "Lora",
          backgroundColor: "#ffffff", // Select background color
          borderRadius: "8px",
          margin: "4px 0px",
          border: "1.5px solid #2799894D", // Remove the default border
          "&.Mui-focused": {
            border: "1.5px solid #27998999",
          },
          "& .MuiInputBase-input": {
            padding: "5px 10px",
            border: "none",
            Color: "red",
          },

          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the default border
          },
        },
        icon: {
          color: "#279989", // Color of the dropdown arrow icon
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffffff", // Background color of the dropdown menu
          border: "1.5px solid #27998999", // Border of the dropdown menu
          boxShadow: "none", // Shadow for dropdown menu
          borderRadius: "8px",
          overflow: "none",
          "& .MuiList-root": {
            //overflow: "auto",
            maxHeight: "150px",
            padding: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginTop: "0px !important",
          fontSize: "12px", // Adjust font size
          fontFamily: "Lora",
          backgroundColor: "#ffffff",
          color: "#000000",
          margin: "0 8px",
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            bottom: "0px",
            left: "4px", // Add left space to the border
            right: "4px", // Add right space to the border
            height: "1px",
            backgroundColor: "#27998933", // Customize border color
          },
          "&:hover": {
            backgroundColor: "#2799891A ! important", // Background color on hover
            fontWeight: "400  !important",
          },
          "&.Mui-selected": {
            backgroundColor: "#27998926 ! important", // Selected background color
            color: "#279989",
            fontWeight: 700,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "20px", // Border radius for the dialog
          maxWidth: "500px", // Max width of the dialog
          width: "100%", // Ensure dialog takes full width if needed
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: "center", // Center align title
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "20px 10px", // Padding for dialog content
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "black", // Color for dialog text
          marginTop: "10px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center", // Center align actions
          marginBottom: "25px", // Margin for bottom of actions
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        filterForm: {
          padding: "16px",
          gap: "15px",
          "& .MuiInputBase-root": {
            minWidth: "80px !important",
            height: "40px",
            marginTop: "22px",
            display: "flex",
            alignItems: "center",
            padding: "0px",
          },
          "& .MuiFormControl-root.MuiTextField-root": {
            margin: "0px ",
          },
          "& .MuiFormControl-root.MuiTextField-root .MuiInputBase-root": {
            marginTop: "22px",
            height: "40px ",
            minWidth: "80px",
            hover: {
              underline: "none",
            },
          },
          "& .MuiInputBase-root.MuiInput-root::before": {
            borderBottom: "0px !important",
          },
          "& .MuiInputBase-root.MuiInput-root::after": {
            borderBottom: "0px !important",
          },
          "& .MuiSelect-root::before": {
            borderBottom: "0px !important",
          },
          "& .MuiSelect-root::after": {
            borderBottom: "0px !important",
          },
        },

        root: {
          "& .MuiDataGrid-root": {
            padding: "0px !important", // Remove all padding
            margin: "0px !important", // Ensure no margin is applied
            border: "none !important", // Remove the border
          },

          border: "0px solid #ffff",
          padding: "0px !important",
          "& .MuiDataGrid-virtualScroller": {
            padding: "0 !important", // Remove padding inside the virtual scroller
          },
          "& .MuiDataGrid-cell": {
            borderTop: "none", // Removes the border between cells
          },

          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "#EEFEFA",
            border: "none",
          },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#ffffff", // Optional: Default color for odd rows
            border: "none",
          },
          "--DataGrid-containerBackground": "#279989", // Set the CSS variable
          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            fontFamily: "Arvo",
            "& .MuiIconButton-root": {
              color: "#ffffff", // Change icon color in header (sorting arrows)
            },
          },
          "& .MuiDataGrid-columnHeader .MuiIconButton-root": {
            color: "#ffffff !important", // Icon color with !important
          },
          "& .MuiTablePagination-root": {
            // marginTop: "10px",
            backgroundColor: "#E4F6F3", // Change pagination button background color
            borderRadius: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          },
          // Target header checkbox
          "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
            // backgroundColor: "#ffffff", // Set background color when selected
            color: "#ffffff !important",
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
            // backgroundColor: "#ffffff",
            borderRadius: "4px",
            color: "#C0C6C9 !important",
          },
          "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked": {
            color: "#279989!important",
          },
          "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
            // Change styles for body row checkbox
            borderRadius: "9px",
            color: "#C0C6C9 !important",
          },
          "& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel":
            {
              margin: 0, // Remove default margins
            },
          "& .MuiInputBase-root": {
            fontSize: "14px",
            fontFamily: "Lora",
            color: "#000000", // Input text color
            backgroundColor: "#ffffff", // Input background color
            // border: "1.5px solid #2799894d",
            borderRadius: "8px",
            // marginY: '13px',

            padding: "0px 0px",
            // height: "30px",
            "&.Mui-disabled": {
              backgroundColor: "#DDDDDD",
              "& .MuiInputBase-input": {
                WebkitTextFillColor: "#000000", // Ensure text color stays consistent for disabled field
              },
            },
          },
        },
      },
    },
  },
  spacing: 5,
});

export const ViewTheme = createTheme({
  typography: {
    h2: {
      fontFamily: "Arvo",
      fontWeight: 400,
      fontSize: "22px",
      padding: "5px 20px",
      color: "#000000",
    },
    h3: {
      fontFamily: "Arvo",
      fontWeight: 400,
      fontSize: "20px",
      padding: "5px 20px",
      color: "#000000",
    },
    h4: {
      fontFamily: "Arvo",
      fontWeight: 700,
      fontSize: "22px",
      paddingBottom: "10px",
      color: "#000000",
    },
    h5: {
      fontFamily: "Arvo",
      fontWeight: 700,
      fontSize: "18px",
      paddingBottom: "10px",
      color: "#000000",
    },
    h6: {
      fontFamily: "Arvo",
      fontWeight: 400,
      fontSize: "16px",
      color: "#000000",
    },
    h7: {
      fontFamily: "Arvo",
      fontWeight: 400,
      fontSize: "14px",
      color: "#000",
    },
    body0: {
      fontSize: "14px",
      padding: "2px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body1: {
      fontSize: "16px",
      padding: "2px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body2: {
      fontSize: "14px",
      padding: "5px 20px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body3: {
      fontSize: "14px",
      fontFamily: "Lora",
      color: "#000000",
    },
    body4: {
      fontSize: "16px",
      fontFamily: "Lora",
      color: "#000000",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Remove shadow
          border: "none",
          backgroundColor: "#F0FCFA",
          display: "flex",
          borderRadius: "10px",
          padding: "10px 20px",
          margin: "20px",
          height: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          textTransform: "none",
          fontFamily: "Arvo",
          fontSize: "16px",
          backgroundColor: "#279989",
          color: "white",
          boxShadow: "none",
          borderRadius: "8px",
          padding: "8px 35px",
          "&:hover": {
            backgroundColor: "#1e7b6c", // Hover color
          },
        },
        outlinedSecondary: {
          textTransform: "none",
          fontFamily: "Arvo",
          fontSize: "16px",
          backgroundColor: "white",
          color: "#279989",
          boxShadow: "none",
          borderRadius: "8px",
          border: "1px solid #279989",
          padding: "2px 14px",
          "&:hover": {
            backgroundColor: "#279989", // Hover color
            color: "#ffffff",
            border: "1px solid #279989",
          },
        },
        startIcon: {
          marginRight: "5px", // Reduce the margin between the icon and text
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#6F7D8299", // Default color
          border: "none",
          boxShadow: "none",
          margin: "0",
          "& .MuiSvgIcon-root": {
            width: "16px", // Smaller width
            height: "16px", // Smaller height
          },
          "&.Mui-disabled ": {
            "& path": {
              fill: "#6F7D8299", // Inner circle color when checked (white)
            },
          },
          "&.Mui-checked": {
            "& path": {
              fill: "#279989", // Inner circle color when checked (white)
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#6F7D8299", // Default color
          border: "none",
          cursor: "default",
          boxShadow: "none",
          margin: "0",
          "& .MuiSvgIcon-root": {
            width: "16px", // Smaller width
            height: "16px", // Smaller height
          },
          "&.Mui-disabled ": {
            "& path": {
              fill: "none", // Inner circle color when checked (white)
            },
          },
          "&.Mui-checked": {
            "& path": {
              fill: "#40A59799", // Inner circle color when checked (white)
            },
          },
          "&.Mui-checked.Mui-disabled": {
            "& path": {
              fill: "none", // Inner circle color when checked (white)
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          padding: "0px",
          fontSize: "15px", // Custom font size
          fontFamily: "Lora",
          color: "#000000", // Custom font color
          "&.Mui-disabled": {
            color: "#000000",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Arvo",
          fontWeight: 400,
          fontSize: "16px",
          color: "#000000",
          textAlign: "left",
          width: "100%",
          wordWrap: "break-word",
          overflow: "hidden",
          whiteSpace: "normal",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textAlign: "left",
          width: "100%",
          wordWrap: "break-word",
          overflow: "hidden",
          whiteSpace: "normal",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // General text field styles
          margin: "10px 0", // Adjust margin

          borderRadius: "8px",
          "& .MuiInputBase-root": {
            fontSize: "14px",
            fontFamily: "Lora",
            color: "#000000", // Input text color
            backgroundColor: "#ffffff", // Input background color
            border: "1.5px solid #2799894d",
            borderRadius: "8px",
            margin: 0,
            padding: "0px 0px",
            height: "33px",
            "&.Mui-disabled": {
              "& .MuiInputBase-input": {
                WebkitTextFillColor: "#000000", // Ensure text color stays consistent for disabled field
                opacity: 0.8,
              },
            },
          },
          "& .MuiInputBase-input": {
            padding: "10px 10px",
          },

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove the border
            },
          },

          "&.multiline-textfield .MuiInputBase-root": {
            height: "auto", // Default height for multiline
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "20px", // Border radius for the dialog
          maxWidth: "1000px", // Max width of the dialog
          width: "100%", // Ensure dialog takes full width if needed
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: "center", // Center align title
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "20px 10px", // Padding for dialog content
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "black", // Color for dialog text
          marginTop: "10px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center", // Center align actions
          marginBottom: "25px", // Margin for bottom of actions
        },
      },
    },
  },
  spacing: 5,
});

export const DataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-root": {
            padding: "0 !important", // Remove all padding
            margin: "0 !important", // Ensure no margin is applied
            border: "0px solid #ffff", // Remove the border
          },
          "& .MuiDataGrid-virtualScroller": {
            padding: "0 !important", // Remove padding inside the virtual scroller
          },
          "& .MuiDataGrid-main": {
            overflow: "hidden", // Prevent scrolling for the grid
          },
          "& .MuiDataGrid-cell": {
            borderTop: "none", // Removes the border between cells
          },

          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "#EEFEFA",
            border: "none",
          },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#ffffff", // Optional: Default color for odd rows
            border: "none",
          },
          "--DataGrid-containerBackground": "#279989", // Set the CSS variable
          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            fontFamily: "Arvo",
            "& .MuiIconButton-root": {
              color: "#ffffff", // Change icon color in header (sorting arrows)
            },
          },
          "& .MuiDataGrid-columnHeader .MuiIconButton-root": {
            color: "#ffffff !important", // Icon color with !important
          },
          "& .MuiTablePagination-root": {
            // marginTop: "10px",
            backgroundColor: "#E4F6F3", // Change pagination button background color
            borderRadius: "0px",
          },
          // Target header checkbox
          "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
            // backgroundColor: "#ffffff", // Set background color when selected
            color: "#ffffff !important",
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
            // backgroundColor: "#ffffff",
            borderRadius: "4px",
            color: "#C0C6C9 !important",
          },
          "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked": {
            color: "#279989!important",
          },
          "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
            // Change styles for body row checkbox
            borderRadius: "9px",
            color: "#C0C6C9 !important",
          },
          "& .MuiFormControl-root-MuiTextField-root": {
            margin: "0px ",
          },
        },
      },
    },
  },
  spacing: 5,
});
