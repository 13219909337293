import { useState, useEffect, useRef } from "react";
import {
  // Button,
  Typography,
} from "@mui/material";
import EnhancedTable from "./ClientTable/ClientTable";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CustomButtom from "../reusableComponents/CustomButton";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import Input from "@mui/material/Input";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import AccountCircle from "@mui/icons-material/AccountCircle";
import TopContentContainer from "../reusableComponents/TopContentContainer";
import ContentContainer from "../reusableComponents/ContentContainer";
import { getCookie } from "../../utils/utils";
import ClientGridTable from "./ClientTable/ClientGridTable";
import CareboxLogo from "../../assets/images/users/logo.jpg";
import agencyLogo from "../../assets/images/users/agency2.jpg";
import Loader from "../../assets/images/common/logo_animation.gif";
import jsPDF from "jspdf";
import "jspdf-autotable";
const doc = new jsPDF();

const ToggleViewAndGridView = ({ isGridSelected, setIsGridSelected }) => {
  // const [active, setActive] = useState("list");
  const [listHover, setListHover] = useState(false);
  const [gridHover, setGridHover] = useState(false);

  return (
    <Box
      sx={{
        border: "1px solid #279989",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        height: "33px",
        cursor: "pointer",
      }}
    >
      <Box
        onMouseEnter={() => setListHover(true)}
        onMouseLeave={() => setListHover(false)}
        onClick={() => setIsGridSelected(false)}
        style={{
          border: "0px",
          height: "33px",
          width: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px 0px 0px 8px",
          background: !isGridSelected ? "#279989" : "none",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M0 8.57143H2.22222V5.71429H0V8.57143ZM0 14.2857H2.22222V11.4286H0V14.2857ZM0 2.85714H2.22222V0H0V2.85714ZM4.44444 8.57143H20V5.71429H4.44444V8.57143ZM4.44444 14.2857H20V11.4286H4.44444V14.2857ZM4.44444 0V2.85714H20V0H4.44444Z"
            fill={
              !isGridSelected ? "#FFFFFF" : listHover ? "#279989" : "#969999"
            }
          />
          <path
            d="M2.22222 14.2857H0V11.4286H2.22222V14.2857Z"
            fill={
              !isGridSelected ? "#FFFFFF" : listHover ? "#279989" : "#969999"
            }
          />
          <path
            d="M20 14.2857H4.44444V11.4286H20V14.2857Z"
            fill={
              !isGridSelected ? "#FFFFFF" : listHover ? "#279989" : "#969999"
            }
          />
          <path
            d="M20 20H4.44444V17.1429H20V20Z"
            fill={
              !isGridSelected ? "#FFFFFF" : listHover ? "#279989" : "#969999"
            }
          />
          <path
            d="M2.22222 20H0V17.1429H2.22222V20Z"
            fill={
              !isGridSelected ? "#FFFFFF" : listHover ? "#279989" : "#969999"
            }
          />
        </svg>
      </Box>

      <Box
        onMouseEnter={() => setGridHover(true)}
        onMouseLeave={() => setGridHover(false)}
        onClick={() => setIsGridSelected(true)}
        style={{
          border: "0px",
          height: "33px",
          width: "40px",
          // borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0px 8px 8px 0px",
          background: isGridSelected ? "#279989" : "none",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M0 0H5V5H0V0ZM7.5 0H12.5V5H7.5V0ZM15 0H20V5H15V0ZM0 7.5H5V12.5H0V7.5ZM7.5 7.5H12.5V12.5H7.5V7.5ZM15 7.5H20V12.5H15V7.5ZM0 15H5V20H0V15ZM7.5 15H12.5V20H7.5V15ZM15 15H20V20H15V15Z"
            fill={
              isGridSelected ? "#FFFFFF" : gridHover ? "#279989" : "#969999"
            }
          />
        </svg>
      </Box>
    </Box>
  );
};

export default function ClientDashBoard() {
  const [isGridSelected, setIsGridSelected] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedIDS, setSelectedIDS] = useState([]);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const styles = {
    paragraph: {
      color: "#FFF",
      padding: "0px",
      position: "relative",
    },
  };
  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setLoading(true);
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/client`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok === true) {
          setUsersList(data.data);
          setFilteredList(data.data);
          setLoading(false);
        } else {
          console.log("get all failed");
          setUsersList([]);
          setFilteredList([]);
          setLoading(false);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };

    fetchUsersAPI();
  }, []);

  const toggleIDsInSelectedIDS = (id) => {
    if (selectedIDS.includes(id)) {
      setSelectedIDS(selectedIDS.filter((each) => each !== id));
    } else {
      setSelectedIDS([...selectedIDS, id]);
    }
  };

  const toggleAllIDs = (checked) => {
    setSelectedIDS(checked === true ? usersList.map((each) => each.id) : []);
  };
  const exportClientsPdf = () => {
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.setFillColor("f5fcff");
    doc.rect(0, 0, width, height, "F");
    doc.addImage(CareboxLogo, "JPEG", 15, 10, 33, 13);
    doc.addImage(agencyLogo, "JPEG", 170, 10, 20, 20);
    doc.setFontSize(24);
    doc.setFont("Helvetica");
    doc.setTextColor("#279989");
    doc.text(`Clients List`, 75, 25);
    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100);
    const today = new Date().toJSON().slice(0, 10);
    doc.text(`Date: ${today}`, 15, 30);
    const finalClients = filteredList.filter((u) => selectedIDS.includes(u.id));
    var body = [
      ...finalClients.map((d) => [
        `${d.prefix} ${d.first_name} ${d.middle_name} ${d.last_name}`,
        `${d.country_code} ${d.contact_number}`,
        d.postcode,
        "Eduardo",
        d.risk_level,
      ]),
    ];
    const clientColoumns = [
      "Name",
      "Contact",
      "Postcode",
      "Key User",
      "Risk Level",
    ];
    doc.autoTable({
      head: [clientColoumns],
      body: body,
      startY: 33,
      borderRadius: "10px",
      headStyles: {
        fillColor: "#279989",
        fontStyle: "Courier",
      },
      alternateRowStyles: { fillColor: "#e4f6f3" },
      didParseCell: (data) => {
        const ind = data.column.index;
        switch (ind) {
          case 0:
            data.cell.styles.cellWidth = 50;
            data.cell.styles.valign = "top";
            break;
          case 1:
            data.cell.styles.halign = "center";
            break;
          case 2:
            data.cell.styles.halign = "center";
            break;
          case 3:
            data.cell.styles.halign = "center";
            break;
          case 4:
            data.cell.styles.halign = "center";
            if (data.cell.raw === "High") {
              data.cell.styles.textColor = "#F73636";
            }
            if (data.cell.raw === "Moderate") {
              data.cell.styles.textColor = "#F0CA44";
            }
            if (data.cell.raw === "Low") {
              data.cell.styles.textColor = "#0FB583";
            }
            break;
          default:
            break;
        }
      },
    });
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        doc.internal.pageSize.width / 2,
        287,
        { align: "center" }
      );
    }
    doc.save(`clients_${today}.pdf`);
  };
  return (
    <Box>
      <TopContentContainer>
        <Typography
          // component="h1"
          variant="h5"
          sx={{
            color: "#279989",
            padding: "0px",
            // margin: "0px",
            // fontWeight: 700,
          }}
        >
          Clients
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginLeft: "20px",
            flex: 1,
          }}
        >
          <TextField
            onChange={(e) => {
              // lowering the text value
              let value = e.target.value.toLowerCase();
              // checking the text value includes in fname, mname, lname, phone number, email
              const filtData = usersList.filter((each) => {
                return (
                  each.first_name.toLowerCase().includes(value) ||
                  each.middle_name.toLowerCase().includes(value) ||
                  each.last_name.toLowerCase().includes(value) ||
                  each.contact_number.toLowerCase().includes(value) ||
                  each.email.toLowerCase().includes(value)
                );
              });

              setFilteredList(filtData);
            }}
            size="small"
            placeholder="Search"
            sx={{
              width: "60%",
              maxWidth: "250px",
              margin: "0px",
              marginRight: "20px",
              "& .MuiOutlinedInput-root": {
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color: "#279989",
                      width: "20px",
                      marginLeft: "10px",

                      padding: 0,
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* <Typography variant="h6" sx={styles.paragraph}>
            <div
              style={{ margin: "0px 20px" }}
              onClick={() => setIsAdminOpen(!isAdminOpen)}
            >
              <CustomButtom text="Export" disabled={selectedIDS.length <= 0} />
            </div>
            {isAdminOpen === true && selectedIDS.length > 0 ? (
              <TablePopup
                exportClientsPdf={exportClientsPdf}
                onClose={() => setIsAdminOpen(false)}
                clientsList={usersList}
                selectedIDS={selectedIDS}
              />
            ) : null}
          </Typography> */}
          <Link to="/clients/add-client">
            <CustomButtom text="+ Add Client" />
          </Link>

          <div style={{ margin: "0px 20px" }}>
            <ToggleViewAndGridView
              isGridSelected={isGridSelected}
              setIsGridSelected={setIsGridSelected}
            />
          </div>
        </div>
      </TopContentContainer>
      <ContentContainer isTransparentBg={false}>
        {loading ? (
          <>
            <Box
              sx={{
                width: "100%",
                height: "calc(100% - 50px)",
                 backgroundColor: "white",
                // border: "1px solid #dddddd",
                overflowY: "auto",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Loader} alt="Loading..." />
            </Box>
          </>
        ) : (
          <>
            {isGridSelected ? (
              <ClientGridTable
                usersList={filteredList}
                toggleIDsInSelectedIDS={toggleIDsInSelectedIDS}
                toggleAllIDs={toggleAllIDs}
                selectedIDS={selectedIDS}
              />
            ) : (
              <EnhancedTable
                usersList={filteredList}
                toggleIDsInSelectedIDS={toggleIDsInSelectedIDS}
                toggleAllIDs={toggleAllIDs}
                selectedIDS={selectedIDS}
              />
            )}
          </>
        )}
      </ContentContainer>
    </Box>
  );
}

export const TablePopup = ({
  exportClientsPdf,
  // exportClientsExcel,
  clientsList,
  selectedIDS,
  onClose = () => {},
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!popupRef.current.contains(event.target)) onClose();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  const styles = {
    tabWrapper: {
      border: "1px solid #27998966",
      background: "white",
      borderRadius: "8px",
      position: "absolute",
      minWidth: "75px",
      zIndex: 99,
      bottom: "0",
      transform: "translate(-50%, 105%)",
      left: "50%",
      padding: "2px ",
    },
    tab1: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      fontSize: "14px",
      padding: "4px 0px 4px 15px",
      margin: "0px",
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "0px",
        left: "8px", // Add left space to the border
        right: "8px", // Add right space to the border
        height: "0.5px",
        backgroundColor: "rgba(39, 153, 137, 0.2)",
      },
      "&:hover": { color: "#279989" },
    },
    tab2: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      fontSize: "14px",
      padding: "4px 0px 4px 15px",
      margin: "0px",
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "0px",
        left: "8px", // Add left space to the border
        right: "8px", // Add right space to the border
        // height: "0.5px",
        backgroundColor: "rgba(39, 153, 137, 0.2)",
      },
      "&:hover": { color: "#279989" },
    },
  };
  const [dynamicUrl, setDynamicUrl] = useState("");
  const token = getCookie();
  const finalClients = clientsList
    .filter((u) => selectedIDS.includes(u.id))
    .map((u) => u.id);
  const URL = `${process.env.REACT_APP_BASE_URL}/client/export/excel?id=${finalClients}&token=${token}`;

  useEffect(() => {
    const newUrl = URL;
    setDynamicUrl(newUrl);
  }, [URL]);
  const exportClientsExcel = () => {
    if (dynamicUrl) {
      window.open(dynamicUrl, "_blank");
    }
  };
  return (
    <Box ref={popupRef} sx={styles.tabWrapper}>
      <Typography onClick={exportClientsPdf} variant="body0" sx={styles.tab1}>
        PDF
      </Typography>
      <Typography onClick={exportClientsExcel} variant="body0" sx={styles.tab2}>
        Excel
      </Typography>
    </Box>
  );
};
