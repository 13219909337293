import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CustomButtom from "../../reusableComponents/CustomButton";
import TodoTable from "./todoTable/TodoTable";

import { ClientHeader } from "../../clients/clientReusableComponents/components";
import Todos from "../../../assets/images/clients/Todos.svg";
import CreateTodoForm from "./CreateTodoForm";

export const TodoDashBord = ({ clientId, clientMainData }) => {
  const [name, setName] = useState("");

  const [openCreateTodo, setOpenCreateTodo] = useState(false);
  const togoleCreateTodoPopup = () => {
    setOpenCreateTodo(!openCreateTodo);
  };

  useEffect(() => {
    if (clientMainData) {
      setName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        padding: "20px 30px",

        // height: "calc(100vh - 200px)",
        // overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          //paddingTop: "10px",
          //paddingBottom: "15px",
          marginBottom: "19px",
        }}
      >
        <Box sx={{ flex: 1, alignItems: "center" }}>
          <ClientHeader head={`To-Do's`} icon={Todos} isHideButton={true} />
        </Box>
        <Box
          sx={
            {
              //paddingBottom: "20px"
            }
          }
        >
          <CustomButtom
            text=" + Create To-Do"
            onClick={() => togoleCreateTodoPopup()}
          />
        </Box>

        {openCreateTodo && (
          <CreateTodoForm
            togoleCreateTodoPopup={togoleCreateTodoPopup}
            clientId={clientId}
          />
        )}
      </Box>

      <TodoTable
        togoleCreateTodoPopup={togoleCreateTodoPopup}
        clientId={clientId}
      />
    </Box>
  );
};
