import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";

const LogTimeSelector = forwardRef(
  (
    {
      label,
      required = false,
      initialHours = "00",
      initialMinutes = "00",
      onHoursChange,
      onMinutesChange,
    },
    ref
  ) => {
    const [hours, setHours] = useState(initialHours);
    const [minutes, setMinutes] = useState(initialMinutes);
    const [errorMessage, setErrorMessage] = useState("");

    const sanitizeInput = (value) => {
      return value.padStart(2, "0").slice(-2);
    };
    const handleHoursChange = (event) => {
      const value = event.target.value;

      if (value >= 0 && value <= 23 && /^\d*$/.test(value)) {
        const sanitizedValue = sanitizeInput(value);
        setHours(sanitizedValue);
        setErrorMessage(""); // Clear error on valid input
        onHoursChange(sanitizedValue); // Notify parent component about value change
      }
    };

    const handleMinutesChange = (event) => {
      const value = event.target.value;
      if (value >= 0 && value <= 59 && /^\d*$/.test(value)) {
        const sanitizedValue = sanitizeInput(value);
        setMinutes(sanitizedValue);
        setErrorMessage(""); // Clear error on valid input
        onMinutesChange(sanitizedValue); // Notify parent component about value change
      }
    };

    // Validation function
    const handleValidation = () => {
      if (required && hours === "00" && minutes === "00") {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      setErrorMessage(""); // Clear error if validation passes
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
      getTime: () => ({ hours, minutes }), // Function to get the current time
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <Grid container spacing={2} alignItems="Top">
          {/* Hours Section */}
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                value={hours}
                onChange={handleHoursChange}
                inputMode="numeric" // Prevent spinner
                pattern="[0-9]*" // For mobile numeric keyboards
                variant="outlined"
                size="small"
                sx={{
                  width: "30px",
                  //height: "20px",
                  "& .MuiInputBase-input": {
                    padding: "5px",
                  },
                  "& .MuiInputBase-root": {
                    height: "28px",
                    border: "1px solid #279989",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="h6" sx={{ paddingTop: "12px" }}>
              :
            </Typography>
          </Grid>

          {/* Minutes Section */}
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                value={minutes}
                onChange={handleMinutesChange}
                inputMode="numeric" // Prevent spinner
                pattern="[0-9]*" // For mobile numeric keyboards
                variant="outlined"
                size="small"
                sx={{
                  width: "30px",
                  //height: "20px",
                  "& .MuiInputBase-input": {
                    padding: "5px",
                  },
                  "& .MuiInputBase-root": {
                    height: "28px",
                    border: "1px solid #279989",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Error Message */}
        {errorMessage && (
          <Typography variant="errmsg" sx={{ marginTop: "-5px" }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  }
);

export default LogTimeSelector;
