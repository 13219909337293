import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Name,
  Email,
  ContactNumber,
  Country,
  RadioInput,
} from "../../reusableComponents";
import { Container, Grid, Button, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../../src/components/users/Theme";
import { getCookie } from "../../../utils/utils";
import Admin from "../../../assets/images/users/Admin.svg";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Address from "../../reusableComponents/Address";
import City from "../../reusableComponents/City";

export default function MyCareNetworkEdit({
  toggleEditMode,
  clientId,
  sideTabId,
  setHasUnsavedChanges,
  setTabId,
  handleDelete,
  delButton,
}) {
  const initialInfo = useMemo(
    () => ({
      name_organization: "",
      occupation_type: "",
      email: "",
      telephone_codes: 217,
      contact_number: "",
      emergency_telephone_codes: 217,
      emergency_contact_number: "",
      address: "",
      city: "",
      postcode: "",
      country: 217,
      org_lpa_behalf: "",
      country_code: "+44",
      country_name: "United Kingdom",

      emergency_country_code: "+44",
      emergency_country_name: "",
    }),
    []
  );

  const [networkInfo, setNetworkInfo] = useState(initialInfo);
  const [networkInfoOriginal, setNetworkInfoOriginal] = useState(initialInfo);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDelDialog, setOpenDelDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => {
    setOpenDialog(false);
    setHasUnsavedChanges(false);
  };
  const onClickYesDialogBtn = () => {
    toggleEditMode();
    setHasUnsavedChanges(false);
  };

  const handleDelOpen = () => setOpenDelDialog(true);
  const handleDelClose = () => setOpenDelDialog(false);
  const onClickYesDelDialogBtn = () => {
    const deletingDefaultTabsData = defaultTabs.includes(
      networkInfo.occupation_type
    );
    if (deletingDefaultTabsData) {
      const tabData = {
        id: networkInfo.id,
        name_organization: "",
        occupation_type: networkInfo.occupation_type,
        telephone_codes: null,
        contact_number: "",
        emergency_telephone_codes: null,
        emergency_contact_number: "",
        email: "",
        address: "",
        city: "",
        postcode: "",
        country: null,
        org_lpa_behalf: null,
      };
      PutNetwork(tabData);
    } else {
      DelNetwork(networkInfo);
    }
  };

  const showToastMessage = async (msg, response) => {
    if (response) {
      toast.success(msg || "Operation successful!", { position: "top-right" });
    } else {
      toast.error(msg || "Operation failed.", { position: "top-right" });
    }
    toggleEditMode();
  };

  useEffect(() => {
    const fetchNetwork = async () => {
      if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network?client_id=${clientId}&id=${sideTabId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const finalData = data.data;
          const tCode = finalData.telephone_codes;
          const cCode = finalData.country_code;
          const emTCode = finalData.emergency_telephone_codes;
          const emCCode = finalData.emergency_country_code;
          const ccCName = finalData.cc_country_name;
          const ccountry = finalData.country;

          const info = {
            ...finalData,
            telephone_codes: tCode === "" ? 217 : tCode,
            country_code: cCode === "" ? "+44" : cCode,
            emergency_telephone_codes: emTCode === "" ? 217 : emTCode,
            emergency_country_code: emCCode === "" ? "+44" : emCCode,
            country_name: ccCName === "" ? "United Kingdom" : ccCName,
            country: ccountry === "" ? 217 : ccountry,
          };
          delButton(finalData.name_organization !== "");
          setNetworkInfo(info);
          setNetworkInfoOriginal(info);
          setHasUnsavedChanges(false);
        } else {
          setNetworkInfo(initialInfo);
          setNetworkInfoOriginal(initialInfo);
        }
      } catch (e) {
        console.error("error", e.message);
      }
    };
    fetchNetwork();
  }, [sideTabId]);

  const orgNameRef = useRef();
  const occTypeRef = useRef();
  const emailRef = useRef();
  const contactRef = useRef();
  const emContactRef = useRef();
  const lpaRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const postcodeRef = useRef();
  const countryRef = useRef();

  const handleValueChange = (newValue, name) => {
    setNetworkInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: newValue,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };

  const handleCountryCodeSelect = (countrycode) => {
    if (countrycode !== null) {
      setNetworkInfo((prevFormData) => {
        const updatedForm = {
          ...prevFormData,
          telephone_codes: countrycode.id,
          country_code: countrycode.code,
        };
        setHasUnsavedChanges(
          !areObjectsEqual(updatedForm, networkInfoOriginal)
        );
        return updatedForm;
      });
    }
  };
  const handleEmCountryCodeSelect = (countrycode) => {
    if (countrycode !== null) {
      setNetworkInfo((prevFormData) => {
        const updatedForm = {
          ...prevFormData,
          emergency_telephone_codes: countrycode.id,
          emergency_country_code: countrycode.code,
        };
        setHasUnsavedChanges(
          !areObjectsEqual(updatedForm, networkInfoOriginal)
        );
        return updatedForm;
      });
    }
  };
  const onChangeContactNumber = (contactVal) => {
    setNetworkInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        contact_number: contactVal,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };
  const onChangeEmergencyContactNumber = (contactVal) => {
    setNetworkInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        emergency_contact_number: contactVal,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };
  const handleEmailChange = (newValue) => {
    setNetworkInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        email: newValue,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };

  const handleRadioChange = (value) => {
    setNetworkInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        org_lpa_behalf: value,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };
  const handleCountrySelect = (country) => {
    setNetworkInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        country: country?.id,
        country_name: country?.country,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleCancel = () => {
    if (!areObjectsEqual(networkInfo, networkInfoOriginal)) {
      handleOpen();
    } else {
      //setTabId("");
      toggleEditMode();
      setHasUnsavedChanges(false);
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    if (orgNameRef.current && !orgNameRef.current.handleValidation()) {
      isValid = false;
    }
    if (occTypeRef.current && !occTypeRef.current.handleValidation()) {
      isValid = false;
    }
    if (emailRef.current && !emailRef.current.handleValidation()) {
      isValid = false;
    }
    if (contactRef.current && !contactRef.current.handleValidation()) {
      isValid = false;
    }
    if (emContactRef.current && !emContactRef.current.handleValidation()) {
      isValid = false;
    }
    if (addressRef.current && !addressRef.current.handleValidation()) {
      isValid = false;
    }
    if (cityRef.current && !cityRef.current.handleValidation()) {
      isValid = false;
    }
    if (postcodeRef.current && !postcodeRef.current.handleValidation()) {
      isValid = false;
    }
    if (countryRef.current && !countryRef.current.handleValidation()) {
      isValid = false;
    }
    if (lpaRef.current && !lpaRef.current.handleValidation()) {
      isValid = false;
    }
    if (isValid) {
      if (networkInfoOriginal === networkInfo) {
        const msg = "No new changes";
        showToastMessage(msg, false);
      } else {
        setHasUnsavedChanges(false);
        toggleEditMode();
        if (sideTabId === 0) {
          PostNetwork();
        } else {
          PutNetwork(networkInfo);
        }
      }
    } else {
      console.error(
        "Form is invalid. Please correct the errors and try again."
      );
    }
  };

  useEffect(() => {
    if (!handleDelete) {
      return;
    }
    handleDelOpen();
  }, [handleDelete]);

  const PostNetwork = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/care-network`;
      const token = getCookie();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...networkInfo, client_id: clientId }),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        const msg = "Added My Care Network Details";
        showToastMessage(msg, true);
        toggleEditMode();
        const mcnId = data.data[0].id;
        setTabId(mcnId);
      } else {
        const msg2 = "Failed to upload My Care Network details.";
        showToastMessage(msg2, false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const PutNetwork = async (updatedData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/care-network/${clientId}/${updatedData.id}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        const msg = "Updated My Care Network Details";
        showToastMessage(msg, true);
        toggleEditMode();
      } else {
        const msg2 = "Failed to update My Care Network details.";
        showToastMessage(msg2, false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const DelNetwork = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/care-network/${networkInfo.id}`;
      const token = getCookie();
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        const msg = "Deleted My Care Network Details";
        showToastMessage(msg, true);
        toggleEditMode();
        setTabId("");
      } else {
        const msg2 = "Failed to delete My Care Network details.";
        showToastMessage(msg2, false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const defaultTabs = [
    "Next Of Kin",
    "District Nurse",
    "GP Practice",
    "Pharmacist",
    "Therapist",
  ];
  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box
          sx={{
            padding: 0,
            width: { xxl: "50%", lg: "90%", xs: "98%" },
            margin: "15px 20px",
            textAlign: "left",
          }}
        >
          <Grid item xs={6} sx={{ textAlign: "left", marginBottom: "10px" }}>
            <h5>{networkInfo.occupation_type}</h5>
          </Grid>
          <Grid container direction="row" alignItems="Top">
            <Grid item xs={10} md={10}>
              <Name
                ref={orgNameRef}
                label="Name / Organization"
                placeholder="Name / Organization"
                onValueChange={handleValueChange}
                name="name_organization"
                preFilledvalue={networkInfo.name_organization}
                required
              />
            </Grid>
            <Grid item xs={10} md={10}>
              <Name
                ref={occTypeRef}
                disabled={defaultTabs.includes(networkInfo.occupation_type)}
                label="Occupation"
                placeholder="Occupation"
                onValueChange={handleValueChange}
                name="occupation_type"
                preFilledvalue={networkInfo.occupation_type}
                required
              />
            </Grid>
            <Grid item md={10} xs={10}>
              <ContactNumber
                title="Contact Number"
                preFilledvalue={networkInfo.contact_number}
                preFilledcode={networkInfo.country_code}
                onValueChange={onChangeContactNumber}
                onCountrySelect={handleCountryCodeSelect}
                ref={contactRef}
                name="contact_number"
              />
            </Grid>
            <Grid item md={10} xs={10}>
              <ContactNumber
                title="Emergency Contact Number"
                preFilledvalue={networkInfo.emergency_contact_number}
                preFilledcode={networkInfo.emergency_country_code}
                onValueChange={onChangeEmergencyContactNumber}
                onCountrySelect={handleEmCountryCodeSelect}
                ref={emContactRef}
                name="emergency_contact_number"
              />
            </Grid>
            <Grid item md={10} xs={10}>
              <Email
                ref={emailRef}
                preFilledvalue={networkInfo.email}
                onEmailValueChange={handleEmailChange}
                name="email"
              />
            </Grid>
            <Grid item xs={10}>
              <Address
                ref={addressRef}
                label="Address"
                placeholder="Address"
                onValueChange={handleValueChange}
                name="address"
                preFilledvalue={networkInfo.address}
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <City
                ref={cityRef}
                label="City"
                placeholder="City"
                onValueChange={handleValueChange}
                name="city"
                preFilledvalue={networkInfo.city}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={4} md={3}>
              <Name
                ref={postcodeRef}
                label="Postcode"
                placeholder="Postcode"
                onValueChange={handleValueChange}
                name="postcode"
                preFilledvalue={networkInfo.postcode}
                AllCapital
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={10} md={3}>
              <Country
                ref={countryRef}
                onCountrySelect={handleCountrySelect}
                name="country"
                preFilledvalue={networkInfo.country_name}
                required
              />
            </Grid>
            <Grid item xs={10} md={10}>
              <RadioInput
                ref={lpaRef}
                label="Does the Person/Organization have LPA to act on behalf?"
                name="org_lpa_behalf"
                required
                options={[
                  { value: "1", label: "Yes" },
                  { value: "0", label: "No" },
                ]}
                onValueChange={handleRadioChange}
                preFilledvalue={networkInfo.org_lpa_behalf.toString()}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button
                  variant="customcancel"
                  color="primary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="customsave"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
          <ToastContainer />
        </Box>
      </Container>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
      {openDelDialog && (
        <ConfirmationDialog
          openDialog={openDelDialog}
          TitleText="Do you want to remove Network?"
          paraText="My Care Network details will be removed."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleDelClose()}
          onConfirmFun={onClickYesDelDialogBtn}
          handleCloseDialog={handleDelClose}
        />
      )}
    </ThemeProvider>
  );
}
