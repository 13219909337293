import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Card,
  Button,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { Name, RadioInput } from "../../reusableComponents";
import Send_invite from "../../../assets/images/users/Send_Invite.svg";
import Admin from "../../../assets/images/users/Admin.svg";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../Theme";
import { getCookie } from "../../../utils/utils";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";

export default function UserAccessibilityEdit({
  userId,
  toggleEdit,
  setHasUnsavedChanges,
}) {
  const [value, setValue] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAdminDialog, setopenAdminDialog] = useState(false);
  const [info, setInfo] = useState("");
  const [pendingradiovalue, SetPendingRadioValue] = useState("");
  //const [pendingRadioValue, setPendingRadioValue] = useState(""); // State for pending radio value
  //const [PValue, setPValue] = useState(""); // State for pending radio value

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleChange = (value) => {
    //const inputValue = event.target.value;
    setValue(value);
    setHasUnsavedChanges(info.role !== value);
  };

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    SetPendingRadioValue(selectedValue);

    if (radioValue === "yes" && selectedValue === "no") {
      //setPendingRadioValue("no");
      //setRadioValue(selectedValue);
      console.log(selectedValue, radioValue);
      setopenAdminDialog(true); // Open dialog
    } else {
      setRadioValue(selectedValue);
      setHasUnsavedChanges((info.admin === 1 ? "yes" : "no") !== selectedValue);
    }
  };

  const handleCancel = () => {
    if (
      (info.admin === 1 ? "yes" : "no") !== radioValue ||
      info.role !== value
    ) {
      handleOpen();
    } else {
      toggleEdit();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };
  const handleAdminNo = () => {
    setopenAdminDialog(false); // Close the dialog
    setRadioValue("no");
    setHasUnsavedChanges(
      (info.admin === 1 ? "yes" : "no") !== pendingradiovalue
    );
    console.log(radioValue);
    //handleSubmit();
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setopenAdminDialog(false);
  };
  const onClickYesDialogBtn = () => {
    toggleEdit();
    setHasUnsavedChanges(false);
  };
  const roleRef = useRef();

  const handleSubmit = async () => {
    let isValid = true;
    if (roleRef.current && !roleRef.current.handleValidation()) {
      isValid = false;
    }
    if (isValid) {
      PostRole();
      setHasUnsavedChanges(false);
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };

  const PostRole = async () => {
    try {
      console.log("sec", value);
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/user/${userId}`;
      const body = { admin: radioValue === "yes" ? 1 : 0, role: value };
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(URL, options);
      await response.json();
      if (response.ok === true) {
        toggleEdit();
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log("error", e.message);
    }
  };

  useEffect(() => {
    const fetchKin = async () => {
      if (!userId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/user/${userId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setRadioValue(info.admin === 1 ? "yes" : "no");
          setValue(info.role);
          setInfo(info);
          //setInfoOriginal(info);
          setHasUnsavedChanges(false);
        } else {
          setInfo("");
          //setInfoOriginal("");
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchKin();
  }, [userId, setHasUnsavedChanges]);

  const sendInvite = async () => {
    try {
      await console.log("inviting");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box sx={{ padding: 0, width: "98%" }}>
          <Card
            variant="elevated"
            elevation={0}
            sx={{ width: "100%", padding: 0 }}
          >
            <Grid container direction="row" alignItems="Center">
              <Grid item xs={12} md={1} lg={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    marginLeft: "20px",
                    height: "100px",
                    justifyContent: { xs: "center", md: "left" },
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    src={Send_invite}
                    alt="Send invitation email"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                <Box
                  textAlign="left"
                  sx={{ padding: EditTheme.spacing(3), width: "100%" }}
                >
                  <Typography variant="h3">Send Invitation Email</Typography>
                  <Typography variant="body2">
                    The User will receive SMS and an Email encouraging them to
                    download the Mobile Application and Login instructions for
                    both the Mobile & Web Applications.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box
                  sx={{
                    padding: EditTheme.spacing(0, 4),
                    width: "100%",
                    textAlign: { xs: "center", md: "Right" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendInvite}
                  >
                    Send Invite
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
        <Box sx={{ mt: 2, padding: 0, width: "98%" }}>
          <Card
            variant="elevated"
            elevation={0}
            sx={{ width: "100%", padding: 0 }}
          >
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12} md={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    marginLeft: "20px",
                    height: "100px",
                    justifyContent: { xs: "center", md: "left" },
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    src={Admin}
                    alt="Admin"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box
                  textAlign="left"
                  sx={{ padding: EditTheme.spacing(3), width: "100%" }}
                >
                  <FormControl sx={{ padding: "5px 20px", width: "100%" }}>
                    <FormLabel sx={{ fontSize: "20px" }}>Admin</FormLabel>
                    <RadioGroup
                      row
                      sx={{ padding: "0", marginTop: "5px" }}
                      value={radioValue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {radioValue === "yes" && (
                      <Box sx={{ mt: 2 }}>
                        <Grid container direction="column">
                          <Grid item>
                            <Name
                              ref={roleRef}
                              label="Role Name"
                              placeholder="Role Name"
                              onValueChange={handleChange}
                              name="role"
                              required
                              preFilledvalue={value}
                              maxLength={35}
                            />
                          </Grid>
                          <Grid item md={0.5} xs={false} />
                        </Grid>
                      </Box>
                    )}
                    <Grid item>
                      <Box sx={{ mt: 4 }}>
                        <Grid container direction="row" alignItems="Center">
                          <Grid item>
                            <Button
                              variant="customcancel"
                              color="primary"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="customsave"
                              color="primary"
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
      {openAdminDialog && (
        <ConfirmationDialog
          openDialog={openAdminDialog}
          TitleText="Are you sure you want to change the admin status?"
          paraText="This will disable web application access to the user."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={() => handleAdminNo()}
          handleCloseDialog={() => handleClose()}
        />
      )}
    </ThemeProvider>
  );
}
