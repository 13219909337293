import { Box } from "@mui/material";
import { Grid } from "@mui/system";
import React, { useState, useEffect } from "react";
import AuditsAndConsentSidebar from "./AuditsAndConsentSidebar";
import AuditsAndConsentEdit from "./AuditsAndConsentEdit";
import ConsentPdfViewer from "./ConsentPdfViewer";
import ConsentFormView from "./ConsentFormView";
import { auditCardDetails } from "./reusableAuditComponents";
import AuditFormView from "./AuditFormView";

const AuditsAndConsent = ({
  clientId,
  clientMainData,
  consent,
  setConsent,
  auditName,
  setAuditName,
}) => {
  const [signatureName, setSignatureName] = useState([]);
  const [selectedSignatureNameIndex, setSelectedSignatureNameIndex] =
    useState();

  const [selectedAuditCard, setSelectedAuditCard] = useState();

  const [openConsentPdf, setOpenConsentPdf] = useState(false);
  const [isDownload, setIsDownload] = useState(null);
  const [clientName, setClientName] = useState("");

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);
  const signName = `${user.first_name + " " + user.last_name}`;
  const [finalAuditDetails, setFinalAuditDetails] = useState(auditCardDetails);

  useEffect(() => {
    if (clientMainData) {
      setClientName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  useEffect(() => {
    console.log("Final selectedAuditCard", selectedAuditCard);
  }, [selectedAuditCard]);

  const handleCloseConsentPDF = () => {
    setOpenConsentPdf(false);
  };

  return (
    <>
      <Grid container direction="row" alignItems="top">
        <Box
          sx={{
            padding: 3,
            borderRadius: "10px",
            height: "calc(100vh - 220px)",
            boxShadow: "0px 0px 10px 0px #27998933",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ width: "20%", height: "100%" }}>
              <AuditsAndConsentSidebar
                clientMainData={clientMainData}
                setConsent={setConsent}
                setSelectedAuditCard={setSelectedAuditCard}
                setAuditName={setAuditName}
              />
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                width: "80%",
                paddingLeft: "25px",
                paddingRight: "15px",
                position: "relative",
              }}
            >
              {consent ? (
                <ConsentFormView
                  consent={consent}
                  signatureName={signatureName}
                  setSignatureName={setSignatureName}
                  setConsent={setConsent}
                />
              ) : !auditName ? (
                <AuditsAndConsentEdit
                  clientMainData={clientMainData}
                  clientId={clientId}
                  clientName={clientName}
                  signatureName={signatureName}
                  setSignatureName={setSignatureName}
                  setSelectedSignatureNameIndex={setSelectedSignatureNameIndex}
                  setOpenConsentPdf={setOpenConsentPdf}
                  selectedSignatureNameIndex={selectedSignatureNameIndex}
                  setIsDownload={setIsDownload}
                  setSelectedAuditCard={setSelectedAuditCard}
                  setAuditName={setAuditName}
                  setConsent={setConsent}
                  finalAuditDetails={finalAuditDetails}
                  setFinalAuditDetails={setFinalAuditDetails}
                />
              ) : (
                <AuditFormView
                  clientId={clientId}
                  auditName={auditName}
                  selectedAuditCard={selectedAuditCard}
                  setSelectedAuditCard={setSelectedAuditCard}
                  clientName={clientName}
                  setAuditName={setAuditName}
                  finalAuditDetails={finalAuditDetails}
                  signName={signName}
                />
              )}
            </div>
          </div>
        </Box>
      </Grid>
      {openConsentPdf && (
        <ConsentPdfViewer
          open={openConsentPdf}
          onClose={handleCloseConsentPDF}
          signatureName={signatureName}
          selectedSignatureNameIndex={selectedSignatureNameIndex}
          setIsDownload={setIsDownload}
          isDownload={isDownload}
        />
      )}
    </>
  );
};

export default AuditsAndConsent;
