import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledCheckbox from "../../reusableComponents/StyledCheckbox";
import incrementIcon from "../../../assets/images/common/incrementIcon.svg";
import {
  Typography,
  // Box
} from "@mui/material";
import ascDescIcon from "../../../assets/images/common/ascdsc.svg";
import { IMG, TablePopup } from "./Components";

const styles = {
  tableCell: {
    backgroundColor: "#279989",
    zIndex: 1,
  },
  paragraph: {
    color: "#FFF",
    //paddingLeft: "0px",
    fontSize: "17px",
    padding: "0px",
    position: "relative",
  },
};

export default function UserTableHeader({
  toggleAllIDs,
  setOrderByName,
  orderByName,

  isAdminOpen,
  setIAdminOpen,
  orderByAdminList,
  setOrderByAdminList,
  orderByStatusList,
  setOrderByStatusList,
  isStatusOpen,
  setIsStatusOpen,
}) {
  const adminFiltList = [
    // {
    //   id: 1,
    //   text: "Select all",
    //   onChange: () => {
    //     setOrderByAdminList(
    //       orderByAdminList.length > 0 ? [] : ["all", "yes", "no"]
    //     );
    //     setIAdminOpen(false)
    //   },
    //   checked: orderByAdminList.length === 3 ? true : orderByAdminList.includes("yes") && orderByAdminList.includes("no")? true:false ,
    // },
    {
      id: 2,
      text: "Yes",
      onChange: () => {
        // Check if "yes" is already selected
        if (orderByAdminList.includes("yes")) {
          setOrderByAdminList([]); // Uncheck if already selected
        } else {
          setOrderByAdminList(["yes"]); // Select "yes"
        }
        setIAdminOpen(false); // Close the popup after selection
      },
      checked: orderByAdminList.includes("yes"),
    },
    {
      id: 3,
      text: "No",
      onChange: () => {
        // Check if "no" is already selected
        if (orderByAdminList.includes("no")) {
          setOrderByAdminList([]); // Uncheck if already selected
        } else {
          setOrderByAdminList(["no"]); // Select "no"
        }
        setIAdminOpen(false); // Close the popup after selection
      },
      checked: orderByAdminList.includes("no"),
    },
  ];

  const statusFiltList = [
    // {
    //   id: 1,
    //   text: "Select all",
    //   onChange: () => {
    //     setOrderByStatusList(
    //         orderByStatusList.length > 0 ? [] : ["all", "yes", "no"]
    //     );
    //     setIsStatusOpen(false)
    //   },
    //   checked: orderByStatusList.length === 3 ? true : orderByStatusList.includes("yes") && orderByStatusList.includes("no")? true:false,
    // },
    {
      id: 2,
      text: "Active",
      onChange: () => {
        // If "Active" is already selected, uncheck it and close the popup
        if (orderByStatusList.includes("yes")) {
          setOrderByStatusList([]); // Uncheck if already selected
        } else {
          setOrderByStatusList(["yes"]); // Select "Active"
        }
        setIsStatusOpen(false); // Close the popup after selection
      },
      checked: orderByStatusList.includes("yes"),
    },
    {
      id: 3,
      text: "Inactive",
      onChange: () => {
        // If "Inactive" is already selected, uncheck it and close the popup
        if (orderByStatusList.includes("no")) {
          setOrderByStatusList([]); // Uncheck if already selected
        } else {
          setOrderByStatusList(["no"]); // Select "Inactive"
        }
        setIsStatusOpen(false); // Close the popup after selection
      },
      checked: orderByStatusList.includes("no"),
    },
  ];

  return (
    <TableHead
      sx={{
        height: "45px",
        //boxShadow: "0px 4px 10px 0px #27998975",
        borderRadius: "15px",
        backgroundColor: "#279989",
      }}
    >
      <TableRow
        sx={
          {
            //backgroundColor: "#279989",
          }
        }
      >
        {/* <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: "#279989",
            color: "#FFF",
            borderTopLeftRadius: "5px",
            "&:first-of-type": {
              borderTopLeftRadius: "5px",
            },
            "&:last-of-type": {
              borderTopRightRadius: "5px",
            },
            width: "20%",
          }}
        >
          <StyledCheckbox onChange={(e) => toggleAllIDs(e.target.checked)} />
        </TableCell> */}

        <TableCell sx={styles.tableCell}>
          <Typography
            variant="h6"
            sx={{ ...styles.paragraph, paddingLeft: "20px" }}
          >
            Name{" "}
            <span
              style={{
                opacity: orderByName === null ? 0.5 : 1,
              }}
            >
              <IMG
                onClick={() => {
                  if (orderByName === null) {
                    setOrderByName("asc");
                  } else if (orderByName === "asc") {
                    setOrderByName("dec");
                  } else {
                    setOrderByName(null);
                  }
                }}
                isDec={
                  orderByName === null
                    ? false
                    : orderByName === "asc"
                    ? false
                    : true
                }
                imgUrl={ascDescIcon}
              />
            </span>
          </Typography>
        </TableCell>

        <TableCell sx={styles.tableCell}>
          <Typography variant="h6" sx={styles.paragraph}>
            Email
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Contact
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Admin
            <span style={{ marginLeft: "3px" }}>
              <IMG
                onClick={() => setIAdminOpen(!isAdminOpen)}
                isDec={false}
                imgUrl={incrementIcon}
              />
            </span>
            {isAdminOpen === true && (
              <TablePopup
                items={adminFiltList}
                onClose={() => setIAdminOpen(false)}
              />
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Status
            <span style={{ marginLeft: "3px" }}>
              <IMG
                onClick={() => setIsStatusOpen(!isStatusOpen)}
                isDec={false}
                imgUrl={incrementIcon}
              />
            </span>
            {isStatusOpen && (
              <TablePopup
                items={statusFiltList}
                onClose={() => setIsStatusOpen(false)}
              />
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Action
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
