import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  Button,
  Box,
  Typography,
  ThemeProvider,
  TextField,
} from "@mui/material";
import { EditTheme } from "../../../themes/Theme";
import { ClientHeader } from "../clientReusableComponents/components";
import { Name, Calendar, RadioInput, Dropdown } from "../../reusableComponents"; // Adjust the path
import AddIcon from "@mui/icons-material/Add";
import AddSignatureDialog from "./AddSignatureDialog";
import { ReactComponent as ConsentFormTemporaryLogo } from "../../../assets/icons/ConsentFormTemporaryLogo.svg";
import { ViewTheme } from "../../users/Theme";

export default function ConsentFormView({
  consent,
  signatureName,
  setSignatureName,
  setConsent,
}) {
  const [consentIsEdit, setConsentIsEdit] = useState(false);
  const [selectedConsentData, setSelectedConsentData] = useState({});
  const [addSignatureDialog, setAddSignatureDialog] = useState(false);

  const agencyName = "Lifeline Carers PVT LTD";

  const [consentData, setConsentData] = useState([
    {
      consentName: "Consent to Care",
      content: `I understand that as an individual using the services at <Agency Name>, this form provides authorisation for the Care Staff to provide me with the care and support described and outlined in my care plan. 
        
    I have contributed to the development of this plan and I confirm that the assessor from <Agency Name> has explained how I can contact <Agency Name> at any time to discuss my care and support.
      
    <strong>I confirm that I am happy for <Agency Name> to share information that is relevant to my care and support with other professionals involved in my care.</strong>  
      
    <strong>I confirm that the complaints procedure has been explained to me and I have been given information on how to make a complaint.</strong>
      
    Where the individual is unable to gain consent, an authorised representative can sign on their behalf in accordance with the Lasting Power of Attorney or in their best interests after a best interests meeting has taken place.`,
    },
    {
      consentName: "Key Safe Consent",
      content: `I agree that <Agency Name> may hold the keys or the access code to my home, for the purposes of access to carry out regular and authorised visits by Care Workers. I understand that <Agency Name> will take all reasonable steps to ensure the security of my home, and will immediately notify me of any concerns they may have with respect to security.
      
    If I have given my consent for you to share information about me with my family or named others, I understand you may contact them too.
      
    I understand that I must immediately report to the management of <Agency Name> any access to my home using the keys by an employee of <Agency Name> who has not been pre-booked as part of my Care Plan.`,
    },
    {
      consentName: "GDPR Consent",
      content: ` <Agency Name> uses your personal data for a number of different reasons. Personal data is any information that identifies you or, in some cases, information that is about you such as an opinion. It includes your name, email address, postal address, job role, photographs, CCTV, and more sensitive types of information such as medical and health records, your care plan, information about your religious beliefs, origin and race, your sexual orientation, and your political views.
      
    We comply with the law in place in the UK around data protection when we use your personal data, including "UK GDPR" (short for the United Kingdom General Data Protection Regulation) and the Data Protection Act 2018. It allows us to use your personal data for a number of reasons without checking with you that it is ok for us to do so. For example, where we can show that we have legitimate reasons to use your personal data or where we need to use your personal data to provide you with the services you have requested from us, or to meet a legal obligation placed on us.
      
    However, in some situations, we need you to confirm that you are happy for us to use your personal data. Please sign to confirm that you consent to this.`,
    },
    {
      consentName: "Family Member Access to Care Log",
      content: `<strong>Purpose of Consent:</strong> This form seeks consent to allow designated family members or representatives to access care information via the Birdie app. The app provides an overview of care visits, including tasks completed, notes from care professionals, and any relevant updates regarding the wellbeing of the service user.
      
    <strong>Scope of Information Shared:</strong> The following types of information will be accessible
       <ul>
      <li>Care visit schedules</li>
      <li>Details of completed tasks (e.g., personal care, medication administration)</li>
      <li>Notes and observations made by care staff</li>
    </ul>
      
    <strong>Consent Statement:</strong> By signing this form, consent is granted for designated family members or representatives to access care information through the Birdie app. 
      
    <strong>It is confirmed that:</strong> The purpose and scope of the information shared have been explained. Consent can be withdrawn at any time by providing written notice to <Agency Name>. <Agency Name> will take reasonable measures to ensure that access is secure and limited to authorised individuals.
      
    <strong>Withdrawal of Consent:</strong> Should you wish to withdraw consent, please contact <Agency Name> directly.`,
    },
  ]);

  useEffect(() => {
    const selectedConsent = consentData.find(
      (data) => data.consentName === consent
    );
    if (selectedConsent) {
      // Replace '<Agency Name>' with the value of agencyName in the selected consent content
      const updatedContent = selectedConsent.content.replace(
        /<Agency Name>/g,
        agencyName
      );

      // Set the whole object (including consentName and updated content) to selectedConsentData
      setSelectedConsentData({
        ...selectedConsent,
        content: updatedContent, // Update the content with the agencyName replaced
      });
    }
  }, [consent, consentData, agencyName]);

  const handleBackButton = () => {
    setConsent("");
  };

  // useEffect(() => {
  //   console.log("Consent selected", selectedConsentData);
  // }, [selectedConsentData]);

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        {/* <Card> */}
        <ClientHeader head="Consent Form" isHideButton={true} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Align all buttons to the right
            alignItems: "center", // Vertically center the buttons
            position: "absolute",
            top: "16px",
            right: "25px",
            zIndex: 11,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setAddSignatureDialog(true)}
            sx={{
              marginRight: "10px", // Space between buttons
              height: "35px",
            }}
          >
            Add Signature
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBackButton}
            sx={{
              marginRight: "10px",
              height: "35px",
            }}
          >
            Back
          </Button>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={9} xs={8}>
              <Card
                // variant="elevated"
                // elevation={0}
                // sx={{
                //   height: "calc(100vh - 325px)",
                //   paddingRight: 2,
                //   margin: 0,
                //   marginRight: "20px",
                //   backgroundColor: "white",
                // }}
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  margin: 0,
                  backgroundColor: "white",
                  display: "flex", // Use flexbox
                  flexDirection: "column", // Stack children vertically
                  //   padding: 2,
                }}
              >
                {/* Consent Title */}
                <Grid container spacing={1}>
                  <Grid lg={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: 5,
                      }}
                    >
                      <ConsentFormTemporaryLogo />
                    </Box>
                  </Grid>
                  <Grid lg={12} xs={12}>
                    <Typography
                      variant="h2"
                      sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                    >
                      {agencyName}
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{
                        textAlign: "center",
                        fontSize: 15,
                        fontWeight: 700,
                      }}
                    >
                      {selectedConsentData.consentName}
                    </Typography>
                  </Grid>
                  <Grid lg={12} xs={12} marginTop={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: "pre-line",
                        textAlign: "left",
                        fontSize: "14px",
                        lineHeight: "20px",
                        "& ul": {
                          marginBottom: "-25px",
                        },
                        "& li": {
                          marginBottom: "-10px", // Add margin to the list items if needed
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: selectedConsentData.content,
                      }}
                    >
                      {/* {selectedConsentData.content} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  margin: 0,
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Consent Forms
                  </Box>
                  <Box sx={{ paddingBlock: "15px" }}>
                    {/* {inActiveAssessment.length > 0 &&
                    inActiveAssessment.some(
                      (assessment) => assessment.sign_1 || assessment.client_id
                    )
                      ? inActiveAssessment.map((inactive, index) => (
                          <HistoricalAssessmentCard
                            index={index} // Use a unique key
                            inactive={inactive} // Pass assessment data
                            inActiveAssessment={inActiveAssessment} // Pass assessment data
                            assessmentQuestions={assessmentQuestions}
                            assessmentName={selectedCard.assessment_name}
                            selectedCard={selectedCard}
                            assessmentId={selectedCard.assessment_id}
                            user={user}
                            name={name}
                          />
                        ))
                      : null} */}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {addSignatureDialog && (
          <AddSignatureDialog
            open={addSignatureDialog}
            onClose={() => setAddSignatureDialog(false)}
            signatureName={signatureName}
            setSignatureName={setSignatureName}
            setConsent={setConsent}
            selectedConsentData={selectedConsentData}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}
