import React, { useState } from "react"; // , { useState }
import { Box, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../Theme";
// import { getCookie } from "../../../utils/utils";
import UserPersonalEdit from "./UserPersonalEdit";
import UserPersonal from "./UserPersonal";
import {
  // TopSection,
  // TabButton,
  // UserListBox,
  UserAvatarSection,
} from "./Components";

export default function UserPersonalView({
  activeUserID,
  isEditMode,
  activeFullName,
  updateModeToView,
  setHasUnsavedChanges,
  userData,
}) {
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState(null);

  return (
    <ThemeProvider theme={EditTheme}>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Grid
          // sx={{ marginTop: "-20px", padding: "0px" }}
          container
          direction="row"
          //alignItems="center"
        >
          <Grid item xs={9} sx={{}}>
            <Box
              sx={{
                height: "calc(100vh - 305px)",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  overflow: "auto",
                  padding: "15px 20px",
                  paddingLeft: "35px",
                }}
              >
                {isEditMode === "View" ? (
                  <UserPersonal activeUserID={activeUserID} />
                ) : (
                  <UserPersonalEdit
                    activeUserID={activeUserID}
                    updateModeToView={updateModeToView}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    userData={userData}
                    image={image}
                    fileName={fileName}
                  />
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{}}>
            <Box
              elevation={0}
              sx={{
                height: "calc(100vh - 305px)",
                marginBottom: 0,
                background: "none",
                paddingTop: "30px",
                overflow: "auto",
                //position: "relative",
              }}
            >
              {/* <hr
                style={{
                  borderLeft: "1.5px solid green",
                  // position:"absolute",
                  // top:"0",
                  // left:"0%",
                  // width:"10px",
                  // height:"100px"
                }}
              /> */}
              {
                <UserAvatarSection
                  activeUserName={activeFullName}
                  isEditMode={isEditMode}
                  userData={userData}
                  image={image}
                  setImage={setImage}
                  fileName={fileName}
                  setFileName={setFileName}
                />
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
